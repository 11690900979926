/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Formik, Field } from 'formik';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Avatar,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Input,
  Box,
} from '@chakra-ui/react';
import {
  SearchIcon,
} from '@chakra-ui/icons';
import styled from 'styled-components';
import { UserFriends } from '@styled-icons/fa-solid/UserFriends';
import { InsertInvitation } from '@styled-icons/material/InsertInvitation';
import EmptyFamily from './EmptyFamily';
import SendInvites from '../../sections/SendInvites';

const StyledTab = styled(Tab)`
  margin: 0;
  &:focus {
    outline:0 !important;
    box-shadow: none;
    border: none;
    border-color: #FFF !important;
  }
`;
const InviteButton = styled(InsertInvitation)`
  color: ##1890ff;
  width: 36px;
`;
const FriendButton = styled(UserFriends)`
  color: ##1890ff;
  width: 36px;
`;
const useStyles = createUseStyles({
  pageView: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
  friendCard: {
    position: 'relative',
    padding: '24px',
    height: '320px',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 4px 16px 0px',
  },
  searchResult: {
    paddingY: '2rem',
    paddingX: '16px',
    marginTop: '16px',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  friendCardButtonsContainer: {
    position: 'absolute',
    bottom: '24px',
  },
});
const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      videoId: PropTypes.string,
    }),
  }).isRequired,
};
// TODO: Loading page
function FamiliesPage({
  history,
}) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [profile, setProfile] = useState();
  const [connectionsFetched, setConnectionsFetched] = useState(false);
  const [connections, setConnections] = useState([]);
  const [foundUser, setFoundUser] = useState();
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [areThereConnections, setAreThereConnections] = useState(false);
  const [retrievedInvites, setRetrievedInvites] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  useEffect(() => {
    // console.log("running")
    if (user.userData) {
      axios({
        method: 'POST',
        url: '/api/users/getProfile',
        data: {
          username: user.userData.username,
        },
      })
        .then((data) => {
          setProfile(data.data);
          // console.log(data.data._id)
          axios.get(`/api/connection/all/${data.data._id}`).then((response) => {
            // console.log(response.data.connections);
            setConnectionsFetched(true);
            setConnections(response.data.connections);
            if (response.data.connections.friends.length > 0) {
              setAreThereConnections(true);
            } else {
              setAreThereConnections(false);
            }
            axios({
              method: 'POST',
              url: '/api/invites/get-invite-list',
              data: {
                userId: user.userData._id,
              },
            })
              .then((inviteData) => {
                // console.log(inviteData.data.length);
                if (areThereConnections === false) {
                  if (inviteData.data.length > 0) {
                    setInviteList(inviteData.data);
                    setAreThereConnections(true);
                  } else {
                    setInviteList([]);
                    setAreThereConnections(false);
                  }
                }
              })
              .catch((err) => {
                // console.log(err);
                setFormErrorMessage(err);
              });
          });
        })
        .catch((err) => {
          setFormErrorMessage('Cannot fetch user profile');
        });
    }
  }, [user.userData, user]);

  const showConnections = () => {
    setAreThereConnections(true);
  };
  const updateInviteList = (newList) => {
    setInviteList(newList);
  };
  const sendFriendRequest = (profileTo) => {
    const body = {
      profileFrom: profile._id,
      profileTo: profileTo._id,
    };
    axios.post('/api/connection/request', body).then(() => {
      // console.log("SENT FRIEND REQUEST");
      const updatedConnections = { ...connections };
      updatedConnections.requested.push(profileTo);
      setConnections(updatedConnections);
      alert('Your Friend request has been sent!');
    });
  };

  const acceptFriendRequest = (profileId) => {
    const body = {
      profileFrom: profile._id,
      profileTo: profileId,
    };
    axios.post('/api/connection/accept', body).then(() => {
      // console.log("ACCEPTED FRIEND REQUEST");
      const updatedConnections = { ...connections };
      const newFriend = updatedConnections.pending.find(
        (conn) => conn._id === profileId,
      );
      updatedConnections.pending = updatedConnections.pending.filter(
        (conn) => conn._id !== profileId,
      );
      updatedConnections.friends.push(newFriend);
      setConnections(updatedConnections);
    });
  };

  const rejectFriendRequest = (profileId) => {
    const body = {
      profileFrom: profile._id,
      profileTo: profileId,
    };
    axios.post('/api/connection/reject', body).then(() => {
      // console.log("REJECTED FRIEND REQUEST");
      const updatedConnections = { ...connections };
      updatedConnections.pending = updatedConnections.pending.filter(
        (conn) => conn._id !== profileId,
      );
      setConnections(updatedConnections);
    });
  };
  const cancelFriendRequest = (profileId) => {
    const body = {
      profileFrom: profile._id,
      profileTo: profileId,
    };
    axios.post('/api/connection/reject', body).then(() => {
      // console.log("REJECTED FRIEND REQUEST");
      const updatedConnections = { ...connections };
      updatedConnections.requested = updatedConnections.requested.filter(
        (conn) => conn._id !== profileId,
      );
      setConnections(updatedConnections);
    });
  };
  const removeFriend = (profileId) => {
    const body = {
      profileFrom: profile._id,
      profileTo: profileId,
    };
    axios.post('/api/connection/reject', body).then(() => {
      // console.log("REMOVED FRIEND");
      const updatedConnections = { ...connections };
      updatedConnections.friends = updatedConnections.friends.filter(
        (conn) => conn._id !== profileId,
      );
      setConnections(updatedConnections);
    });
  };

  const renderSearchResult = () => (
    <Flex
      paddingY="2rem"
      paddingX="16px"
      className={classes.profileCard}
      width={{ base: '94%', md: '460px' }}
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack isInline>
        <Box
          as="img"
          crossOrigin="anonymous"
          src={foundUser.image}
          width="50px"
          height="50px"
          objectFit="cover"
          borderRadius="50%"
          verticalAlign="middle"
        />
        <Stack spacing={1}>
          <Text fontWeight="bold" m="0">{foundUser.username}</Text>
          <Text m="0">{foundUser.email}</Text>
        </Stack>
      </Stack>
      <Button
        onClick={() => sendFriendRequest(foundUser)}
        isDisabled={
          foundUser._id === profile._id
          || connections.friends.some(
            (conn) => conn._id === foundUser._id,
          )
          || connections.requested.some(
            (conn) => conn._id === foundUser._id,
          )
        }
        isLoading={false}
        loadingText="Submitting..."
        display="block"
        width="160px"
        color="white"
        borderRadius="24px"
        bg="#FF1A75"
        _hover={{
          bg: '#FFE6EE',
          color: '#FF1A75',
        }}
        marginTop={{ base: '1rem', md: '0' }}
      >
        Connect
      </Button>
    </Flex>
  );

  const renderSearchSection = () => (
    <Formik
      margin="auto"
      initialValues={{
        query: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(async () => {
          try {
            const res = await axios.post('/api/users/find-profile', { query: values.query });
            if (res.data.success) {
              setFoundUser(res.data.profile);
            }
          } catch (err) {
            // console.log(err);
            const errMsg = err?.response?.status === 404
              ? 'No user with this username or email address was found'
              : 'Something went wrong';
            setFormErrorMessage(errMsg);
            setFoundUser(undefined);
            setTimeout(() => {
              setFormErrorMessage('');
            }, 3000);
          }
          setSubmitting(false);
        }, 500);
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <>
            <Box onSubmit={handleSubmit} width={{ base: '100%', md: '352px' }} margin="auto">
              <Field name="query">
                {() => (
                  <FormControl mb="4px" margin="auto">
                    <FormLabel htmlFor="query" marginLeft="4px" fontWeight="600" fontSize={{ base: '1em', md: '1.3em' }}>
                      Email address or username
                    </FormLabel>
                    <Stack isInline align="center" margin="auto">
                      <Input
                        id="query"
                        placeholder="Search"
                        type="text"
                        value={values.query}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        width={{ base: '100%', sm: '352px', md: '352px' }}
                      />
                      <IconButton
                        type="submit"
                        icon={<SearchIcon />}
                        isLoading={isSubmitting}
                        onClick={handleSubmit}
                        ml="6px"
                        color="white"
                        borderRadius="24px"
                        bg="#FF1A75"
                        _hover={{
                          bg: '#FFE6EE',
                          color: '#FF1A75',
                        }}
                      />
                    </Stack>
                    <div className={classes.formErrorMessage}>
                      {touched.query && errors.query}
                    </div>
                  </FormControl>
                )}
              </Field>
              {formErrorMessage && (
                <label>
                  <p
                    style={{
                      color: '#ff0000bf',
                      fontSize: '0.7rem',
                      border: '1px solid',
                      padding: '1rem',
                      borderRadius: '10px',
                    }}
                  >
                    {formErrorMessage}
                  </p>
                </label>
              )}
            </Box>
            {foundUser && renderSearchResult()}
          </>
        );
      }}
    </Formik>
  );
  const renderSentRequestsCards = () => connections.requested.map((friend) => (
    <Flex
      key={friend._id}
      className={classes.friendCard}
      flexDirection="column"
      alignItems="center"
      width={{ base: '100%', xl: '260px' }}
    >
      <Box
        as="img"
        crossOrigin="anonymous"
        src={friend.image}
        width="100px"
        height="100px"
        objectFit="cover"
        borderRadius="50%"
        verticalAlign="middle"
        arginBottom="8px"
      />
      <Text fontSize="lg" fontWeight="bold" m="0">{friend.username}</Text>
      <Text m="0">{friend.email}</Text>
      <Stack className={classes.friendCardButtonsContainer} isInline>
        <Button
          onClick={() => cancelFriendRequest(friend._id)}
          isLoading={false}
          variant="outline"
          display="block"
          width="110px"
          borderRadius="24px"
          color="#FF1A75"
          borderColor="#FF1A75"
          _hover={{
            bg: '#FFE6EE',
            color: '#FF1A75',
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  ));
  const renderPendingRequestsCards = () => connections.pending.map((friend) => (
    <Flex key={friend._id} className={classes.friendCard} flexDirection="column" alignItems="center" width={{ base: '100%', xl: '260px' }}>
      <Box
        as="img"
        crossOrigin="anonymous"
        src={friend.image}
        width="100px"
        height="100px"
        objectFit="cover"
        borderRadius="50%"
        verticalAlign="middle"
        arginBottom="8px"
      />
      <Text fontSize="lg" fontWeight="bold" m="0">{friend.username}</Text>
      <Text m="0">{friend.email}</Text>
      <Stack className={classes.friendCardButtonsContainer} isInline>
        <Button
          onClick={() => acceptFriendRequest(friend._id)}
          display="block"
          width="110px"
          color="white"
          borderRadius="24px"
          bg="#FF1A75"
          _hover={{
            bg: '#FFE6EE',
            color: '#FF1A75',
          }}
        >
          Accept
        </Button>
        <Button
          onClick={() => rejectFriendRequest(friend._id)}
          isLoading={false}
          variant="outline"
          display="block"
          width="110px"
          borderRadius="24px"
          color="#FF1A75"
          borderColor="#FF1A75"
          _hover={{
            bg: '#FFE6EE',
            color: '#FF1A75',
          }}
        >
          Decline
        </Button>
      </Stack>
    </Flex>
  ));

  const renderFriendsCards = () => connections.friends.map((friend) => (
    <Flex key={friend._id} className={classes.friendCard} flexDirection="column" alignItems="center" width={{ base: '100%', xl: '260px' }}>
      <Box
        as="img"
        crossOrigin="anonymous"
        src={friend.image}
        width="100px"
        height="100px"
        objectFit="cover"
        borderRadius="50%"
        verticalAlign="middle"
        arginBottom="8px"
        cursor="pointer"
        onClick={() => { history.push(`/profile/${friend.username}`); }}
      />
      <Text
        fontSize="lg"
        fontWeight="bold"
        m="0"
        cursor="pointer"
        onClick={() => { history.push(`/profile/${friend.username}`); }}
      >
        {friend.username}
      </Text>
      <Text
        m="0"
        cursor="pointer"
        onClick={() => { history.push(`/profile/${friend.username}`); }}
      >
        {friend.email}
      </Text>
      <div className={classes.friendCardButtonsContainer}>
        <Button
          onClick={() => removeFriend(friend._id)}
          isLoading={false}
          variant="outline"
          display="block"
          width="180px"
          borderRadius="24px"
          color="#FF1A75"
          borderColor="#FF1A75"
          _hover={{
            bg: '#FFE6EE',
            color: '#FF1A75',
          }}
        >
          Remove
        </Button>
      </div>
    </Flex>
  ));

  if (!connectionsFetched) {
    return (
      <Flex
        className="app"
        mt="128px"
        minHeight="90vh"
        width="100%"
      >
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="#FF1A75"
          size="xl"
        />
      </Flex>
    );
  }
  return (
    <Flex
      justifyContent="center"
      marginTop="2rem"
      minHeight="90vh"
      width="100%"
    >
      {areThereConnections
        ? (
          <Box
            width="100%"
          >
            <Tabs
              width={{
                base: '90%', lg: '95%', xl: '1023px',
              }}
              margin="auto"
            >
              <TabList>
                {/*
                <StyledTab><FriendButton /></StyledTab>
                <StyledTab><InviteButton /></StyledTab>
                */
                }
                <StyledTab>
                  <Text
                    textAlign="center"
                    fontSize={{ base: '1em', md: '1.3em' }}
                    fontWeight="600"
                  >
                    Family
                  </Text>
                </StyledTab>
                <StyledTab>
                  <Text
                    textAlign="center"
                    fontSize={{ base: '1em', md: '1.3em' }}
                    fontWeight="600"
                  >
                    Invites
                  </Text>
                </StyledTab>
              </TabList>
              <TabPanels>
                <TabPanel padding="0">
                  <Flex className="app">
                    <Heading
                      marginY="2rem"
                      fontWeight="500"
                      fontSize={{ base: '1.3em', md: '2.5em' }}
                      color="#0B67C2"
                    >
                      Connect with Families
                    </Heading>
                    {renderSearchSection()}
                    <Divider width="100%" marginY="48px" />
                    {connections.pending?.length > 0 && (
                    <>
                      <Heading size="lg" fontWeight="500" marginY="2rem">Pending Requests</Heading>
                      <SimpleGrid
                        columns={{
                          base: 1, sm: 1, md: 2, lg: 3,
                        }}
                        spacing="32px"
                        mb="48px"
                      >
                        {connections.friends && renderPendingRequestsCards()}
                      </SimpleGrid>
                    </>
                    )}
                    {connections.requested?.length > 0 && (
                    <>
                      <Heading size="lg" fontWeight="500" marginY="2rem">Sent Requests</Heading>
                      <SimpleGrid
                        columns={{
                          base: 1, sm: 1, md: 2, lg: 3,
                        }}
                        spacing="32px"
                        mb="48px"
                      >
                        {connections.friends && renderSentRequestsCards()}
                      </SimpleGrid>
                    </>
                    )}
                    <Heading
                      marginY="2rem"
                      fontWeight="500"
                      fontSize={{ base: '1.3em', md: '2.5em' }}
                      color="#0B67C2"
                    >
                      My Connections
                    </Heading>
                    <SimpleGrid
                      columns={{
                        base: 1, sm: 2, md: 2, lg: 3,
                      }}
                      spacing="32px"
                      mb="48px"
                    >
                      {connections.friends && renderFriendsCards()}
                    </SimpleGrid>
                    <Divider width="100%" marginY="28px" />
                  </Flex>
                </TabPanel>
                <TabPanel padding="0">
                  <SendInvites profile={profile} inviteList={inviteList} updateInviteList={updateInviteList} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        ) : (
          <EmptyFamily showConnections={showConnections} userId={user.userData._id} profile={profile} />
        )}
    </Flex>
  );
}
FamiliesPage.propTypes = propTypes;
export default withRouter(FamiliesPage);
