/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import {
  Box,
  Text,
  Flex,
  Input,
  Spinner,
  Button,
  Image,
} from '@chakra-ui/react';
import {
  Badge,
} from '@material-ui/core';
import { ChatDotsFill } from '@styled-icons/bootstrap/ChatDotsFill';
import { NewMessage } from '@styled-icons/entypo/NewMessage';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack';
import { PaperPlane } from '@styled-icons/ionicons-solid/PaperPlane';
import {
  sendMessage,
  recieveChat,
  recieveChatNotification,
  enterChatRoom,
  disconnectSocket,
} from '../../sections/Socket';
import ChatRoom2 from './sections/ChatRoom2';
import CustomRoom from './sections/CustomRoom';
import GroupChatRoom from './sections/GroupChatRoom';
import {
  generateRegistrationId,
  generatePreKeyBundle,
  generateKeyPair,
  generatePreKey,
  generateSignedPreKey,
} from '../../sections/LibSignal';
import {
  createSession,
  encryptMsg,
  decryptMsg,
} from '../../sections/LibSession';
import Server from '../../../assets/images/server.png';
// const ENDPOINT = 'http://localhost:5000/';

const StyledButton = styled(Button)`
color: #006644;
background-color: #BBEFDF;
border-radius: 10px;
&:hover {
  background-color: #BBEFDF;
}
&:focus {
  outline:0 !important;
  box-shadow: none;
  border: none;
}
`;

const NewMessageButton = styled(NewMessage)`
  width: 24px;
  margin: 12px;
`;
const CloseButton = styled(CloseOutline)`
  width: 28px;
  margin: 12px;
`;
const ArrowButton = styled(ArrowBack)`
  width: 24px;
  margin: 12px;
`;
const SumbitButton = styled(PaperPlane)`
  width: 42px;
  padding: 8px;
  margin: 8px;
  background-color: #17A589;
  color: white;
  border-radius: 0.7rem;
  cursor: pointer;
`;
const ChatButton = styled(ChatDotsFill)`
  width: 36px;
  position: relative;
  z-index: 9999;
  color: #000;
  cursor: pointer;
`;
const propTypes = {
  profile: PropTypes.object.isRequired,
};
function ChatBox2({
  profile,

}) {
  const user = useSelector((state) => state.user);
  const [profileTo, setProfileTo] = useState();
  const [err, setErr] = useState();
  const [familyResult, setFamilyResult] = useState([]);
  const [loadedResults, setLoadedResults] = useState(true);
  const [loadedChatRoom, setLoadedChatRoom] = useState(false);
  const [chatRoomId, setChatRoomId] = useState();
  const [chatRoomList, setChatRoomList] = useState();
  const [chatRoomsLoaded, setChatRoomsLoaded] = useState(false);
  const [customChatRoom, setCustomChatRoom] = useState(false);
  // const [notifications, setNotifications] = useState([]);
  const [notifCount, setNotifCount] = useState(0);
  const [conversationArr, setConversationArr] = useState([]);
  const [lastConPageReached, setLastConPageReached] = useState(false);
  const [initializedCon, setInitializedCon] = useState(false);
  const [loadingCon, setloadingCon] = useState(true);
  const [currentConPage, setCurrentConPage] = useState(0);
  const [conLinesPerPage, setConLinesPerPage] = useState(15);
  const [currentChatRoom, setCurrentChatRoom] = useState();
  const [currentMessage, setCurrentMessage] = useState('');
  const [totalConversationSize, setTotalConversationSize] = useState(0);
  const [messageRecipients, setMessageRecipients] = useState();
  const [currenNotifcationId, setCurrentNotificationId] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [socket, setSocket] = useState();
  useEffect(() => {
    if (profile._id) {
      setLastConPageReached(true);
      if (profile.username === 'samk0' || profile.username === 'andyykim') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, [profile]);

  const openChatBox = () => {
    // console.log('I am the one who fetches!!!');
    document.getElementById('chat-box').style.display = 'block';
    axios({
      method: 'POST',
      url: '/api/chatbox/fetch-chatrooms',
      data: {
        profileId: profile._id,
      },
    })
      .then((response) => {
        // console.log(`username : ${response.data.allChatRooms[0].messages[0].message.profile}`);
        const getAllChatrooms = response.data.allChatRooms;
        setChatRoomList(getAllChatrooms);
        setChatRoomsLoaded(true);
      })
      .catch((profErr) => {
        setErr(profErr);
      });
  };

  const resetChatPage = () => {
    setCurrentConPage(0);
    setConversationArr([]);
    setLoadedChatRoom(false);
    setInitializedCon(false);
    setLastConPageReached(false);
  };
  const closeChatBox = () => {
    document.getElementById('chat-box').style.display = 'none';
    setLoadedChatRoom(false);
    setChatRoomId();
    resetChatPage();
  };
  const onProfileChange = (event) => {
    // console.log('I keep getting called too');
    event.persist();
    setChatRoomsLoaded(false);
    axios({
      method: 'POST',
      url: '/api/chatbox/find-profiles',
      data: {
        query: String(event.target.value),
        profileId: profile._id,
      },
    })
      .then((response) => {
        if (event.target.value === '') {
          if (chatRoomList.length > 0) {
            setChatRoomsLoaded(true);
          }
          setLoadedResults(false);
        } else {
          const { profiles } = response.data;
          setFamilyResult(profiles);
          setLoadedResults(true);
          document.getElementById('family-results').style.display = 'block';
          // console.log('what');
        }
      })
      .catch((profErr) => {
        setErr(profErr);
      });
  };

  const displayConversation = (isNewChatLine, currentHeight) => {
    // document.getElementById('chat-box').style.display = 'none';
    // document.getElementById('chat-room').style.display = 'block';
    if (isNewChatLine) {
      const conView = document.getElementById('chatlist-view');
      conView.scrollTop = conView.scrollHeight - conView.clientHeight;
    } else {
      const conView = document.getElementById('chatlist-view');
      // console.log(`new height: ${conView.scrollHeight}`);
      conView.scrollTop = conView.scrollHeight - currentHeight;
    }
  };
  const getConversationByProfile = (currentProfileTo) => {
    // const socket = socketIOClient(ENDPOINT);
    const newSocket = io(window.location.hostname === 'localhost' ? 'http://localhost:5000/' : 'https://www.gokloak.com/', { withCredentials: true });
    if (newSocket) {
      // console.log(`creating chat room: ${profile._id}`);
      // console.log(typeof (profileIdRoom));
      newSocket.emit('chatProfileRoom', profile._id);
      setSocket(newSocket);
    }
    axios({
      method: 'POST',
      url: '/api/chatbox/profile-conversation',
      data: {
        profileIds: [profile._id, currentProfileTo._id],
        page: currentConPage,
        perPage: conLinesPerPage,
      },
    })
      .then((res) => {
        // console.log(`profile data: + ${data.data._id}`);
        const updateNotifList = [];
        enterChatRoom(res.data.room._id);
        const isNewChatLine = true;
        const currentHeight = 0;
        setInitializedCon(true);
        // console.log(res.data.room);
        setCurrentChatRoom(res.data.room);
        setProfileTo(currentProfileTo);
        setConversationArr(res.data.conversation);
        setChatRoomId(res.data.room._id);
        setLoadedChatRoom(true);
        setChatRoomsLoaded(false);
        setCurrentConPage(currentConPage + 1);
        setloadingCon(false);
        displayConversation(isNewChatLine, currentHeight);
        setTotalConversationSize(res.data.conversationSize);
        document.getElementById('family-input-search').value = '';
        setLoadedResults(false);
        // console.log(`typeof : ${typeof (socket)}`);
        // socket.emit('join', res.data.room._id);
      })
      .catch((convErr) => {
        setErr(convErr);
      });
  };
  const getConversation = (myChatRoom) => {
    // const socket = socketIOClient(ENDPOINT);
    setChatRoomsLoaded(false);
    const updateNotifList = [];
    enterChatRoom(myChatRoom._id);
    axios({
      method: 'POST',
      url: '/api/chatbox/conversation-query',
      data: {
        roomId: myChatRoom._id,
        page: currentConPage,
        perPage: conLinesPerPage,
      },
    })
      .then((res) => {
        // console.log(`profile data: + ${data.data._id}`);
        let isChatLine;
        let currentHeight = 0;
        if (!initializedCon) {
          setInitializedCon(true);
          setCurrentChatRoom(myChatRoom);
          setProfileTo(myChatRoom.profiles[1]._id === profile._id
            ? myChatRoom.profiles[0] : myChatRoom.profiles[1]);
          setChatRoomId(myChatRoom._id);
          setTotalConversationSize(res.data.conversationSize);
          isChatLine = true;
        } else {
          isChatLine = false;
          currentHeight = document.getElementById('chatlist-view').scrollHeight;
          // console.log(`current height: ${currentHeight}`);
        }
        setConversationArr(res.data.conversation.concat(conversationArr));
        if (res.data.conversation.length === 0) {
          setLastConPageReached(true);
          setloadingCon(false);
        } else {
          setCurrentConPage(currentConPage + 1);
          setloadingCon(false);
        }
        const recipients = [];
        myChatRoom.profiles.forEach((eachProfile) => {
          if (eachProfile._id !== profile._id) {
            recipients.push(eachProfile._id);
          }
        });
        setMessageRecipients(recipients);
        setLoadedChatRoom(true);
        displayConversation(isChatLine, currentHeight);
        // enterChatRoom(res.data.room._id);
        // socket.emit('join', myChatRoom._id);
        /* socket.emit('profileRoom', myChatRoom.profiles[1]._id === profile._id
          ? myChatRoom.profiles[0]._id : myChatRoom.profiles[1]._id); */
      })
      .catch((convErr) => {
        setErr(convErr);
      });
  };
  useEffect(() => {
    if (document.getElementById('chatlist-view')) {
      document.getElementById('chatlist-view').onscroll = debounce(() => {
        // console.log('scroll detection');
        // console.log(document.getElementById('chatlist-view').scrollTop);
        if ((document.getElementById('chatlist-view').scrollTop < 50)
            && (lastConPageReached === false) && (initializedCon)) {
          // console.log('match found');
          // Do awesome stuff like loading more content!
          setloadingCon(true);
          getConversation(currentChatRoom);
        }
      }, 300);
    }
  });
  const updateConversation = (newConverArr) => {
    setConversationArr(newConverArr);
    // console.log('timeframe 1');
    setLoadedChatRoom(true);
  };
  const backToChatBox = () => {
    setLoadedChatRoom(false);
    setCustomChatRoom(false);
    resetChatPage();
    setChatRoomId();
    openChatBox();
  };
  const closeRoomBox = () => {
    axios({
      method: 'DELETE',
      url: '/api/chatbox/delete-notif-room',
      data: {
        roomId: chatRoomId,
      },
    })
      .then((res) => {
        // console.log(`profile data: + ${data.data._id}`);
        document.getElementById('chat-box').style.display = 'none';
        setLoadedChatRoom(false);
        setChatRoomsLoaded(false);
        resetChatPage();
      })
      .catch((convErr) => {
        setErr(convErr);
      });
  };
  const closeCustomChat = () => {
    document.getElementById('chat-box').style.display = 'none';
    setCustomChatRoom(false);
    setChatRoomsLoaded(false);
    resetChatPage();
  };
  const handleMessageChange = (event) => {
    setCurrentMessage(event.target.value);
  };

  const submitMessage = () => {
    if (currentMessage === '') {
      return;
    }
    const updatedConversation = conversationArr;
    // console.log(conversationArr);
    const msgDataObject = {
      messageBody: currentMessage,
      profile,
      roomId: chatRoomId,
      profileTo,
    };
    const notificationObj = {
      profile: profileTo,
      messageBody: currentMessage,
      room: chatRoomId,
    };
    updatedConversation.push(msgDataObject);
    updateConversation(updatedConversation);
    // socket.emit('profileRoom', profileTo._id);
    // const socket = socketIOClient(ENDPOINT);
    // socket.emit('sendMessage', msgDataObject, chatRoomId, notificationObj, profileTo._id);
    // socket.emit('sendNotification', notificationObj, profileTo._id);
    axios({
      method: 'POST',
      url: '/api/chatbox/submit-message',
      data: msgDataObject,
    })
      .then((res) => {
        setCurrentMessage('');
        setTotalConversationSize(totalConversationSize + 1);
        const conView = document.getElementById('chatlist-view');
        conView?.lastElementChild.scrollIntoView({ behavior: 'smooth' });
        document.getElementById('current-input').value = '';
        // sendMessage(msgDataObject, chatRoomId, notificationObj, messageRecipients, profile, res.data.newNotification._id);
      })
      .catch((convErr) => {
        setErr(convErr);
      });
  };
  const checkEnterKey = (event) => {
    if (event.key === 'Enter') {
      submitMessage();
    }
  };
  const openNewMessageBox = () => {
    document.getElementById('chat-box').style.display = 'none';
    setCustomChatRoom(true);
    setChatRoomsLoaded(false);
    setLoadedChatRoom(false);
  };
  const listFamilyResults = () => familyResult.map((familyProfile, index) => (
    <Box
      onClick={() => { getConversationByProfile(familyProfile); }}
    >
      <Flex margin="8px">
        <Box
          marginTop="7px"
          as="img"
          crossOrigin="anonymous"
          src={familyProfile.image}
          width="42px"
          height="42px"
          objectFit="cover"
          borderRadius="50%"
          verticalAlign="middle"
          alignItems="center"
        />
        <Box>
          <Text
            color="#34495E"
            fontWeight="500"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            marginTop="4px"
          >
            {familyProfile.username}
          </Text>
          <Text
            color="#34495E"
            fontWeight="400"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {familyProfile.email}
          </Text>
        </Box>
      </Flex>
    </Box>
  ));

  if (isAdmin === false) {
    return (
      <Box
        borderRadius="2px"
      >
        <Box
          id="chat-box"
          position="fixed"
          zIndex="9999"
          bottom="0"
          right={{ base: '0', md: '100px' }}
          color="#34495E"
          cursor="pointer"
          width={{ base: '100%', md: '350px' }}
          height={{ base: '100%', md: '600px' }}
          backgroundColor="#DEE5EC"
          display="none"
          borderTopLeftRadius="0.7rem"
          borderTopRightRadius="0.7rem"
        >
          <Flex
            width="100%"
            justifyContent="flex-end"
          >
            <CloseButton onClick={closeChatBox} />
          </Flex>
          <Flex
            display="flex"
            height={{ base: '100%', md: '450px' }}
          >
            {chatRoomsLoaded
              ? (
                <Box>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    height="100%"
                    paddingX="1rem"
                  >
                    <Image src={Server} width={{ base: '90%' }} />
                    <Text
                      fontSize="1.2em"
                      fontWeight="600"
                    >
                      Chat is under construction
                    </Text>
                    <Text
                      fontSize="1.2em"
                      fontWeight="600"
                    >
                      Check Back Later!
                    </Text>
                  </Flex>
                </Box>

              ) : <Box />}
          </Flex>
        </Box>
        <Box
          position="fixed"
          zIndex="4"
          bottom="0"
          right={{ base: '12px', md: '100px' }}
          color="#000"
          cursor="pointer"
          width={{ base: '250px', md: '250px' }}
          height="auto"
          backgroundColor="#DEE5EC"
          padding="15px 10px"
          borderTopLeftRadius=".70rem"
          borderTopRightRadius=".70rem"
          onClick={openChatBox}
        >
          <Flex
            justifyContent="space-around"
          >
            <Text
              fontSize="1.1rem"
              fontWeight="500"
              backgroundColor="#DEE5EC"
              padding="4px 8px"
              color="#34495E"
            >
              See Messages
            </Text>
            <Box
              as="img"
              crossOrigin="anonymous"
              src={profile && profile.image}
              width="37px"
              height="37px"
              objectFit="cover"
              borderRadius="50%"
              verticalAlign="middle"
              alignItems="center"
            />
          </Flex>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      borderRadius="2px"
    >
      <Box
        id="chat-box"
        position="fixed"
        zIndex="9999"
        bottom="0"
        right={{ base: '0', md: '100px' }}
        color="#34495E"
        cursor="pointer"
        width={{ base: '100%', md: '350px' }}
        height={{ base: '100%', md: '600px' }}
        backgroundColor="#DEE5EC"
        display="none"
        borderTopLeftRadius="0.7rem"
        borderTopRightRadius="0.7rem"
      >
        <Flex
          width="100%"
          justifyContent="space-between"
        >
          <Box>
            <Flex
              justifyContent="center"
              flexDir="column"
              height="100%"
              marginLeft="10px"
            >
              <StyledButton
                onClick={openNewMessageBox}
              >
                New Group Chat
              </StyledButton>
            </Flex>
          </Box>
          <CloseButton onClick={closeChatBox} />
        </Flex>
        <Box
          marginX="0.5rem"
          borderRadius="5px"
        >
          <Input
            id="family-input-search"
            onChange={onProfileChange}
            placeholder="Enter username or email"
          />
        </Box>
        <Box id="family-results">
          {loadedResults
            ? (
              <Box> {listFamilyResults()}
              </Box>
            ) : <Box />}
        </Box>
        <Flex
          display="flex"
          height={{ base: '100%', md: '450px' }}
        >
          {chatRoomsLoaded
            ? (
              <Box>
                {chatRoomList.map((chatRoom) => (
                  <Box key={`chat_${chatRoom._id}`}>
                    {chatRoom.profiles.length <= 2
                      ? (
                        <Flex
                          marginY="0.5rem"
                          marginX="0.5rem"
                          width="100%"
                          onClick={() => { getConversation(chatRoom); }}
                        >
                          <Badge
                            badgeContent={chatRoom.notificationCount}
                            color="error"
                          >
                            <Box
                              marginTop="7px"
                              as="img"
                              crossOrigin="anonymous"
                              src={chatRoom.profiles[1]._id === profile._id
                                ? chatRoom.profiles[0].image : chatRoom.profiles[1].image}
                              width="42px"
                              height="42px"
                              objectFit="cover"
                              borderRadius="50%"
                              verticalAlign="middle"
                              alignItems="center"
                            />

                          </Badge>
                          <Box
                            width="200px"
                            marginLeft="1rem"
                            marginBottom="4px"
                          >
                            <Text
                              color="#34495E"
                              fontWeight="600"
                              maxWidth={{
                                base: '100%',
                              }}
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              marginTop="4px"
                            >
                              {chatRoom.profiles[1]._id === profile._id
                                ? chatRoom.profiles[0].username : chatRoom.profiles[1].username}
                            </Text>
                            <Text
                              color="#34495E"
                              fontWeight="400"
                              maxWidth={{
                                base: '100%',
                              }}
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {chatRoom.messages[chatRoom.messages.length - 1].message.messageBody}
                            </Text>
                          </Box>
                        </Flex>
                      ) : (
                        <Flex
                          marginY="0.5rem"
                          marginX="0.5rem"
                          width="100%"
                          onClick={() => { getConversation(chatRoom); }}
                          height="50px"
                        >
                          <Box>
                            <Flex
                              justifyContent="center"
                              flexDirection="column"
                              height="100%"
                              marginRight="8px"
                              marginTop="4px"
                            >
                              <Box
                                as="img"
                                crossOrigin="anonymous"
                                src={chatRoom.profiles[1].image}
                                width="37px"
                                height="37px"
                                objectFit="cover"
                                borderRadius="50%"
                                verticalAlign="middle"
                                alignItems="center"
                              />
                            </Flex>
                          </Box>
                          <Box>
                            <Flex
                              justifyContent="center"
                              flexDirection="column"
                              height="100%"
                              marginLeft="-25px"
                            >
                              <Box
                                as="img"
                                crossOrigin="anonymous"
                                src={chatRoom.profiles[2].image}
                                width="37px"
                                height="37px"
                                objectFit="cover"
                                borderRadius="50%"
                                verticalAlign="middle"
                                alignItems="center"
                              />
                            </Flex>
                          </Box>

                          <Box
                            width="200px"
                            marginLeft="1rem"
                          >
                            <Flex
                              justifyContent="center"
                              flexDir="column"
                              height="100%"
                            >
                              <Text
                                color="#34495E"
                                fontWeight="600"
                                maxWidth={{
                                  base: '100%',
                                }}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                marginTop="4px"
                              >
                                {chatRoom.name}
                              </Text>
                              { chatRoom.messages.length > 0
                                ? (
                                  <Box>
                                    <Text
                                      color="#34495E"
                                      fontWeight="400"
                                      maxWidth={{
                                        base: '100%',
                                      }}
                                      whiteSpace="nowrap"
                                      overflow="hidden"
                                      textOverflow="ellipsis"
                                    >
                                      {chatRoom.messages[chatRoom.messages.length - 1].message.profile.username}: {chatRoom.messages[chatRoom.messages.length - 1].message.messageBody}
                                    </Text>
                                  </Box>
                                ) : <Box />}
                            </Flex>
                          </Box>
                        </Flex>
                      )}
                  </Box>
                ))}
              </Box>

            ) : <Box />}
        </Flex>
      </Box>
      <Box>
        {customChatRoom
          ? (
            <Box
              id="custom-room"
              position="fixed"
              zIndex="9999"
              bottom="0"
              right={{ base: '0', md: '100px' }}
              color="#000"
              cursor="pointer"
              width={{ base: '100%', md: '350px' }}
              height={{ base: '100%', md: '600px' }}
              backgroundColor="#DEE5EC"
              borderTopLeftRadius="0.7rem"
              borderTopRightRadius="0.7rem"
            >
              <Flex
                width="100%"
                justifyContent="space-between"
              >
                <ArrowButton onClick={backToChatBox} />
                <Box>
                  <Flex justifyContent="center" flexDirection="column" alignItems="center" height="100%">
                    <Text>New Group Chat</Text>
                  </Flex>
                </Box>
                <CloseButton onClick={closeCustomChat} />
              </Flex>
              <CustomRoom
                profile={profile}
                backToChatBox={backToChatBox}
              />
            </Box>
          ) : <Box />}
      </Box>
      <Box>
        {loadedChatRoom
          ? (
            <Box>
              <Box
                id="chat-room"
                position="fixed"
                zIndex="9999"
                bottom="0"
                right={{ base: '0', md: '100px' }}
                color="#000"
                cursor="pointer"
                width={{ base: '100%', md: '350px' }}
                height={{ base: '100%', md: '600px' }}
                borderTopLeftRadius="0.7rem"
                borderTopRightRadius="0.7rem"
                backgroundColor="#DEE5EC"
              >
                <Box>
                  <ChatRoom2
                    profile={profile}
                    profileTo={profileTo}
                    conversationArr={conversationArr}
                    updateConversation={updateConversation}
                    roomId={chatRoomId}
                    resetChatPage={resetChatPage}
                    loadingCon={loadingCon}
                    backToChatBox={backToChatBox}
                    closeRoomBox={closeRoomBox}
                  />
                </Box>
                <Flex
                  position="absolute"
                  bottom="0.5rem"
                  width="100%"
                >
                  <Box
                    width="100%"
                    marginX="0.5rem"
                  >
                    <Flex
                      backgroundColor="#FFF"
                      bottom="0"
                      margin="auto"
                      height="100%"
                    >
                      <Input
                        position="relative"
                        id="current-input"
                        placeholder="Type Message Here"
                        onChange={handleMessageChange}
                        onKeyDown={checkEnterKey}
                        backgroundColor="#FFF"
                        border="0"
                        height="100%"
                        _focus={{ outline: 'none' }}
                      />
                      <SumbitButton onClick={submitMessage} />
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Box>
          ) : <Box />}
      </Box>
      <Box
        position="fixed"
        zIndex="4"
        bottom="0"
        right={{ base: '12px', md: '100px' }}
        color="#000"
        cursor="pointer"
        width={{ base: '250px', md: '250px' }}
        height="auto"
        backgroundColor="#DEE5EC"
        padding="15px 10px"
        borderTopLeftRadius=".70rem"
        borderTopRightRadius=".70rem"
        onClick={openChatBox}
      >
        <Flex
          justifyContent="space-around"
        >
          <Text
            fontSize="1.1rem"
            fontWeight="500"
            backgroundColor="#DEE5EC"
            padding="4px 8px"
            color="#34495E"
          >
            See Messages
          </Text>
          <Box
            as="img"
            crossOrigin="anonymous"
            src={profile && profile.image}
            width="35px"
            height="35px"
            objectFit="cover"
            borderRadius="50%"
            verticalAlign="middle"
          />
        </Flex>
      </Box>
    </Box>
  );
}
ChatBox2.propTypes = propTypes;
export default ChatBox2;

// NewMessageButton onClick={openNewMessageBox} />
