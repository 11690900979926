/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import {
  Avatar,
  Button,
  Stack,
  Box,
  SimpleGrid,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Select,
  AspectRatio,
  FormControl,
  FormLabel,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from '@chakra-ui/react';

import debounce from 'lodash.debounce';
import axios from 'axios';
// import ReactionModal from './ReactionModal';

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',

  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 99999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#111',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#F1F1F1',
    fontSize: '30px',
    fontWeight: 'bold',
    transition: '0.3s',
    cursor: 'pointer',
    padding: '0 14px',
    borderRadius: '50%',
    '&:hover': {
      color: '#bbb',
      textDecoration: 'none',
    },
  },
  leftButton: {
    position: 'absolute',
    top: '50%',
    left: '24px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  rightButton: {
    position: 'absolute',
    top: '50%',
    right: '-26px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  privacyButton: {
    backgroundColor: '#222',
    borderRadius: '5px',
    padding: '6px 12px',
    color: '#FFF',
    marginTop: '1rem',
  },
});
const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];

function EditAlbumImages(props) {
  const classes = useStyles();
  const {
    image,
    profile,
    updateImages,
    images,
    deletedImage,
  } = props;
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isOpenEditModal,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();
  const [imagePrivacy, setImagePrivacy] = useState();
  // const { isOpenEdit, onOpen, onClose } = useDisclosure();
  const [errMessage, setErrMessage] = useState();
  const deleteImage = () => {
    // console.log(image);
    // include the path for both thumbnail and actual image
    const files = [{ Key: image.fileName }, { Key: image.thumbName }];
    axios({
      method: 'POST',
      url: '/api/image/deleteImageByProfile',
      data: {
        fileNames: files,
        filePath: image.filePath,
        profileId: profile._id,
        fileSize: image.fileSize,
        imageId: image._id,
      },
    })
      .then(() => {
        const newImageList = [];
        images.forEach((eachImage) => {
          if (eachImage._id !== image._id) {
            newImageList.push(eachImage);
          }
        });
        deletedImage(newImageList);
      })
      .catch((err) => {
        setErrMessage(err.message);
      });
  };

  const renderDetailsForm = () => (
    <Formik
      initialValues={{
        description: image.description,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setTimeout(() => {
          const data = {
            description: values.description,
            privacy: image.privacy,
            imageId: image._id,
            privacyLabel: image.privacyLabel,
          };
          axios.post('/api/image/updateOneImage', data).then((response) => {
            // console.log(response.data.imageData);
            updateImages(response.data.imageData);
            onCloseEditModal();
          });
        });
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form onSubmit={handleSubmit} width="100%">
            <Field name="description">
              {() => (
                <FormControl mb="25px">
                  <FormLabel htmlFor="description" ml="4px" fontWeight="300">
                    Description
                  </FormLabel>
                  <br />
                  <textarea
                    id="description"
                    placeholder="What is this image about?"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '100%' }}
                  />
                </FormControl>
              )}
            </Field>
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Publishing..."
              onClick={handleSubmit}
              display="block"
              borderRadius="24px"
              mt="16px"
              mb="16px"
              mr="auto"
              ml="auto"
              width="200px"
              color="white"
              bg="#FF1A75"
              _hover={{
                bg: '#FFE6EE',
                color: '#FF1A75',
              }}
            >
              Save
            </Button>
          </form>
        );
      }}
    </Formik>
  );
  return (
    <Box>
      <Menu>
        <MenuButton>
          <Text
            marginTop="4px"
            color="#FFF"
            fontSize="sm"
            backgroundColor="#3388FF"
            padding="4px 8px"
            borderRadius="3px"
            cursor="pointer"
            width="70px"
            textAlign="center"
          >
            Settings
          </Text>
        </MenuButton>
        <MenuList
          placement="bottom-start"
          zIndex="9999"
          backgroundColor="#FFF"
          width="125px"
        >
          <MenuItem onClick={onOpenEditModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Edit
            </Text>
          </MenuItem>
          <MenuItem onClick={onOpenDeleteModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Delete
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal}>
        <ModalOverlay />
        <ModalContent zIndex="99999">
          <ModalHeader>Edit Image Properties</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderDetailsForm()}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ModalOverlay />
        <ModalContent zIndex="99999">
          <ModalHeader>Delete Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p1>Are you sure you want to Delete this image?</p1>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseDeleteModal}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={deleteImage}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default EditAlbumImages;
