/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Box,
  Link,
  Text,
} from '@chakra-ui/react';
import PropTypes, { checkPropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../../_actions/user_actions';

import AccountImg from '../../../assets/images/account.png';
import { getProfile } from '../../../_actions/profile_actions';

const useStyles = createUseStyles({
  formContainer: {
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function LoginPage({
  history,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [formErrorMessage, setFormErrorMessage] = useState('');

  const rememberMeChecked = !!localStorage.getItem('rememberMe');
  const [rememberMe, setRememberMe] = useState(rememberMeChecked);

  // console.log('rememberMe', rememberMe);

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const initialEmail = localStorage.getItem('rememberMe') || '';

  return (
    <Formik
      minHeight="90vh"
      initialValues={{
        email: initialEmail,
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Email is invalid')
          .required('Email is required'),
        password: Yup.string()
          .min(6, 'Password must be at least 6 characters')
          .required('Password is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          const dataToSubmit = {
            email: values.email,
            password: values.password,
          };
          // console.log(dataToSubmit);
          dispatch(loginUser(dataToSubmit))
            .then((response) => {
              if (response.payload.loginSuccess) {
                window.localStorage.setItem('userId', response.payload.userId);
                if (rememberMe === true) {
                  window.localStorage.setItem('rememberMe', values.email);
                } else {
                  localStorage.removeItem('rememberMe');
                }
                // console.log('username: ' + response.payload.username);
                dispatch(getProfile({ username: response.payload.username }))
                  .then(() => {
                    history.push('/home');
                  });
              } else {
                setFormErrorMessage('Check out your Account or Password again');
              }
            })
            .catch(() => {
              setFormErrorMessage('Check out your Account or Password again');
              setTimeout(() => {
                setFormErrorMessage('');
              }, 3000);
            });
          setSubmitting(false);
        }, 500);
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <Flex width="100%" margin="auto" className="app" minHeight="90vh" flexDirection={{ base: 'column', sm: 'column', xl: 'row' }}>
            <Flex flexGrow="1" alignItems="center" justifyContent="center">
              <Image width={{ base: '100px', sm: '200px', xl: '400px' }} objectFit="contain" src={AccountImg} />
            </Flex>
            <Flex className={classes.formContainer} flexGrow="1" flexDirection="column" alignItems={{ base: 'left', lg: 'center' }} width={{ base: '94%', sm: '94%', md: '352px' }}>
              <Heading fontWeight="400" textAlign="center">Sign In</Heading>
              <form className={classes.form} onSubmit={handleSubmit} width="100%">
                <Field name="email">
                  {() => (
                    <FormControl
                      isRequired
                      isInvalid={errors.email && touched.email}
                      mb="4px"
                    >
                      <FormLabel htmlFor="email" ml="4px" fontWeight="400" paddingLeft={{ base: '3%', lg: '0' }}>
                        Email
                      </FormLabel>
                      <Input
                        id="email"
                        placeholder="Enter your email address"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="auto"
                        width={{ base: '100%', sm: '100%', md: '352px' }}
                      />
                      <Box className={classes.formErrorMessage} paddingLeft={{ base: '3%', lg: '0' }}>
                        {touched.email && errors.email}
                      </Box>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {() => (
                    <FormControl
                      isRequired
                      isInvalid={errors.password && touched.password}
                      mb="4px"
                    >
                      <FormLabel htmlFor="password" ml="4px" fontWeight="400" paddingLeft={{ base: '3%', lg: '0' }}>
                        Password
                      </FormLabel>
                      <Input
                        id="password"
                        placeholder="Enter a password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="auto"
                        width={{ base: '100%', sm: '100%', md: '352px' }}
                      />
                      <div className={classes.formErrorMessage}>
                        {touched.password && errors.password}
                      </div>
                    </FormControl>
                  )}
                </Field>
                {formErrorMessage && (
                  <label>
                    <p
                      style={{
                        color: '#ff0000bf',
                        fontSize: '0.7rem',
                        border: '1px solid',
                        paddingLeft: '1rem',
                        borderRadius: '10px',
                      }}
                    >
                      {formErrorMessage}
                    </p>
                  </label>
                )}
                <Flex alignItems="center" justifyContent="space-between" flexDirection={{ base: 'column', sm: 'row', md: 'row' }}>
                  <Field name="rememberMe" width="100px">
                    {() => (
                      <FormControl width="15  0px">
                        <Checkbox
                          id="rememberMe"
                          onChange={handleRememberMe}
                          isChecked={rememberMe}
                        >
                          Remember me
                        </Checkbox>

                      </FormControl>
                    )}
                  </Field>

                  <Link className="login-form-forgot" color="#1890ff" href="/forgot-password" marginTop={{ base: '1rem', sm: '1rem', md: '0' }}>
                    Forgot password?
                  </Link>
                </Flex>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Logging in..."
                  onClick={handleSubmit}
                  display="block"
                  mt="2rem"
                  mb="2rem"
                  mr="auto"
                  ml="auto"
                  width="200px"
                  color="white"
                  borderRadius="8px"
                  bg="#3480E3"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Sign In
                </Button>
                <Box paddingBottom={{ base: '4rem', sm: '4rem', md: '0' }}>
                  Or <Link color="#1890ff" href="/register">register now!</Link>
                </Box>
              </form>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

LoginPage.propTypes = propTypes;

export default withRouter(LoginPage);
