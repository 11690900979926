/* eslint-disable prefer-template */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as linkify from 'linkifyjs';
import { createUseStyles } from 'react-jss';
import debounce from 'lodash.debounce';
import {
  Box,
  Flex,
  Avatar,
  Text,
  List,
  SimpleGrid,
  AspectRatio,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  Heading,
  Button,
  Textarea,
  Stack,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
} from '@chakra-ui/react';

import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
// import Subscriber from './Sections/Subscriber';
import { BackgroundColor } from '@styled-icons/foundation';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import Post from './sections/Post';
import SinglePost from './sections/SinglePost';
import ErrorImage from '../../../assets/images/famshare.png';
import MessagingImg from '../../../assets/images/undraw_messaging_uok8.png';
import ImageBanner from './sections/ImageBanner';
import GroupMembers from './sections/GroupMembers';

const StyledTab = styled(Tab)`
  margin: 0;
  &:focus {
    outline:0 !important;
    box-shadow: none;
    border: none;
    border-color: #FFF !important;
  }
`;

const SeeMoreButton = styled(EyeFill)`
  width: 24px;
  color: #BBB;
  cursor: pointer;
`;
const HeartActive = styled(SuitHeartFill)`
  color: #FF0000; 
  right: 12px;
  top: 12px;
  position: relative;
  width: 16px;
`;
const HeartButton = styled(SuitHeartFill)`
  background-color: inherit;
  width: 24px;
  color: inherit;
  cursor: pointer;
`;
const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  familyPostGrid: {
    display: 'flex',
    overflowX: 'scroll',
    gridGap: '1em',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  postGrid: {
    width: '1200px',
    '@media screen and (max-width: 1600px)': {
      width: '1023px',
    },
    '@media screen and (max-width: 1400px)': {
      width: '760px',
    },
    '@media screen and (max-width: 1023px)': {
      width: '95%',
    },
    '@media screen and (max-width: 800px)': {
      width: '100%',
    },
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function DetailGroupPage({
  match,
  history,
}) {
  const { groupId } = match.params;
  const [image, setImage] = useState();
  const [error, setError] = useState('');
  const [loadCompleted, setLoadCompleted] = useState(false);
  const [profile, setProfile] = useState();
  const [myId, setMyId] = useState();
  const [reactionActive, setReactionActive] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(true);
  const [imageReactions, setImageReactions] = useState([]);
  const [viewReactions, setViewReactions] = useState([]);
  const [postText, setPostText] = useState('');
  const user = useSelector((state) => state.user);
  const [groupData, setGroupData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [previewLink, setPreviewLink] = useState();
  const [lastPageAlbumReached, setLastPageAlbumReached] = useState(false);
  const [itemsPerAlbumPage, setAlbumsPerMediaPage] = useState(4);
  const [currentAlbumIndex, setCurrenAlbumIndex] = useState(0);
  const [albums, setAlbums] = useState([]);
  const [allAlbums, setAllAlbums] = useState([]);
  const [loadingAlbums, setLoadingAlbums] = useState(true);
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState();
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [changedMade, setChangeMade] = useState(false);
  const [groupPosts, setGroupPosts] = useState([]);
  const [currentAlbumId, setCurrentAlbumId] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isRequester, setIsRequester] = useState(false);
  const [posts, setPosts] = useState();
  const [renderPostList, setRenderPostList] = useState(false);
  const [groupPostsLoaded, setGroupPostsLoaded] = useState(false);
  const classes = useStyles();
  const getReactions = (profileData, imageId) => {
    // console.log(profileData);
    axios({
      url: '/api/image/getAllReactions',
      method: 'POST',
      data: {
        imageId,
        profile: profileData,
      },
    })
      .then((response) => {
        // console.log(response.data.reactions[0]);
        setImageReactions(response.data.reactions);
        setViewReactions(response.data.reactions?.slice(0, 4));
        // console.log(`result: ${response.data.reactions}`);
        // eslint-disable-next-line eqeqeq
        if (response.data.reactions === undefined
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == []
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == '') {
          // console.log('reached me');
          setReactionActive(false);
          setLoadCompleted(true);
          // document.getElementById(`heart-button`).style.color = '#DDD';
          return;
        }
        if (response.data.reactions[0].profile._id === profileData._id) {
          // console.log('match found');
          // document.getElementById(`heart-button`).style.color = '#FF0000';
          setReactionActive(true);
          // console.log('reached ne');
        } else {
          // document.getElementById(`heart-button`).style.color = '#DDD';
          setReactionActive(false);
        }
        // console.log('finally');
        setLoadCompleted(true);
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
      })
      .catch((err) => {
        setError(err);
      });
  };
  const handleTextChange = (event) => {
    setPostText(event.target.value);
    // console.log(typeof ([]));
    const linkRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?/g;
    const editedText = event.target.value.replace(linkRegex, (url) => '<a href="' + url + '">');
    // console.log(editedText);
    const matchesFound = linkify.find(event.target.value);
    const linksToAdd = [];
    if (matchesFound) {
      matchesFound.forEach((link) => {
        if (link.type === 'url') {
          linksToAdd.push(link.href);
        }
      });
      // console.log(linksToAdd);
    }
    if (previewLink !== linksToAdd[linksToAdd.length - 1]) {
      setPreviewLink(linksToAdd[linksToAdd.length - 1]);
    }
  };

  useEffect(() => {
    // console.log('test');
    if (!user.userData) {
      return;
    }
    axios({
      method: 'POST',
      url: '/api/profile/get-my-profile',
      data: {
        username: user.userData.username,
      },
    })
      .then((profResponse) => {
        // console.log(profResponse.data._id);
        axios({
          method: 'POST',
          url: '/api/groups/group-by-id',
          data: {
            groupId,
            profileId: profResponse.data._id,
          },
        })
          .then((groupResponse) => {
            // console.log(groupResponse.data.group);
            let checkAdmin = false;
            groupResponse.data.group.admins.forEach((testAdmin) => {
              if (profResponse.data._id === testAdmin._id) {
                checkAdmin = true;
              }
            });
            setIsAdmin(checkAdmin);
            setProfile(profResponse.data);
            setPosts(profResponse.data.posts);
            setGroupData(groupResponse.data.group);
            setGroupPosts(groupResponse.data.group.posts);
            setLoadingPosts(false);
            setIsMember(groupResponse.data.isMember);
            setIsRequester(groupResponse.data.isRequester);
            setLoadCompleted(true);
            setRenderPostList(true);
            setGroupPostsLoaded(true);
          })
          .catch((groupErr) => {
            setError(groupErr);
          });
      })
      .catch((err) => {
        setError('Cannot fetch user profile');
      });
  }, [user, groupId]);

  const updatePostList = (postList) => {
    setGroupPosts(postList);
  };
  const selectPost = (post) => {
    setChangeMade(false);
    const borderStyle = document.getElementById(`group_post_${post._id}`).style.border;
    if (borderStyle === '') {
      document.getElementById(`group_post_${post._id}`).style.border = '1px solid blue';
      const updatedPosts = selectedPosts;
      updatedPosts.push(post._id);
      setSelectedPosts(updatedPosts);
    } else {
      document.getElementById(`group_post_${post._id}`).style.border = '';
      const updatedPosts = [];
      const getPosts = selectedPosts;
      getPosts.forEach((postId) => {
        if (post._id !== postId) {
          updatedPosts.push(postId);
        }
      });
      setSelectedPosts(updatedPosts);
      setChangeMade(true);
    }
    // console.log(selectedPosts);
  };
  const selectAlbum = (album) => {
    setChangeMade(false);
    const borderStyle = document.getElementById(`group_album_${album._id}`).style.border;
    const selectedStyle = document.getElementById(selectedAlbum);
    if (selectedStyle !== '' && selectedStyle !== null) {
      document.getElementById(selectedAlbum).style.border = '';
    }
    setSelectedAlbum(`group_album_${album._id}`);
    setCurrentAlbumId(album._id);
    if (borderStyle === '') {
      document.getElementById(`group_album_${album._id}`).style.border = '1px solid blue';
    } else {
      document.getElementById(`group_album_${album._id}`).style.border = '';
    }
    setChangeMade(true);
  };
  const retrievePosts = () => {
    setLoadingPosts(true);
    setPosts(profile.posts);
    setLoadingPosts(false);
    setSelectedAlbum();
  };

  const retrieveAlbums = () => {
    setLoadingPosts(true);
    setPosts();
    setSelectedPosts([]);
    axios({
      method: 'POST',
      url: '/api/image/my-albums',
      data: {
        profileId: profile._id,
      },
    })
      .then((data) => {
        // console.log(data.data.albums);
        if (data.data.albums.length > 0) {
          const tempAlbums = data.data.albums;
          const albumArr = [];
          for (let i = 0; i < tempAlbums.length; i += 1) {
            // console.log(albums[i]);
            if (i === tempAlbums.length - 1) {
              setLastPageAlbumReached(true);
              setCurrenAlbumIndex(i);
              albumArr.push(tempAlbums[i]);
              break;
            }
            if (i === itemsPerAlbumPage - 1) {
              albumArr.push(tempAlbums[i]);
              setCurrenAlbumIndex(i + 1);
              break;
            }
            albumArr.push(tempAlbums[i]);
          }
          if (albumArr.length > 0) {
            setLoadingAlbums(true);
          }
          // console.log (albumArr);
          setAlbums(albumArr);
          setAllAlbums((data.data.albums));
          setLoadingAlbums(false);
        }
      })
      .catch((albumErr) => {
        // console.log(err);
        let errMsg;

        switch (albumErr?.response?.status) {
          case 403:
            errMsg = 'You do Not have access to these images';
            break;
          case 500:
            errMsg = 'Server is currently down';
            break;
          default:
            errMsg = 'Something has went wrong';
            break;
        }
        setError(errMsg);
        setTimeout(() => {
          setError('');
        }, 3000);
      });
  };
  const saveGroupPost = () => {
    onClose();
    // console.log(selectedPosts);
    axios({
      method: 'post',
      url: '/api/groups/create-group-post',
      data: {
        text: postText,
        album: currentAlbumId,
        posts: selectedPosts,
        groupId: groupData._id,
        profile,
      },
    })
      .then((res) => {
        setGroupPostsLoaded(false);
        const updatedGroupPosts = groupPosts;
        const newGroupPost = res.data.returnGroupPost;
        newGroupPost.author = profile;
        updatedGroupPosts.unshift(newGroupPost);
        setGroupPosts(updatedGroupPosts);
        setCurrentAlbumId();
        setSelectedPosts([]);
        setPostText();
        setGroupPostsLoaded(true);
      })
      .catch((err) => {
        setError(err);
      });
  };
  const renderMyPosts = () => posts.map((post) => (
    <Box
      key={post._id}
    >
      {post.video
        ? (
          <Box
            id={`group_post_${post._id}`}
            height="250px"
            padding="10px"
            cursor="pointer"
            borderRadius="10px"
            onClick={() => { selectPost(post); }}
          >
            <Box position="relative" textAlign="right" width="100%">
              <AspectRatio
                ratio={16 / 9}
                position="relative"
                width="250px"
              >
                <Box
                  as="img"
                  crossOrigin="anonymous"
                  alt="thumbnail"
                  src={post.video.thumbNail}
                  objectFit="cover"
                  cursor="pointer"
                  borderRadius=".70rem"
                  boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                />
              </AspectRatio>
              <Text
                as="span"
                position="relative"
                top="-30px"
                right="10px"
                zIndex="2"
                color="#FFFFFF"
                backgroundColor="rgb(0, 0, 0, 0.7)"
                borderRadius="4px"
                padding="4px"
                marginLeft="5px"
              >
                {Math.floor(post.video.duration / 60)}:{`0${Math.floor(post.video.duration - Math.floor(post.video.duration / 60) * 60)}`.slice(-2)}
              </Text>
            </Box>
            <Box
              marginTop="-15px"
            >
              <Stack isInline>
                <Box>
                  <Text
                    className={classes.title}
                    fontWeight="500"
                    fontSize={{ base: '1.1em' }}
                    color="#003971"
                    whiteSpace="wrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    marginTop="0.5em"
                  >
                    {post.video.title}
                  </Text>
                  <Text
                    color="#AAA"
                    fontWeight="500"
                    fontSize={{ base: '1em' }}
                    whiteSpace="wrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {post.video.description}
                  </Text>
                  <Text
                    color="#AAA"
                    fontWeight="500"
                    fontSize={{ base: '1em' }}
                  >
                    {moment(post.video.createdAt).format('LL')}
                  </Text>
                  <Text
                    color="#AAA"
                    fontWeight="500"
                    fontSize={{ base: '1em' }}
                  >
                    {post.video.privacyLabel}
                  </Text>
                </Box>
              </Stack>
            </Box>
          </Box>
        )
        : (
          <Box
            id={`group_post_${post._id}`}
            height="250px"
            padding="10px"
            cursor="pointer"
            borderRadius="10px"
            onClick={() => { selectPost(post); }}
          >
            <AspectRatio
              ratio={16 / 9}
              width="250px"
              borderRadius="1rem"
              border="1px solid blue"
            >
              <Box
                as="img"
                crossOrigin="anonymous"
                alt="thumbnail"
                objectFit="cover"
                src={post.image.thumbNail}
                cursor="pointer"
                borderRadius=".70rem"
                boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
              />
            </AspectRatio>
            <Flex>
              <Box marginTop={{ base: '0', md: '4px' }}>
                <Text
                  color="#003971"
                  fontWeight="500"
                  fontSize={{ base: '1.1em' }}
                  whiteSpace="wrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  marginTop="0.5em"
                >
                  {post.image.description}
                </Text>
                <Text
                  color="#AAA"
                  fontWeight="500"
                  fontSize={{ base: '1em' }}
                >
                  { moment(post.image.createdAt).format('LL') }
                </Text>
                <Text
                  color="#AAA"
                  fontWeight="500"
                  fontSize={{ base: '1em' }}
                >
                  {post.image.privacyLabel}
                </Text>
              </Box>
            </Flex>
          </Box>
        )}
    </Box>
  ));
  const renderMyAlbums = () => albums.map((album) => (
    <Box
      id={`group_album_${album._id}`}
      height="250px"
      padding="10px"
      cursor="pointer"
      borderRadius="10px"
      onClick={() => { selectAlbum(album); }}
      key={album._id}
    >
      <Box>
        <AspectRatio
          ratio={16 / 9}
          borderRadius="1rem"
          width="250px"
        >
          <Box
            as="img"
            crossOrigin="anonymous"
            alt="thumbnail"
            objectFit="cover"
            src={album.thumbnail}
            cursor="pointer"
            borderRadius=".70rem"
            boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
          />
        </AspectRatio>
        <Flex>
          <Box marginTop={{ base: '0', md: '4px' }}>
            <Text
              color="#003971"
              fontWeight="bold"
              fontSize={{ base: '1.3em', sm: '1.4em' }}
              whiteSpace="wrap"
              overflow="hidden"
              textOverflow="ellipsis"
              marginTop="0.4em"
            >
              {album.name}
            </Text>
            <Text
              color="#AAA"
              fontWeight="bold"
              fontSize={{ base: '1.1em', sm: '1.2em' }}
              whiteSpace="wrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {album.description}
            </Text>
            <Text
              color="#AAA"
              fontWeight="bold"
              fontSize={{ base: '1.1em', sm: '1.1em' }}
            >
              { moment(album.createdAt).format('LL') }
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  ));
  // author={<Text fontSize={{base:".7rem", sm:".7rem", md:".9rem"}} fontWeight="500" color="#000">{video.writer.username}</Text>}
  if (error) {
    if (error === 'ACCESS_DENIED') {
      return (
        <Flex className="app" width="100%" minHeight="90vh">
          <Box as="img" crossOrigin="anonymous" src={ErrorImage} width="35%" />
          <Text
            fontSize="1.3em"
            fontWeight="600"
          >
            You do not have permission to view this album.
          </Text>
        </Flex>
      );
    }
    return (
      <Flex className="app" width="100%" minHeight="90vh">
        <Box as="img" crossOrigin="anonymous" src={ErrorImage} width="35%" />
        <Text
          fontSize="1.3em"
          fontWeight="600"
        >
          An error occurred while loading the album.
        </Text>
      </Flex>
    );
  }
  return (
    <Box
      minHeight="90vh"
      paddingTop="3rem"
      margin="auto"
      className={classes.postGrid}
    >
      {
        loadCompleted ? (
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            width={{
              base: '100%',
            }}
            margin="auto"
          >
            <ImageBanner
              profile={profile}
              groupData={groupData}
              isAdmin={isAdmin}
            />
            <Box margin="auto">
              <Heading
                fontWeight="500"
                fontSize={{ base: '1.6rem', sm: '2rem' }}
                color="#0B67C2"
                marginTop="2rem"
              >
                {groupData.name}
              </Heading>
            </Box>
            <Box>
              <Text
                fontWeight="600"
                fontSize={{ base: '1.2rem' }}
                marginY="1em"
              >
                {groupData.description}
              </Text>
            </Box>
            <Box
              marginY="1em"
            >
              <GroupMembers
                groupData={groupData}
                profile={profile}
                isMember={isMember}
                isAdmin={isAdmin}
                isRequester={isRequester}
              />
            </Box>
            {isMember && renderPostList
              ? (
                <Box
                  width="100%"
                  margin="auto"
                >
                  <Flex
                    marginY="1em"
                    marginX="auto"
                    width="100%"
                  >
                    <Button
                      width={{ base: '250px' }}
                      backgroundColor="#2C6DAD"
                      _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                      color="#FFF"
                      padding="10px 16px"
                      borderRadius="50px"
                      cursor="pointer"
                      boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                      onClick={() => { onOpen(); retrievePosts(); }}
                      margin="auto"
                    >
                      <Text
                        textAlign="center"
                        fontSize="1.3em"
                        fontWeight="600"
                      >
                        Create New Post
                      </Text>
                    </Button>
                  </Flex>
                  <Box
                    width="100%"
                  >
                    {groupPostsLoaded
                      ? (
                        <Box>
                          {groupPosts.map((post) => (
                            <SinglePost
                              groupPost={post}
                              profile={profile}
                              allGroupPosts={groupPosts}
                              updatePostList={updatePostList}
                            />
                          ))}
                        </Box>
                      ) : <Box />}
                  </Box>
                  {
                /*
              <Post
                groupPosts={groupPosts}
                profile={profile}
              /> */
              }
                  <Modal isOpen={isOpen} onClose={onClose} size={{ base: '90%', md: '50%' }}>
                    <ModalOverlay />
                    <ModalContent width={{ base: '90%', md: '50%' }}>
                      <ModalCloseButton />
                      <ModalBody>
                        <Box margin="auto">
                          <Heading
                            fontWeight="500"
                            fontSize={{ base: '1.6rem', sm: '2rem' }}
                            color="#0B67C2"
                            marginTop="2rem"
                          >
                            Create New Post
                          </Heading>
                        </Box>
                        <Box marginY="3rem">
                          <Textarea
                            style={{ width: '100%', borderRadius: '5px' }}
                            onChange={handleTextChange}
                            value={postText}
                            placeholder="Add your statement"
                          />
                        </Box>
                        <Tabs>
                          <TabList>
                            <StyledTab
                              onClick={retrievePosts}
                            >
                              <Text
                                textAlign="center"
                                fontSize="1.3em"
                                fontWeight="600"
                              >
                                Image/Videos
                              </Text>
                            </StyledTab>
                            <StyledTab
                              onClick={retrieveAlbums}
                            >
                              <Text
                                textAlign="center"
                                fontSize="1.3em"
                                fontWeight="600"
                              >
                                Albums
                              </Text>
                            </StyledTab>
                          </TabList>
                          <TabPanels>
                            <TabPanel>
                              <Box>
                                {
                              loadingPosts ? <Box /> : (
                                <Box
                                  className={classes.familyPostGrid}
                                  spacing="10px"
                                  mb="24px"
                                  maxWidth={{
                                    base: '100%',
                                  }}
                                  height="300px"
                                >
                                  {renderMyPosts()}
                                </Box>
                              )
                            }
                              </Box>
                            </TabPanel>
                            <TabPanel
                              className={classes.pageView}
                            >
                              <Box>
                                {
                              loadingAlbums ? <Box /> : (
                                <Box
                                  className={classes.familyPostGrid}
                                  spacing="10px"
                                  mb="24px"
                                  maxWidth={{
                                    base: '100%',
                                  }}
                                  height="300px"
                                >
                                  {renderMyAlbums()}
                                </Box>
                              )
                            }
                              </Box>
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                        <Box
                          width={{ base: '200px' }}
                          backgroundColor="#2C6DAD"
                          color="#FFF"
                          padding="10px 16px"
                          borderRadius="50px"
                          cursor="pointer"
                          marginLeft="auto"
                          boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                          onClick={() => { saveGroupPost(); }}
                        >
                          <Text
                            textAlign="center"
                            fontSize="1.3em"
                            fontWeight="600"
                          >
                            Post
                          </Text>
                        </Box>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Box>
              ) : (
                <Box />
              )}
          </Flex>
        ) : <Box />
      }
    </Box>
  );
}

DetailGroupPage.propTypes = propTypes;

export default DetailGroupPage;
