/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Flex,
  Link,
  Heading,
  FormLabel,
  Input,
  Text,
  Stack,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  FormControl,
  Spinner,
  Checkbox,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import axios from 'axios';
import styled from 'styled-components';
import { Home } from '@styled-icons/boxicons-regular/Home';
import { Videos } from '@styled-icons/boxicons-solid/Videos';
import { FamilyRestroom } from '@styled-icons/material/FamilyRestroom';
import { Images } from '@styled-icons/bootstrap/Images';
import { Group2 } from '@styled-icons/remix-line/Group2';

import Share from '../../../assets/images/sharing.png';
import FamilyImg from '../../../assets/images/family2.png';

const propTypes = {
  showConnections: PropTypes.func.isRequired,
  setInviteList: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};

const useStyles = createUseStyles({
  thumbnailContainer: {
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});

function EmptyHome({
  showConnections,
  profile,
  userId,
}) {
  const [friendEmail, setFriendEmail] = useState();
  const [formErrorMessage, setFormErrorMessage] = useState();
  const classes = useStyles();
  const startConnection = () => {
    showConnections();
  };
  const handleSubmit = () => {
    axios({
      method: 'POST',
      url: '/api/invites/adminInvite',
      data: {
        email: friendEmail,
        userId,
        profile,
      },
    }).then(() => {
      // console.log(data.data)
      window.location.reload();
    })
      .catch((err) => {
        // console.log(err);
        let errMsg;

        switch (err?.response?.status) {
          case 400:
            errMsg = 'Invite has already been sent.';
            break;
          default:
            errMsg = 'Something has went wrong';
            break;
        }
        setFormErrorMessage(errMsg);
        setTimeout(() => {
          setFormErrorMessage('');
        }, 3000);
      });
  };
  const validateEmail = () => {
    const schema = yup.object().shape({
      email: yup.string().email().required(),
    });

    // check validity
    schema
      .isValid({
        email: friendEmail,
      })
      .then((valid) => {
        if (valid) {
          handleSubmit();
        } else {
          setFormErrorMessage('Email is not Valid');
          setTimeout(() => {
            setFormErrorMessage('');
          }, 3000);
        }
      });
  };
  return (
    <Box width="100%">
      <Box width="95%" position="relative" left="5%" top="5%">
        <Heading
          fontWeight="600"
          fontSize={{ base: '1.5em', sm: '2.0em' }}
          color="#0B67C2"
        >
          Invite Your Family
        </Heading>
      </Box>
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        minHeight="75vh"
        marginX="auto"
      >
        <Stack spacing={8} mx="auto" bg="blue.50" width={{ base: '90%', md: '500px' }} py={12} px={6}>
          <Stack align="center">
            <Heading fontSize="4xl">Start Sharing</Heading>
            <Text fontSize="1.3em" color="gray.600">
              By inviting your family ✌️
            </Text>
          </Stack>
          <Box
            rounded="lg"
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="lg"
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel fontSize="1.2em" fontWeight="500">Email address</FormLabel>
                <Input type="email" onChange={(event) => { setFriendEmail(event.target.value); }} />
              </FormControl>
              <Stack>
                <Button
                  bg="blue.400"
                  color="white"
                  _hover={{
                    bg: 'blue.500',
                  }}
                  fontSize="1.2em"
                  onClick={validateEmail}
                >
                  Invite My Family
                </Button>
                <Button
                  bg="white"
                  color="blue.400"
                  _hover={{
                    bg: '#EEE',
                  }}
                  onClick={startConnection}
                  fontSize="1.2em"
                >
                  Inviite More
                </Button>
              </Stack>
            </Stack>
            {formErrorMessage && (
            <Box>
              <p
                style={{
                  color: '#ff0000bf',
                  fontSize: '0.7rem',
                  border: '1px solid',
                  padding: '1rem',
                  borderRadius: '10px',
                }}
              >
                {formErrorMessage}
              </p>
            </Box>
            )}
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
}
EmptyHome.propTypes = propTypes;
export default EmptyHome;
