/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Divider,
  Flex,
  Heading,
  Text,
  Box,
} from '@chakra-ui/react';

const useStyles = createUseStyles({
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
  gridView: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',

  },
  friendCard: {
    margin: '1rem auto',
    padding: '1rem 1.2rem',
    width: '95%',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 4px 16px 0px',
  },
  searchResult: {
    padding: '16px',
    marginTop: '16px',
    width: '460px',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  friendCardButtonsContainer: {
    position: 'absolute',
    bottom: '24px',
  },
});

const MetaData = () => {
  const classes = useStyles();
  const [friendEmail, setFriendEmail] = useState('');
  const List = [{ userId: 'asdfwe', email: 'asfeff', _id: 'afeafsf' }];
  const [retrievedData, setRetrievedData] = useState(false);
  const [metaData, setMetaData] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/api/users/get-metadata',
    })
      .then((res) => {
        // console.log(data.data);
        setMetaData(res.data.metaData);
        setRetrievedData(true);
        // console.log(data.data);
      })
      .catch((err) => {
        // console.log(err);
        setErrorMessage(err);
      });
  }, [friendEmail]);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      marginTop="3rem"
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Heading
          fontWeight="500"
          fontSize={{ base: '2rem' }}
          color="#0B67C2"
        >
          Kloak New User Data
        </Heading>
        {!retrievedData
          ? <Heading fontWeight="500" size="lg" marginBottom="1rem">Loading Data</Heading>
          : (
            <Box
              marginY="1rem"
            >
              <Flex
                minH="100px"
                className="app"
                bg="#F6F6F6"
                margin="auto"
                padding="1rem"
                borderRadius="5px"
              >
                <Text
                  fontSize="1.2rem"
                  fontWeight="650"
                  margin="1em"
                >
                  Number of New User Accounts: {metaData.newAcconutsMade}
                </Text>
              </Flex>
            </Box>
          )}
      </Flex>
      <Divider width="100%" marginY="28px" />
    </Flex>
  );
};

export default MetaData;
