/* eslint-disable linebreak-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Flex } from '@chakra-ui/react';
import LandingHead from '../sections/LandingHead';
import LandingBody from '../sections/LandingBody';
import LandingSimple from '../sections/LandingSimple';
import LandingSecure from '../sections/LandingSecure';
import LandingShare from '../sections/LandingShare';
import SignUp from '../sections/SignUp';
import Pricing from '../sections/Pricing';
// import Footer from "./sections/Footer" // will add this in the part 2

export default function LandingLayout(props) {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      maxW={{ sm: '100%', xl: '1600px' }}
      m="0 auto"
    >
      <LandingHead />
      <LandingBody />
      <LandingSimple />
      <LandingSecure />
      <LandingShare />
      <SignUp />
      <Pricing />
      {props.children}
      {/* <Footer /> */}
    </Flex>
  );
}
