/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { createUseStyles } from 'react-jss';
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Divider,
  Spinner,
  AspectRatio,
  Button,
  Badge,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GroupAdd } from '@styled-icons/material-rounded/GroupAdd';
import GroupSearch from './sections/GroupSearch';

const InviteButton = styled(GroupAdd)`
  width: 56px;
  outline: none;
  border: none;
  color: #888;
  margin: auto;
  @media (max-width: 350px) {
    width: 24px;
  }
`;
const StyledButton = styled(Box)`
text-align: center;
color: #FFF;
background-color: #2C6DAD;
padding: 16px 32px;
border-radius: 50px;
cursor: pointer;
font-size: 1.2rem;
margin: 0 auto 1rem auto;
&:hover {
  background-color: #2C8EBD;
}
`;
const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  groupPostGrid: {
    display: 'grid',
    gridGap: '1em',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function Group({
  history,
}) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [profile, setProfile] = useState();
  const [errMessage, setErrMessage] = useState();
  const [allGroups, setAllGroups] = useState([]);
  const [groupInvites, setGroupInvites] = useState([]);
  const [groupPageLoading, setGroupPageLoading] = useState(true);

  const acceptRequest = (invite) => {
    setGroupPageLoading(true);
    axios({
      method: 'POST',
      url: '/api/groups/accept-group-invite',
      data: {
        profileId: profile._id,
        invite,
      },
    })
      .then((res) => {
        const allInvites = [];
        const updatedGroups = allGroups;
        updatedGroups.unshift(res.data.updatedGroup);
        groupInvites.forEach((eachInvite) => {
          if (eachInvite._id !== invite._id) {
            allInvites.push(eachInvite);
          }
        });
        setAllGroups(updatedGroups);
        setGroupInvites(allInvites);
        setGroupPageLoading(false);
      })
      .catch((err) => {
        setErrMessage(err);
      });
  };

  const rejectRequest = (invite) => {
    setGroupPageLoading(true);
    axios({
      method: 'POST',
      url: '/api/groups/reject-group-invite',
      data: {
        profileId: profile._id,
        invite,
      },
    })
      .then((res) => {
        const allInvites = [];
        groupInvites.forEach((eachInvite) => {
          if (eachInvite._id !== invite._id) {
            allInvites.push(eachInvite);
          }
        });
        setGroupInvites(allInvites);
        setGroupPageLoading(false);
      })
      .catch((err) => {
        setErrMessage(err);
      });
  };

  const renderMyGroups = () => allGroups.map((group) => (
    <Box>
      <Box position="relative" textAlign="right" width="100%">
        <AspectRatio
          ratio={16 / 9}
          position="relative"
        >
          {group.banner
            ? (
              <Box
                as="img"
                crossOrigin="anonymous"
                alt="thumbnail"
                src={group.banner.thumbNail}
                objectFit="cover"
                cursor="pointer"
                borderRadius=".70rem"
                marginTop="20px"
                boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                onClick={() => { history.push(`/group/${group._id}`); }}
              />
            ) : (
              <Box
                width={{
                  base: '100%',
                }}
                marginTop="20px"
                height="300px"
                cursor="pointer"
                borderRadius=".70rem"
                backgroundColor="#000"
                onClick={() => { history.push(`/group/${group._id}`); }}
              />
            )}
        </AspectRatio>
      </Box>
      <Box>
        <Stack isInline>
          <Box>
            <a href={`/group/${group._id}`}>
              <Text
                className={classes.title}
                fontWeight="bold"
                fontSize={{ base: '1.3em', sm: '1.4em' }}
                color="#003971"
                whiteSpace="wrap"
                overflow="hidden"
                textOverflow="ellipsis"
                marginTop="1.5em"
              >
                {group.name}
              </Text>
            </a>
            <Text
              color="#AAA"
              fontWeight="bold"
              fontSize={{ base: '1.1em', sm: '1.2em' }}
              whiteSpace="wrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {group.description}
            </Text>
            {
              group.members.length > 0
                ? (
                  <Text
                    fontWeight="600"
                    color="#AAA"
                    fontSize={{ base: '1.2rem' }}
                  >
                    {group.members.length + group.admins.length} members
                  </Text>
                ) : (
                  <Text
                    fontWeight="600"
                    color="#AAA"
                    fontSize={{ base: '1.2rem' }}
                  >
                    {group.members.length + group.admins.length} member
                  </Text>
                )
            }
          </Box>
        </Stack>
      </Box>
    </Box>
  ));
  const renderMyGroupInvites = () => groupInvites.map((invite) => (
    <Box>
      <Box position="relative" textAlign="right" width="100%">
        <AspectRatio
          ratio={16 / 9}
          position="relative"
        >
          {invite.group.banner
            ? (
              <Box
                as="img"
                crossOrigin="anonymous"
                alt="thumbnail"
                src={invite.group.banner.thumbNail}
                objectFit="cover"
                cursor="pointer"
                borderRadius=".70rem"
                marginTop="20px"
                boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                onClick={() => { history.push(`/group/${invite.group._id}`); }}
              />
            ) : (
              <Box
                width={{
                  base: '100%',
                }}
                marginTop="20px"
                height="300px"
                cursor="pointer"
                borderRadius=".70rem"
                backgroundColor="#000"
                onClick={() => { history.push(`/group/${invite.group._id}`); }}
              />
            )}
        </AspectRatio>
      </Box>
      <Box>
        <Stack isInline>
          <Box>
            <a href={`/group/${invite.group._id}`}>
              <Text
                className={classes.title}
                fontWeight="bold"
                fontSize={{ base: '1.3em', sm: '1.4em' }}
                color="#003971"
                whiteSpace="wrap"
                overflow="hidden"
                textOverflow="ellipsis"
                marginTop="1.5em"
              >
                {invite.group.name}
              </Text>
            </a>
            <Text
              color="#AAA"
              fontWeight="bold"
              fontSize={{ base: '1.1em', sm: '1.2em' }}
              whiteSpace="wrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {invite.group.description}
            </Text>
            {
              invite.group.members.length > 0
                ? (
                  <Text
                    fontWeight="600"
                    color="#AAA"
                    fontSize={{ base: '1.2rem' }}
                  >
                    {invite.group.members.length + invite.group.admins.length} members
                  </Text>
                ) : (
                  <Text
                    fontWeight="600"
                    color="#AAA"
                    fontSize={{ base: '1.2rem' }}
                  >
                    {invite.group.members.length + invite.group.admins.length} member
                  </Text>
                )
            }

          </Box>
        </Stack>
        <Stack className={classes.friendCardButtonsContainer} isInline>
          <Button
            onClick={() => acceptRequest(invite)}
            display="block"
            width="110px"
            color="white"
            borderRadius="24px"
            bg="#FF1A75"
            _hover={{
              bg: '#FFE6EE',
              color: '#FF1A75',
            }}
          >
            Accept
          </Button>
          <Button
            onClick={() => rejectRequest(invite)}
            isLoading={false}
            variant="outline"
            display="block"
            width="110px"
            borderRadius="24px"
            color="#FF1A75"
            borderColor="#FF1A75"
            _hover={{
              bg: '#FFE6EE',
              color: '#FF1A75',
            }}
          >
            Decline
          </Button>
        </Stack>
      </Box>
    </Box>
  ));
  useEffect(() => {
    if (user.userData) {
      axios({
        method: 'POST',
        url: '/api/users/getProfile',
        data: {
          username: user.userData.username,
        },
      })
        .then((data) => {
          setProfile(data.data);
          axios({
            method: 'POST',
            url: '/api/groups/groups-by-profile',
            data: {
              profileId: data.data._id,
            },
          })
            .then((res) => {
              setGroupPageLoading(true);
              // console.log(res.data.groupInvites);
              setAllGroups(res.data.myGroups);
              setGroupInvites(res.data.groupInvites);
              setGroupPageLoading(false);
            })
            .catch((groupErr) => {
              setErrMessage(groupErr);
            });
        })
        .catch((err) => {
          setErrMessage('Cannot fetch user profile');
        });
    }
  }, [user.userData, user]);

  return (
    <Box
      width={{ base: '90%', xl: '1200px' }}
      marginX="auto"
      marginY="2rem"
      minHeight="90vh"
    >
      {groupPageLoading ? (
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <Spinner
            thickness="4px"
            emptyColor="gray.200"
            color="#FF1A75"
            size="xl"
            mb="8px"
            width="50px"
            margin="auto"
          />
          <Text>Loading</Text>
        </Flex>
      )
        : (
          <Flex
            justifyContent="center"
            flexDirection="column"
            marginX="1rem "
            width={{ base: '90%', xl: '1200px' }}
          >
            <Box>
              <GroupSearch />
            </Box>
            <Box
              marginY="3rem"
            >
              <Heading
                fontWeight="500"
                fontSize={{ base: '1.4em', sm: '2em' }}
                marginBottom="1rem"
                color="#0B67C2"
                marginTop="1em"
                textAlign="center"
              >
                Group Invites
              </Heading>
              { groupInvites.length > 0
                ? (
                  <Box
                    className={classes.groupPostGrid}
                  >
                    {renderMyGroupInvites()}
                  </Box>
                ) : (
                  <Box>
                    <Flex
                      justifyContent="center"
                    >
                      <InviteButton />
                    </Flex>
                    <Flex
                      justifyContent="center"
                    >
                      <Text
                        fontWeight="500"
                        fontSize={{ base: '1.3rem' }}
                        marginBottom="1rem"
                        color="#888"
                        marginTop="1em"
                        textAlign="center"
                      >
                        Group Invites will appear here
                      </Text>
                    </Flex>
                  </Box>
                )}
            </Box>
            <Box>
              <Heading
                fontWeight="500"
                fontSize={{ base: '1.4em', sm: '2em' }}
                marginTop="1em"
                color="#0B67C2"
                textAlign="center"
              >
                My Groups
              </Heading>
              { allGroups.length === 0
                ? (
                  <Box>
                    <Flex
                      justifyContent="center"
                    >
                      <InviteButton />
                    </Flex>
                    <Flex
                      justifyContent="center"
                    >
                      <Text
                        fontWeight="500"
                        fontSize={{ base: '1.3rem' }}
                        marginBottom="1rem"
                        color="#888"
                        marginTop="1em"
                        textAlign="center"
                      >
                        Your Groups will show up here
                      </Text>
                    </Flex>
                  </Box>
                ) : (
                  <Box />
                )}
              <Flex justifyContent="center">
                <Button
                  marginY="1em"
                  bg="#3480E3"
                  color="#FFF"
                  borderRadius="24px"
                  py="4"
                  px="4"
                  width="230px"
                  lineHeight="1"
                  onClick={() => { history.push('/new-group'); }}
                >
                  <Text>Create New Group</Text>
                </Button>
              </Flex>
              { allGroups.length > 0
                ? (
                  <Box
                    className={classes.groupPostGrid}
                  >
                    {renderMyGroups()}
                  </Box>
                ) : (
                  <Box />
                )}
            </Box>
          </Flex>
        )}
    </Box>
  );
}
Group.propTypes = propTypes;
export default withRouter(Group);
