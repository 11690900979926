/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Box,
  Text,
  Avatar,
  Flex,
  Input,
  SimpleGrid,
  Spinner,
  Button,
} from '@chakra-ui/react';
import { ChatDotsFill } from '@styled-icons/bootstrap/ChatDotsFill';
import { NewMessage } from '@styled-icons/entypo/NewMessage';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack';
import debounce from 'lodash.debounce';
import ChatRoom from './ChatRoom';

const StyledButton = styled(Button)`
color: #006644;
background-color: #BBEFDF;
border-radius: 10px;
&:hover {
  background-color: #BBEFDF;
}
&:focus {
  outline:0 !important;
  box-shadow: none;
  border: none;
}
`;

const NewMessageButton = styled(NewMessage)`
  width: 24px;
  margin: 12px;
`;
const CloseButton = styled(CloseOutline)`
  width: 28px;
  margin: 12px;
`;
const ArrowButton = styled(ArrowBack)`
  width: 24px;
  margin: 12px;
`;
const ChatButton = styled(ChatDotsFill)`
  width: 36px;
  position: relative;
  z-index: 9999;
  color: #000;
  cursor: pointer;
`;
const propTypes = {
  profile: PropTypes.object.isRequired,
  profileTo: PropTypes.object.isRequired,
  roomId: PropTypes.string.isRequired,
  resetChatPage: PropTypes.func.isRequired,
  backToChatBox: PropTypes.func.isRequired,
};
function CustomRoom({
  profile,
  profileTo,
  roomId,
  resetChatPage,
  backToChatBox,
}) {
  const [err, setErr] = useState('');
  const [familyResult, setFamilyResult] = useState([]);
  const [addedUsertoRoom, setAddedUsersToRoom] = useState(false);
  const [loadedUserResults, setLoadedUserResults] = useState(true);
  const [loadedChatRoom, setLoadedChatRoom] = useState(false);
  const [recipientList, setRecipientList] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [loadingCon, setloadingCon] = useState(false);
  const [processComplete, setProcessComplete] = useState(false);
  const [conversationArr, setConversationArr] = useState([]);
  const [userMessages, setUserMessages] = useState([]);
  const [lastConPageReached, setLastConPageReached] = useState(false);
  const [initializedCon, setInitializedCon] = useState(false);
  const [currentConPage, setCurrentConPage] = useState(0);
  const [conLinesPerPage, setConLinesPerPage] = useState(15);
  const [currentChatRoom, setCurrentChatRoom] = useState();
  const [chatRoomEstablished, setChatRoomEstablished] = useState(false);
  const [totalConversationSize, setTotalConversationSize] = useState(0);
  const [chatRoomId, setChatRoomId] = useState();
  const [groupName, setGroupName] = useState('');

  const closeRoomBox = () => {
    document.getElementById('chat-room').style.display = 'none';
    resetChatPage();
  };
  const displayConversation = (isNewChatLine, currentHeight) => {
    conversationArr.forEach((conv) => {
      // console.log(`new on ${conv.messageBody}`);
    });
    if (isNewChatLine) {
      // console.log(`conversation arr: ${conversationArr}`);
      const conView = document.getElementById('custom-chatbox');
      conView.scrollTop = conView.scrollHeight - conView.clientHeight;
    } else {
      // console.log(`conversation arr: ${conversationArr}`);
      const conView = document.getElementById('custom-chatbox');
      // console.log(`new height: ${conView.scrollHeight}`);
      conView.scrollTop = conView.scrollHeight - currentHeight;
    }
  };

  const updateConversation = (newConverArr) => {
    setUserMessages(userMessages.concat(newConverArr));
    // console.log('timeframe 1');
    setLoadedChatRoom(true);
  };
  const findConversation = (newRecipientList) => {
    setChatRoomEstablished(false);
    setCurrentConPage(0);
    if (newRecipientList.length === 0) return;
    axios({
      method: 'POST',
      url: '/api/chatbox/profile-conversation',
      data: {
        profileIds: newRecipientList,
        page: 0,
        perPage: conLinesPerPage,
      },
    })
      .then((res) => {
        // console.log('reached');
        // console.log(`profile data: + ${data.data._id}`);
        const isNewChatLine = true;
        const currentHeight = 0;
        setInitializedCon(true);
        // console.log(`newData: ${typeof (res.data.conversation)}`);
        setCurrentChatRoom(res.data.room);
        const newConversation = res.data.conversation;
        // console.log(newConversation);
        setUserMessages(newConversation);
        setChatRoomId(res.data.room._id);
        setLoadedChatRoom(true);
        setCurrentConPage(currentConPage + 1);
        setTotalConversationSize(res.data.conversationSize);
        // socket.emit('join', res.data.room._id);
        // console.log(`conversation arr: ${conversationArr}`);
        setloadingCon(false);
        // console.log(`total pages: ${currentConPage}`);
        setChatRoomEstablished(true);
        displayConversation(isNewChatLine, currentHeight);
      })
      .catch((convErr) => {
        setErr(convErr);
      });
  };
  const onProfileChange = (event) => {
    event.persist();
    setCurrentConPage(0);
    setConversationArr([]);
    setLoadedChatRoom(false);
    setChatRoomEstablished(false);
    const recipientIds = [];
    recipientList.forEach((user) => {
      recipientIds.push(user._id);
    });
    axios({
      method: 'POST',
      url: '/api/chatbox/add-recipient',
      data: {
        query: String(event.target.value),
        profileId: profile._id,
        recipientIds,
      },
    })
      .then((response) => {
        if (event.target.value === '') {
          setLoadedChatRoom(true);
          setLoadedUserResults(false);
        } else {
          const { profiles } = response.data;
          setFamilyResult(profiles);
          setLoadedUserResults(true);
          document.getElementById('family-results').style.display = 'block';
          // console.log('what');
        }
      })
      .catch((profErr) => {
        setErr(profErr);
      });
  };

  const getConversation = (myChatRoom) => {
    axios({
      method: 'POST',
      url: '/api/chatbox/conversation-query',
      data: {
        roomId: myChatRoom._id,
        page: currentConPage,
        perPage: conLinesPerPage,
      },
    })
      .then((res) => {
        // console.log(`profile data: + ${data.data._id}`);
        let isChatLine;
        let currentHeight = 0;
        if (!initializedCon) {
          setInitializedCon(true);
          setCurrentChatRoom(myChatRoom);
          setChatRoomId(myChatRoom._id);
          setTotalConversationSize(res.data.conversationSize);
          isChatLine = true;
        } else {
          isChatLine = false;
          currentHeight = document.getElementById('custom-chatbox').scrollHeight;
          // console.log(`current height: ${currentHeight}`);
        }
        setUserMessages(res.data.conversation.concat(userMessages));
        setProcessComplete(true);
        if (res.data.conversation.length === 0) {
          setLastConPageReached(true);
          setloadingCon(false);
        } else {
          setCurrentConPage(currentConPage + 1);
          setloadingCon(false);
        }
        setLoadedChatRoom(true);
        // console.log('reached final');
        displayConversation(isChatLine, currentHeight);
        // socket.emit('join', myChatRoom._id);
      })
      .catch((convErr) => {
        setErr(convErr);
      });
  };
  useEffect(() => {
    if (document.getElementById('custom-chatbox')) {
      document.getElementById('custom-chatbox').onscroll = debounce(() => {
        // console.log('scroll detection');
        // console.log(document.getElementById('custom-chatbox').scrollTop);
        if ((document.getElementById('custom-chatbox').scrollTop < 50)
            && (lastConPageReached === false) && (initializedCon)) {
          // console.log('match found');
          // Do awesome stuff like loading more content!
          setloadingCon(true);
          getConversation(currentChatRoom);
        }
      }, 300);
    }
  });
  const onGroupNameChange = (event) => {
    setErr('');
    setGroupName(event.target.value);
  };
  const addRecipient = (newProfile) => {
    // console.log(newProfile);
    const newRecipientList = recipientList;
    newRecipientList.push(newProfile);
    setRecipientList(newRecipientList);
    setLoadedUserResults(false);
    setAddedUsersToRoom(true);
    setProcessComplete(true);
    document.getElementById('recipient-adder').value = '';

    const allRoomUsers = [profile._id];
    newRecipientList.forEach((recProfile) => {
      allRoomUsers.push(recProfile._id);
    });
    /*
    axios({
      method: 'POST',
      url: '/api/chatbox/profile-conversation',
      data: {
        profileIds: allRoomUsers,
        page: currentConPage,
        perPage: conLinesPerPage,
      },
    })
      .then((res) => {
        console.log('reached');
        // console.log(`profile data: + ${data.data._id}`);
        const isNewChatLine = true;
        const currentHeight = 0;
        setInitializedCon(true);
        console.log(`newData: ${typeof (res.data.conversation)}`);
        setCurrentChatRoom(res.data.room);
        const newConversation = res.data.conversation;
        newConversation.forEach((conv) => {
          console.log(`m: ${conv.messageBody}`);
        });
        setUserMessages(newConversation);
        setChatRoomId(res.data.room._id);
        setLoadedChatRoom(true);
        setCurrentConPage(currentConPage + 1);
        setTotalConversationSize(res.data.conversationSize);
        // socket.emit('join', res.data.room._id);
        // console.log(`conversation arr: ${conversationArr}`);
        setloadingCon(false);
        console.log(`total: ${userMessages}`);
        setChatRoomEstablished(true);
        displayConversation(isNewChatLine, currentHeight);
      })
      .catch((convErr) => {
        setErr(convErr);
      });
    console.log(`new list ${recipientList[0].username}`);
    */
  };
  const removeRecipient = (user) => {
    const newRecipientList = [];
    recipientList.forEach((recipient) => {
      if (recipient._id !== user._id) {
        newRecipientList.push(recipient);
      }
    });
    setCurrentConPage(0);
    setRecipientList(newRecipientList);
    setProcessComplete(true);
    // console.log(newRecipientList);
    findConversation(newRecipientList);
  };
  const createGroup = () => {
    if (groupName === '') {
      // console.log('Error: Group name cannot by empty.');
      setErr('Error: Group name cannot by empty.');
      return;
    }
    // console.log(groupName);
    axios({
      method: 'POST',
      url: '/api/chatbox/create-chat-group',
      data: {
        name: groupName,
        author: profile._id,
        profiles: recipientList,
      },
    })
      .then((data) => {
        // console.log(data.data);
        setErr('');
        setGroupName('');
        setRecipientList([]);
        backToChatBox();
      })
      .catch((convErr) => {
        setErr(convErr);
      });
  };
  const listUserResults = () => familyResult.map((familyProfile, index) => (
    <Box
      onClick={() => { addRecipient(familyProfile); }}
      id="message-recipient"
    >
      <Flex margin="8px">
        <Box
          as="img"
          crossOrigin="anonymous"
          src={familyProfile.image}
          width="42px"
          height="42px"
          objectFit="cover"
          borderRadius="50%"
          verticalAlign="middle"
          alignItems="center"
        />
        <Box>
          <Text
            color="#000"
            fontWeight="500"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            marginTop="4px"
          >
            {familyProfile.username}
          </Text>
          <Text
            color="#000"
            fontWeight="400"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {familyProfile.email}
          </Text>
        </Box>
      </Flex>
    </Box>
  ));
  const handleMessageChange = (event) => {
    setCurrentMessage(event.target.value);
  };
  const listofChatUsers = () => recipientList.map((user, index) => (
    <Box id={`username${user._id}`}>
      <Text
        color="#000"
      >
        {user.username}
      </Text>
      <Text as="span" onClick={() => { removeRecipient(user); }}>
        &times;
      </Text>
    </Box>
  ));
  const viewConversation = () => userMessages.map((conversation, index) => (
    <Box
      width="90%"
      margin="auto"
    >
      {
        conversation.profile._id === profile._id ? (
          <Flex
            width="100%"
            display="flex"
            justifyContent="end"
          >
            <Text
              as="span"
              maxW="80%"
              color="#000"
              fontWeight="400"
              backgroundColor="#FFF"
              whiteSpace="wrap"
              overflowY="hidden"
              overflowX="auto"
              textOverflow="ellipsis"
              marginY="0.5rem"
              marginLeft="auto"
              marginRight="0"
              padding="5px 10px"
              borderRadius="5px"
            >
              {conversation.messageBody}
            </Text>
          </Flex>
        ) : (
          <Flex margin="8px">
            <Box
              as="img"
              crossOrigin="anonymous"
              src={conversation.profile.image}
              width="42px"
              height="42px"
              objectFit="cover"
              borderRadius="50%"
              verticalAlign="middle"
              alignItems="center"
              marginRight="10px"
            />
            <Box>
              <Text
                color="#000"
                fontWeight="500"
                width={{
                  base: '100%',
                }}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                marginTop="4px"
              >
                {conversation.profile.username}:
              </Text>
              <Text
                color="#000"
                fontWeight="400"
                width={{
                  base: '100%',
                }}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {conversation.messageBody}
              </Text>
            </Box>
          </Flex>
        )
      }
    </Box>
  ));
  useEffect(() => {
    // console.log('running');
    /*
    if (socket) {
      socket.once('customMsgSent', (newMsg) => {
        setUserMessages(userMessages.concat(newMsg));
        const conView = document.getElementById('custom-chatbox');
        conView.lastElementChild.scrollIntoView({ behavior: 'smooth' });
      });
    }
    */
  });
  const createRoomSubmitMessage = () => {
    const updatedConversation = conversationArr;
    // console.log(conversationArr);
    const msgDataObject = {
      messageBody: currentMessage,
      profile,
      roomId: chatRoomId,
    };
    updatedConversation.push(msgDataObject);
    updateConversation(updatedConversation);
    // socket.emit('sendCustomMessage', msgDataObject, chatRoomId);
    axios({
      method: 'POST',
      url: '/api/chatbox/submit-message',
      data: msgDataObject,
    })
      .then(() => {
        setCurrentMessage('');
        setTotalConversationSize(totalConversationSize + 1);
        const conView = document.getElementById('chatlist-view');
        conView.lastElementChild.scrollIntoView({ behavior: 'smooth' });
        document.getElementById('custom-chat-input').value = '';
      })
      .catch((convErr) => {
        setErr(convErr);
      });
  };
  const checkEnterKey = (event) => {
    if (event.key === 'Enter') {
      createRoomSubmitMessage();
    }
  };
  return (
    <Box
      width="90%"
      margin="auto"
    >
      <Box
        marginY="0.5em"
      >
        <Text
          fontSize="1.1em"
          fontWeight="600"
        >
          Group Name
        </Text>
        <Input
          id="custom-chat-group"
          onChange={onGroupNameChange}
          placeholder="Enter Group name"
        />
      </Box>
      <Box
        marginY="0.5em"
      >
        <Text
          fontSize="1.1em"
          fontWeight="600"
        >
          Add Group Memebers
        </Text>
        <Input
          id="recipient-adder"
          onChange={onProfileChange}
          placeholder="Search Family Members"
        />
      </Box>
      <Box
        marginY="0.5rem"
        maxHeight="100px"
        overflowY="scroll"
      >
        {recipientList.length > 0
          ? (
            <Flex
              flexWrap="wrap"
            >
              { recipientList.map((user) => (
                <Box
                  margin="5px"
                  id={`username${user._id}`}
                  backgroundColor="#DDD"
                >
                  <Flex>
                    <Box>
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        marginLeft="0.5rem"
                      >
                        <Text
                          color="#000"
                          fontWeight="500"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {user.username}
                        </Text>
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        marginX="0.5rem"
                      >
                        <Text
                          as="span"
                          fontSize="1.2rem"
                          onClick={() => { removeRecipient(user); }}
                        >
                          &times;
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              ))}
            </Flex>
          ) : <Box />}
      </Box>
      <Box id="user-add-room">
        {loadedUserResults
          ? (
            <Box
              marginY="1rem"
              height="200px"
              overflowY="scroll"
            >
              {listUserResults()}
            </Box>
          ) : <Box />}
      </Box>
      {loadingCon
        ? (
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <Spinner
              thickness="4px"
              emptyColor="gray.200"
              color="#FF1A75"
              size="xl"
              mb="8px"
            />
            <Text>Loading</Text>
          </Flex>
        )
        : <Box />}
      <Flex
        position="absolute"
        bottom="0.5rem"
        width="100%"
      >
        <Box
          width="100%"
        >
          <Box
            bottom="0"
            margin="auto"
            height="100%"
          >
            {err !== '' ? (
              <Box>
                <Text
                  marginY="0.5em"
                  color="#FF0000"
                >
                  Error: Group name cannot by empty.
                </Text>
              </Box>
            ) : <Box />}
            <StyledButton
              onClick={createGroup}
            >
              Create Group
            </StyledButton>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

CustomRoom.propTypes = propTypes;

export default CustomRoom;
