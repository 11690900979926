/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createUseStyles } from 'react-jss';
import { ThreeDots } from '@styled-icons/bootstrap/ThreeDots';

const SettingsMenu = styled(ThreeDots)`
  width: 28px;
  outline: none;
  border: none;
  color: #777;
  @media (max-width: 350px) {
    width: 24px;
  }
`;

const propTypes = {
  groupPost: PropTypes.object.isRequired,
  groupPostList: PropTypes.array.isRequired,
  updatePostList: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',

  },
  familyPostGrid: {
    display: 'grid',
    gridGap: '1em',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#111',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#F1F1F1',
    fontSize: '30px',
    fontWeight: 'bold',
    transition: '0.3s',
    cursor: 'pointer',
    padding: '0 14px',
    borderRadius: '50%',
    '&:hover': {
      color: '#bbb',
      textDecoration: 'none',
    },
  },
  leftButton: {
    position: 'absolute',
    top: '50%',
    left: '24px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  rightButton: {
    position: 'absolute',
    top: '50%',
    right: '-26px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  privacyButton: {
    backgroundColor: '#222',
    borderRadius: '5px',
    padding: '6px 12px',
    color: '#FFF',
    marginTop: '1rem',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
function EditPost({
  groupPost,
  groupPostList,
  updatePostList,
  profile,
}) {
  const classes = useStyles();
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isOpenEditModal,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();
  const [imagePrivacy, setImagePrivacy] = useState();
  // const { isOpenEdit, onOpen, onClose } = useDisclosure();
  const [errMessage, setErrMessage] = useState();
  const deletePost = () => {
    // console.log(image);
    // include the path for both thumbnail and actual image
    axios({
      method: 'DELETE',
      url: '/api/groups/post-by-id',
      data: {
        postId: groupPost._id,
        profileId: profile._id,
      },
    })
      .then((res) => {
        const newPostList = [];
        // console.log(albumList);
        groupPostList.forEach((eachPost) => {
          if (groupPost._id !== eachPost._id) {
            newPostList.push(eachPost);
          }
        });
        updatePostList(newPostList);
        onCloseDeleteModal();
      })
      .catch((err) => {
        setErrMessage(err.message);
      });
  };
  /*
  const renderDetailsForm = () => (
    <Formik
      initialValues={{
        name: album.name,
        description: album.description,
        privacy: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Album must have a name'),
        privacy: Yup.string()
          .required('Must select a privacy option'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let label = '';
        Privacy.forEach((item) => {
          if (item.value === values.privacy) label = item.label;
        });
        setTimeout(() => {
          const data = {
            name: values.name,
            description: values.description,
            privacy: values.privacy,
            albumId: album._id,
            privacyLabel: label,
          };
          axios.post('/api/image/updateAlbum', data).then((response) => {
            // console.log(response.data.imageData);
            updateAlbum(response.data.updatedAlbum);
            onCloseEditModal();
          });
        });
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form onSubmit={handleSubmit} width="100%">
            <Field name="name">
              {() => (
                <FormControl
                  isRequired
                  isInvalid={errors.name && touched.name}
                  mb="4px"
                >
                  <FormLabel htmlFor="title" ml="4px" fontWeight="300">
                    Name
                  </FormLabel>
                  <br />
                  <Input
                    id="name"
                    placeholder="Give your Album a name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width={{ base: '98%', md: '100%' }}
                    margin="auto"
                  />
                  <div className={classes.formErrorMessage}>
                    {touched.name && errors.name}
                  </div>
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {() => (
                <FormControl mb="25px">
                  <FormLabel htmlFor="description" ml="4px" fontWeight="300">
                    Description
                  </FormLabel>
                  <br />
                  <textarea
                    id="description"
                    placeholder="What is this video about?"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '100%' }}
                  />
                </FormControl>
              )}
            </Field>
            <Field
              name="privacy"
            >
              {() => (
                <FormControl
                  isRequired
                  isInvalid={errors.privacy && touched.privacy}
                  mb="25px"
                >
                  <FormLabel htmlFor="privacy" ml="4px" fontWeight="300">
                    Privacy
                  </FormLabel>
                  <br />
                  <Select id="privacy" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                    {Privacy.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                  <Box className={classes.formErrorMessage}>
                    {touched.privacy && errors.privacy}
                  </Box>
                </FormControl>
              )}
            </Field>
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Publishing..."
              onClick={handleSubmit}
              display="block"
              borderRadius="24px"
              mt="16px"
              mb="16px"
              mr="auto"
              ml="auto"
              width="200px"
              color="white"
              bg="#FF1A75"
              _hover={{
                bg: '#FFE6EE',
                color: '#FF1A75',
              }}
            >
              Save
            </Button>
          </form>
        );
      }}
    </Formik>
  );
  */
  return (
    <Box
      zIndex="9"
    >
      <Menu>
        <MenuButton>
          <SettingsMenu />
        </MenuButton>
        <MenuList
          placement="bottom-start"
          zIndex="9999"
          backgroundColor="#EEE"
          width="125px"
        >
          {/*
            <MenuItem onClick={() => { getAlbumImages(album); }}>
              <Text
                fontWeight="500"
                fontSize="1rem"
                padding="12px"
              >
                Add Images
              </Text>
            </MenuItem>
          <MenuItem onClick={onOpenEditModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Edit
            </Text>
          </MenuItem>
          */
          }
          <MenuItem onClick={onOpenDeleteModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Delete
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      {
          /*
      <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Album Properties</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderDetailsForm()}
          </ModalBody>
        </ModalContent>
      </Modal>
      */
      }
      <Modal isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Post</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p1>Are you sure you want to Delete this Post?</p1>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseDeleteModal}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={deletePost}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
EditPost.propTypes = propTypes;
export default EditPost;
