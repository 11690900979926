/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Text,
  List,
  SimpleGrid,
  AspectRatio,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  Button,
  Heading,
  Input,
  Textarea,
  Stack,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
} from '@chakra-ui/react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SearchAlt } from '@styled-icons/boxicons-regular/SearchAlt';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const SearchButton = styled(SearchAlt)`
  width: 36px;
  outline: none;
  border: none;
  color: #2C8EBD;
  cursor: pointer;
  &:focus {
    outline:0 !important;
    border: 0 !important;
    color: #1890ff !important;
  }
  @media (max-width: 350px) {
    width: 24px;
  }
`;
function GroupSearch({
  history,
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [errMessage, setErrMessage] = useState();

  const initSearch = () => {
    if (searchQuery === '') return;
    const query = searchQuery.replace(/[^\w\s]/gi, '');
    // console.log(query);
    history.push(`/group-search-result/${searchQuery}`);
  };
  const updateQuery = (event) => {
    // console.log(event.target.value);
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    // Get the input field
    const input = document.getElementById('group-search-bar');
    if (input) {
      // Execute a function when the user releases a key on the keyboard
      input.addEventListener('keyup', (event) => {
        const query = searchQuery.replace(/[^\w\s]/gi, '');
        // Number 13 is the "Enter" key on the keyboard
        if (event.key === 'Enter' && query.length > 0) {
          // Cancel the default action, if needed
          event.preventDefault();
          // Trigger the button element with a click
          // console.log(query);
          history.push(`/group-search-result/${searchQuery}`);
        }
      });
    }
  });
  return (
    <Box>
      <Box>
        <Heading
          fontWeight="500"
          fontSize={{ base: '1.4em', sm: '2em' }}
          marginBottom="1rem"
          color="#0B67C2"
          marginY="1em"
          textAlign="center"
        >
          Discover New Groups
        </Heading>
      </Box>
      <Flex
        borderRadius="50px"
        height="50px"
        width={{ base: '100%', lg: '500px' }}
        padding="5px 15px"
        margin="auto"
        backgroundColor="#555"
      >
        <Input
          id="group-search-bar"
          color="#000"
          style={{ color: '#FFF' }}
          placeholder="Search For Groups"
          onChange={updateQuery}
          border="0"
          outlin="0"
          marginRight="10px"
          _focus={{ outline: '0' }}
          backgroundColor="#555"
        />
        <SearchButton
          cursor="pointer"
          id="search-group-button"
          onClick={() => { initSearch(); }}
        />
      </Flex>
    </Box>
  );
}
GroupSearch.propTypes = propTypes;
export default withRouter(GroupSearch);
