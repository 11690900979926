/* eslint-disable no-param-reassign */
/* eslint-disable no-unreachable */
/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import PropTypes, { checkPropTypes } from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { compress, compressAccurately } from 'image-conversion';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Select,
  Spinner,
  Text,
  Input,
  AspectRatio,
  SimpleGrid,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/react';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import s3BucketUrl from '../../../environmentVar';
import UploadImg from '../../../../assets/images/upload.png';

const SeeMoreButton = styled(EyeFill)`
          width: 58px;
          color: #BBB;
          cursor: pointer;
        `;

const UploadStatus = Object.freeze({
  AWAITING_FILE: 'AWAITING_FILE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  ERROR: 'ERROR',
});

const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];

const Category = [
  { value: 'FILM', label: 'Film & Animation' },
  { value: 'AUTOS', label: 'Autos & Vehicles' },
  { value: 'MUSIC', label: 'Music' },
  { value: 'PETS', label: 'Pets & Animals' },
  { value: 'SPORTS', label: 'Sports' },
  { value: 'FAMILY', label: 'Family' },
  { value: 'OTHER', label: 'Other' },
];

const baseDropzoneStyle = {
  height: '65vh',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderRadius: '8px',
  borderColor: '#EEEEEE',
  padding: '32px',
  cursor: 'pointer',
  marginBottom: '128px',
  width: '95%',
};
const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

const useStyles = createUseStyles({
  uploadIcon: {
    margin: '32px auto',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  freeDataSpace: PropTypes.number.isRequired,
  maxSpace: PropTypes.number.isRequired,
  currentSpace: PropTypes.number.isRequired,
};

function AddImagesAlbum(props) {
  const classes = useStyles();

  const user = useSelector((state) => state.user);

  const [status, setStatus] = useState();
  const {
    profile, freeDataSpace, maxSpace, currentSpace, currentAlbum, updateAlbum,
  } = props;
  const [formMessage, setFormErrorMessage] = useState();
  const [s3FilePath, setS3FilePath] = useState('');
  const [duration, setDuration] = useState('');
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [allImageFiles, setAllImageFiles] = useState();
  const [thumbFiles, setThumbFiles] = useState();
  const [compressedImages, setCompressedImages] = useState();
  const [viewThumbnails, setViewThumbnails] = useState();
  useEffect(() => {
    // console.log('running')
    if (profile._id) {
      setStatus(UploadStatus.AWAITING_FILE);
    }
  }, [profile]);

  // A much more higher quality image and thumbnail compression
  // https://github.com/WangYuLue/image-conversion
  const compressImages = async (files) => {
    // console.log(files)
    const imageFiles = Array.from(files);

    const options = {
      size: 900,
      accuracy: 0.9,
      scale: 0.4,
    };
    try {
      // converts files to compressed blobs
      const compressedBlobs = await Promise.all(
        imageFiles.map((imageFile) => compressAccurately(imageFile, options)),
      );
      return compressedBlobs;
    } catch (error) {
      setFormErrorMessage(error);
    }
  };
  const thumbnailImages = async (files) => {
    // console.log(files)
    const imageFiles = Array.from(files);

    const options = {
      size: 400,
      accuracy: 1,
      scale: 0.2,
    };
    try {
      // converts files to compressed blobs
      const compressedBlobs = await Promise.all(
        imageFiles.map((imageFile) => compressAccurately(imageFile, options)),
      );
      return compressedBlobs;
    } catch (error) {
      setFormErrorMessage(error);
    }
  };

  const postImage = async (formData) => axios.post(s3BucketUrl, formData, { timeout: 0 });

  // see https://stackoverflow.com/questions/52669596/promise-all-with-axios
  // https://github.com/axios/axios/blob/d35b5b5902f72305216f245cc58bc2231470fb19/README.md#example
  const postAllImageData = (imageList, compressedFiles) => {
    const formArray = [];
    imageList.map((image) => {
      const formData = new FormData();
      formData.append('Content-Type', 'image/**');
      Object.entries(image.promise.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      compressedFiles.map((file) => {
        if (file.name === image.fileName) {
          formData.append('file', file);
        }
      });
      formArray.push(formData);
    });

    return Promise.all(formArray.map(postImage));
  };

  const onDropAccepted = async (files) => {
    setStatus(UploadStatus.UPLOADING);
    // create array from the file list
    const imageFiles = Array.from(files);

    if (imageFiles.length > 50) {
      alert('Files must be jpg or png format and you cannot upload more than 50 photos at once.');
      setStatus(UploadStatus.AWAITING_FILE);
      return;
    }

    // compress files here
    const compressedBlobs = await compressImages(imageFiles);
    let totalFileSize = 0;
    await compressedBlobs.map((blob) => {
      totalFileSize += blob.size;
    });
    // console.log(totalFileSize);
    if (totalFileSize + currentSpace > maxSpace) {
      alert('Ran out of File space! Please either delete existing files or upgrade your account.');
      setStatus(UploadStatus.AWAITING_FILE);
      return;
    }
    const compressedFiles = [];

    // convert the blobs back to files
    // https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
    Promise.all(compressedBlobs.map((blob, index) => {
      // change images blobs back to files
      compressedFiles.push(new File([blob], `${nanoid()}${imageFiles[index].name}`));
    })).then(async () => {
      // gneerate thumbnail blobs from the same images
      const compressedThumbs = await thumbnailImages(imageFiles);

      await compressedThumbs.map((blob) => {
        totalFileSize += blob.size;
      });
      // console.log(totalFileSize);
      if (totalFileSize + currentSpace > maxSpace) {
        alert('Ran out of File space! Please either delete existing files or upgrade your account.');
        setStatus(UploadStatus.AWAITING_FILE);
        return;
      }

      const compressedThumbFiles = [];

      Promise.all(compressedThumbs.map((blob, index) => {
        // convert blobs back to file and then push to array for both
        compressedThumbFiles.push(new File([blob], `thumb_${nanoid()}${imageFiles[index].name}`));
      })).then(async () => {
        // add isThumb property to indicate file is a thumbnail
        const thumbFilesSrc = [];
        Promise.all(compressedThumbFiles.map((thumbFile) => {
          thumbFilesSrc.push(Object.assign(thumbFile, { preview: URL.createObjectURL(thumbFile) }));
        })).then(() => {
          // console.log(thumbFilesSrc[0].preview);
          setThumbnailSrc(thumbFilesSrc);
          setThumbFiles(compressedThumbFiles);
          setCompressedImages(compressedFiles);
          setViewThumbnails(thumbFilesSrc.slice(0, 8));
          setStatus(UploadStatus.FILE_ACCEPTED);
        });
      });
    });
  };
  const onDropRejected = () => alert('Files must be jpg or png format and you cannot upload more than 100 photos at once.');

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ['image/png', 'image/jpg', 'image/jpeg'], // TODO: Accept other video types
    multiple: true,
    maxFiles: 50,
    onDropAccepted,
    onDropRejected,
  });

  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);

  const renderDropzone = () => (
    <Flex {...getRootProps({ style: dropzoneStyle })}>
      <input {...getInputProps()} />
      <Flex
        flexDirection="column"
        width={{
          base: '100%',
        }}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Image className={classes.uploadIcon} size="32px" objectFit="contain" src={UploadImg} />
        <Text fontSize="2xl" fontWeight="300" textAlign="center" color="#FFF">Drag and drop your image(s) here to upload</Text>
        <Text color="#FFF">or click here</Text>
      </Flex>
    </Flex>
  );

  const renderUploadProgressIndicator = () => (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={{
        base: '100vw', sm: '100vw', md: '520px', lg: '770px', xl: '1200px',
      }}
    >
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FF1A75"
        size="xl"
        mb="8px"
      />
      <Text>{status === UploadStatus.UPLOADING ? 'Uploading...' : 'Processing...'}</Text>
    </Flex>
  );

  const renderDetailsForm = () => (
    <Box>
      <Formik
        initialValues={{
          category: 'FILM',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setStatus(UploadStatus.UPLOADING);
          const savedImages = [];
          let label = '';
          Privacy.forEach((item) => {
            if (item.value === values.privacy) label = item.label;
          });
          await Promise.all(compressedImages.map((file, index) => {
            savedImages.push({
              writer: profile._id,
              filePath: `${s3BucketUrl}${file.name}`,
              fileSize: file.size,
              fileName: file.name,
              thumbNail: `${s3BucketUrl}${thumbFiles[index].name}`,
              thumbName: thumbFiles[index].name,
              thumbSize: thumbFiles[index].size,
              privacy: currentAlbum.privacy,
              privacyLabel: currentAlbum.privacyLabel,
              album: currentAlbum._id,
            });
          }));
          const allFiles = compressedImages.concat(thumbFiles);
          axios({
            method: 'POST',
            url: '/api/image/uploadImages',
            header: { 'content-type': 'image/*' },
            data: {
              imageData: compressedImages,
              thumbData: thumbFiles,
              profileId: profile._id,
              savedImages,
            },
            timeout: 0,
          })
            .then(async (response) => {
              // console.log(response.data.promises)
              postAllImageData(response.data.promises, allFiles)
                .then((res) => {
                  // console.log(res);
                  if (res[0].status === 204) {
                    alert('Images have been sucessfully uploaded!');
                    setStatus(UploadStatus.AWAITING_FILE);
                  }
                })
                .catch((err) => {
                  setFormErrorMessage(err);
                });
            })
            .catch((err) => {
              setFormErrorMessage(err);
            });
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box
              onSubmit={handleSubmit}
            >
              {thumbFiles && (
              <>
                {/* TODO: Add support for custom thumbnails */}
                {viewThumbnails.length > 1
                  ? (
                    <SimpleGrid
                      gridTemplateColumns={{
                        base: '1fr 1fr 1fr',
                        md: '1fr 1fr 1fr',
                      }}
                      gridTemplateRows={{
                        base: '1fr 1fr',
                      }}
                      spacing="10px"
                      width={{
                        base: '90vw', sm: '90vw', md: '90vw', lg: '500px', xl: '700px',
                      }}
                      marginX="auto"
                      marginY="1rem"
                    >
                      {viewThumbnails.map((thumbnail) => (
                        <AspectRatio ratio={16 / 9}>
                          <Image
                            src={thumbnail.preview}
                            objectFit="cover"
                          />
                        </AspectRatio>
                      ))}
                      <ImageViewModal imageList={thumbnailSrc} />
                    </SimpleGrid>
                  ) : (
                    <Box
                      width={{
                        base: '90vw', sm: '90vw', md: '90vw', lg: '500px', xl: '500px',
                      }}
                    >
                      {viewThumbnails.map((thumbnail) => (
                        <AspectRatio ratio={16 / 9}>
                          <Image
                            src={thumbnail.preview}
                            objectFit="cover"
                          />
                        </AspectRatio>
                      ))}
                    </Box>
                  )}
              </>
              )}
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="Publishing..."
                onClick={handleSubmit}
                display="block"
                borderRadius="24px"
                mt="16px"
                mb="16px"
                mr="auto"
                ml="auto"
                width="200px"
                color="white"
                bg="#FF1A75"
                _hover={{
                  bg: '#FFE6EE',
                  color: '#FF1A75',
                }}
              >
                Publish
              </Button>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );

  const renderContent = () => {
    switch (status) {
      case UploadStatus.AWAITING_FILE:
        return renderDropzone();
      case UploadStatus.FILE_ACCEPTED:
        return renderDetailsForm();
      case UploadStatus.UPLOADING:
        return renderUploadProgressIndicator();
      case UploadStatus.PROCESSING:
        return renderUploadProgressIndicator();
      case UploadStatus.PROCESSING_COMPLETE:
        return renderDetailsForm();
      default:
        return renderDetailsForm();
    }
  };

  return (
    <Flex
      className="app"
    >
      <Heading
        fontWeight="400"
        fontSize="1.7rem"
        margin="2rem"
        color="#FFF"
      >
        Add Image(s) to Album
      </Heading>
      {renderContent()}
    </Flex>
  );
}

function ImageViewModal(props) {
  const {
    imageList,
  } = props;
  const {
    isOpen: isOpenViewModal,
    onOpen: onOpenViewModal,
    onClose: onCloseViewModal,
  } = useDisclosure();

  const [errMessage, setErrMessage] = useState();
  const classes = useStyles();

  return (
    <Box>
      <AspectRatio ratio={16 / 9}>
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          backgroundColor="#DDD"
          cursor="pointer"
          onClick={onOpenViewModal}
        >
          <SeeMoreButton />
        </Flex>
      </AspectRatio>
      <Modal
        isOpen={isOpenViewModal}
        onClose={onCloseViewModal}
        size={{
          base: '100%', sm: '100%', md: '620px', lg: '870px', xl: '1300px',
        }}
        margin="auto"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Image(s) to Upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding="10px">
            <SimpleGrid
              gridTemplateColumns={{
                base: '1fr',
                sm: '1fr',
                md: '1fr 1fr 1fr',
                xl: '1fr 1fr 1fr 1fr 1fr',
              }}
              gridTemplateRows={{
                base: '1fr 1fr',
              }}
              spacing="10px"
              width={{
                base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1200px',
              }}
              marginX="auto"
              marginY="1rem"
            >
              {imageList.map((thumbnail) => (
                <AspectRatio ratio={16 / 9}>
                  <Image
                    src={thumbnail.preview}
                    objectFit="cover"
                  />
                </AspectRatio>
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

AddImagesAlbum.propTypes = propTypes;

export default AddImagesAlbum;
