/* eslint-disable linebreak-style */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  Box, Button, Flex, Image, Heading, Stack, Text, Container,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import FamilyImg from '../../assets/images/home.png';

export default function Hero({
  title,
  subtitle,
  image,
  ctaLink,
  ctaText,
  ...rest
}) {
  return (
    <Container maxW="5xl" pb="8em">
      <Stack
        textAlign="center"
        align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
        >
          A Vault for Life&apos;s Treasured Memories
        </Heading>
        <Text
          maxW="3xl"
          fontSize={{ md: '2xl' }}
        >
          {subtitle}
        </Text>
        <Link to={ctaLink}>
          <Button
            bg="#3480E3"
            color="white"
            borderRadius="8px"
            py="4"
            px="4"
            lineHeight="1"
            size="md"
            _hover={{ bg: '#FFE6EE', color: '#FF1A75' }}
          >
            {ctaText}
          </Button>
        </Link>
        <Text
          fontSize="m"
          mt={2}
          textAlign="center"
          color="primary.800"
          opacity="0.6"
        />
      </Stack>
      <Box w={{ base: '100%', sm: '6   0%', md: '100%' }} mb={{ base: 12, md: 0 }}>
        {/* <Image src={FamilyImg} size="100%" rounded="1rem" shadow="2xl" /> */}
        <Image src={FamilyImg} width="100%" />
      </Box>
    </Container>
  );
}
// eslint-disable-next-line linebreak-style

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
};

Hero.defaultProps = {
  subtitle:
        'The privacy-friendly place to share and collaborate with your family.',
  ctaText: 'Start Kloaking For Free',
  ctaLink: '/register',
};
