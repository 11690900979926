/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Image,
  AspectRatio,
  Flex,
  SimpleGrid,
  Avatar,
  useDisclosure,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  Spinner,
  Stack,
  Textarea,
  Heading,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import styled from 'styled-components';
import axios from 'axios';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import Comments from './Comments';
import EditPost from './EditPost';

const HeartButton = styled(SuitHeartFill)`
  background-color: inherit;
  width: 24px;
  height: 24px;
  color: inherit;
  cursor: pointer;
  margin-top: 0.5em;
`;
const HeartActive = styled(SuitHeartFill)`
  color: #FF0000; 
  right: 12px;
  top: 20px;
  position: relative;
  width: 20px;
  height: 20px
`;
const SeeMoreButton = styled(EyeFill)`
  width: 24px;
  height: 36px;
  color: #BBB;
  cursor: pointer;
`;
const propTypes = {
  groupPost: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  allGroupPosts: PropTypes.array.isRequired,
  updatePostList: PropTypes.func.isRequired,
};
const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',

  },
  familyPostGrid: {
    display: 'flex',
    overflowX: 'scroll',
    gridGap: '1em',
    gridTemplateColumns: '1fr 1fr',
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#111',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#F1F1F1',
    fontSize: '30px',
    fontWeight: 'bold',
    transition: '0.3s',
    cursor: 'pointer',
    padding: '0 14px',
    borderRadius: '50%',
    '&:hover': {
      color: '#bbb',
      textDecoration: 'none',
    },
  },
  leftButton: {
    position: 'absolute',
    top: '50%',
    left: '24px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  rightButton: {
    position: 'absolute',
    top: '50%',
    right: '-26px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  privacyButton: {
    backgroundColor: '#222',
    borderRadius: '5px',
    padding: '6px 12px',
    color: '#FFF',
    marginTop: '1rem',
  },
});
function SinglePost({
  allGroupPosts,
  updatePostList,
  groupPost,
  profile,
}) {
  const classes = useStyles();
  const {
    isOpen: isPostOpen,
    onOpen: onPostOpen,
    onClose: onPostClose,
  } = useDisclosure();
  const {
    isOpen: isReactionOpen,
    onOpen: onReactionOpen,
    onClose: onReactionClose,
  } = useDisclosure();
  const [loadedPost, setLoadedPost] = useState(false);
  const [currentSelectedUrl, setCurrentSelectedUrl] = useState();
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [selectedPost, setSelectedPost] = useState();
  const [mediaType, setMediaType] = useState();
  const [reactionActive, setReactionActive] = useState(false);
  const [groupReactions, setGroupReactions] = useState([]);
  const [viewReactions, setViewReactions] = useState([]);
  const [loadCompleted, setLoadCompleted] = useState(false);
  const [error, setError] = useState();

  const getReactions = (profileData) => {
    // console.log(profileData);
    axios({
      url: '/api/groups/reactions-by-id',
      method: 'POST',
      data: {
        groupPostId: groupPost._id,
        profileId: profileData._id,
      },
    })
      .then((response) => {
        // console.log(response.data.reactions);
        setGroupReactions(response.data.reactions);
        setViewReactions(response.data.reactions?.slice(0, 4));
        // console.log(`result: ${response.data.reactions}`);
        // eslint-disable-next-line eqeqeq
        if (response.data.reactions === undefined
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == []
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == '') {
          // console.log('reached me');
          setReactionActive(false);
          setLoadCompleted(true);
          // document.getElementById(`heart-button`).style.color = '#DDD';
          return;
        }
        if (response.data.reactions[0].profile._id === profileData._id) {
          // console.log('match found');
          // document.getElementById(`heart-button`).style.color = '#FF0000';
          setReactionActive(true);
          // console.log('reached ne');
        } else {
          // document.getElementById(`heart-button`).style.color = '#DDD';
          setReactionActive(false);
        }
        // console.log('finally');
        setLoadCompleted(true);
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
      })
      .catch((err) => {
        setError(err);
      });
  };
  useEffect(() => {
    if (groupPost) {
      getReactions(profile);
      setLoadedPost(true);
    }
  }, [groupPost]);
  const openMedia = (post, type) => {
    setMediaLoaded(false);
    setMediaType(type);
    setSelectedPost(post);
    switch (type) {
      case 'image':
        setCurrentSelectedUrl(post.image.filePath);
        break;
      case 'video':
        setCurrentSelectedUrl(post.video.filePath);
        break;
      case 'album':
        setCurrentSelectedUrl(post.filePath);
        break;
      default:
        break;
    }
    onPostOpen();
    setMediaLoaded(true);
  };
  const goPreviousPost = () => {
    setMediaLoaded(false);
    let count;
    const allPosts = mediaType === 'album' ? groupPost.albumImages : groupPost.posts;
    for (let i = 0; i < allPosts.length; i += 1) {
      if (allPosts[i]._id === selectedPost._id) {
        if (i === 0) {
          count = allPosts.length - 1;
        } else {
          count = i - 1;
        }
        setSelectedPost(allPosts[count]);
        const dataType = allPosts[count].type;
        switch (dataType) {
          case 'IMAGE':
            setCurrentSelectedUrl(allPosts[count].image.filePath);
            setMediaType('image');
            break;
          case 'VIDEO':
            setCurrentSelectedUrl(allPosts[count].video.filePath);
            setMediaType('video');
            break;
          default:
            setCurrentSelectedUrl(allPosts[count].filePath);
            setMediaType('album');
            break;
        }
      }
    }
    setMediaLoaded(true);
  };

  const goNextPost = () => {
    setMediaLoaded(false);
    let count;
    const allPosts = mediaType === 'album' ? groupPost.albumImages : groupPost.posts;
    for (let i = 0; i < allPosts.length; i += 1) {
      if (allPosts[i]._id === selectedPost._id) {
        if (i === allPosts.length - 1) {
          count = 0;
        } else {
          count = i + 1;
        }
        setSelectedPost(allPosts[count]);
        const dataType = allPosts[count].type;
        switch (dataType) {
          case 'IMAGE':
            setCurrentSelectedUrl(allPosts[count].image.filePath);
            setMediaType('image');
            break;
          case 'VIDEO':
            setCurrentSelectedUrl(allPosts[count].video.filePath);
            setMediaType('video');
            break;
          default:
            setCurrentSelectedUrl(allPosts[count].filePath);
            setMediaType('album');
            break;
        }
      }
    }
    setMediaLoaded(true);
  };
  const reactionHandler = () => {
    // console.log(imageReactions);
    if (reactionActive === false) {
      axios({
        url: '/api/groups/post-reaction',
        method: 'POST',
        data: {
          groupPostId: groupPost._id,
          profile,
          reactionType: 'Love',
        },
      })
        .then((reactionData) => {
          // console.log(`reached? ${reactionData.data.reactionData.profile._id}`);
          const newReaction = viewReactions;
          const allreactions = groupReactions;
          newReaction.unshift(reactionData.data.reactionData);
          allreactions.unshift(reactionData.data.reactionData);
          //  document.getElementById(`heart-button`).style.color = '#FF0000';
          setViewReactions(newReaction);
          setGroupReactions(allreactions);
          setReactionActive(true);
          // console.log(profile.image);
        })
        .catch((err) => {
          setError(err);
        });
    } else {
      axios({
        url: '/api/groups/remove-reaction',
        method: 'POST',
        data: {
          profile,
          groupPostId: groupPost._id,
        },
      })
        .then(() => {
          // console.log(imageReactions);
          const newReaction = viewReactions;
          const allReactions = groupReactions;
          allReactions.shift();
          newReaction.shift();
          // console.log(`new ${newReactions.length}`);
          setGroupReactions(allReactions);
          setViewReactions(newReaction);
          setReactionActive(false);
        });
    }
  };
  const renderSpinner = () => (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FF1A75"
        size="xl"
        mb="8px"
        width="50px"
        margin="auto"
      />
      <Text>Loading</Text>
    </Flex>
  );
  const renderPost = () => (
    <Box
      id={`group_post_${groupPost._id}`}
      padding="1rem"
      borderRadius="10px"
      marginY="3rem"
      backgroundColor="#DDD"
    >
      <Flex
        justifyContent="space-between"
      >
        <Box>
          <Flex
            alignItems="left"
            paddingY="0.5rem"
          >
            <Box>
              <Flex
                justifyContent="center"
                flexDir="column"
                alignItems="left"
              >
                <Box
                  as="img"
                  crossOrigin="anonymous"
                  src={groupPost.author.image}
                  width="42px"
                  height="42px"
                  objectFit="cover"
                  borderRadius="50%"
                  verticalAlign="middle"
                />
              </Flex>
            </Box>
            <Box>
              <Flex
                justifyContent="center"
                flexDir="column"
                alignItems="left"
                height="100%"
              >
                <Text
                  fontSize="1.2rem"
                  fontWeight="650"
                  marginLeft="0.5rem"
                  cursor="pointer"
                  color="#777"
                >
                  {groupPost.author.username}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box>
          { groupPost.author._id === profile._id
            ? (
              <EditPost
                groupPost={groupPost}
                profile={profile}
                updatePostList={updatePostList}
                groupPostList={allGroupPosts}
              />
            ) : <Box />}
        </Box>
      </Flex>
      <Box
        marginY="1em"
      >
        <Text
          color="#444"
          fontWeight="bold"
          fontSize={{ base: '1.2em', sm: '1.4em' }}
        >
          {groupPost.text}
        </Text>
      </Box>
      <Box>
        {
          groupPost.album ? (
            <SimpleGrid
              width={{
                base: '100%', sm: '100%', md: '100%', lg: '770px', xl: '1200px',
              }}
              gridTemplateColumns="1fr 1fr"
            >
              {groupPost.albumImages.map((allposts, index) => (
                <Box>
                  {index < 4
                    ? (
                      <Box>
                        <Box
                          width="100%"
                          onClick={() => { openMedia(allposts, 'album'); }}
                        >
                          <AspectRatio ratio={16 / 9} backgroundColor="#111">
                            <Box
                              as="img"
                              crossOrigin="anonymous"
                              alt="thumbnail"
                              objectFit="cover"
                              width="auto"
                              maxHeight="100%"
                              margin="auto"
                              src={allposts.thumbNail}
                              cursor="pointer"
                            />
                          </AspectRatio>
                        </Box>

                      </Box>
                    ) : <Box />}
                </Box>
              ))}
            </SimpleGrid>
          ) : (
            <SimpleGrid
              gridTemplateColumns="1fr 1fr"
              width={{
                base: '100%',
              }}
            >
              {groupPost.posts.map((allposts, index) => (
                <Box>
                  {index < 4
                    ? (
                      <Box>
                        {
                  allposts.image
                    ? (
                      <Box
                        width="100%"
                        onClick={() => { openMedia(allposts, 'image'); }}
                      >
                        <AspectRatio ratio={16 / 9} backgroundColor="#111">
                          <Box as="img" crossOrigin="anonymous" alt="thumbnail" objectFit="cover" width="auto" maxHeight="100%" margin="auto" src={allposts.image.thumbNail} cursor="pointer" />
                        </AspectRatio>
                      </Box>
                    ) : (
                      <Box
                        width="100%"
                        onClick={() => { openMedia(allposts, 'video'); }}
                      >
                        <Box
                          marginTop="-20px"
                        >
                          <Box position="relative" textAlign="right" width="100%">
                            <AspectRatio
                              ratio={16 / 9}
                              position="relative"
                            >
                              <Box
                                as="img"
                                crossOrigin="anonymous"
                                alt="thumbnail"
                                src={allposts.video.thumbNail}
                                objectFit="cover"
                                cursor="pointer"
                                borderRadius=".70rem"
                                marginTop="20px"
                                boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                              />
                            </AspectRatio>
                            <Text
                              as="span"
                              position="relative"
                              top="-10px"
                              right="10px"
                              zIndex="2"
                              color="#FFFFFF"
                              backgroundColor="rgb(0, 0, 0, 0.7)"
                              borderRadius="4px"
                              padding="4px"
                              marginLeft="5px"
                            >
                              {Math.floor(allposts.video.duration / 60)}:{`0${Math.floor(allposts.video.duration - Math.floor(allposts.video.duration / 60) * 60)}`.slice(-2)}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    )
                  }
                      </Box>
                    ) : <Box />}
                </Box>
              ))}
            </SimpleGrid>
          )
        }
      </Box>
      <Box
        color={reactionActive === false ? '#AAA' : '#FF0000'}
        marginTop="1em"
      >
        <HeartButton id="heart-button" onClick={() => reactionHandler()} />
      </Box>
      <Box>{viewReactions.length > 0
        ? (
          <SimpleGrid
            gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
            width="250px"
          >
            {viewReactions?.map((data) => (
              <Flex marginY="8px" height="32px">
                <Box
                  as="img"
                  crossOrigin="anonymous"
                  src={data.profile && data.profile.image}
                  width="32px"
                  height="32px"
                  objectFit="cover"
                  borderRadius="50%"
                  verticalAlign="middle"
                />
                <HeartActive />
              </Flex>
            ))}
            <Flex marginY="8px" marginLeft="5px" height="32px">
              <SeeMoreButton onClick={() => { onReactionOpen(); }} />
            </Flex>
            <Box>
              <Modal isOpen={isPostOpen} onClose={onPostClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalCloseButton />
                  <ModalBody>
                    <Box marginY="3rem">
                      {groupReactions?.map((data) => (
                        <Flex marginY="1rem">
                          <Box
                            as="img"
                            crossOrigin="anonymous"
                            src={data.profile && data.profile.image}
                            width="50px"
                            height="50px"
                            objectFit="cover"
                            borderRadius="50%"
                            verticalAlign="middle"
                          />
                          <HeartActive />
                          <Text marginTop="5px" fontWeight="500">{data.profile.username}</Text>
                        </Flex>
                      ))}
                    </Box>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          </SimpleGrid>
        )
        : <Box />}
      </Box>
      <Comments
        groupPostId={groupPost._id}
        profile={profile}
      />
      <Modal
        isOpen={isPostOpen}
        onClose={onPostClose}
        size={{
          base: '100%', sm: '100%', md: '100%', lg: '770px', xl: '1200px',
        }}
      >
        <ModalOverlay />
        <ModalContent
          width={{
            base: '100%', sm: '100%', md: '100%', lg: '770px', xl: '1200px',
          }}
        >
          <ModalCloseButton />
          <ModalBody
            marginY="2rem"
            width={{
              base: '100%', sm: '100%', md: '100%', lg: '770px', xl: '1200px',
            }}
          >
            <Box
              margin="auto"
            >
              {
                mediaLoaded
                  ? (
                    <Box>
                      <Box>
                        {
                          mediaType === 'video' ? (
                            <Flex justifyContent="center" flexDirection="column" backgroundColor="#111">
                              <AspectRatio ratio={16 / 9}>
                                <Flex justifyContent="center" position="relative">
                                  <button type="submit" className={classes.leftButton} onClick={goPreviousPost}>&lt;</button>
                                  <Box
                                    as="video"
                                    crossOrigin="anonymous"
                                    alignSelf="center"
                                    width="auto"
                                    maxHeight="100%"
                                    backgroundColor="#111"
                                    src={currentSelectedUrl}
                                    controls
                                  />
                                  <button type="submit" className={classes.rightButton} onClick={goNextPost}>&gt;</button>
                                </Flex>
                              </AspectRatio>
                            </Flex>

                          ) : (
                            <Flex justifyContent="center" flexDirection="column" backgroundColor="#111">
                              <AspectRatio ratio={16 / 9}>
                                <Flex justifyContent="center" position="relative">
                                  <button type="submit" className={classes.leftButton} onClick={() => { goPreviousPost(); }}>&lt;</button>
                                  <Box
                                    as="img"
                                    crossOrigin="anonymous"
                                    alt="photo"
                                    src={currentSelectedUrl}
                                    alignSelf="center"
                                    width="auto"
                                    maxHeight="100%"
                                  />
                                  <button type="submit" className={classes.rightButton} onClick={() => { goNextPost(); }}>&gt;</button>
                                </Flex>
                              </AspectRatio>
                            </Flex>
                          )
                        }
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      {renderSpinner()}
                    </Box>
                  )
              }
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box>
        <Modal isOpen={isReactionOpen} onClose={onReactionClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box marginY="3rem">
                {groupReactions?.map((data) => (
                  <Flex marginY="1rem">
                    <Box
                      as="img"
                      crossOrigin="anonymous"
                      src={data.profile && data.profile.image}
                      width="32px"
                      height="32px"
                      objectFit="cover"
                      borderRadius="50%"
                      verticalAlign="middle"
                    />
                    <HeartActive />
                    <Text marginTop="5px" fontWeight="500">{data.profile.username}</Text>
                  </Flex>
                ))}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
  return (
    <Box
      width="100%"
      marginX="auto"
      marginY="2rem"
    >
      {
            loadedPost
              ? (
                <Box>
                  {
                      renderPost()
                  }
                </Box>
              )
              : <Box />
        }
    </Box>
  );
}
SinglePost.propTypes = propTypes;
export default SinglePost;
