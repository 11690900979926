/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
import io from 'socket.io-client';

let socket;
export const initiateSocket = (/* room */) => {
  // https://kloak.herokuapp.com/
  // http://localhost:5000/
  // socket = io('https://kloak.herokuapp.com/');
  // // console.log('Connecting socket...');
  // if (socket && room) socket.emit('profileRoom', room);
};
export const createProfileRoom = (profileIdRoom) => {
  if (socket) {
    // console.log(`creating profile room: ${profileIdRoom}`);
    // console.log(typeof (profileIdRoom));
    socket.emit('profileRoom', profileIdRoom);
  }
};
export const enterChatRoom = (chatRoomId) => {
  // console.log(`my chat room : ${chatRoomId}`);
  if (socket) socket.emit('join', chatRoomId);
};
export const disconnectSocket = () => {
  // console.log('Disconnecting socket...');
  if (socket) socket.disconnect();
};
export const subscribeToChat = (cb) => {
  if (!socket) return (true); socket.on('chat', (msg) => {
    // console.log('Websocket event received!');
    return cb(null, msg);
  });
};
export const recieveChatNotification = (cb) => {
  if (!socket) return (true);
  socket?.once('notificationSent', (newNotifications, author, notificationId) => {
    // console.log('shout out');
    // setNotificationCounter(newNotifications);
    socket.off('notificationSent');
    return cb(null, newNotifications, author, notificationId);
  });
};
export const recieveChat = (cb) => {
  socket?.once('msgSent', (newMessage, notificationId) => {
    // console.log(`new ${newMessage}`);
    // setNotificationCounter(newNotifications);
    socket.off('msgSent');
    return cb(null, newMessage, notificationId);
  });
};
export const sendMessage = (msgDataObject, chatRoomId, notificationObj, messageRecipients, author, notificationId) => {
  // console.log(messageRecipients);
  if (socket) socket.emit('sendMessage', msgDataObject, chatRoomId, notificationObj, messageRecipients, author, notificationId);
};
