/* eslint-disable react/forbid-prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  Avatar,
  Flex,
  Spinner,
  Stack,
  Box,
  Image,
  useDisclosure,
  Text,
  AspectRatio,
  Heading,
  Button,
} from '@chakra-ui/react';
import moment from 'moment';

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  familyPostGrid: {
    display: 'grid',
    gridRowGap: '2em',
    gridColumnGap: '1em',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  albums: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

function ProfileLibrary({
  history,
  albums,
  profile,
}) {
  const [displayAlbums, setDisplayAlbums] = useState(false);
  const [loadedAlbums, setLoadedAlbums] = useState(false);
  const [lastPageAlbumReached, setLastPageAlbumReached] = useState(false);
  const [itemsPerAlbumPage, setAlbumsPerMediaPage] = useState(4);
  const [currentAlbumIndex, setCurrenAlbumIndex] = useState(0);
  const classes = useStyles();

  const goToLink = (link, username) => {
    // console.log(username);
    // const userNameTab = document.getElementById(`sidefamily_${username}`);
    // if (userNameTab) userNameTab.style.color = '#FF0000';
    document.getElementById('home-side').style.color = '#888888';
    history.push(link);
  };
  useEffect(() => {
    if (albums && profile) {
      const albumArr = [];
      for (let i = 0; i < albums.length; i += 1) {
        // console.log(albums[i]);
        if (i === albums.length - 1) {
          setLastPageAlbumReached(true);
          setCurrenAlbumIndex(i);
          albumArr.push(albums[i]);
          break;
        }
        if (i === itemsPerAlbumPage - 1) {
          albumArr.push(albums[i]);
          setCurrenAlbumIndex(i + 1);
          break;
        }
        albumArr.push(albums[i]);
      }
      setDisplayAlbums(albumArr);
      if (albumArr.length > 0) {
        setLoadedAlbums(true);
      }
      // console.log('this videos' + video.filePath)

      // console.log(`here I am ${displayAlbums}`);
    }
  }, [albums, profile]);

  const loadMore = () => {
    const albumArr = [];
    for (let i = currentAlbumIndex; i < albums.length; i += 1) {
      if (i === albums.length - 1) {
        setLastPageAlbumReached(true);
        setCurrenAlbumIndex(i);
        albumArr.push(albums[i]);
        break;
      }
      if (i === currentAlbumIndex + itemsPerAlbumPage) {
        setCurrenAlbumIndex(i);
        break;
      }
      albumArr.push(albums[i]);
    }
    setDisplayAlbums(displayAlbums.concat(albumArr));
    // console.log('this videos' + video.filePath)

    // console.log(`here I am ${displayAlbums}`);
  };

  const renderMyAlbums = () => displayAlbums.map((album) => (
    <Box
      key={album._id}
    >
      <Box>
        <AspectRatio
          ratio={16 / 9}
          borderRadius="1rem"
          onClick={() => { goToLink(`/albums/${album._id}`); }}
        >
          <Box
            as="img"
            crossOrigin="anonymous"
            alt="thumbnail"
            src={album.thumbnail}
            objectFit="cover"
            cursor="pointer"
            borderRadius=".70rem"
            marginTop="20px"
            boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
          />
        </AspectRatio>
        <Flex>
          <Box marginTop={{ base: '0', md: '4px' }}>
            <Text
              color="#003971"
              fontWeight="bold"
              fontSize={{ base: '1.3em', sm: '1.4em' }}
              whiteSpace="wrap"
              overflow="hidden"
              textOverflow="ellipsis"
              marginTop="0.4em"
            >
              {album.name}
            </Text>
            <Text
              color="#AAA"
              fontWeight="bold"
              fontSize={{ base: '1.1em', sm: '1.2em' }}
              whiteSpace="wrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {album.description}
            </Text>
            <Text
              color="#AAA"
              fontWeight="bold"
              fontSize={{ base: '1.1em', sm: '1.1em' }}
            >
              { moment(album.createdAt).format('LL') }
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  ));
  return (

    <Box
      width={{
        base: '100%',
      }}
      margin="auto"
    >
      {loadedAlbums
        ? (
          <Box>
            <Flex
              width={{
                base: '100%',
              }}
              height={{ base: '50px', sm: '75px' }}
              marginBottom="0.7rem"
            >
              <Box width="100%" margin="auto">
                <Heading
                  fontWeight="600"
                  fontSize={{ base: '1.6rem', sm: '2rem' }}
                  marginBottom="1rem"
                  color="#0B67C2"
                  marginY="1em"
                >
                  Album(s)
                </Heading>
              </Box>
            </Flex>
            <Box
              className={classes.familyPostGrid}
              spacing="10px"
              mb="24px"
              maxWidth={{
                base: '100%',
              }}
            >
              {renderMyAlbums()}
            </Box>
            {lastPageAlbumReached
              ? <Box />
              : (
                <Box
                  display={{ base: 'flex', md: 'flex' }}
                  justifyContent="flex-end"
                  alignItems={{ base: 'center', md: 'flex-end' }}
                  height="100%"
                  marginBottom="4rem"
                  width="100%"
                >
                  <Button
                    cursor="pointer"
                    onClick={() => { loadMore(); }}
                    backgroundColor="#2C6DAD"
                    color="#FFF"
                    padding="10px 20px"
                    width={{ base: '100%', md: '125px', lg: '200px' }}
                    _hover={{ backgroundColor: '#5C8DFF' }}
                    borderRadius="1px"
                  >
                    View More
                  </Button>
                </Box>
              )}
          </Box>
        )
        : <Box /> }
    </Box>

  );
}
ProfileLibrary.propTypes = propTypes;
export default withRouter(ProfileLibrary);
