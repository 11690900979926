/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip, Icon } from 'antd';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { createUseStyles } from 'react-jss';

const propTypes = {
  video: PropTypes.bool,
  videoId: PropTypes.string,
  commentId: PropTypes.string,
  userId: PropTypes.string.isRequired,
};

const useStyles = createUseStyles({
  iconLike: {
    fontSize: '20px',
  },
  '@media screen and (max-width: 600px)': {
    iconLike: {
      fontSize: '14px',
    },
  },
});

const defaultProps = {
  video: false,
  videoId: undefined,
  commentId: undefined,
};

function LikeDislikes({
  video,
  videoId,
  commentId,
  userId,
}) {
  const classes = useStyles();
  const [Likes, setLikes] = useState(0);
  const [Dislikes, setDislikes] = useState(0);
  const [LikeAction, setLikeAction] = useState(null);
  const [DislikeAction, setDislikeAction] = useState(null);

  const variable = useMemo(
    () => (video ? { videoId, userId } : { commentId, userId }),
    [video, videoId, commentId, userId],
  );

  useEffect(() => {
    Axios.post('/api/like/getLikes', variable).then((response) => {
      // console.log('getLikes', response.data);

      if (response.data.success) {
        // How many likes does this video or comment have
        setLikes(response.data.likes.length);

        // if I already click this like button or not
        response.data.likes.forEach((like) => {
          if (like.userId === userId) {
            setLikeAction('liked');
          }
        });
      } else {
        alert('Failed to get likes');
      }
    });

    Axios.post('/api/like/getDislikes', variable).then((response) => {
      // console.log('getDislike', response.data);
      if (response.data.success) {
        // How many likes does this video or comment have
        setDislikes(response.data.dislikes.length);

        // if I already click this like button or not
        response.data.dislikes.forEach((dislike) => {
          if (dislike.userId === userId) {
            setDislikeAction('disliked');
          }
        });
      } else {
        alert('Failed to get dislikes');
      }
    });
  }, [variable, userId]);

  const onLike = () => {
    if (LikeAction === null) {
      Axios.post('/api/like/upLike', variable).then((response) => {
        if (response.data.success) {
          setLikes(Likes + 1);
          setLikeAction('liked');

          // If dislike button is already clicked

          if (DislikeAction !== null) {
            setDislikeAction(null);
            setDislikes(Dislikes - 1);
          }
        } else {
          alert('Failed to increase the like');
        }
      });
    } else {
      Axios.post('/api/like/unLike', variable).then((response) => {
        if (response.data.success) {
          setLikes(Likes - 1);
          setLikeAction(null);
        } else {
          alert('Failed to decrease the like');
        }
      });
    }
  };

  const onDisLike = () => {
    if (DislikeAction !== null) {
      Axios.post('/api/like/unDisLike', variable).then((response) => {
        if (response.data.success) {
          setDislikes(Dislikes - 1);
          setDislikeAction(null);
        } else {
          alert('Failed to decrease dislike');
        }
      });
    } else {
      Axios.post('/api/like/upDisLike', variable).then((response) => {
        if (response.data.success) {
          setDislikes(Dislikes + 1);
          setDislikeAction('disliked');

          // If dislike button is already clicked
          if (LikeAction !== null) {
            setLikeAction(null);
            setLikes(Likes - 1);
          }
        } else {
          alert('Failed to increase dislike');
        }
      });
    }
  };

  return (
    <Flex justifyContent="space-between" width={{base:"60px",  sm:"75px", md:"90px"}}>
      <Flex key="comment-basic-like">
          <Icon
            type="like"
            theme={LikeAction === 'liked' ? 'filled' : 'outlined'}
            onClick={onLike}
            className={classes.iconLike}
            style={{marginTop:"0.5rem", cursor: "pointer"}}
          />
        <Text as="span" marginTop={{base:"3px", md:0}} fontSize={{base:"14px", sm:"17px",md: "20px"}} paddingLeft={{base:"4px", sm:"5px", md:"6px"}} cursor='auto' >
          {Likes}
        </Text>
      </Flex>

    </Flex>
  );
}
/*
      <Flex key="comment-basic-dislike">
          <Icon
            type="dislike"
            theme={DislikeAction === 'disliked' ? 'filled' : 'outlined'}
            onClick={onDisLike}
            className={classes.iconLike}
            style={{marginTop:"0.5rem", cursor: "pointer"}}
          />
        <Text as="span" marginTop={{base:"3px", md:0}} fontSize={{base:"14px", sm:"17px",md: "20px"}} paddingLeft={{base:"4px", sm:"5px", md:"6px"}} cursor='auto' >
          {Dislikes}
        </Text>
      </Flex>
*/
LikeDislikes.propTypes = propTypes;
LikeDislikes.defaultProps = defaultProps;

export default LikeDislikes;
