/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Box,
  Text,
  Avatar,
  Flex,
  Input,
  Spinner,
} from '@chakra-ui/react';
import { ChatDotsFill } from '@styled-icons/bootstrap/ChatDotsFill';
import { NewMessage } from '@styled-icons/entypo/NewMessage';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack';

const NewMessageButton = styled(NewMessage)`
  width: 24px;
  margin: 12px;
`;
const CloseButton = styled(CloseOutline)`
  width: 28px;
  margin: 12px;
`;
const ArrowButton = styled(ArrowBack)`
  width: 24px;
  margin: 12px;
`;
const ChatButton = styled(ChatDotsFill)`
  width: 36px;
  position: relative;
  z-index: 9999;
  color: #000;
  cursor: pointer;
`;
const propTypes = {
  profile: PropTypes.object.isRequired,
  profileTo: PropTypes.object.isRequired,
  conversationArr: PropTypes.array.isRequired,
  loadingCon: PropTypes.bool.isRequired,
  backToChatBox: PropTypes.func.isRequired,
  closeRoomBox: PropTypes.func.isRequired,
};
function ChatRoom2({
  profile,
  conversationArr,
  profileTo,
  loadingCon,
  backToChatBox,
  closeRoomBox,
}) {
  const [err, setErr] = useState();
  const [familyResult, setFamilyResult] = useState([]);
  const [loadedResults, setLoadedResults] = useState(true);
  const [currentMessage, setCurrentMessage] = useState('');

  const viewConversation = () => conversationArr.map((conversation, index) => (
    <Box
      width="90%"
      margin="auto"
    >

      {
        conversation.profile._id === profile._id ? (
          <Flex
            width="100%"
            display="flex"
            justifyContent="end"
          >
            <Text
              as="span"
              maxW="80%"
              color="#34495E"
              fontWeight="400"
              backgroundColor="#FFF"
              whiteSpace="wrap"
              overflowY="hidden"
              overflowX="auto"
              textOverflow="ellipsis"
              marginY="0.5rem"
              marginLeft="auto"
              marginRight="0"
              padding="5px 10px"
              borderRadius="5px"
            >
              {conversation.messageBody}
            </Text>
          </Flex>
        ) : (
          <Flex
            marginY="12px"
            width="100%"
          >
            <Box
              as="img"
              crossOrigin="anonymous"
              src={conversation.profile.image}
              width="32px"
              height="32px"
              objectFit="cover"
              borderRadius="50%"
              verticalAlign="middle"
              alignItems="center"
              marginRight="10px"
            />
            <Box>
              <Text
                color="#34495E"
                fontWeight="600"
                width={{
                  base: '100%',
                }}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                marginTop="4px"
              >
                {conversation.profile.username}:
              </Text>
              <Text
                color="#34495E"
                fontWeight="400"
                width={{
                  base: '100%',
                }}
              >
                {conversation.messageBody}
              </Text>
            </Box>
          </Flex>
        )
      }
    </Box>
  ));

  return (
    <Box>
      <Box>
        <Flex
          width="100%"
          justifyContent="space-between"
        >
          <Box>
            <ArrowButton onClick={backToChatBox} />
          </Box>
          <CloseButton onClick={closeRoomBox} />
        </Flex>
        <Flex margin="8px">
          <Box
            as="img"
            crossOrigin="anonymous"
            src={profileTo.image}
            width="42px"
            height="42px"
            objectFit="cover"
            borderRadius="50%"
            verticalAlign="middle"
            alignItems="center"
            marginRight="10px"
          />
          <Box>
            <Text
              color="#34495E"
              fontWeight="600"
              width={{
                base: '100%',
              }}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              marginTop="4px"
            >
              {profileTo.username}
            </Text>
            <Text
              color="#34495E"
              fontWeight="400"
              width={{
                base: '100%',
              }}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {profileTo.email}
            </Text>
          </Box>
        </Flex>
        {loadingCon
          ? (
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Spinner
                thickness="4px"
                emptyColor="gray.200"
                color="#FF1A75"
                size="xl"
                mb="8px"
              />
              <Text>Loading</Text>
            </Flex>
          )
          : <Box />}
        <Box
          // id="con-viewport"
          id="chatlist-view"
          overflowY="scroll"
          height="400px"
        >
          {viewConversation()}
        </Box>
      </Box>
    </Box>
  );
}

ChatRoom2.propTypes = propTypes;

export default ChatRoom2;
