/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Text,
  List,
  SimpleGrid,
  AspectRatio,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  Button,
  Spinner,
  Heading,
  Input,
  Textarea,
  Stack,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
} from '@chakra-ui/react';
import { withRouter } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';
import { SearchAlt } from '@styled-icons/boxicons-regular/SearchAlt';
import GroupSearch from '../Groups/sections/GroupSearch';

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  groupPostGrid: {
    width: '100%',
    display: 'grid',
    gridGap: '1em',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const SearchButton = styled(SearchAlt)`
  width: 36px;
  outline: none;
  border: none;
  color: #156BBF;
  cursor: pointer;
  &:focus {
    outline:0 !important;
    border: 0 !important;
    color: #1890ff !important;
  }
  @media (max-width: 350px) {
    width: 24px;
  }
`;
const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      searchQuery: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
function GroupSearchResults({
  history,
  match,
}) {
  const { searchQuery } = match.params;
  const [groupResults, setGroupResults] = useState();
  const [errMessage, setErrMessage] = useState();
  const [loadedSearchResults, setLoadedSearchResults] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (searchQuery) {
      const query = searchQuery.replace(/[^\w\s]/gi, '');
      axios({
        method: 'POST',
        url: '/api/groups/search-group-database',
        data: {
          searchTerm: query,
        },
      })
        .then((res) => {
          // console.log(res.data.searchResults);
          setGroupResults(res.data.searchResults);
          setLoadedSearchResults(true);
        })
        .catch((err) => {
          setErrMessage(err);
        });
    }
  }, [searchQuery]);
  const renderSearchResults = () => groupResults.map((group) => (
    <Box>
      <Box position="relative" textAlign="right" width="100%">
        <AspectRatio
          ratio={16 / 9}
          position="relative"
        >
          {group.banner
            ? (
              <Box
                as="img"
                crossOrigin="anonymous"
                alt="thumbnail"
                src={group.banner.thumbNail}
                objectFit="cover"
                cursor="pointer"
                borderRadius=".70rem"
                marginTop="20px"
                boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                onClick={() => { history.push(`/group/${group._id}`); }}
              />
            ) : (
              <Box
                width={{
                  base: '100%',
                }}
                marginTop="20px"
                height="300px"
                cursor="pointer"
                borderRadius=".70rem"
                backgroundColor="#000"
                onClick={() => { history.push(`/group/${group._id}`); }}
              />
            )}
        </AspectRatio>
      </Box>
      <Box>
        <Stack isInline>
          <Box>
            <a href={`/group/${group._id}`}>
              <Text
                className={classes.title}
                fontWeight="bold"
                fontSize={{ base: '1.3em', sm: '1.4em' }}
                color="#003971"
                whiteSpace="wrap"
                overflow="hidden"
                textOverflow="ellipsis"
                marginTop="1.5em"
              >
                {group.name}
              </Text>
            </a>
            <Text
              color="#AAA"
              fontWeight="bold"
              fontSize={{ base: '1.1em', sm: '1.2em' }}
              whiteSpace="wrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {group.description}
            </Text>
            {
              group.members.length > 0
                ? (
                  <Text
                    fontWeight="600"
                    color="#AAA"
                    fontSize={{ base: '1.2rem' }}
                  >
                    {group.members.length + group.admins.length} members
                  </Text>
                ) : (
                  <Text
                    fontWeight="600"
                    color="#AAA"
                    fontSize={{ base: '1.2rem' }}
                  >
                    {group.members.length + group.admins.length} member
                  </Text>
                )
            }
          </Box>
        </Stack>
      </Box>
    </Box>
  ));
  return (
    <Flex
      className="app"
      width={{ base: '90%', lg: '1200px' }}
      marginX="auto"
      marginY="2rem"
    >
      {/*
      <GroupSearch />
      */
      }
      <Box>
        <Heading
          fontWeight="500"
          fontSize={{ base: '1.6rem', sm: '2rem' }}
          marginBottom="1rem"
          color="#0B67C2"
          marginY="1em"
          textAlign="center"
        >
          Search results for `{searchQuery}`
        </Heading>
      </Box>
      { loadedSearchResults
        ? (
          <Box
            className={classes.groupPostGrid}
          >
            {renderSearchResults()}
          </Box>
        ) : (
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <Spinner
              thickness="4px"
              emptyColor="gray.200"
              color="#FF1A75"
              size="xl"
              mb="8px"
              width="50px"
              margin="auto"
            />
            <Text>Loading</Text>
          </Flex>
        )}
    </Flex>
  );
}
GroupSearchResults.propTypes = propTypes;
export default GroupSearchResults;
