/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import PropTypes, { checkPropTypes } from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import {
  Box,
  Button,
  Text,
  Input,
  ModalFooter,
  AspectRatio,
  SimpleGrid,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/react';

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  profileId: PropTypes.string.isRequired,
};

function InviteFamilyModal(props) {
  const {
    history,
    profileId,
  } = props;
  const {
    isOpen: isOpenViewModal,
    onOpen: onOpenViewModal,
    onClose: onCloseViewModal,
  } = useDisclosure();
  const [formMessage, setFormErrorMessage] = useState();
  const [errMessage, setErrMessage] = useState();

  useEffect(() => {
    onOpenViewModal(true);
  }, []);

  const increaseUploadCounter = (link) => {
    axios({
      method: 'PUT',
      url: '/api/profile/increase-upload-counter',
      data: {
        profileId,
      },
    })
      .then(() => {
        history.push(link);
      })
      .catch((err) => {
        setFormErrorMessage(err);
      });
  };
  return (
    <Box>
      <Modal
        isOpen={isOpenViewModal}
        onClose={onCloseViewModal}
        width={{
          base: '200px', sm: '200px', md: '320px',
        }}
        margin="auto"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite Family</ModalHeader>
          <ModalCloseButton onClick={() => { increaseUploadCounter('/families'); }} />
          <ModalBody>
            <Text
              fontSize="1.2em"
              fontWeight="500"
            >
              You have successfully uploaded your files.
            </Text>
            <Text
              fontSize="1.2em"
              fontWeight="500"
            >
              Would you like to share your files by inviiting family memebers?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => { increaseUploadCounter('/families'); }}>
              Yes
            </Button>
            <Button colorScheme="blue" onClick={() => { increaseUploadCounter('/my-profile'); }}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
InviteFamilyModal.propTypes = propTypes;
export default withRouter(InviteFamilyModal);
