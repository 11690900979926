/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import {
  Box,
  Flex,
  Text,
  List,
  SimpleGrid,
  AspectRatio,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Image,
} from '@chakra-ui/react';
import {
  Row,
  Col,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { BackgroundColor } from '@styled-icons/foundation';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import SideVideo from './Sections/SideVideo';
import SidePosts from '../../sections/SidePosts';
import Subscriber from './Sections/Subscriber';
import Comments from './Sections/Comments';
import LikeDislikes from './Sections/LikeDislikes';
import VideoReaction from './Sections/VideoReaction';
import ErrorImage from '../../../assets/images/famshare.png';

const SeeMoreButton = styled(EyeFill)`
  width: 24px;
  height: 36px;
  color: #BBB;
  cursor: pointer;
`;
const HeartActive = styled(SuitHeartFill)`
  color: #FF0000; 
  right: 12px;
  top: 20px;
  position: relative;
  width: 20px;
  height: 20px;
`;
const HeartButton = styled(SuitHeartFill)`
  background-color: inherit;
  width: 24px;
  height: 24px;
  color: inherit;
  cursor: pointer;
  margin-top: 0.5em;
`;

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      videoId: PropTypes.string,
    }),
  }).isRequired,
};

function DetailVideoPage({
  match,
}) {
  const { videoId } = match.params;
  const [video, setVideo] = useState();
  const [error, setError] = useState('');
  const [loadCompleted, setLoadCompleted] = useState(false);
  const [profile, setProfile] = useState();
  const [myId, setMyId] = useState();
  const [reactionActive, setReactionActive] = useState(false);
  const [videoReactions, setVideoReactions] = useState([]);
  const [viewReactions, setViewReactions] = useState([]);
  const [videoWriterProfileId, setVideoWriterProfileId] = useState();
  const [initialPost, setInitialPost] = useState();
  const user = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getReactions = (profileData, newVideoId) => {
    // console.log('hello');
    axios({
      url: '/api/video/getAllReactions',
      method: 'POST',
      data: {
        videoId: newVideoId,
        profileId: profileData._id,
      },
    })
      .then((response) => {
        // console.log(`help: ${response.data.reactions[0]}`);
        setVideoReactions(response.data.reactions);
        setViewReactions(response.data.reactions?.slice(0, 4));
        // console.log(`result: ${response.data.reactions}`);
        // eslint-disable-next-line eqeqeq
        if (response.data.reactions === undefined
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == []
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == '') {
          // console.log('reached me');
          setReactionActive(false);
          setLoadCompleted(true);
          // document.getElementById(`heart-button`).style.color = '#DDD';
          return;
        }
        if (response.data.reactions[0].profile._id === profileData._id) {
          // console.log('match found');
          // document.getElementById(`heart-button`).style.color = '#FF0000';
          setReactionActive(true);
          // console.log('reached ne');
        } else {
          // document.getElementById(`heart-button`).style.color = '#DDD';
          setReactionActive(false);
        }
        // console.log('finally');
        setLoadCompleted(true);
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
      })
      .catch((err) => {
        setError(err);
      });
  };
  const reactionHandler = () => {
    // console.log(imageReactions);
    if (reactionActive === false) {
      axios({
        url: '/api/video/addVideoReaction',
        method: 'POST',
        data: {
          videoId,
          profile,
          reactionType: 'Love',
        },
      })
        .then((reactionData) => {
          // console.log(`reached? ${reactionData.data.reactionData.profile._id}`);
          const newReaction = viewReactions;
          const allreactions = videoReactions;
          newReaction.unshift(reactionData.data.reactionData);
          allreactions.unshift(reactionData.data.reactionData);
          //  document.getElementById(`heart-button`).style.color = '#FF0000';
          setViewReactions(newReaction);
          setVideoReactions(allreactions);
          setReactionActive(true);
          // console.log(profile.image);
        })
        .catch((err) => {
          setError(err);
        });
    } else {
      axios({
        url: '/api/video/removeVideoReaction',
        method: 'POST',
        data: {
          profile,
          videoId,
        },
      })
        .then(() => {
          // console.log(imageReactions);
          const allReactions = videoReactions;
          const newReaction = viewReactions;
          allReactions.shift();
          newReaction.shift();
          // console.log(`new ${newReactions.length}`);
          setVideoReactions(allReactions);
          setViewReactions(newReaction);
          setReactionActive(false);
        });
    }
  };
  useEffect(() => {
    if (!user.userData) {
      return;
    }
    axios({
      method: 'POST',
      url: '/api/users/getProfile',
      data: {
        username: user.userData.username,
      },
    }, [user, user.userData])
      .then((data) => {
        axios.post('/api/post/video-post-byid', { videoId, profileId: data.data._id }).then((response) => {
          if (response.data.success) {
            // console.log(`got data:  + ${response.data.post.video?.duration}`);
            // console.log(response.data.post.video);
            setVideo(response.data.post.video);
            setInitialPost(response.data.post);
            // console.log('reached');
            setVideoWriterProfileId(response.data.post.video?.writer._id);
            setProfile(data.data);
            setMyId(data.data._id);
            getReactions(data.data, response.data.post.video?._id);
            window.scrollTo(0, 0);
            // console.log('reached');
          }
        }).catch((err) => {
        // console.log(JSON.stringify(err));
          if (err.response && err.response.status === 403) {
            setError('ACCESS_DENIED');
          }
        });
      })
      .catch((err) => {
        setError('Cannot fetch user profile');
      });
  }, [videoId, user]);

  // author={<Text fontSize={{base:".7rem", sm:".7rem", md:".9rem"}} fontWeight="500" color="#000">{video.writer.username}</Text>}
  if (error) {
    if (error === 'ACCESS_DENIED') {
      return (
        <Flex className="app" width="100%" minHeight="90vh">
          <Image src={ErrorImage} width="35%" />
          <Text
            fontSize="1.3em"
            fontWeight="600"
          >
            You do not have permission to view this video.
          </Text>
        </Flex>
      );
    }
    return (
      <Flex className="app" width="100%" minHeight="90vh">
        <Image src={ErrorImage} width="35%" />
        <Text
          fontSize="1.3em"
          fontWeight="600"
        >
          An error occurred while loading the video.
        </Text>
      </Flex>
    );
  }

  return (
    <Box minHeight="90vh" width="100vw">
      {loadCompleted
        ? (
          <SimpleGrid gridTemplateColumns={{ base: '1fr', lg: '3fr 1fr' }}>
            <Box
              width="100%"
              marginTop="1rem"
            >
              <Box padding={{ base: '0', md: '.1rem 3rem' }}>
                <AspectRatio ratio={16 / 9} objectFit="contain">
                  <Box backgroundColor="#111">
                    <Box
                      as="video"
                      width="auto"
                      height="100%"
                      src={video.filePath}
                      controls
                    />
                  </Box>
                </AspectRatio>
              </Box>
              <Box padding={{ base: '0.5rem', md: '.1rem 3rem' }}>
                <Flex justifyContent="space-between">
                  <Box
                    marginTop="0.5rem"
                  >
                    <Flex>
                      <Box paddingTop="5px" paddingRight="5px">
                        <Box
                          as="img"
                          crossOrigin="anonymous"
                          src={video.writer && video.writer.image}
                          width="50px"
                          height="50px"
                          objectFit="cover"
                          borderRadius="50%"
                          verticalAlign="middle"
                          backgroundSize="cover"
                        />
                      </Box>
                      <Box marginBottom="1rem">
                        <Text
                          fontWeight="bold"
                          fontSize={{ base: '1.3em', sm: '1.4em' }}
                          color="#003971"
                          whiteSpace="wrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          marginTop="0.5em"
                        >
                          {video.title}
                        </Text>
                        <Text
                          color="#AAA"
                          fontWeight="bold"
                          fontSize={{ base: '1.1em', sm: '1.2em' }}
                          whiteSpace="wrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {video.description}
                        </Text>
                        <Text
                          color="#AAA"
                          fontWeight="bold"
                          fontSize={{ base: '1.1em', sm: '1.2em' }}
                        >
                          {video.writer.username}
                        </Text>
                        <Text
                          color="#AAA"
                          fontWeight="bold"
                          fontSize={{ base: '1em', sm: '1.1em' }}
                        >
                          {moment(video.createdAt).format('LL')}
                        </Text>
                        <Box>{viewReactions.length > 0
                          ? (
                            <SimpleGrid
                              gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                              width="250px"
                            >
                              {viewReactions?.map((data) => (
                                <Flex marginY="8px" height="32px">
                                  <Box
                                    as="img"
                                    crossOrigin="anonymous"
                                    src={data.profile && data.profile.image}
                                    width="32px"
                                    height="32px"
                                    objectFit="cover"
                                    borderRadius="50%"
                                    verticalAlign="middle"
                                    backgroundSize="cover"
                                  />
                                  <HeartActive />
                                </Flex>
                              ))}
                              <Flex marginY="8px" marginLeft="5px" height="32px">
                                <SeeMoreButton onClick={() => { onOpen(); }} />
                              </Flex>
                              <Box>
                                <Modal isOpen={isOpen} onClose={onClose}>
                                  <ModalOverlay />
                                  <ModalContent>
                                    <ModalCloseButton />
                                    <ModalBody>
                                      <Box marginY="3rem">
                                        {videoReactions?.map((data) => (
                                          <Flex marginY="1rem">
                                            <Box
                                              as="img"
                                              crossOrigin="anonymous"
                                              src={data.profile && data.profile.image}
                                              width="42px"
                                              height="42px"
                                              objectFit="cover"
                                              borderRadius="50%"
                                              verticalAlign="middle"
                                              backgroundSize="cover"
                                            />
                                            <HeartActive />
                                            <Text marginTop="5px" fontWeight="500">{data.profile.username}</Text>
                                          </Flex>
                                        ))}
                                      </Box>
                                    </ModalBody>
                                  </ModalContent>
                                </Modal>
                              </Box>
                            </SimpleGrid>
                          )
                          : <Box />}
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                  <Box marginRight={{ base: '0.5rem', md: '1rem' }} marginTop={{ base: '0.5rem', md: '1rem' }}>
                    <Box color={reactionActive === false ? '#AAA' : '#FF0000'}>
                      <HeartButton id="heart-button" onClick={() => reactionHandler()} />
                    </Box>
                  </Box>
                </Flex>
                <Comments
                  videoId={video._id}
                  profile={profile}
                />
              </Box>
            </Box>
            <SidePosts
              profileId={myId}
              postWriterId={videoWriterProfileId}
              initialPost={initialPost}
            />
          </SimpleGrid>
        )
        : (
          <Flex flexDirection="column" justifyContent="center" alignItems="center" height="75vh">
            <Box>
              <Spinner
                thickness="4px"
                emptyColor="gray.200"
                color="#FF1A75"
                size="xl"
                mb="8px"
                width="50px"
                margin="auto"
              />
              <Text>Loading</Text>
            </Box>
          </Flex>
        )}
    </Box>
  );
}

DetailVideoPage.propTypes = propTypes;

export default DetailVideoPage;
