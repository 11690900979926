/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Button,
  Image,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import DataUsage from '../../../sections/DataUsage';

const StyledButton = styled(Box)`
color: #006644;
background-color: #BBEFDF;
font-size: 1.2em;
padding: 10px 16px;
border-radius: 10px;
cursor: pointer;
text-align: center;
width: 80%;
&:hover {
  background-color: #BBEFDF;
}
&:focus {
  outline:0 !important;
  box-shadow: none;
  border: none;
}
`;
const DisabledButton = styled(Box)`
color: #AAA;
background-color: #CCC;
font-size: 1.2em;
padding: 10px 16px;
border-radius: 10px;
width: 80%;
text-align: center;
&:focus {
  outline:0 !important;
  box-shadow: none;
  border: none;
}
`;

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  profile: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
};

function SubCancel({
  history,
  profile,
  productId,
}) {
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const [pageLoaded, setPageLoaded] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState();
  const [productList, setProductList] = useState();
  const [currentProfileSub, setCurrentProfileSub] = useState();
  const [dataUsageObj, setDataUsageObj] = useState();

  useEffect(() => {
    if (profile._id && productId) {
      // console.log(productId);
      // console.log(profile.subscriptionId);
      setPageLoaded(true);
    }
  }, [productId, profile]);

  const cancelSubscription = () => {
    axios({
      method: 'POST',
      url: '/api/stripe/cancel-subscription',
      data: {
        profile,
        productId,
      },
    })
      .then((res) => {
        if (res.data.success) {
          window.location.reload();
        }
      })
      .catch((err) => {
        setFormErrorMessage(err);
      });
  };
  return (
    <Flex>
      <Menu>
        <Flex
          justifyContent="center"
          width="100%"
          marginY="0.5rem"
        >
          <StyledButton
            onClick={onOpenModal}
          >
            <Text
              fontSize="1.2rem"
              fontWeight="600"
            >
              Select Plan
            </Text>
          </StyledButton>
        </Flex>
        <MenuList
          placement="bottom-start"
          zIndex="9999"
          backgroundColor="#EEE"
          width="125px"
        >
          <MenuItem onClick={onOpenModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Edit
            </Text>
          </MenuItem>

        </MenuList>
      </Menu>

      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancel Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              fontSize="1.1rem"
              fontWeight="600"
            >
              Are you sure you want to cancel your paid subscription?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseModal}>
              No
            </Button>
            <Button colorScheme="blue" onClick={cancelSubscription}>
              Yes, Cancel Subscription
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
SubCancel.propTypes = propTypes;
export default withRouter(SubCancel);
