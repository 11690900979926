/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import {
  Box,
  Flex,
  Avatar,
  Text,
  List,
  SimpleGrid,
  AspectRatio,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  Spinner,
} from '@chakra-ui/react';
import {
  Row,
  Col,
} from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import { BackgroundColor } from '@styled-icons/foundation';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import SideImage from './sections/SideImage';
import SidePosts from '../../sections/SidePosts';
// import Subscriber from './Sections/Subscriber';
import Comments from './sections/Comments';
import LikeDislikes from './sections/LikeDislikes';
import ImageReaction from './sections/ImageReaction';
import ErrorImage from '../../../assets/images/famshare.png';

const SeeMoreButton = styled(EyeFill)`
  width: 24px;
  height: 36px;
  color: #BBB;
  cursor: pointer;
`;
const HeartActive = styled(SuitHeartFill)`
  color: #FF0000; 
  right: 12px;
  top: 20px;
  position: relative;
  height: 20px;
  width: 20px;
`;
const HeartButton = styled(SuitHeartFill)`
  background-color: inherit;
  width: 24px;
  height: 24px;
  color: inherit;
  cursor: pointer;
  margin-top: 0.2em;
`;

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      imageId: PropTypes.string,
    }),
  }).isRequired,
};

function DetailImagePage({
  match,
}) {
  const { imageId } = match.params;
  const [image, setImage] = useState();
  const [error, setError] = useState('');
  const [loadCompleted, setLoadCompleted] = useState(false);
  const [profile, setProfile] = useState();
  const [myId, setMyId] = useState();
  const [reactionActive, setReactionActive] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(true);
  const [imageReactions, setImageReactions] = useState([]);
  const [viewReactions, setViewReactions] = useState([]);
  const [relatedImagesWriter, setRelatedImagesWriter] = useState();
  const [initialPost, setInitialPost] = useState();
  const user = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getReactions = (profileData, newImageId) => {
    // console.log(profileData);
    axios({
      url: '/api/image/getAllReactions',
      method: 'POST',
      data: {
        imageId: newImageId,
        profile: profileData,
      },
    })
      .then((response) => {
        // console.log(response.data.reactions[0])
        setImageReactions(response.data.reactions);
        setViewReactions(response.data.reactions?.slice(0, 4));
        // console.log(`result: ${response.data.reactions}`);
        // eslint-disable-next-line eqeqeq
        if (response.data.reactions === undefined
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == []
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == '') {
          // console.log('reached me');
          setReactionActive(false);
          setLoadCompleted(true);
          // document.getElementById(`heart-button`).style.color = '#DDD';
          return;
        }
        if (response.data.reactions[0].profile._id === profileData._id) {
          // console.log('match found');
          // document.getElementById(`heart-button`).style.color = '#FF0000';
          setReactionActive(true);
          // console.log('reached ne');
        } else {
          // document.getElementById(`heart-button`).style.color = '#DDD';
          setReactionActive(false);
        }
        // console.log('finally');
        setLoadCompleted(true);
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
      })
      .catch((err) => {
        setError(err);
      });
  };
  const reactionHandler = () => {
    // console.log(imageReactions);
    if (reactionActive === false) {
      axios({
        url: '/api/image/addImageReaction',
        method: 'POST',
        data: {
          imageId,
          profile,
          reactionType: 'Love',
        },
      })
        .then((reactionData) => {
          // console.log(`reached? ${reactionData.data.reactionData.profile._id}`);
          const newReaction = viewReactions;
          const allreactions = imageReactions;
          newReaction.unshift(reactionData.data.reactionData);
          allreactions.unshift(reactionData.data.reactionData);
          //  document.getElementById(`heart-button`).style.color = '#FF0000';
          setViewReactions(newReaction);
          setImageReactions(allreactions);
          setReactionActive(true);
          // console.log(profile.image);
        })
        .catch((err) => {
          setError(err);
        });
    } else {
      axios({
        url: '/api/image/removeImageReaction',
        method: 'POST',
        data: {
          profile,
          imageId,
        },
      })
        .then(() => {
          // console.log(imageReactions);
          const allReactions = imageReactions;
          const newReaction = viewReactions;
          allReactions.shift();
          newReaction.shift();
          // console.log(`new ${newReactions.length}`);
          setImageReactions(allReactions);
          setViewReactions(newReaction);
          setReactionActive(false);
        });
    }
  };
  useEffect(() => {
    if (!user.userData) {
      return;
    }
    axios({
      method: 'POST',
      url: '/api/users/getProfile',
      data: {
        username: user.userData.username,
      },
    }, [user, user.userData])
      .then((data) => {
        axios.post('/api/post/image-post-byid', { imageId, profileId: data.data._id }).then((response) => {
          if (response.data.success) {
            // console.log(`got data: ${response.data.post.image.writer.image}`);
            // console.log(response.data.video)
            // console.log(data.data);
            setImage(response.data.post.image);
            setRelatedImagesWriter(response.data.post.image.writer._id);
            setProfile(data.data);
            setInitialPost(response.data.post);
            setMyId(data.data._id);
            getReactions(data.data, response.data.post.image._id);
            window.scrollTo(0, 0);
          }
        }).catch((err) => {
        // console.log(JSON.stringify(err));
          if (err.response && err.response.status === 403) {
            setError('ACCESS_DENIED');
          }
        });
      })
      .catch((err) => {
        setError('Cannot fetch user profile');
      });
  }, [imageId, user]);
  const userChangeMedia = (newImage) => {
    setImageLoaded(false);
    setImage(newImage);
    getReactions(profile, newImage._id);
    setImageLoaded(true);
  };
  // author={<Text fontSize={{base:".7rem", sm:".7rem", md:".9rem"}} fontWeight="500" color="#000">{video.writer.username}</Text>}
  if (error) {
    if (error === 'ACCESS_DENIED') {
      return (
        <Flex className="app" width="100%" minHeight="90vh">
          <Box as="img" crossOrigin="anonymous" src={ErrorImage} width="35%" />
          <Text
            fontSize="1.3em"
            fontWeight="600"
          >
            You do not have permission to view this image.
          </Text>
        </Flex>
      );
    }
    return (
      <Flex className="app" width="100%" minHeight="90vh">
        <Box as="img" crossOrigin="anonymous" src={ErrorImage} width="35%" />
        <Text
          fontSize="1.3em"
          fontWeight="600"
        >
          An error occurred while loading the image.
        </Text>
      </Flex>
    );
  }
  return (
    <Box minHeight="90vh" width="100vw">
      {loadCompleted
        ? (
          <SimpleGrid gridTemplateColumns={{ base: '1fr', lg: '3fr 1fr' }}>
            <Box
              className="postPage"
              width="100%"
              marginTop="1rem"
              paddingX="1.5rem"
            >
              <AspectRatio
                ratio={16 / 9}
                backgroundColor="#111"
              >
                {imageLoaded
                  ? (
                    <Flex justifyContent="center">
                      <Box as="img" crossOrigin="anonymous" alt="photo" src={image.filePath} alignSelf="center" width="auto" height="100%" />
                    </Flex>
                  ) : <Box />}
              </AspectRatio>
              <Box marginTop="0.5rem">
                <Flex justifyContent="space-between">
                  <Box
                    marginBottom="1rem"
                  >
                    <Flex>
                      <Box paddingTop="5px" paddingRight="10px">
                        <Box
                          as="img"
                          crossOrigin="anonymous"
                          src={image.writer && image.writer.image}
                          width="50px"
                          height="50px"
                          objectFit="cover"
                          borderRadius="50%"
                          verticalAlign="middle"
                        />
                      </Box>
                      <Box marginBottom="1rem">
                        <Text
                          color="#003971"
                          fontWeight="bold"
                          fontSize={{ base: '1.3em', sm: '1.4em' }}
                          whiteSpace="wrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          marginTop="0.5em"
                        >
                          {image.description}
                        </Text>
                        <Text
                          color="#AAA"
                          fontWeight="bold"
                          fontSize={{ base: '1.1em', sm: '1.2em' }}
                          whiteSpace="wrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {image.writer.username}
                        </Text>
                        <Text
                          color="#AAA"
                          fontWeight="bold"
                          fontSize={{ base: '1em', sm: '1.1em' }}
                        >
                          {moment(image.createdAt).format('LL')}
                        </Text>
                        <Box>{viewReactions.length > 0
                          ? (
                            <Flex>
                              {viewReactions?.map((data) => (
                                <Flex marginY="1rem">
                                  <Box
                                    as="img"
                                    crossOrigin="anonymous"
                                    src={data.profile && data.profile.image}
                                    width="32px"
                                    height="32px"
                                    objectFit="cover"
                                    borderRadius="50%"
                                    verticalAlign="middle"
                                    backgroundSize="cover"
                                  />
                                  <HeartActive />
                                </Flex>
                              ))}
                              <Flex marginY="8px" marginLeft="5px" height="50px">
                                <SeeMoreButton verticalAlign="middle" onClick={() => { onOpen(); }} />
                              </Flex>
                              <Box>
                                <Modal isOpen={isOpen} onClose={onClose}>
                                  <ModalOverlay />
                                  <ModalContent>
                                    <ModalCloseButton />
                                    <ModalBody>
                                      <Box marginY="3rem">
                                        {imageReactions?.map((data) => (
                                          <Flex marginY="1rem">
                                            <Box
                                              as="img"
                                              crossOrigin="anonymous"
                                              src={data.profile && data.profile.image}
                                              width="42px"
                                              height="42px"
                                              objectFit="cover"
                                              borderRadius="50%"
                                              verticalAlign="middle"
                                              backgroundSize="cover"
                                            />
                                            <HeartActive />
                                            <Text marginTop="5px" fontWeight="500">{data.profile.username}</Text>
                                          </Flex>
                                        ))}
                                      </Box>
                                    </ModalBody>
                                  </ModalContent>
                                </Modal>
                              </Box>
                            </Flex>
                          )
                          : <Box />}
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                  <Box marginRight={{ base: '0.5rem', md: '1rem' }} marginTop={{ base: '0.5rem', md: '1rem' }}>
                    <Box color={reactionActive === false ? '#AAA' : '#FF0000'}>
                      <HeartButton id="heart-button" onClick={() => reactionHandler()} />
                    </Box>
                  </Box>
                </Flex>
                <Comments
                  imageId={image._id}
                  profile={profile}
                />
              </Box>
            </Box>
            <SidePosts
              profileId={myId}
              initialPost={initialPost}
              postWriterId={relatedImagesWriter}
              userChangeMedia={userChangeMedia}
            />
          </SimpleGrid>
        )
        : (
          <Flex flexDirection="column" justifyContent="center" alignItems="center" height="75vh">
            <Box>
              <Spinner
                thickness="4px"
                emptyColor="gray.200"
                color="#FF1A75"
                size="xl"
                mb="8px"
                width="50px"
                margin="auto"
              />
              <Text>Loading</Text>
            </Box>
          </Flex>
        )}
    </Box>
  );
}

DetailImagePage.propTypes = propTypes;

export default DetailImagePage;
