/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Button,
  Image,
  Spinner,
} from '@chakra-ui/react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import DataUsage from '../../sections/DataUsage';
import Server from '../../../assets/images/server.png';
import AlertBanner from '../../sections/AlertBanner';
import SubCancel from './sections/SubCancel';

const StyledButton = styled(Box)`
color: #006644;
background-color: #BBEFDF;
font-size: 1.2em;
padding: 10px 16px;
border-radius: 10px;
cursor: pointer;
text-align: center;
width: 80%;
&:hover {
  background-color: #BBEFDF;
}
&:focus {
  outline:0 !important;
  box-shadow: none;
  border: none;
}
`;
const DisabledButton = styled(Box)`
color: #AAA;
background-color: #CCC;
font-size: 1.2em;
padding: 10px 16px;
border-radius: 10px;
width: 80%;
text-align: center;
&:focus {
  outline:0 !important;
  box-shadow: none;
  border: none;
}
`;

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function UpgradeStorePage({
  history,
}) {
  const user = useSelector((state) => state.user);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [profile, setProfile] = useState();
  const [formErrorMessage, setFormErrorMessage] = useState();
  const [productList, setProductList] = useState();
  const [currentProfileSub, setCurrentProfileSub] = useState();
  const [dataUsageObj, setDataUsageObj] = useState();
  const alertTitle = 'Please make sure your current storage is lower than the maximum amount of storage that is offered by the plan you wish to select';
  const fetchDataUsage = (myProfile) => {
    axios({
      method: 'POST',
      url: 'api/users/getDataUsage',
      data: {
        profileId: myProfile._id,
      },
    })
      .then((response) => {
        // console.log(response.data.dataUsage);
        setDataUsageObj(response.data.dataUsage);
        // console.log(response.data.dataUsage);
        /*
        if (myProfile.username === 'lolwhat101' || myProfile.username === 'andyykim') {
          setPageLoaded(true);
        }
        */
        setPageLoaded(true);
      })
      .catch((err) => {
        setFormErrorMessage(err);
      });
  };

  useEffect(() => {
    if (user.userData) {
      axios({
        method: 'POST',
        url: '/api/profile/profile-product',
        data: {
          email: user.userData.email,
        },
      })
        .then((data) => {
          axios.get('/api/stripe/retrieve-products').then((response) => {
            // console.log(data.data);
            // console.log(response.data.products);
            setCurrentProfileSub(data.data.product);
            setProductList(response.data.products);
            setProfile(data.data);
            fetchDataUsage(data.data);
          });
        })
        .catch((err) => {
          setFormErrorMessage('Cannot fetch user profile');
        });
    }
  }, [user.userData, user]);

  const checkout = (selectedProduct) => {
    axios({
      url: 'api/stripe/create-checkout-session',
      method: 'POST',
      data: {
        product: selectedProduct,
        profile,
        profileId: profile._id,
      },
    }).then((res) => {
      if (res.data.success) {
        window.location.href = res.data.url;
      }
    });
  };
  const retrieveProducts = () => productList.map((product) => (
    <Flex
      backgroundColor="#F1F1F1"
      borderRadius="10px"
      justifyContent="space-around"
      flexDirection="column"
      marginBottom="3rem"
      paddingY="3em"
    >
      <Box
        width="90%"
        marginX="auto"
        marginY="1rem"
      >
        <Text
          fontSize={{ base: '1.3em', md: '1.6em' }}
          fontWeight="600"
        >
          {product.name}
        </Text>
        <Text
          marginY="0.5rem"
          fontSize={{ base: '1.1em', md: '1.2em' }}
          fontWeight="600"
        >
          {product.description}
        </Text>
      </Box>
      <Box
        width="90%"
        marginX="auto"
        marginY="1rem"
      >
        <Text
          marginY="0.5rem"
          fontSize={{ base: '1.2em', md: '1.4em' }}
          fontWeight="600"
        >
          {product.price_label}
        </Text>
      </Box>

      {
        currentProfileSub._id === product._id
          ? (
            <Flex
              justifyContent="flex-end"
              alignItems="center"
              flexDirection="column"
              width="100%"
              marginY="0.5rem"
            >
              <Button
                color="#FF1A75"
                border="1px solid"
                borderColor="#FF1A75"
                marginY="1em"
                borderRadius="24px"
                py="4"
                px="4"
                width="200px"
                lineHeight="1"
              >
                Your Current Plan
              </Button>
            </Flex>
          ) : (
            <Box>
              {dataUsageObj.currentUsage < product.storage_amount
                ? (
                  <Box>{
                      product.name === 'Free Plan'
                        ? (
                          <SubCancel
                            productId={product._id}
                            profile={profile}
                          />
                        )
                        : (
                          <Flex
                            justifyContent="center"
                            width="100%"
                            marginY="0.5rem"
                          >
                            <Button
                              marginY="1em"
                              bg="#3480E3"
                              color="#FFF"
                              borderRadius="24px"
                              py="4"
                              px="4"
                              width="200px"
                              lineHeight="1"
                              onClick={() => { checkout(product); }}
                              _hover={{ bg: 'blue.300' }}
                            >
                              Select Plan
                            </Button>
                          </Flex>
                        )
                      }
                  </Box>
                ) : (
                  <Flex
                    justifyContent="center"
                    width="100%"
                    marginY="0.5rem"
                  >
                    <Button
                      marginY="1em"
                      bg="#CCC"
                      color="#AAA"
                      borderRadius="24px"
                      py="4"
                      px="4"
                      width="200px"
                      lineHeight="1"
                      onClick={() => { checkout(product); }}
                    >
                      Select Plan
                    </Button>
                  </Flex>
                )}
            </Box>
          )
}
    </Flex>
  ));
  if (pageLoaded === false) {
    return (
      <Box
        width="100%"
        minH="90vh"
      >
        <Flex flexDirection="column" justifyContent="center" alignItems="center" minH="90vh">
          <Spinner
            thickness="4px"
            emptyColor="gray.200"
            color="#FF1A75"
            size="xl"
            mb="8px"
            width="50px"
            margin="auto"
          />
          <Text>Loading</Text>
        </Flex>
      </Box>
    );
  }
  return (
    <Box
      width="100%"
      minHeight="90vh"
    >
      <Flex
        justifyContent="center"
        alignItem="center"
        flexDirection="column"
        height="100%"
      >
        <Box
          width={{
            base: '90%', md: '90%', lg: '700px', xl: '1000px',
          }}
          margin="auto"
        >
          <Box
            marginY="1rem"
            textAlign="center"
          >
            <Text
              fontSize={{ base: '1.4em', md: '2.5em' }}
              fontWeight="600"
            >
              Upgrade Your Kloak
            </Text>
          </Box>
          <DataUsage
            displayUsage={dataUsageObj.displayUsage}
            maxUsage={dataUsageObj.displayMax}
            availableSpace={dataUsageObj.displayFreeData}
          />
          <Box>
            <AlertBanner title={alertTitle} />
          </Box>
          <Box
            marginY="1rem"
          >
            <Text
              fontSize={{ base: '1.3em', md: '2em' }}
              fontWeight="600"
            >
              Choose your plan:
            </Text>
          </Box>
          <SimpleGrid
            gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }}
            gridGap="10px"
            width="100%"
          >
            {retrieveProducts()}
          </SimpleGrid>
        </Box>
      </Flex>
    </Box>
  );
}
UpgradeStorePage.propTypes = propTypes;
export default withRouter(UpgradeStorePage);
