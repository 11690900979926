/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Formik, Field } from 'formik';
import axios from 'axios';

import {
  Avatar,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Input,
} from '@chakra-ui/react';
// import { Invites } from '../../../../../server/models/Invites';
import styled from 'styled-components';
import { UserFriends } from '@styled-icons/fa-solid/UserFriends';
import { InsertInvitation } from '@styled-icons/material/InsertInvitation';
import { Settings2Outline } from '@styled-icons/evaicons-outline/Settings2Outline';
import SettingsPage from './sections/SettingsPage';
import InvitesPage from './sections/InvitesPage';
import MetaData from './sections/MetaData';

const StyledTab = styled(Tab)`
  margin: 0;
  &:focus {
    outline:0 !important;
    box-shadow: none;
    border: none;
    border-color: #FFF !important;
  }
`;

const FriendButton = styled(UserFriends)`
  color: ##1890ff;
  width: 36px;
`;
const InviteButton = styled(InsertInvitation)`
color: ##1890ff;
width: 36px;
`;
const SettingsButton = styled(Settings2Outline)`
color: ##1890ff;
width: 36px;
`;
const useStyles = createUseStyles({
  pageView: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
  friendCard: {
    position: 'relative',
    padding: '24px',
    width: '300px',
    height: '320px',
    borderRadius: '8px',
    boxShadow: 'rgba(90, 90, 111, 0.2) 0px 4px 16px 0px',
  },
  searchResult: {
    padding: '16px',
    marginTop: '16px',
    width: '460px',
    borderRadius: '8px',
    boxShadow: 'rgba(90, 90, 111, 0.2) 0px 7px 29px 0px',
  },
  friendCardButtonsContainer: {
    position: 'absolute',
    bottom: '24px',
  },
});

// TODO: Loading page
function AdminPage() {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [profile, setProfile] = useState();
  const [connectionsFetched, setConnectionsFetched] = useState(false);
  const [connections, setConnections] = useState([]);
  const [foundUser, setFoundUser] = useState();
  const [formErrorMessage, setFormErrorMessage] = useState('');
  useEffect(() => {
    console.log('running');
    if (user.userData) {
      axios({
        method: 'POST',
        url: '/api/users/getProfile',
        data: {
          username: user.userData.username,
        },
      })
        .then((data) => {
          setProfile(data.data);
          console.log(data.data._id);
          axios.get(`/api/connection/all/${data.data._id}`).then((response) => {
            console.log(response.data.connections);
            setConnections(response.data.connections);
            setConnectionsFetched(true);
          });
        })
        .catch((err) => {
          setFormErrorMessage('Cannot fetch user profile');
        });
    }
  }, [user.userData, user]);

  return (
    <Flex
      className="app"
      width="100%"
      marginTop="2rem"
    >
      <Heading
        fontWeight="600"
        fontSize={{ base: '2.5rem' }}
        color="#0B67C2"
      >
        Admin Version
      </Heading>
      <Tabs
        width={{
          base: '90%', sm: '90%', md: '100%', lg: '770px', xl: '1200px',
        }}
        margin="auto"
      >
        <TabList>
          <StyledTab>
            <InviteButton />
          </StyledTab>
          <StyledTab>
            <SettingsButton />
          </StyledTab>
          <StyledTab>
            <FriendButton />
          </StyledTab>
        </TabList>
        <TabPanels>
          <TabPanel
            minHeight="90vh"
          >
            <InvitesPage profile={profile} />
          </TabPanel>
          <TabPanel
            minHeight="90vh"
          >
            <SettingsPage />
          </TabPanel>
          <TabPanel
            minHeight="90vh"
          >
            <MetaData />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default AdminPage;
