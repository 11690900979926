/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const propTypes = {
  userTo: PropTypes.string.isRequired,
  userFrom: PropTypes.string.isRequired,
};

function Subscriber({
  userTo,
  userFrom,
}) {
  const [SubscribeNumber, setSubscribeNumber] = useState(0);
  const [Subscribed, setSubscribed] = useState(false);

  const onSubscribe = () => {
    const subscribeVariables = {
      userTo,
      userFrom,
    };

    if (Subscribed) {
      // when we are already subscribed
      axios
        .post('/api/subscribe/unSubscribe', subscribeVariables)
        .then((response) => {
          if (response.data.success) {
            setSubscribeNumber(SubscribeNumber - 1);
            setSubscribed(!Subscribed);
          } else {
            alert('Failed to unsubscribe');
          }
        });
    } else {
      // when we are not subscribed yet

      axios
        .post('/api/subscribe/subscribe', subscribeVariables)
        .then((response) => {
          if (response.data.success) {
            setSubscribeNumber(SubscribeNumber + 1);
            setSubscribed(!Subscribed);
          } else {
            alert('Failed to subscribe');
          }
        });
    }
  };

  useEffect(() => {
    const subscribeNumberVariables = { userTo, userFrom };
    axios
      .post('/api/subscribe/subscribeNumber', subscribeNumberVariables)
      .then((response) => {
        if (response.data.success) {
          setSubscribeNumber(response.data.subscribeNumber);
        } else {
          alert('Failed to get subscriber Number');
        }
      });

    axios
      .post('/api/subscribe/subscribed', subscribeNumberVariables)
      .then((response) => {
        if (response.data.success) {
          setSubscribed(response.data.subcribed);
        } else {
          alert('Failed to get Subscribed Information');
        }
      });
  }, [userTo, userFrom]);

  return (
    <div>
      <button
        type="button"
        onClick={onSubscribe}
        style={{
          backgroundColor: `${Subscribed ? '#AAAAAA' : '#CC0000'}`,
          borderRadius: '4px',
          color: 'white',
          padding: '10px 16px',
          fontWeight: '500',
          fontSize: '1rem',
          textTransform: 'uppercase',
        }}
      >
        {SubscribeNumber} {Subscribed ? 'Subscribed' : 'Subscribe'}
      </button>
    </div>
  );
}

Subscriber.propTypes = propTypes;

export default Subscriber;
