/* eslint-disable */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, AspectRatio, Avatar, Text, Stack, Image } from "@chakra-ui/react";
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});
const propTypes = {
  profileId: PropTypes.string.isRequired,
  relatedImagesWriter: PropTypes.string.isRequired,
  initialImage: PropTypes.object.isRequired,
  userChangeImage: PropTypes.func.isRequired,
};

function SideImage({
  profileId,
  relatedImagesWriter,
  initialImage,
  userChangeImage,
}) {
  const [sideImages, setSideImages] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const classes = useStyles();  
  useEffect(() => {
    if (profileId && relatedImagesWriter && initialImage) {
      let axiosUrl;
      if (profileId === relatedImagesWriter) {
        axiosUrl = '/api/image/my-images-byId'
      } else {
        axiosUrl = '/api/image/related-images-byId'
      }
      axios({
        method: 'POST',
        url: axiosUrl,
        data: {
          profileId,
          relatedImagesWriter,
        }
      })
      .then((response) => {
        setSideImages(response.data.images);
        // console.log(response.data.images);
        setImagesLoaded(true);
        setCurrentImage(initialImage);
        document.getElementById(`imageidpage_${initialImage._id}`).style.backgroundColor = '#DDD';
      })
      .catch(() => {
        alert('Failed to get Videos');
      })
    }
  }, [profileId, relatedImagesWriter, initialImage]);
  const goToImage = (image) => {
    if (image._id === currentImage._id) return;
    document.getElementById(`imageidpage_${image._id}`).style.backgroundColor = '#DDD';
    document.getElementById(`imageidpage_${currentImage._id}`).style.backgroundColor = '#FFF';
    setCurrentImage(image);
    userChangeImage(image);
  };
  const sideImageItem = sideImages.map((image) => {
    return (
    <Box
      id={`imageidpage_${image._id}`}
      key={image._id}
      marginBottom="8px"
      onClick={() => { goToImage(image); }}
    >
      <Box position="relative">
        <AspectRatio ratio={16 / 9} position="relative">
          <Image
            width="auto"
            alt="thumbnail"
            src={image.thumbNail}
            height="100%"
            margin="auto"
            objectFit="cover"
            cursor="pointer"
          />
        </AspectRatio>
      </Box>
      <Box padding="6px">
        <Stack isInline>
          <Avatar size="sm" src={image.writer && image.writer.image} mt="4px" />
          <Box>
            <a href={`/image/${image._id}`}>
              <Text className={classes.title} fontWeight="semibold" as="h4">{image.title}</Text>
            </a>
            <Text
              m="0"
              color="gray.600"
              fontSize="sm"
              width={{
                base: "20ch",
                sm: "45ch",
                md: "20ch",
                lg: "20ch",
                xl: '32ch',
              }}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {image.writer.username}
            </Text>
            <Text
              m="0"
              color="gray.600"
              fontSize="sm"
              width={{
                base: "20ch",
                sm: "45ch",
                md: "20ch",
                lg: "20ch",
                xl: '32ch',
              }}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {moment(image.createdAt).format('LL')}
            </Text>
          </Box>
        </Stack>
      </Box>
    </Box>
    );
  });

  return (
    <Box
      paddingTop="1rem"
      marginBottom="2rem"
      paddingRight={{ base: '0', md: '2rem' }}
    >
            {
        imagesLoaded
          ? (
            <Box
              height="76vh"
              overflowY={{ base: 'none', md: 'auto' }}
            >
              {sideImageItem}
            </Box>
          )
          : (
            <Box />
          )
      }
    </Box>
  );
}
SideImage.propTypes = propTypes;
export default SideImage;
