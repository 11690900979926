/* eslint-disable react/jsx-fragments */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable linebreak-style */
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ChakraProvider,
  Flex,
  Box,
} from '@chakra-ui/react';
import Auth from '../hoc/auth';
// pages for this product
// import HomePage from './views/HomePage/HomePage';
// import HomePage2 from './views/HomePage/HomePage2';
import HomePage4 from './views/HomePage/HomePage4';
// import SideNav from './sections/SideNav';
import LoginPage from './views/LoginPage/LoginPage';
import RegisterPage from './views/RegisterPage/RegisterPage';
import Header from './sections/Header';
import Footer from './sections/Footer';
import UploadPage from './views/UploadPage/UploadPage';
import DetailVideoPage from './views/DetailVideoPage/DetailVideoPage';
import DetailAlbumPage from './views/DetailAlbumPage/DetailAlbumPage';
import SubscriptionPage from './views/SubscriptionPage/SubscriptionPage';
import FamiliesPage from './views/FamiliesPage/FamiliesPage';
import LandingLayout from './layouts/LandingLayout';
import EmailConfirmationPage from './views/EmailConfirmationPage/EmailConfirmationPage';
import ForgotPasswordPage from './views/ForgotPasswordPage/ForgotPasswordPage';
import PasswordResetPage from './views/PasswordResetPage/PasswordResetPage';
import ProfilePage2 from './views/ProfilePage/ProfilePage2';
import FamilyProfile2 from './views/FamilyProfile/FamilyProfile2';
import ErrorPage from './views/ErrorPage/ErrorPage';
import AdminPage from './views/AdminPage/AdminPage';
import PrivacyPolicy from './sections/PrivacyPolicy';
import Terms from './sections/Terms';
import Cookies from './sections/Cookies';
import AcceptableUse from './sections/AcceptableUse';
import DetailImagePage from './views/DetailImagePage/DetailImagePage';
import SideNav from './sections/SideNav';
import UpgradeStorePage from './views/UpgradeStorePage/UpgradeStorePage';
import CheckoutPage from './views/CheckoutPage/CheckoutPage';
import SubConfirm from './views/SubConfirm/SubConfirm';
import Groups from './views/Groups/Groups';
import NewGroup from './views/NewGroup/NewGroup';
import DetailGroupPage from './views/DetailGroupPage/DetailGroupPage';
import GroupSearchResults from './views/GroupSearchResults/GroupSearchResults';
import Notification from './views/Notification/Notification';

function App() {
  return (
    <ChakraProvider>
      <React.Fragment>
        <Suspense fallback={(<div>Loading...</div>)}>
          <Header />
          <Box
            display="flex"
          >
            <SideNav />
            <Switch>
              <Route exact path="/" component={Auth(LandingLayout, false, false)} />
              <Route exact path="/admin" component={Auth(AdminPage, null, true, true)} />
              <Route exact path="/notification" component={Auth(Notification, null)} />
              <Route exact path="/home" component={Auth(HomePage4, null)} />
              <Route exact path="/login" component={Auth(LoginPage, false, false)} />
              <Route exact path="/register" component={Auth(RegisterPage, false, false)} />
              <Route exact path="/upload" component={Auth(UploadPage, true)} />
              <Route exact path="/video/:videoId" component={Auth(DetailVideoPage, null)} />
              <Route exact path="/image/:imageId" component={Auth(DetailImagePage, null)} />
              <Route exact path="/albums/:albumId" component={Auth(DetailAlbumPage)} />
              <Route exact path="/subscription" component={Auth(SubscriptionPage, null)} />
              <Route exact path="/families" component={Auth(FamiliesPage, null)} />
              <Route exact path="/upgrade-store" component={Auth(UpgradeStorePage, null)} />
              <Route exact path="/checkout-page" component={Auth(CheckoutPage, null)} />
              <Route exact path="/confirm-email/:token" component={Auth(EmailConfirmationPage, null, false)} />
              <Route exact path="/forgot-password" component={Auth(ForgotPasswordPage, null, false)} />
              <Route exact path="/reset-password/:token" component={Auth(PasswordResetPage, null, false)} />
              <Route exact path="/my-profile" component={Auth(ProfilePage2, null)} />
              <Route exact path="/subscription-confirm" component={Auth(SubConfirm, null)} />
              <Route exact path="/profile/:profileName" component={Auth(FamilyProfile2, null)} />
              <Route exact path="/groups" component={Auth(Groups, null)} />
              <Route exact path="/group/:groupId" component={Auth(DetailGroupPage, null)} />
              <Route exact path="/group-search-result/:searchQuery" component={Auth(GroupSearchResults, null)} />
              <Route exact path="/new-group" component={Auth(NewGroup, null)} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/cookies" component={Cookies} />
              <Route exact path="/acceptable-use" component={AcceptableUse} />
              <Route component={ErrorPage} />
            </Switch>
          </Box>
          <Footer />
        </Suspense>
      </React.Fragment>
    </ChakraProvider>
  );
}

export default App;
