/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Text, Button, Stack, PseudoBox, Heading, Icon, Menu, MenuList, MenuButton, IconButton, MenuItem } from "@chakra-ui/react";
import { createUseStyles } from 'react-jss';
const HeadingItem = props => {
    const { children, isLast, to = "/", ...rest } = props
    return (
        <Flex height="50px" justifyContent="center" flexDirection="column">
            <Heading 
                fontSize={{base:"1rem", md:"1.2rem", lg:"1.5rem"}}
                as="h1"
                margin="0"
                {...rest}
            >
                <Link to={to}>{children}</Link>
            </Heading>
        </Flex>
    )
}
const Footer = () => {

    return(
        <Box
            backgroundColor="#444"
            minHeight="100px"
            padding="2rem"
        >
            <Flex justifyContent="center" flexDirection="column" alignItems="center">
                <Box>
                    <HeadingItem to="/" color="#1890ff">Kloak</HeadingItem>
                </Box>
                <Box display={{base:"block", md:"flex"}} justifyContent="center" paddingLeft={{base:"1rem", md:"0"}} width="100%">
                    <Text color="#FFF" fontSize={{base:"0.7rem", sm:"0.7rem", md:"0.8rem", lg:"1rem"}} padding="0.5rem">
                        <a target="_blank" href="/privacy">Do Not Sell My Personal Info</a>
                    </Text>
                    <Text color="#FFF" fontSize={{base:"0.7rem", sm:"0.7rem", md:"0.8rem", lg:"1rem"}} padding="0.5rem">
                        <a target="_blank" href="/privacy"> Privacy Policy</a>
                    </Text>
                    <Text color="#FFF" fontSize={{base:"0.7rem", sm:"0.7rem", md:"0.8rem", lg:"1rem"}} padding="0.5rem">
                        <a target="_blank" href="/terms">Terms of Service</a>
                    </Text>
                    <Text color="#FFF" fontSize={{base:"0.7rem", sm:"0.7rem", md:"0.8rem", lg:"1rem"}} padding="0.5rem">
                        <a target="_blank" href="/cookies">Cookies</a>
                    </Text>
                    <Text color="#FFF" fontSize={{base:"0.7rem", sm:"0.7rem", md:"0.8rem", lg:"1rem"}} padding="0.5rem">
                        <a target="_blank" href="/acceptable-use">Acceptable Use</a>
                    </Text>
                </Box>
                <Box>
                    <Text fontSize={{base:"0.7rem", sm:"0.7rem", md:"0.8rem", lg:"1rem"}} padding="0.5rem" color="#FFF">Copyright 2021, Kloak Inc. All rights reserved.</Text>
                </Box>
            </Flex>
        </Box>
    )

}

export default Footer;