/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Skeleton,
  Text,
  Box,
  Input,
  Spinner,
} from '@chakra-ui/react';

const useStyles = createUseStyles({
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function PasswordResetPage({
  match,
  history,
}) {
  const { token } = match.params;

  const classes = useStyles();
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [hasValidToken, setHasValidToken] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      return;
    }
    axios.get(`/api/users/is-valid-password-reset-token/${token}`)
      .then((res) => {
        setHasValidToken(res.data.success && res.data.isValidToken);
        setLoading(false);
      }).catch(() => {
        // TODO: Handle error
        setLoading(false);
      });
  }, [token]);

  if (loading) {
    return (
      <Flex className="app">
        <Skeleton height="32px" width="100%" my="8px" />
        <Skeleton height="32px" width="100%" my="8px" />
        <Skeleton height="32px" width="100%" my="8px" />
      </Flex>
    );
  }

  if (!hasValidToken) {
    return (
      <Flex className="app">
        <Heading fontWeight="300">This password reset link is not valid.</Heading>
        <Text fontSize="lg" fontWeight="300">It may have already expired, but you can <a href="/forgot-password">request another password change</a>.</Text>
      </Flex>
    );
  }

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required('Password is required')
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-+(){}[\]|;:"'=,.<>~`_/\\])[A-Za-z\d@$!%*#?&-+(){}[\]|:;"'=,.<>~`_/\\]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Number and One Special Character',
          ),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Confirm Password is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(async () => {
          try {
            const dataToSubmit = {
              token,
              password: values.password,
            };
            const res = await axios.post('/api/users/reset-password', dataToSubmit);
            if (res.data.success) {
              history.push('/login');
            }
          } catch (err) {
            setFormErrorMessage('Something went wrong');
            setTimeout(() => {
              setFormErrorMessage('');
            }, 3000);
          }
          setSubmitting(false);
        }, 500);
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <Flex className="app" width="100%">
            {
              loading
                ? (
                  <Flex className="app" mt="128px" minHeight="90vh">
                    <Spinner
                      thickness="4px"
                      emptyColor="gray.200"
                      color="#FF1A75"
                      size="xl"
                    />
                  </Flex>
                )
                : (
                  <Box onSubmit={handleSubmit} marginTop="1rem" width={{ base: '94%', md: '450px' }} margin="auto" minHeight="90vh">
                    <Heading fontWeight="300">Reset Your Password</Heading>
                    <Field name="password">
                      {() => (
                        <FormControl
                          isRequired
                          isInvalid={errors.password && touched.password}
                          mb="4px"
                        >
                          <FormLabel htmlFor="password" ml="4px" fontWeight="300">
                            Password
                          </FormLabel>
                          <br />
                          <Input
                            id="password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            width="100%"
                          />
                          <div className={classes.formErrorMessage}>
                            {touched.password && errors.password}
                          </div>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="confirmPassword">
                      {() => (
                        <FormControl
                          isRequired
                          isInvalid={errors.confirmPassword && touched.confirmPassword}
                          mb="4px"
                        >
                          <FormLabel htmlFor="confirmPassword" ml="4px" fontWeight="300">
                            Confirm Password
                          </FormLabel>
                          <br />
                          <Input
                            id="confirmPassword"
                            type="password"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            width="100%"
                          />
                          <div className={classes.formErrorMessage}>
                            {touched.confirmPassword && errors.confirmPassword}
                          </div>
                        </FormControl>
                      )}
                    </Field>
                    {formErrorMessage && (
                    <label>
                      <p
                        style={{
                          color: '#ff0000bf',
                          fontSize: '0.7rem',
                          border: '1px solid',
                          padding: '1rem',
                          borderRadius: '10px',
                        }}
                      >
                        {formErrorMessage}
                      </p>
                    </label>
                    )}
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      loadingText="Submitting..."
                      onClick={handleSubmit}
                      display="block"
                      mt="16px"
                      mb="16px"
                      mr="auto"
                      ml="auto"
                      width="200px"
                      color="white"
                      borderRadius="8px"
                      bg="#3480E3"
                      _hover={{
                        bg: '#FFE6EE',
                        color: '#FF1A75',
                      }}
                    >
                      Update Password
                    </Button>
                  </Box>
                )
}
          </Flex>
        );
      }}
    </Formik>
  );
}

PasswordResetPage.propTypes = propTypes;

export default withRouter(PasswordResetPage);
