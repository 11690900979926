/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  Avatar,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Input,
  Checkbox,
  Box,
  useDisclosure,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react';
import {
  AddIcon,
} from '@chakra-ui/icons';
import AdminData from './AdminData';

const useStyles = createUseStyles({
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
  gridView: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',

  },
  friendCard: {
    margin: '1rem auto',
    padding: '1rem 1.2rem',
    width: '95%',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 4px 16px 0px',
  },
  searchResult: {
    padding: '16px',
    marginTop: '16px',
    width: '460px',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  friendCardButtonsContainer: {
    position: 'absolute',
    bottom: '24px',
  },
});

const propTypes = {
  inviteEmail: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
};

const InvitesPage = ({
  profile,
}) => {
  const classes = useStyles();
  const [friendEmail, setFriendEmail] = useState('');
  const List = [{ userId: 'asdfwe', email: 'asfeff', _id: 'afeafsf' }];
  const [inviteList, setInviteList] = useState(List);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [retrievedInvites, setRetrievedInvites] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios({
      method: 'POST',
      url: '/api/invites/get-invite-list',
      data: {
        userId: localStorage.getItem('userId'),
      },
    })
      .then((data) => {
        // console.log(data.data);
        setInviteList(data.data);
        setRetrievedInvites(true);
      })
      .catch((err) => {
        // console.log(err);
        setErrorMessage(err);
      });
  }, [friendEmail]);

  const handleSubmit = () => {
    // console.log(friendEmail);
    // console.log(localStorage.getItem('userId'));
    axios({
      method: 'POST',
      url: '/api/invites/adminInvite',
      data: {
        email: friendEmail,
        userId: localStorage.getItem('userId'),
        profile,
      },
    }).then((data) => {
      // console.log(data.data)
      setRetrievedInvites(false);
      setInviteList(data.data.newList);
      setRetrievedInvites(true);
    })
      .catch((err) => {
        // console.log(err);
        let errMsg;

        switch (err?.response?.status) {
          case 400:
            errMsg = 'Invite has already been sent.';
            break;
          case 406:
            errMsg = 'You can only send a maximum of 3 invites per account.';
            break;
          default:
            errMsg = 'Something has went wrong';
            break;
        }
        setFormErrorMessage(errMsg);
        setTimeout(() => {
          setFormErrorMessage('');
        }, 3000);
      });
  };
  const validateEmail = () => {
    const schema = yup.object().shape({
      email: yup.string().email().required(),
    });

    // check validity
    schema
      .isValid({
        email: friendEmail,
      })
      .then((valid) => {
        if (valid) {
          handleSubmit();
        } else {
          setFormErrorMessage('Email is not Valid');
          setTimeout(() => {
            setFormErrorMessage('');
          }, 3000);
        }
      });
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
    >
      <Box>
        <AdminData />
      </Box>
      <Heading
        fontWeight="500"
        fontSize={{ base: '1.2rem', md: '1.6rem' }}
        color="#0B67C2"
      >
        Send Invite To Your Friends and Family to Join Us!
      </Heading>
      <FormControl m="1rem">
        <FormLabel textAlign="center" fontSize="1.1rem" htmlFor="query" ml="4px" fontWeight="500">
          Email address or username
        </FormLabel>
        <br />
        <Flex justifyContent="center">
          <Input
            id="query"
            placeholder="Enter an email address "
            type="text"
            onChange={(event) => { setFriendEmail(event.target.value); }}
            width={{ base: '100%', sm: '352px', md: '352px' }}
          />
          <IconButton
            type="submit"
            icon={<AddIcon />}
            onClick={validateEmail}
            ml="12px"
            color="white"
            borderRadius="24px"
            bg="#FF1A75"
            _hover={{
              bg: '#FFE6EE',
              color: '#FF1A75',
            }}
          />
        </Flex>
      </FormControl>
      {formErrorMessage && (
      <label>
        <p
          style={{
            color: '#ff0000bf',
            fontSize: '0.7rem',
            fontWeight: '500',
            border: '1px solid',
            padding: '1rem',
            borderRadius: '10px',
          }}
        >
          {formErrorMessage}
        </p>
      </label>
      )}
      <Divider width="100%" marginY="48px" />
      <Flex
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Heading
          fontWeight="500"
          fontSize={{ base: '1.2rem', md: '1.6rem' }}
          color="#0B67C2"
        >
          My Sent Invites
        </Heading>
        {!retrievedInvites
          ? <div>Loading Invites </div>
          : (
            <SimpleGrid
              columns={{ sm: 1, md: 2, lg: 3 }}
              mb="48px"
              width="100%"
            >
              {inviteList.map((invite) => (
                <Box className={classes.friendCard}>
                  <Box>
                    <Flex key={invite._id} justifyContent="space-between">
                      <Text as="span" fontWeight="500" marginRight="1rem">{invite.email} </Text>
                      {invite.accountCreated
                        ? <Checkbox defaultIsChecked isDisabled colorScheme="green" />
                        : <Checkbox isDisabled colorScheme="green" />}
                    </Flex>
                  </Box>
                  <DeleteModal inviteEmail={invite.email} />
                </Box>
              ))}
            </SimpleGrid>
          )}
      </Flex>
      <Divider width="100%" marginY="28px" />
    </Flex>
  );
};

function DeleteModal(props) {
  const { inviteEmail } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errMessage, setErrMessage] = useState();
  const deleteInvite = () => {
    // console.log(video);
    // include the key necessary to delete the video and thumbnail in s3
    // filePath deletes the object in mongodb.
    axios({
      method: 'POST',
      url: '/api/invites/delete-invite',
      data: {
        email: inviteEmail,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setErrMessage(err.message);
      });
  };
  return (
    <Flex justifyContent="center" marginTop="1rem">
      <Button onClick={onOpen}>Remove Invite</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Invite</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p1>Are you sure you want to Remove this Invite?</p1>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={deleteInvite}>
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
InvitesPage.propTypes = propTypes;
DeleteModal.propTypes = propTypes;

export default withRouter(InvitesPage);
