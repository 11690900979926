/* eslint-disable react/forbid-prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  Avatar,
  Flex,
  Spinner,
  Stack,
  Box,
  Image,
  useDisclosure,
  Text,
  AspectRatio,
  Heading,
  Button,
} from '@chakra-ui/react';
import moment from 'moment';
import DownloadModal from './DownloadModal';
import EditVideo from './EditVIdeo';
import EditImage from './EditImage';

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  familyPostGrid: {
    display: 'grid',
    gridGap: '1em',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  posts: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

function ProfileLibrary({
  history,
  posts,
  profile,
}) {
  const [allPosts, setAllPosts] = useState();
  const [displayPosts, setDisplayPosts] = useState([]);
  const [loadedPosts, setLoadedPosts] = useState(false);
  const [lastPageMediaReached, setLastPageMediaReached] = useState(false);
  const [itemsPerMediaPage, setItemsPerMediaPage] = useState(4);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [postErr, setPostErr] = useState();
  const classes = useStyles();

  const goToLink = (link, username) => {
    // console.log(username);
    // const userNameTab = document.getElementById(`sidefamily_${username}`);
    // if (userNameTab) userNameTab.style.color = '#FF0000';
    document.getElementById('home-side').style.color = '#888888';
    history.push(link);
  };
  useEffect(() => {
    if (profile) {
      axios({
        method: 'POST',
        url: '/api/post/post-by-id',
        data: {
          profileId: profile._id,
        },
      })
        .then((postData) => {
          const retrievedPosts = postData.data.posts;
          // console.log(Date.now());
          // console.log(retrievedPosts[0].createdAt);
          const postArr = [];
          // console.log(retrievedPosts);
          setAllPosts(retrievedPosts);
          for (let i = 0; i < retrievedPosts.length; i += 1) {
            if (i === retrievedPosts.length - 1) {
              setLastPageMediaReached(true);
              setCurrentMediaIndex(i);
              postArr.push(retrievedPosts[i]);
              break;
            }
            if (i === 3) {
              postArr.push(retrievedPosts[i]);
              setCurrentMediaIndex(4);
              break;
            }
            postArr.push(retrievedPosts[i]);
          }
          setDisplayPosts(postArr);
          if (postArr.length > 0) {
            setLoadedPosts(true);
          }
        })
        .catch((postsErr) => {
          setPostErr(postsErr);
        });
    }
  }, [profile]);

  /*
  useEffect(() => {
    if (posts && profile) {
      const postArr = [];
      setAllPosts(posts);
      for (let i = 0; i < posts.length; i += 1) {
        if (i === posts.length - 1) {
          setLastPageMediaReached(true);
          setCurrentMediaIndex(i);
          postArr.push(posts[i]);
          break;
        }
        if (i === itemsPerMediaPage) {
          setCurrentMediaIndex(i);
          break;
        }
        postArr.push(posts[i]);
      }
      setDisplayPosts(postArr);
      if (postArr.length > 0) {
        setLoadedPosts(true);
      }
      // console.log('this videos' + video.filePath)

      // console.log(`here I am ${displayPosts}`);
    }
  }, [posts, profile]);
*/
  const loadMore = () => {
    const postArr = [];
    for (let i = currentMediaIndex; i < allPosts.length; i += 1) {
      if (i === allPosts.length - 1) {
        setLastPageMediaReached(true);
        setCurrentMediaIndex(i);
        postArr.push(allPosts[i]);
        break;
      }
      if (i === currentMediaIndex + 3) {
        setCurrentMediaIndex(currentMediaIndex + itemsPerMediaPage);
        postArr.push(allPosts[i]);
        break;
      }
      postArr.push(allPosts[i]);
    }
    setDisplayPosts(displayPosts.concat(postArr));
    // console.log('this videos' + video.filePath)

    // console.log(`here I am ${displayPosts}`);
  };

  const updatePosts = (updatedPost) => {
    setLoadedPosts(false);
    const tempAllPosts = [];
    const tempDisplayPosts = [];
    displayPosts.forEach((post) => {
      if (post._id === updatedPost._id) {
        tempDisplayPosts.push(updatedPost);
      } else {
        tempDisplayPosts.push(post);
      }
    });
    allPosts.forEach((post) => {
      if (post._id === updatedPost._id) {
        tempAllPosts.push(updatedPost);
      } else {
        tempAllPosts.push(post);
      }
    });
    setDisplayPosts(tempDisplayPosts);
    setAllPosts(tempAllPosts);
    setLoadedPosts(true);
  };
  const deletePosts = (deletedPost) => {
    setLoadedPosts(false);
    const tempAllPosts = [];
    for (let i = 0; i < displayPosts.length; i += 1) {
      if (displayPosts[i]._id !== deletedPost._id) {
        tempAllPosts.push(displayPosts[i]);
      }
      // if (i === displayPosts.length - 1) {
      //   setLastPageMediaReached(true);
      // }
    }
    const updatedPosts = [];
    for (let i = 0; i < allPosts.length; i += 1) {
      if (allPosts[i]._id !== deletedPost._id) {
        updatedPosts.push(allPosts[i]);
      }
    }
    setAllPosts(updatedPosts);
    setDisplayPosts(tempAllPosts);
    setLoadedPosts(true);
  };
  const renderMyPosts = () => displayPosts.map((post) => (
    <Box
      key={post._id}
    >
      {post.video
        ? (
          <Box
            marginTop="-20px"
          >
            <Box position="relative" textAlign="right" width="100%">
              <AspectRatio
                ratio={16 / 9}
                position="relative"
                crossOrigin="anonymous"
              >
                <Box
                  as="img"
                  crossOrigin="anonymous"
                  alt="thumbnail"
                  src={post.video.thumbNail}
                  objectFit="cover"
                  cursor="pointer"
                  borderRadius=".70rem"
                  marginTop="20px"
                  boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                  onClick={() => { goToLink(`/video/${post.video._id}`); }}
                />
              </AspectRatio>
              <Text
                as="span"
                position="relative"
                top="-10px"
                right="10px"
                zIndex="2"
                color="#FFFFFF"
                backgroundColor="rgb(0, 0, 0, 0.7)"
                borderRadius="4px"
                padding="4px"
                marginLeft="5px"
              >
                {Math.floor(post.video.duration / 60)}:{`0${Math.floor(post.video.duration - Math.floor(post.video.duration / 60) * 60)}`.slice(-2)}
              </Text>
            </Box>
            <Box>
              <Stack isInline>
                <Box>
                  <a href={`/video/${post.video._id}`}>
                    <Text
                      className={classes.title}
                      fontWeight="bold"
                      fontSize={{ base: '1.3em', sm: '1.4em' }}
                      color="#003971"
                      whiteSpace="wrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      marginTop="0.5em"
                    >
                      {post.video.title}
                    </Text>
                  </a>
                  <Text
                    color="#AAA"
                    fontWeight="bold"
                    fontSize={{ base: '1.1em', sm: '1.2em' }}
                    whiteSpace="wrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {post.video.description}
                  </Text>
                  <Text
                    color="#AAA"
                    fontWeight="bold"
                    fontSize={{ base: '1em', sm: '1.1em' }}
                  >
                    {moment(post.video.createdAt).format('LL')}
                  </Text>
                  <Text
                    color="#AAA"
                    fontWeight="bold"
                    fontSize={{ base: '1.1em', sm: '1.1em' }}
                  >
                    {post.video.privacyLabel}
                  </Text>
                  <EditVideo
                    post={post}
                    updatePosts={updatePosts}
                    deletePosts={deletePosts}
                    profile={profile}
                  />
                </Box>
              </Stack>
            </Box>
          </Box>
        )
        : (
          <Box>
            <AspectRatio
              ratio={16 / 9}
              borderRadius="1rem"
              onClick={() => { goToLink(`/image/${post.image._id}`); }}
            >
              <Box
                as="img"
                crossOrigin="anonymous"
                alt="thumbnail"
                objectFit="cover"
                src={post.image?.thumbNail}
                cursor="pointer"
                borderRadius=".70rem"
                boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
              />
            </AspectRatio>
            <Flex>
              <Box marginTop={{ base: '0', md: '4px' }}>
                <Text
                  color="#003971"
                  fontWeight="bold"
                  fontSize={{ base: '1.2em', sm: '1.4em' }}
                  whiteSpace="wrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  marginTop="0.5em"
                >
                  {post.image?.description}
                </Text>
                <Text
                  color="#AAA"
                  fontWeight="bold"
                  fontSize={{ base: '1.1em', sm: '1.1em' }}
                >
                  { moment(post.image?.createdAt).format('LL') }
                </Text>
                <Text
                  color="#AAA"
                  fontWeight="bold"
                  fontSize={{ base: '1.1em', sm: '1.1em' }}
                >
                  {post.image?.privacyLabel}
                </Text>
                <EditImage
                  post={post}
                  updatePosts={updatePosts}
                  deletePosts={deletePosts}
                  profile={profile}
                />
              </Box>
            </Flex>
          </Box>
        )}
    </Box>
  ));
  return (

    <Box
      width="95%"
      marginX="auto"
    >
      {loadedPosts
        ? (
          <Box>
            <Flex
              width={{
                base: '100%',
              }}
              height={{ base: '50px', sm: '75px' }}
            >
              <Flex width="100%" justifyContent="space-between" margin="auto" marginBottom="1em">
                <Heading
                  fontWeight="500"
                  fontSize={{ base: '1.4em', md: '2em' }}
                  color="#0B67C2"
                >
                  My Library
                </Heading>
                <DownloadModal profile={profile} posts={posts} />
              </Flex>
            </Flex>
            <Box
              className={classes.familyPostGrid}
              spacing="10px"
              mb="24px"
              maxWidth={{
                base: '100%',
              }}
            >
              {renderMyPosts()}
            </Box>
            {lastPageMediaReached
              ? <Box />
              : (
                <Box
                  display={{ base: 'flex', md: 'flex' }}
                  justifyContent="flex-end"
                  alignItems={{ base: 'center', md: 'flex-end' }}
                  height="100%"
                  width="100%"
                >
                  <Button
                    cursor="pointer"
                    onClick={() => { loadMore(); }}
                    backgroundColor="#2C6DAD"
                    color="#FFF"
                    padding="10px 20px"
                    width={{ base: '100%', md: '125px', lg: '200px' }}
                    _hover={{ backgroundColor: '#5C8DFF' }}
                    borderRadius="1px"
                  >
                    View More
                  </Button>
                </Box>
              )}
          </Box>
        )
        : <Box /> }
    </Box>

  );
}
ProfileLibrary.propTypes = propTypes;
export default withRouter(ProfileLibrary);
