/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Simplicity from '../../assets/images/server.png';

export default function Hero({
  title,
  subtitle,
  image,
  ctaLink,
  ctaText,
  ...rest
}) {
  return (
    <Container maxW="7xl">
      <Stack
        align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
          >
            <Text
              as="span"
              position="relative"
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: '#3480E3',
                zIndex: -1,
              }}
            >
              {title}
            </Text>
            <br />
            <Text as="span" color="#3480E3">
              Cloud Storage
            </Text>
          </Heading>
          <Text>
            {subtitle}
          </Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}
          >
            <Link to={ctaLink}>
              <Button
                bg="#3480E3"
                color="white"
                borderRadius="8px"
                py="4"
                px="4"
                lineHeight="1"
                size="md"
                _hover={{ bg: '#FFE6EE', color: '#FF1A75' }}
              >
                {ctaText}
              </Button>
            </Link>
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify="center"
          align="center"
          position="relative"
          w="full"
        >
          <Box
            position="relative"
            height="300px"
            rounded="2xl"
            boxShadow="2xl"
            width="full"
            overflow="hidden"
          >
            {/* <Image src={FamilyImg} size="100%" rounded="1rem" shadow="2xl" /> */}
            <Image
              alt="Hero Image"
              fit="cover"
              align="center"
              w="100%"
              h="100%"
              src={
              Simplicity
            }
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
};

Hero.defaultProps = {
  title: 'Secure.',
  subtitle:
        'Cloud storage vault service where your memories are stored on an encrypted server. Your photos are only for your love ones and for your eyes only, period! An alternate to Google Photos/Facebook/Instagram for those who care about privacy and data protection.',
  ctaText: 'Start Securing Now',
  ctaLink: '/register',
};
