/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Flex, Text, Button, Stack, PseudoBox, Heading,
} from '@chakra-ui/react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    backgroundColor: '#E9E9E9',
    margin: 'auto',
    borderRadius: '10px',
    textAlign: 'center',
    '& h2': {
      fontSize: '1.5rem',
    },

  },
});

const ErrorPage = () => {
  const classes = useStyles();
  const HeadingItem = (props) => {
    const {
      children, isLast, to = '/', ...rest
    } = props;
    return (
      <Flex height="50px" justifyContent="center" flexDirection="column">
        <Heading
          fontSize={{ base: '1.5rem' }}
          as="h1"
          marginLeft="2rem"
          paddingTop="2rem"
          {...rest}
        >
          <Link to={to}>{children}</Link>
        </Heading>
      </Flex>
    );
  };
  return (
    <Box
      width="100%"
    >
      <Box>
        <HeadingItem to="/">Kloak</HeadingItem>
      </Box>
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        padding={{
          sm: '0', md: '0', lg: '1rem', xl: '2rem',
        }}
        minHeight="100vh"
      >
        <Box
          marginTop="2rem"
          padding={{ base: '1rem', md: '4rem' }}
          width={{
            sm: '98%', md: '95%', lg: '700px', xl: '1023px',
          }}
          className={classes.container}
        >
          <h2> 404: Page does not Exist</h2>
        </Box>
      </Flex>
    </Box>
  );
};

export default ErrorPage;
