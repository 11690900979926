/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box, AspectRatio, Avatar, Text, Stack, Image, Flex,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});
const propTypes = {
  profileId: PropTypes.string.isRequired,
  allPosts: PropTypes.array.isRequired,
  changePost: PropTypes.func.isRequired,
};

function SidePosts({
  profileId,
  allPosts,
  changePost,
}) {
  const [postsLoaded, setPostsLoaded] = useState(false);
  const [currentPost, setCurrentPost] = useState();
  const classes = useStyles();

  useEffect(() => {
    // console.log(`all posts ${allPosts}`);
    if (profileId && allPosts?.length > 0) {
      // console.log(`all posts ${allPosts}`);
      setCurrentPost(allPosts[0]);
      setPostsLoaded(true);
    }
  }, [profileId]);

  useEffect(() => {
    if (postsLoaded) {
      if (document.getElementById(`albumid_${allPosts[0]._id}`)) {
        document.getElementById(`albumid_${allPosts[0]._id}`).style.backgroundColor = '#DDD';
      }
    }
  }, [postsLoaded]);
  const goToPost = (post) => {
    if (post._id === currentPost._id) return;
    if (document.getElementById(`albumid_${post._id}`)) {
      document.getElementById(`albumid_${post._id}`).style.backgroundColor = '#DDD';
      document.getElementById(`albumid_${currentPost._id}`).style.backgroundColor = '#FFF';
      setCurrentPost(post);
      changePost(post);
    }
  };

  const sidePosts = allPosts.map((post) => (
    <Box>
      {post?.image
        ? (
          <Box
            key={`album_${post._id}`}
            id={`albumid_${post._id}`}
            marginBottom="0.5rem"
            paddingBottom="0.5rem"
            borderRadius=".70rem"
            onClick={() => { goToPost(post); }}
            cursor="pointer"
          >
            <Box>
              <AspectRatio
                ratio={16 / 9}
                borderRadius="1rem"
                onClick={() => { goToPost(post); }}
              >
                <Box
                  as="img"
                  crossOrigin="anonymous"
                  alt="thumbnail"
                  objectFit="cover"
                  src={post?.image.thumbNail}
                  cursor="pointer"
                  borderRadius=".70rem"
                  boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                />
              </AspectRatio>
              <Flex>
                <Box
                  marginTop={{ base: '0', md: '4px' }}
                  paddingLeft="0.5rem"
                >
                  <Text
                    color="#003971"
                    fontWeight="bold"
                    fontSize={{ base: '1.3em', sm: '1.4em' }}
                    whiteSpace="wrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    marginTop="0.4em"
                  >
                    {post?.image.description}
                  </Text>
                  <Text
                    color="#999"
                    fontWeight="bold"
                    fontSize={{ base: '1.1em', sm: '1.1em' }}
                  >
                    { moment(post?.image.createdAt).format('LL') }
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Box>
        ) : (
          <Box
            key={`album_${post._id}`}
            id={`albumid_${post._id}`}
            marginBottom="0.5rem"
            paddingBottom="0.5rem"
            borderRadius=".70rem"
            onClick={() => { goToPost(post); }}
            cursor="pointer"
          >
            <Box>
              <Box position="relative" textAlign="right" width="100%">
                <AspectRatio
                  ratio={16 / 9}
                  position="relative"
                >
                  <Box
                    as="img"
                    crossOrigin="anonymous"
                    alt="thumbnail"
                    src={post.video.thumbNail}
                    objectFit="cover"
                    cursor="pointer"
                    borderRadius=".70rem"
                    boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                    onClick={() => { goToPost(post); }}
                  />
                </AspectRatio>
                <Text
                  as="span"
                  position="relative"
                  top="-30px"
                  right="10px"
                  color="#FFFFFF"
                  backgroundColor="rgb(0, 0, 0, 0.7)"
                  borderRadius="4px"
                  padding="4px"
                >
                  {Math.floor(post.video.duration / 60)}:{`0${Math.floor(post.video.duration - Math.floor(post.video.duration / 60) * 60)}`.slice(-2)}
                </Text>
              </Box>
              <Flex>
                <Box
                  marginTop={{ base: '0', md: '4px' }}
                  paddingLeft="0.5rem"
                >
                  <Text
                    color="#003971"
                    fontWeight="bold"
                    fontSize={{ base: '1.3em', sm: '1.4em' }}
                    whiteSpace="wrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    marginTop="0.4em"
                  >
                    {post.video.description}
                  </Text>
                  <Text
                    color="#999"
                    fontWeight="bold"
                    fontSize={{ base: '1.1em', sm: '1.1em' }}
                  >
                    { moment(post.video.createdAt).format('LL') }
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Box>
        )}
    </Box>
  ));

  return (
    <Box
      paddingTop="1rem"
      marginBottom="2rem"
      paddingRight={{ base: '0', md: '2rem' }}
    >
      {
        postsLoaded
          ? (
            <Box
              height="76vh"
              overflowY={{ base: 'none', md: 'auto' }}
            >
              {sidePosts}
            </Box>
          )
          : (
            <Box />
          )
      }
    </Box>
  );
}
SidePosts.propTypes = propTypes;
export default SidePosts;
