/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable max-len */
/* eslint-disable no-unreachable */
/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useMemo, useState, useCallback, useEffect, useLayoutEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import PropTypes, { checkPropTypes } from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import io from 'socket.io-client';
import styled from 'styled-components';
import { compress, compressAccurately } from 'image-conversion';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Select,
  Spinner,
  Text,
  Input,
  ModalFooter,
  AspectRatio,
  SimpleGrid,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/react';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import s3BucketUrl from '../../../environmentVar';
import UploadImg from '../../../../assets/images/upload.png';
import AlertBanner from '../../../sections/AlertBanner';
import InviteFamilyModal from './InviteFamilyModal';

const SeeMoreButton = styled(EyeFill)`
          width: 58px;
          color: #BBB;
          cursor: pointer;
        `;

const UploadStatus = Object.freeze({
  AWAITING_FILE: 'AWAITING_FILE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  ERROR: 'ERROR',
});

const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];

const Category = [
  { value: 'FILM', label: 'Film & Animation' },
  { value: 'AUTOS', label: 'Autos & Vehicles' },
  { value: 'MUSIC', label: 'Music' },
  { value: 'PETS', label: 'Pets & Animals' },
  { value: 'SPORTS', label: 'Sports' },
  { value: 'FAMILY', label: 'Family' },
  { value: 'OTHER', label: 'Other' },
];

const baseDropzoneStyle = {
  height: '65vh',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderRadius: '8px',
  borderColor: '#EEEEEE',
  padding: '32px',
  cursor: 'pointer',
  marginBottom: '128px',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '95%',
};
const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

const useStyles = createUseStyles({
  uploadIcon: {
    margin: '32px auto',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  maxSpace: PropTypes.number.isRequired,
  currentSpace: PropTypes.number.isRequired,
  profile: PropTypes.object.isRequired,
};

function MassUploadServer({
  history,
  maxSpace,
  currentSpace,
  profile,
}) {
  const classes = useStyles();

  const user = useSelector((state) => state.user);

  const [status, setStatus] = useState();
  const [formMessage, setFormErrorMessage] = useState();
  const [loadcomplete, setLoadComplete] = useState(false);
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [thumbFiles, setThumbFiles] = useState();
  const [filesToUpload, setFilesToUpload] = useState();
  const [viewThumbnails, setViewThumbnails] = useState([]);
  const [existingAlbums, setExistingAlbums] = useState([{ _id: '', name: '' }]);
  const [videoConverting, setVideoConverting] = useState('');
  const [videoProgress, setVideoProgress] = useState(0);
  const [allVideoFiles, setAllVideoFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [newUserUploader, setNewUserUploader] = useState(false);
  const [videosToProcess, setVideosToProcess] = useState(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [socket, setSocket] = useState();
  const [newPath, setNewPath] = useState();
  const [numOfFilesUpload, setNumOfFilesUpload] = useState(0);
  const [uploadIndex, setUploadIndex] = useState(0);
  const [processEnded, setProcessEnded] = useState(true);
  const [uploadEnded, setUploadEnded] = useState(true);
  const [alertMessage, setAlertMessage] = useState('Warning: Safari users can only upload mp4 format for videos at this time. Please use a different browser for other video formats');
  /*
  const ffmpeg = createFFmpeg({
    corePath: window.location.hostname === 'localhost' ? 'http://localhost:3000/ffmpeg-core.js' : 'https://www.gokloak.com/ffmpeg-core.js',
    log: false,
  });
  */
  useEffect(() => {
    // console.log('running')
    /*
    const newSocket = io(window.location.hostname === 'localhost' ? 'http://localhost:5000/' : 'https://www.gokloak.com/', { withCredentials: true });
    if (newSocket) {
      console.log(`creating upload room: ${profile._id}`);
      // console.log(typeof (profileIdRoom));
      newSocket.emit('uploadRoom', profile._id);
      setSocket(newSocket);
    }
    */
    if (profile._id) {
      // loadFFMpeg();
      axios({
        method: 'POST',
        url: '/api/image/my-albums',
        data: {
          profileId: profile._id,
        },
      })
        .then((data) => {
          const getAlbums = existingAlbums.concat(data.data.albums);
          setExistingAlbums(getAlbums);
          setStatus(UploadStatus.AWAITING_FILE);
          setLoadComplete(true);
        })
        .catch((albumErr) => {
          // console.log(err);
          let errMsg;
          switch (albumErr?.response?.status) {
            case 403:
              errMsg = 'You do Not have access to these images';
              break;
            case 500:
              errMsg = 'Server is currently down';
              break;
            default:
              errMsg = 'Something has went wrong';
              break;
          }
          setFormErrorMessage(errMsg);
          setTimeout(() => {
            setFormErrorMessage('');
          }, 3000);
        });
    }
  }, [profile]);
  const processVideos = async (videos) => {
    // console.log(videos);
    let tempFileSize = 0;
    if (videos.length > 0) {
      for (let i = 0; i < videos.length; i++) {
        const videoData = await axios.post('/api/upload/video-thumnail', { video: videos[i] });
        // console.log(everyVideoFiles[i].duration);
        // console.log(`results 2: ${videoData.data.video.preview}`);
        // const updatedVideoFiles = await getVideoDuration(videoData.data.videos);
        videos[i] = videoData.data.video;
      }
    }
    if (videos.length > 0) {
      for (let i = 0; i < videos.length; i++) {
        const videoData = await axios.post('/api/upload/video-info', { video: videos[i] });
        // console.log(everyVideoFiles[i].duration);
        // console.log(`results 2: ${videoData.data.video}`);
        // const updatedVideoFiles = await getVideoDuration(videoData.data.videos);
        videos[i] = videoData.data.video;
        tempFileSize += videoData.data.thumbSize;
      }
    }
    const allFiles = imageFiles.concat(videos);
    // console.log(allFiles);
    if ((totalFileSize + tempFileSize + currentSpace) > maxSpace) {
      alert('Ran out of File space! Please either delete existing files or upgrade your account.');
      setStatus(UploadStatus.AWAITING_FILE);
      return;
    }
    // console.log(allFiles);
    // setThumbnailSrc([{ preview: 'C:\\Users\\sams4\\Documents\\Projects\\Test\\Kloak\\server\\routes\\uploads\\screenshots\\YzshzYkpXTxBlVlSBUNO0.png' }]);
    setThumbnailSrc(allFiles);
    setThumbFiles(allFiles);
    setFilesToUpload(allFiles);
    setViewThumbnails(allFiles.slice(0, 8));
    setProcessEnded(true);
    setVideoConverting('');
    setStatus(UploadStatus.FILE_ACCEPTED);
    setVideoProgress();
    // console.log('finish');
  };
  useEffect(() => {
    if (socket && !processEnded) {
      socket.on('video conversion', (data) => {
        // console.log(data);
        if (data.finished) {
          const tempFiles = allVideoFiles;
          const videos = allVideoFiles.concat([data.video]);
          // console.log(`here are the files ${videos}`);
          setAllVideoFiles(videos);
          const newIndex = currentVideoIndex + 1;
          setCurrentVideoIndex(newIndex);
          // console.log(currentVideoIndex + 1);
          // console.log(newIndex);
          // console.log(videosToProcess);
          if (data.video.currentIndex === data.video.maxIndex) {
            // console.log('reaching');
            processVideos(videos);
          }
        }
        if (data.progress) {
          setVideoConverting(data.progress.originalName);
          setVideoProgress(Math.floor(data.progress.percent ? data.progress.percent : undefined));
        }
        // setNotificationCounter(newNotifications);
      });
      /*
      return () => {
        socket.leave(profile._id);
      };
      */
    }
  }, [processEnded]);

  useEffect(() => {
    if (socket && !uploadEnded) {
      socket.on('file upload', (data) => {
        // console.log(data);
        if (data.finished) {
          const newIndex = uploadIndex + 1;
          setUploadIndex(newIndex);
          // console.log(currentVideoIndex + 1);
          // console.log(newIndex);
          // console.log(numOfFilesUpload);
          if (newIndex === numOfFilesUpload) {
            alert('File(s) have been sucessfully uploaded!');
            history.push('/my-profile');
          }
        }
        if (data.progress) {
          setVideoConverting(data.originalName);
          setVideoProgress(Math.floor(data.progress ? data.progress : undefined));
        }
        // setNotificationCounter(newNotifications);
      });
    }
  }, [uploadEnded]);
  // A much more higher quality image and thumbnail compression
  // https://github.com/WangYuLue/image-conversion
  const compressImages = async (files) => {
    // console.log(files)
    const images = Array.from(files);

    const options = {
      size: 900,
      accuracy: 0.9,
      scale: 0.4,
      type: 'image/png',
    };
    try {
      // converts files to compressed blobs
      const compressedBlobs = await Promise.all(
        images.map((imageFile) => compressAccurately(imageFile, options)),
      );
      return compressedBlobs;
    } catch (error) {
      setFormErrorMessage(error);
    }
  };
  // see https://stackoverflow.com/questions/52669596/promise-all-with-axios

  const cancelPublish = async () => {
    setStatus(UploadStatus.PROCESSING);
    const files = filesToUpload;
    const everyImageFiles = [];
    const everyVideoFiles = [];
    for (let i = 0; i < files.length; i++) {
      // console.log(files[i].type);
      const validImageTypes = ['image', 'image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
      if (validImageTypes.includes(files[i].type)) {
        // invalid file type code goes here.
        everyImageFiles.push(files[i]);
      } else {
        // console.log(`nokay: ${file.type}`);
        everyVideoFiles.push(files[i]);
      }
    }
    if (everyVideoFiles.length > 0) {
      for (let i = 0; i < everyVideoFiles.length; i++) {
        // console.log(everyVideoFiles[i]);
        await axios.post('/api/upload/delete-video', { video: everyVideoFiles[i] });
        // console.log(everyVideoFiles[i].duration);
      }
    }
    if (everyImageFiles.length > 0) {
      for (let i = 0; i < everyImageFiles.length; i++) {
        await axios.post('/api/upload/delete-image', { image: everyImageFiles[i] });
        // console.log(everyVideoFiles[i].duration);
      }
    }
    setThumbnailSrc();
    setThumbFiles([]);
    setFilesToUpload();
    setViewThumbnails([]);
    setUploadEnded(true);
    setProcessEnded(true);
    setStatus(UploadStatus.AWAITING_FILE);
  };

  // find a way to make this work
  /*
  useLayoutEffect(() => () => {
    // Your code here.
    socket.leave(profile._id);
  }, []);
  */
  function upload(file, chunk, chunkId) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open('post', '/upload');

      xhr.setRequestHeader('Content-Type', 'application/octet-stream');
      xhr.setRequestHeader('X-Chunk-Id', chunkId);
      xhr.setRequestHeader('X-Content-Id', file.name);
      xhr.setRequestHeader('Content-Length', chunk.size);
      // Size and real name of whole file, not just a chunk
      xhr.setRequestHeader('X-Content-Length', file.size);
      xhr.setRequestHeader('X-Content-Name', file.name);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          resolve();
        }
      };

      xhr.onerror = reject;

      xhr.send(chunk);
    });
  }
  const videoChunkUpload = () => {

  };
  const createChunks = (file/* cSize should be byte 1024*1 = 1KB */) => {
    const cSize = ((1024 * 1000) * 5);
    let startPointer = 0;
    const endPointer = file.size;
    const filechunks = [];
    while (startPointer < endPointer) {
      const newStartPointer = startPointer + cSize;
      const fileChunk = file.slice(startPointer, newStartPointer);
      // console.log(fileChunk);
      const newName = `${nanoid()}.${file.name.split('.').pop()}`;
      const newFileChunk = new File([fileChunk], newName, {
        type: `video/${file.name.split('.').pop()}`,
      });
      newFileChunk.path = newName;
      filechunks.push(newFileChunk);
      startPointer = newStartPointer;
    }
    return filechunks;
  };
  const onDropAccepted = async (files) => {
    const newSocket = io(window.location.hostname === 'localhost' ? 'http://localhost:5000/' : 'https://www.gokloak.com/', { withCredentials: true });
    if (newSocket) {
      // console.log(`creating upload room: ${profile._id}`);
      // console.log(typeof (profileIdRoom));
      newSocket.emit('uploadRoom', profile._id);
      setSocket(newSocket);
    }
    // allows app to check the total number of files being uploaded
    // console.log(`length: ${files.length}`);
    setNumOfFilesUpload(files.length);

    setStatus(UploadStatus.PROCESSING);
    // console.log(files);
    const everyImageFiles = [];
    const everyVideoFiles = [];
    let tempFileSizes = 0;

    // separate images and videos
    files.forEach((file, index) => {
      // console.log(file.type);
      file.indexNum = index;
      const validImageTypes = ['image', 'image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
      if (validImageTypes.includes(file.type)) {
        // invalid file type code goes here.
        everyImageFiles.push(file);
      } else {
        // console.log(`nokay: ${file.type}`);
        everyVideoFiles.push(file);
      }
    });
    setProcessEnded(false);
    // test video file size
    everyVideoFiles.map((file) => {
      tempFileSizes += file.size;
    });
    const videoChunks = [];
    if (everyVideoFiles.length > 0) {
      const chunkedFiles = createChunks(everyVideoFiles[0]);
      videoChunks.push(chunkedFiles);
    }
    // console.log(`resultt: ${videoChunks.length}`);
    // warning and alert for lack of disk storage space
    if (totalFileSize + currentSpace > maxSpace) {
      alert('Ran out of File space! Please either delete existing files or upgrade your account.');
      setStatus(UploadStatus.AWAITING_FILE);
      return;
    }
    // compress the images into image blobs
    const compressedImageBlobs = await compressImages(everyImageFiles);
    compressedImageBlobs.map((blob) => {
      tempFileSizes += blob.size;
    });
    if (totalFileSize + currentSpace > maxSpace) {
      alert('Ran out of File space! Please either delete existing files or upgrade your account.');
      setStatus(UploadStatus.AWAITING_FILE);
      return;
    }
    // console.log(compressedImageBlobs);
    // take the newly formed blobs and convert back to image
    const compressedImages = [];

    // convert the blobs back to files for images
    // https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
    await Promise.all(compressedImageBlobs.map((blob, index) => {
      // change images blobs back to files
      // console.log(blob);
      const newImgFile = new File([blob], `${nanoid()}.png`, {
        type: 'image/png',
      });
      compressedImages.push(newImgFile);
    }));
    // console.log(compressedImages);
    const config = {
      header: { 'content-type': 'multipart/form-data' },
      timeout: 5 * 60 * 60 * 1000,
    };
    let allFiles = [];

    if (compressedImages.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < compressedImages.length; i++) {
        formData.append('mediaFiles', compressedImages[i]);
      }
      const imagesData = await axios.post('/api/upload/process-images', formData, config);
      // console.log(`results: ${imagesData.data.images[0].preview}`);
      // images ) property contains
      tempFileSizes += imagesData.data.thumbSize;
      allFiles = allFiles.concat(imagesData.data.images);
    }
    setVideosToProcess(videoChunks.length);
    setImageFiles(allFiles);
    setTotalFileSize(tempFileSizes);
    // const formData = new FormData();
    // formData.append('mediaFiles', videoChunks[0][0]);
    // formData.append('profileId', profile._id);
    // console.log(`chunk: ${videoChunks[0]}`);
    // const req = await axios.post('/api/upload/convert-video-chunk', formData, config);
    const fileId = nanoid();
    const gatherChunks = [];
    let storedVideo = {};
    if (videoChunks.length > 0) {
      for (let i = 0; i < videoChunks.length; i++) {
        let currentIndex = 1;
        // console.log(everyVideoFiles[i]);
        const maxIndex = videoChunks[i].length;
        const finalName = `${nanoid()}_${everyVideoFiles[i].name}`;
        const fileSize = everyVideoFiles[i].duration;
        const formData = new FormData();
        formData.append('mediaFiles', videoChunks[i][0]);
        formData.append('profileId', profile._id);
        formData.append('fileId', fileId);
        formData.append('maxIndex', maxIndex);
        formData.append('finalName', finalName);
        formData.append('currentIndex', currentIndex);
        currentIndex += 1;
        // console.log(videoChunks[i][0]);
        // console.log(everyVideoFiles);
        const res = await axios.post('/api/upload/process-video-data', formData, config);
        storedVideo = res.data.video;
        const videoData = new FormData();
        videoData.append('video', storedVideo);
        videoData.append('profileId', profile._id);
        await axios.post('/api/upload/convert-video-data', { video: storedVideo, profileId: profile._id });
        // console.log(everyVideoFiles[i].duration);
        // console.log(`results: ${videoData.data.video.preview}`);
        // const updatedVideoFiles = await getVideoDuration(videoData.data.videos);
        // proccessVideos.push(videoData.data.video);
      }
      /*
      axios({
        method: 'POST',
        url: '/api/upload/handle-video-chunks',
        data: {
          fileChunks: gatherChunks,
        },
      })
        .then((res) => {
          console.log('all files dealt with');
        })
        .catch((err) => {
          setFormErrorMessage(err);
        });
        */
    } else {
      setThumbnailSrc(allFiles);
      setThumbFiles(allFiles);
      setFilesToUpload(allFiles);
      setViewThumbnails(allFiles.slice(0, 8));
      setStatus(UploadStatus.FILE_ACCEPTED);
      // console.log('finish');
    }
  };
  const onDropRejected = () => alert('One or more of your file`s format is not supported by Kloak!');

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4', '.MOV', '.MPEG', '.mpg', '.wmv'], // TODO: Accept other video types
    multiple: true,
    maxFiles: 100,
    onDropAccepted,
    onDropRejected,
  });

  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);

  const renderDropzone = () => (
    <Flex {...getRootProps({ style: dropzoneStyle })}>
      <input {...getInputProps()} />
      <Flex
        flexDirection="column"
        width={{
          base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1200px',
        }}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Image className={classes.uploadIcon} size="32px" objectFit="contain" src={UploadImg} />
        <Text fontSize={{ base: '1.2em', md: '1.5em' }} fontWeight="300" textAlign="center">Drag and drop your files here to upload</Text>
        <Text>or click here</Text>
      </Flex>
    </Flex>
  );

  const renderUploadProgressIndicator = () => (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={{
        base: '100vw', sm: '100vw', md: '520px', lg: '770px', xl: '1200px',
      }}
    >
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FF1A75"
        size="xl"
        mb="8px"
      />
      {
          videoConverting !== ''
            ? (
              <Box>
                <Text
                  textAlign="center"
                  fontSize="1.2em"
                  fontWeight="500"
                >
                  {videoProgress}%
                </Text>
                <Text
                  textAlign="center"
                  fontSize="1.2em"
                  fontWeight="500"
                >
                  {videoConverting}
                </Text>
              </Box>
            ) : <Box />
        }
      {
          status === UploadStatus.UPLOADING
            ? (
              <Text
                textAlign="center"
                fontSize="1.1em"
                fontWeight="400"
              >
                Uploading...
              </Text>
            )
            : <Box />
        }
      {
          status === UploadStatus.PROCESSING
            ? (
              <Text
                textAlign="center"
                fontSize="1.1em"
                fontWeight="400"
              >
                Processing...
              </Text>
            )
            : <Box />
        }
      {
          status === UploadStatus.PAGE_LOADING
            ? (
              <Text
                textAlign="center"
                fontSize="1.1em"
                fontWeight="400"
              >
                Loading...
              </Text>
            )
            : <Box />
        }
      {
          newUserUploader
            ? (
              <Box>
                <InviteFamilyModal profileId={profile._id} />
              </Box>
            ) : <Box />
        }
    </Flex>
  );

  const renderDetailsForm = () => (
    <Box>
      <Formik
        initialValues={{
          title: '',
          album: '',
          description: '',
          category: 'FILM',
          privacy: '',
        }}
        validationSchema={Yup.object().shape({
          privacy: Yup.string()
            .required('Must select a privacy option'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setUploadEnded(false);
          setStatus(UploadStatus.UPLOADING);
          const allUploadFiles = [];
          let label = '';
          Privacy.forEach((item) => {
            if (item.value === values.privacy) label = item.label;
          });
          await Promise.all(filesToUpload.map((file, index) => {
            // console.log(file.type);
            allUploadFiles.push({
              title: values.title,
              writer: profile._id,
              filePath: file.filePath,
              fileSize: file.fileSize,
              fileName: file.fileName,
              thumbNail: file.thumbNail,
              thumbName: file.thumbName,
              thumbSize: file.thumbSize,
              privacy: values.privacy,
              privacyLabel: label,
              type: file.type,
              duration: file.duration,
              description: values.description,
            });
          }));
          let album;
          if (values.album) {
            album = {
              writer: profile._id,
              name: values.album,
              description: values.description,
              thumbnail: allUploadFiles[0].thumbNail,
              privacy: values.privacy,
              privacyLabel: label,
            };
          }
          const allFiles = allUploadFiles;
          // console.log(`all files including thumbs: ${allFiles}`);
          // console.log(`all thumbs: ${thumbFiles}`);
          axios({
            method: 'POST',
            url: '/api/upload/upload-file-info',
            data: {
              mediaData: filesToUpload,
              thumbData: thumbFiles,
              profileId: profile._id,
              allFiles,
              album,
              testing: true,
            },
            timeout: 0,
          })
            .then(async (response) => {
              // console.log(response.data);
              for (let i = 0; i < filesToUpload.length; i++) {
                await axios.post('/api/upload/upload-file-wasabi', { file: filesToUpload[i], profileId: profile._id });
                // console.log(everyVideoFiles[i].duration);
                // const updatedVideoFiles = await getVideoDuration(videoData.data.videos);
              }
              // console.log('end');
            })
            .catch((err) => {
              setFormErrorMessage(err);
            });
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box
              onSubmit={handleSubmit}
            >
              <Image className={classes.uploadIcon} size="32px" objectFit="contain" src={newPath} />
              {thumbFiles && (
              <>
                {/* TODO: Add support for custom thumbnails */}
                {viewThumbnails.length > 1
                  ? (
                    <SimpleGrid
                      gridTemplateColumns={{
                        base: '1fr 1fr 1fr',
                        md: '1fr 1fr 1fr',
                      }}
                      gridTemplateRows={{
                        base: '1fr 1fr',
                      }}
                      spacing="10px"
                      width={{
                        base: '90vw', sm: '90vw', md: '90vw', lg: '500px', xl: '700px',
                      }}
                      marginX="auto"
                      marginY="1rem"
                    >
                      {viewThumbnails.map((thumbnail) => (
                        <AspectRatio ratio={16 / 9}>
                          <Image
                            src={thumbnail.preview}
                            objectFit="cover"
                          />
                        </AspectRatio>
                      ))}
                      <ImageViewModal imageList={viewThumbnails} />
                    </SimpleGrid>
                  ) : (
                    <Box
                      width={{
                        base: '90vw', sm: '90vw', md: '90vw', lg: '500px', xl: '500px',
                      }}
                    >
                      <AspectRatio ratio={16 / 9}>
                        <Image
                          src={thumbnailSrc[0].preview}
                          objectFit="cover"
                        />
                      </AspectRatio>
                    </Box>
                  )}
              </>
              )}
              {viewThumbnails?.length <= 1
                ? (
                  <Box>
                    <Field name="title">
                      {() => (
                        <FormControl marginY="25px">
                          <FormLabel htmlFor="title" ml="4px" fontWeight="450">
                            Title
                          </FormLabel>

                          <Input
                            id="title"
                            placeholder="Add a title to your file"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="description">
                      {() => (
                        <FormControl mb="25px">
                          <FormLabel htmlFor="description" ml="4px" fontWeight="450">
                            Description
                          </FormLabel>

                          <Input
                            id="description"
                            placeholder="Add a description to your file"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                ) : <Box />}
              <Field
                name="album"
              >
                {() => (
                  <FormControl
                    mb="4px"
                  >
                    <FormLabel htmlFor="album" ml="4px" fontWeight="450">
                      Album Name (Optional)
                    </FormLabel>

                    <Input
                      id="album"
                      placeholder="Create an album by adding an album name"
                      type="text"
                      value={values.album}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel htmlFor="description" ml="4px" fontWeight="450">
                      Album Description (Optional)
                    </FormLabel>

                    <Textarea
                      id="description"
                      placeholder="Add a album description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              {
                  /*
                <Field name="existing_album">
                  {() => (
                    <FormControl
                      isInvalid={errors.privacy && touched.privacy}
                      mb="25px"
                    >
                      <FormLabel htmlFor="existing_album" ml="4px" fontWeight="450">
                        Add to an existing Album
                      </FormLabel>

                      <Select id="existing_album" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                        {existingAlbums.map((album) => (
                          <option key={album} value={album._id}>
                            {album.name}
                          </option>
                        ))}
                      </Select>
                      <div className={classes.formErrorMessage}>
                        {touched.privacy && errors.privacy}
                      </div>
                    </FormControl>
                  )}
                </Field>
                */
                }
              <Field name="privacy">
                {() => (
                  <FormControl
                    isRequired
                    isInvalid={errors.privacy && touched.privacy}
                    mb="25px"
                  >
                    <FormLabel htmlFor="privacy" ml="4px" fontWeight="450">
                      Set privacy of your File(s)
                    </FormLabel>

                    <Select id="privacy" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                      {Privacy.map((item) => (
                        <option key={item} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                    <div className={classes.formErrorMessage}>
                      {touched.privacy && errors.privacy}
                    </div>
                  </FormControl>
                )}
              </Field>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                marginY="1rem"
              >
                <Button
                  type="cancel"
                  onClick={cancelPublish}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="auto"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Publishing..."
                  onClick={handleSubmit}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="auto"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Publish
                </Button>
              </Flex>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
  const renderFirstTimeUpload = () => (
    <Box>
      <InviteFamilyModal />
    </Box>
  );
  const renderContent = () => {
    switch (status) {
      case UploadStatus.AWAITING_FILE:
        return renderDropzone();
      case UploadStatus.FILE_ACCEPTED:
        return renderDetailsForm();
      case UploadStatus.UPLOADING:
        return renderUploadProgressIndicator();
      case UploadStatus.PROCESSING:
        return renderUploadProgressIndicator();
      case UploadStatus.PROCESSING_COMPLETE:
        return renderDetailsForm();
      case UploadStatus.LOADING:
        return renderUploadProgressIndicator();
      case UploadStatus.UPLOAD_SUCCESS:
        return renderFirstTimeUpload();
      default:
        return renderDetailsForm();
    }
  };

  return (
    <Flex
      className="app"
    >
      <AlertBanner message={alertMessage} />
      <Heading fontWeight="500" fontSize={{ base: '1.3em', sm: '1.5em', lg: '2em' }} margin="2rem">Upload Media File(s)</Heading>
      {loadcomplete
        ? (
          <Box>
            {renderContent()}
          </Box>
        )
        : (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={{
              base: '100vw', sm: '100vw', md: '520px', lg: '770px', xl: '1200px',
            }}
          >
            <Spinner
              thickness="4px"
              emptyColor="gray.200"
              color="#FF1A75"
              size="xl"
              mb="8px"
            />
            <Text>Loading...</Text>
          </Flex>
        )}
    </Flex>
  );
}

function ImageViewModal(props) {
  const {
    imageList,
  } = props;
  const {
    isOpen: isOpenViewModal,
    onOpen: onOpenViewModal,
    onClose: onCloseViewModal,
  } = useDisclosure();

  const [errMessage, setErrMessage] = useState();
  const classes = useStyles();

  return (
    <Box>
      <AspectRatio ratio={16 / 9}>
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          backgroundColor="#DDD"
          cursor="pointer"
          onClick={onOpenViewModal}
        >
          <SeeMoreButton />
        </Flex>
      </AspectRatio>
      <Modal
        isOpen={isOpenViewModal}
        onClose={onCloseViewModal}
        size={{
          base: '100%', sm: '100%', md: '620px', lg: '870px', xl: '1300px',
        }}
        margin="auto"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mass Upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding="10px">
            <SimpleGrid
              gridTemplateColumns={{
                base: '1fr',
                sm: '1fr',
                md: '1fr 1fr 1fr',
                xl: '1fr 1fr 1fr 1fr 1fr',
              }}
              gridTemplateRows={{
                base: '1fr 1fr',
              }}
              spacing="10px"
              width={{
                base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1200px',
              }}
              marginX="auto"
              marginY="1rem"
            >
              {imageList.map((thumbnail) => (
                <AspectRatio ratio={16 / 9}>
                  <Image
                    src={thumbnail.preview}
                    objectFit="cover"
                  />
                </AspectRatio>
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

MassUploadServer.propTypes = propTypes;

export default withRouter(MassUploadServer);
