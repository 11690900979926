/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Avatar,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Input,
  Checkbox,
  Box,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react';

const StyledTab = styled(Tab)`
  margin: 0;
  &:focus {
    outline:0 !important;
    box-shadow: none;
    border: none;
    border-color: #FFF !important;
  }
`;

const useStyles = createUseStyles({
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
  gridView: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',

  },
  friendCard: {
    margin: '1rem auto',
    padding: '1rem 1.2rem',
    width: '95%',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 4px 16px 0px',
  },
  searchResult: {
    padding: '16px',
    marginTop: '16px',
    width: '460px',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  friendCardButtonsContainer: {
    position: 'absolute',
    bottom: '24px',
  },
});

const AdminData = () => {
  const classes = useStyles();
  const [friendEmail, setFriendEmail] = useState('');
  const List = [{ userId: 'asdfwe', email: 'asfeff', _id: 'afeafsf' }];
  const [inviteList, setInviteList] = useState(List);
  const [retrievedInvites, setRetrievedInvites] = useState(false);
  const [acceptedUsers, setAcceptedUsers] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/api/invites/all-invites',
    })
      .then((data) => {
        // console.log(data.data);
        setInviteList(data.data);
        // console.log(data.data);
        const users = [];
        data.data.forEach((invite) => {
          if (invite.accountCreated === true) {
            users.push(invite);
          }
        });
        setAcceptedUsers(users);
        setRetrievedInvites(true);
      })
      .catch((err) => {
        // console.log(err);
        setErrorMessage(err);
      });
  }, [friendEmail]);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      marginTop="3rem"
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Heading
          fontWeight="500"
          fontSize={{ base: '2rem' }}
          color="#0B67C2"
        >
          Kloak Data
        </Heading>
        {!retrievedInvites
          ? <Heading fontWeight="500" size="lg" marginBottom="1rem">Loading Data</Heading>
          : (
            <Box
              marginY="1rem"
            >
              <Flex
                minH="100px"
                className="app"
                bg="#F6F6F6"
                margin="auto"
                padding="1rem"
                borderRadius="5px"
              >
                <Text
                  fontSize="1.2rem"
                  fontWeight="650"
                >
                  Number of Users Invited: {inviteList.length}
                </Text>
                <Text
                  fontSize="1.2rem"
                  fontWeight="650"
                  margin="1em"
                >
                  Number of User Accounts: {acceptedUsers.length}
                </Text>
                <Button
                  width={{ base: '250px' }}
                  backgroundColor="#2C6DAD"
                  _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                  color="#FFF"
                  padding="10px 16px"
                  borderRadius="50px"
                  cursor="pointer"
                  boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                  onClick={onOpen}
                  margin="auto"
                >
                  See All Users
                </Button>
              </Flex>
              <Modal isOpen={isOpen} onClose={onClose} size={{ base: '90%', md: '50%' }}>
                <ModalOverlay />
                <ModalContent>
                  <ModalCloseButton />
                  <ModalBody>
                    <Tabs>
                      <TabList>
                        <StyledTab>
                          <Text
                            textAlign="center"
                            fontSize="1.3em"
                            fontWeight="600"
                          >
                            All Invites
                          </Text>
                        </StyledTab>
                        <StyledTab>
                          <Text
                            textAlign="center"
                            fontSize="1.3em"
                            fontWeight="600"
                          >
                            All Users
                          </Text>
                        </StyledTab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <Box>
                            <SimpleGrid
                              columns={{ sm: 1, md: 2, lg: 3 }}
                              mb="48px"
                              width="100%"
                            >
                              {inviteList.map((invite) => (
                                <Box className={classes.friendCard}>
                                  <Box>
                                    <Flex key={invite._id} justifyContent="space-between">
                                      <Text as="span" fontWeight="500" marginRight="1rem">{invite.email} </Text>
                                      {invite.accountCreated
                                        ? <Checkbox defaultIsChecked isDisabled colorScheme="green" />
                                        : <Checkbox isDisabled colorScheme="green" />}
                                    </Flex>
                                  </Box>
                                </Box>
                              ))}
                            </SimpleGrid>
                          </Box>
                        </TabPanel>
                        <TabPanel
                          className={classes.pageView}
                        >
                          <Box>
                            <SimpleGrid
                              columns={{ sm: 1, md: 2, lg: 3 }}
                              mb="48px"
                              width="100%"
                            >
                              {acceptedUsers.map((invite) => (
                                <Box className={classes.friendCard}>
                                  <Box>
                                    <Flex key={invite._id} justifyContent="space-between">
                                      <Text as="span" fontWeight="500" marginRight="1rem">{invite.email} </Text>
                                      {invite.accountCreated
                                        ? <Checkbox defaultIsChecked isDisabled colorScheme="green" />
                                        : <Checkbox isDisabled colorScheme="green" />}
                                    </Flex>
                                  </Box>
                                </Box>
                              ))}
                            </SimpleGrid>
                          </Box>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          )}
      </Flex>
      <Divider width="100%" marginY="28px" />
    </Flex>
  );
};

export default AdminData;
