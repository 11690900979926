/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SingleComment from './SingleComment';
import ReplyComment from './ReplyComment';
import debounce from "lodash.debounce";
import { Box, Flex, Spinner, Text, Button } from "@chakra-ui/react";
const { TextArea } = Input;

const propTypes = {
  imageId: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  commentsList: PropTypes.array,
  updateCommentList: PropTypes.func,
};

function ImageComments({
  imageId,
  profile,
  commentsList,
  updateCommentList
}) {
  const user = useSelector((state) => state.user);
  // const [CommentLists, setCommentLists] = useState([]);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [initPage, setInitPage] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (imageId) {
      setLoading(false)
    }
  }, [imageId, commentsList])

  const handleChange = (e) => {

    setComment(e.currentTarget.value);
  };
  /*
  const getComments = () => {
    axios.post('/api/comment/getImageComments', { imageId, page, perPage }).then((response) => {
      if (response.data.success) {
        if (!initPage) {
          setInitPage(true);
        }
        console.log(response.data.success)
        console.log('response.data.comments', response.data.comments);
        updateCommentList(response.data.comments);
        if (response.data.comments.length === 0) {
          setLastPage(true);
          setLoading(false);
          return;
        }
        setPage(page + 1);
        setLoading(false);
      } else {
        alert('Failed to get video Info');
      }
    });
  }

  useEffect(() => {
    window.onscroll = debounce((event) => {
        if (( window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) && 
            (lastPage === false) && (initPage)) {
            // Do awesome stuff like loading more content!
              setLoading(true);
              getComments();
        }
      
    }, 300);
  });
  */
  
  const onSubmit = (e) => {
    console.log(`comments; ${commentsList}`)
    if (comment ===null || comment === '') {
      return;
    }
    e.preventDefault();

    const variables = {
      content: comment,
      writer: profile._id,
      imagePostId: imageId,
    };

    axios.post('/api/comment/saveComment', variables).then((response) => {
      if (response.data.success) {
        setComment('');
        updateCommentList(response.data.result);
      } else {
        alert('Failed to save Comment');
      }
    });
  };

  return (
    <Box>
        {loading === false ?
        <Box>
      <Box>
        <Box display={{base:"block", sm:"block", md:"block"}} marginTop="0.5rem" onSubmit={onSubmit}>
          <TextArea
            style={{ width: '100%', borderRadius: '5px' }}
            onChange={handleChange}
            value={comment}
            placeholder="write some comments"
          />
          <Flex justifyContent="flex-end" width="100%">
            <Button width={{base:"100px", sm:"100px", md: "100px"}} height={{base:"42px", sm:"42px", md:"42px"}} onClick={onSubmit}>
              Submit
            </Button>
          </Flex>
        </Box>
      </Box>
      <br />
      <Text color="#FFF" fontSize={{base:"14px", sm:"14px",md: "18px"}}> Replies</Text>
      <hr />
      <br />
      {/* Comment Lists  */}
      <Box marginTop="1rem" overflowY={{ base:"none", md:"auto" }} height="50vh">
        {commentsList.length > 0 ? 
           commentsList.map(
            (comment) => !comment.responseTo && (
            <Box>
              <SingleComment
                comment={comment}
                postId={imageId}
                refreshFunction={updateCommentList}
                profile={profile}
              />
              <ReplyComment
                commentsList={commentsList}
                postId={imageId}
                parentCommentId={comment._id}
                refreshFunction={updateCommentList}
                profile={profile}
              />
            </Box>
            ),
          ): <Box />
        }
      </Box>
      </Box>:
      <Box />}
    </Box>
  );
}

ImageComments.propTypes = propTypes;

export default ImageComments;
