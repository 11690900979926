/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { createUseStyles } from 'react-jss';
import {
  Avatar,
  Button,
  Heading,
  Flex,
  Spinner,
  Stack,
  Box,
  SimpleGrid,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  AspectRatio,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import SettingIcon from '../../../../assets/images/settings.png';

const baseDropzoneStyle = {
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: '50%',
  borderColor: '#EEEEEE',
  padding: '2px',
  cursor: 'pointer',
};

const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];

const Category = [
  { value: 'FILM', label: 'Film & Animation' },
  { value: 'AUTOS', label: 'Autos & Vehicles' },
  { value: 'MUSIC', label: 'Music' },
  { value: 'PETS', label: 'Pets & Animals' },
  { value: 'SPORTS', label: 'Sports' },
  { value: 'FAMILY', label: 'Family' },
  { value: 'OTHER', label: 'Other' },
];
function VideoModal(props) {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    video,
    profile,
    updateVideoList,
    videoList,
    deletedVideos,
  } = props;
  const [videoLoaded, setVideoLoaded] = useState(false);
  const minutes = Math.floor(video.duration / 60);
  const seconds = Math.floor(video.duration - minutes * 60);

  const goToVideo = (videoId) => {
    window.location.href = `/video/${videoId}`;
  };

  useEffect(() => {
    if (video && profile) {
      setVideoLoaded(true);
      // console.log('this videos' + video.filePath)
    }
  }, [video, profile]);

  return (

    <div>
      {video
        ? (
          <Box
            key={video._id}
            marginTop="-20px"
            padding="0"
            maxWidth={{
              base: '100%', sm: '100%', md: '250px', lg: '250px', xl: '350px',
            }}
          >
            <Box position="relative">
              <Text
                as="span"
                position="relative"
                top="30px"
                zIndex="1"
                color="#FFFFFF"
                backgroundColor="rgb(0, 0, 0, 0.7)"
                borderRadius="4px"
                padding="4px"
                marginLeft="5px"
              >
                {minutes}:{`0${seconds}`.slice(-2)}
              </Text>
              <AspectRatio ratio={16 / 9} position="relative">
                <Image
                  width="auto"
                  alt="thumbnail"
                  src={video.thumbNail}
                  height="100%"
                  margin="auto"
                  objectFit="cover"
                  cursor="pointer"
                  onClick={() => { goToVideo(video._id); }}
                />
              </AspectRatio>
            </Box>
            <Box padding="12px">
              <Flex justifyContent="space-between">
                <Stack isInline>
                  <Box>
                    <a href={`/video/${video._id}`}>
                      <Text
                        className={classes.title}
                        fontWeight="semibold"
                        as="h4"
                        width={{
                          base: '90vw',
                          md: '30ch',
                          lg: '30ch',
                          xl: '40ch',
                        }}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {video.title}
                      </Text>
                    </a>
                    <Text m="0" color="gray.600" fontSize="sm">{moment(video.createdAt).format('LL')}</Text>
                    <Text m="0" color="gray.600" fontSize="sm">{video.privacyLabel}</Text>
                    <SettingsDropdown
                      video={video}
                      profile={profile}
                      updateVideoList={updateVideoList}
                      videoList={videoList}
                      deletedVideos={deletedVideos}
                    />
                  </Box>
                </Stack>
              </Flex>
            </Box>
          </Box>
        )
        : <Flex><p>Loading</p></Flex> }
      <Modal isOpen={isOpen} onClose={onClose} size="75%">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <Divider width="100%" marginY="1rem" />
          <ModalBody padding="1rem">
            <div>
              <Image alt="photo" src={video.filePath} />
            </div>
          </ModalBody>

        </ModalContent>
      </Modal>
    </div>

  );
}
function SettingsDropdown(props) {
  const {
    video, profile, updateVideoList, videoList, deletedVideos,
  } = props;
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isOpenEditModal,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();
  const [errMessage, setErrMessage] = useState();
  const classes = useStyles();

  const deleteVideo = () => {
    // console.log(video);
    // include the key necessary to delete the video and thumbnail in s3
    const files = [{ Key: video.fileName }, { Key: video.thumbName }];
    // filePath deletes the object in mongodb.
    axios({
      method: 'POST',
      url: '/api/video/deleteOneVideo',
      data: {
        fileNames: files,
        filePath: video.filePath,
        profileId: profile._id,
        fileSize: video.fileSize,
        videoId: video._id,
        thumbSize: video.thumbSize,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setErrMessage(err.message);
      });
  };
  const renderDetailsForm = (myFile) => (
    <Formik
      initialValues={{
        title: video.title,
        description: video.description,
        // category: video.category,
        privacy: '',
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .required('Title is required'),
        privacy: Yup.string()
          .required('Must select a privacy option'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let label = '';
        Privacy.forEach((item) => {
          if (item.value === values.privacy) label = item.label;
        });

        setTimeout(() => {
          const data = {
            title: values.title,
            description: values.description,
            privacy: values.privacy,
            privacyLabel: label,
            // category: values.category,
            videoId: video._id,
          };
          axios.post('/api/video/updateOneVideo', data).then((response) => {
            // console.log(response.data.promises);
            updateVideoList(response.data.videoData);
            onCloseEditModal();
          });
        });
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <Box>
            <Box onSubmit={handleSubmit}>
              <Field name="title">
                {() => (
                  <FormControl
                    isRequired
                    isInvalid={errors.title && touched.title}
                    mb="4px"
                  >
                    <FormLabel htmlFor="title" ml="4px" fontWeight="300">
                      Title
                    </FormLabel>
                    <br />
                    <Input
                      id="title"
                      placeholder="Give your video a title"
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width={{ base: '98%', md: '100%' }}
                      margin="auto"
                    />
                    <div className={classes.formErrorMessage}>
                      {touched.title && errors.title}
                    </div>
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel htmlFor="description" ml="4px" fontWeight="300">
                      Description
                    </FormLabel>
                    <br />
                    <Textarea
                      id="description"
                      placeholder="What is this video about?"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width={{ base: '98%', md: '100%' }}
                      margin="auto"
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="privacy">
                {() => (
                  <FormControl
                    isRequired
                    isInvalid={errors.privacy && touched.privacy}
                    mb="25px"
                  >
                    <FormLabel htmlFor="privacy" ml="4px" fontWeight="300">
                      Privacy
                    </FormLabel>
                    <br />
                    <Select id="privacy" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                      {Privacy.map((item) => (
                        <option key={item} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                    <div className={classes.formErrorMessage}>
                      {touched.privacy && errors.privacy}
                    </div>
                  </FormControl>
                )}
              </Field>
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="Publishing..."
                onClick={handleSubmit}
                display="block"
                borderRadius="24px"
                mt="16px"
                mb="3rem"
                mr="auto"
                ml="auto"
                width="200px"
                color="white"
                bg="#FF1A75"
                _hover={{
                  bg: '#FFE6EE',
                  color: '#FF1A75',
                }}
              >
                Publish
              </Button>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
  return (
    <Flex>
      <Menu>
        <MenuButton>
          <Text
            marginTop="4px"
            color="#FFF"
            fontSize="sm"
            backgroundColor="#3388FF"
            padding="4px 8px"
            borderRadius="3px"
            cursor="pointer"
            width="70px"
            textAlign="center"
          >
            Settings
          </Text>
        </MenuButton>
        <MenuList
          placement="bottom-start"
          zIndex="9999"
          backgroundColor="#EEE"
          width="125px"
        >
          <MenuItem onClick={onOpenEditModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Edit
            </Text>
          </MenuItem>
          <MenuItem onClick={onOpenDeleteModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Delete
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Video Properties</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderDetailsForm()}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p1>Are you sure you want to Delete this video?</p1>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseDeleteModal}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={deleteVideo}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default VideoModal;

/*
              <Field name="category">
                {() => (
                  <FormControl isRequired mb="25px">
                    <FormLabel htmlFor="category" ml="4px" fontWeight="300">
                      Category
                    </FormLabel>
                    <br />
                    <Select id="category"
                      onChange={handleChange}
                      width={{ base: '98%', md: '100%' }}
                      margin="auto"
                    >
                      {Category.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              */
