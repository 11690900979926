/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Box,
  Link,
} from '@chakra-ui/react';
import { registerUser } from '../../../_actions/user_actions';
import MessagingImg from '../../../assets/images/undraw_messaging_uok8.png';
import AlertBanner from '../../sections/AlertBanner';

const useStyles = createUseStyles({
  formContainer: {
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

// TODO: Add form fields for name
function RegisterPage({
  history,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const alertTitle = 'Kloak registration is currently invite only';
  const debouncedUsernameCheck = useCallback(async (username) => {
    if (!username) {
      return true;
    }
    const res = await axios.get(`/api/users/username/${username.trim()}`);
    return !res.data.user;
  }, []);

  const debouncedEmailCheck = useCallback(async (email) => {
    if (!email) {
      return true;
    }
    const res = await axios.get(`/api/users/email/${email.trim()}`);
    return !res.data.user;
  }, []);

  return (
    <Formik
      initialValues={{
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        termsCheck: false,
      }}
      // TODO: Evaluate username / email check only when those inputs are changed
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .required('Name is required')
          .test('usernameAvailable', 'This username is already taken', debouncedUsernameCheck),
        email: Yup.string()
          .email('Email is invalid')
          .required('Email is required')
          .test('emailAvailable', 'An account with this email already exists', debouncedEmailCheck),
        password: Yup.string()
          .required('Password is required')
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-+(){}[\]|;:"'=,.<>~`_/\\])[A-Za-z\d@$!%*#?&-+(){}[\]|:;"'=,.<>~`_/\\]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Number and One Special Character',
          ),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Confirm Password is required'),
        termsCheck: Yup.string()
          .matches(
            true,
            'You must click on checkbox to agree with Kloak\'s terms and services',
          ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          // console.log('sta')
          const dataToSubmit = {
            email: values.email,
            password: values.password,
            username: values.username,
            image: `http://gravatar.com/avatar/${moment().unix()}?d=identicon`,
          };

          dispatch(registerUser(dataToSubmit)).then((response) => {
            if (response.payload.success) {
              history.push('/login');
            } else {
              alert(response.payload.err.errmsg);
            }
          });
          setSubmitting(false);
          // axios({
          //   method: 'POST',
          //   url: '/api/invites/check-invite-list',
          //   data: {
          //     email: values.email,
          //   },
          // })
          //   .then((res) => {
          //     // console.log('reached')
          //     if (res.data.success) {
          //       const dataToSubmit = {
          //         email: values.email,
          //         password: values.password,
          //         username: values.username,
          //         image: `http://gravatar.com/avatar/${moment().unix()}?d=identicon`,
          //       };
          //
          //       dispatch(registerUser(dataToSubmit)).then((response) => {
          //         if (response.payload.success) {
          //           history.push('/login');
          //         } else {
          //           alert(response.payload.err.errmsg);
          //         }
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     // console.log(err);
          //     const errMsg = err?.response?.status === 400
          //       ? 'An invite has not been found for this email address.'
          //       : 'Something went wrong';
          //     setFormErrorMessage(errMsg);
          //     setTimeout(() => {
          //       setFormErrorMessage('');
          //     }, 3000);
          //   });
          // setSubmitting(false);
        }, 500);
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <Flex width="100%" margin="auto" minHeight="90vh" flexDirection={{ base: 'column', sm: 'column', xl: 'row' }}>
            <Flex flexGrow="1" alignItems="center" justifyContent="center">
              <Image width={{ base: '100px', sm: '100px', xl: '400px' }} objectFit="contain" src={MessagingImg} />
            </Flex>
            <Flex className={classes.formContainer} flexGrow="1" flexDirection="column" alignItems={{ base: 'left', lg: 'center' }}>
              <Box className={classes.form} onSubmit={handleSubmit} width={{ base: '94%', sm: '94%', md: '352px' }} margin="auto">
                {
                  /*
                <Box marginBottom="1.5rem" marginTop={{ base: '1.5rem', xl: '0' }} width={{ base: '94%', sm: '94%', md: '352px' }} margin="auto">
                  <AlertBanner title={alertTitle} />
                </Box>
                */
                }
                <Heading fontWeight="400" marginY="1rem" textAlign="center">Sign Up</Heading>
                <Field name="username">
                  {() => (
                    <FormControl
                      isRequired
                      isInvalid={errors.username && touched.username}
                      mb="4px"
                    >
                      <FormLabel htmlFor="username" ml="4px" fontWeight="400" paddingLeft={{ base: '3%', lg: '0' }}>
                        Username
                      </FormLabel>

                      <Input
                        id="username"
                        placeholder="Enter your username"
                        type="text"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        width={{ base: '94%', sm: '94%', md: '352px' }}
                        margin="auto"
                      />
                      <div className={classes.formErrorMessage}>
                        {touched.username && errors.username}
                      </div>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {() => (
                    <FormControl
                      isRequired
                      isInvalid={errors.email && touched.email}
                      mb="4px"
                    >
                      <FormLabel htmlFor="email" ml="4px" fontWeight="400" paddingLeft={{ base: '3%', lg: '0' }}>
                        Email
                      </FormLabel>

                      <Input
                        id="email"
                        placeholder="Enter your email address"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        width={{ base: '94%', sm: '94%', md: '352px' }}
                        margin="auto"
                      />
                      <Box className={classes.formErrorMessage} paddingLeft={{ base: '3%', lg: '0' }}>
                        {touched.email && errors.email}
                      </Box>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {() => (
                    <FormControl
                      isRequired
                      isInvalid={errors.password && touched.password}
                      addingLeft={{ base: '3%', lg: '0' }}
                      mb="4px"
                    >
                      <FormLabel htmlFor="password" ml="4px" fontWeight="400" paddingLeft={{ base: '3%', lg: '0' }}>
                        Password
                      </FormLabel>

                      <Input
                        id="password"
                        placeholder="Enter a password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        width={{ base: '94%', sm: '94%', md: '352px' }}
                        margin="auto"
                      />
                      <Box className={classes.formErrorMessage} paddingLeft={{ base: '3%', lg: '0' }}>
                        {touched.password && errors.password}
                      </Box>
                    </FormControl>
                  )}
                </Field>
                <Field name="confirmPassword">
                  {() => (
                    <FormControl
                      isRequired
                      isInvalid={
                        errors.confirmPassword && touched.confirmPassword
                      }
                      mb="4px"
                    >
                      <FormLabel htmlFor="confirmPassword" ml="4px" fontWeight="400" paddingLeft={{ base: '3%', lg: '0' }}>
                        Confirm Password
                      </FormLabel>

                      <Input
                        id="confirmPassword"
                        placeholder="Confirm your password"
                        type="password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        width={{ base: '100%', sm: '100%', md: '352px' }}
                        margin="auto"
                      />
                      <Box className={classes.formErrorMessage} addingLeft={{ base: '3%', lg: '0' }}>
                        {touched.confirmPassword && errors.confirmPassword}
                      </Box>
                    </FormControl>
                  )}
                </Field>
                <Flex marginTop={{ base: '0.5rem' }}>
                  <Box>
                    <Field name="termsCheck" type="checkbox" style={{ marginTop: '7px' }} />
                  </Box>
                  <FormControl
                    isRequired
                    isInvalid={
                      errors.termsCheck && touched.termsCheck
                    }
                    mb="4px"
                  >
                    <FormLabel htmlFor="termsCheck" ml="4px" fontWeight="400" paddingLeft={{ base: '0.5rem' }}>
                      I agree to the Kloak <Link href="/terms" color="#1890ff" target="_blank">Terms of Service</Link> and
                      <Link href="/privacy" color="#1890ff" target="_blank"> Privacy Policy</Link>
                    </FormLabel>
                    <Box className={classes.formErrorMessage} paddingLeft={{ base: '0.5rem' }}>
                      {touched.termsCheck && errors.termsCheck}
                    </Box>
                  </FormControl>
                </Flex>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Submitting..."
                  onClick={handleSubmit}
                  display="block"
                  mt="16px"
                  mb="4rem"
                  mr="auto"
                  ml="auto"
                  width="200px"
                  color="white"
                  borderRadius="8px"
                  bg="#3480E3"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Create Account
                </Button>
                {formErrorMessage && (
                <Box>
                  <p
                    style={{
                      color: '#ff0000bf',
                      fontSize: '0.7rem',
                      border: '1px solid',
                      padding: '1rem',
                      borderRadius: '10px',
                    }}
                  >
                    {formErrorMessage}
                  </p>
                </Box>
                )}
              </Box>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

RegisterPage.propTypes = propTypes;
export default withRouter(RegisterPage);
