/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import {
  Box,
  Flex,
  Avatar,
  Text,
  Spinner,
  SimpleGrid,
  AspectRatio,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
} from '@chakra-ui/react';

import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
// import Subscriber from './Sections/Subscriber';
import { BackgroundColor } from '@styled-icons/foundation';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import ImageReaction from './sections/ImageReaction';
import Comments from './sections/Comments';
import SidePosts from './sections/SidePosts';
import ErrorImage from '../../../assets/images/famshare.png';

const SeeMoreButton = styled(EyeFill)`
  width: 24px;
  height: 36px;
  color: #BBB;
  cursor: pointer;
`;
const HeartActive = styled(SuitHeartFill)`
  color: #FF0000; 
  right: 12px;
  top: 20px;
  position: relative;
  width: 20px;
  height: 20px
`;
const HeartButton = styled(SuitHeartFill)`
  background-color: inherit;
  width: 24px;
  height: 24px;
  color: inherit;
  cursor: pointer;
`;

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      albumId: PropTypes.string,
    }),
  }).isRequired,
};

function DetailAlbumPage({
  match,
}) {
  const { albumId } = match.params;
  const [viewPost, setViewPost] = useState();
  const [error, setError] = useState('');
  const [loadCompleted, setLoadCompleted] = useState(false);
  const [profile, setProfile] = useState();
  const [myId, setMyId] = useState();
  const [reactionActive, setReactionActive] = useState(false);
  const [postLoaded, setPostLoaded] = useState(true);
  const [imageReactions, setImageReactions] = useState([]);
  const [viewReactions, setViewReactions] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const user = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getReactions = (profileData, post) => {
    // console.log(profileData);
    if (post.image) {
      axios({
        url: '/api/image/getAllReactions',
        method: 'POST',
        data: {
          imageId: post.image._id,
          profile: profileData,
        },
      })
        .then((response) => {
        // console.log(response.data.reactions[0]);
          setImageReactions(response.data.reactions);
          setViewReactions(response.data.reactions?.slice(0, 4));
          // console.log(`result: ${response.data.reactions}`);
          // eslint-disable-next-line eqeqeq
          if (response.data.reactions === undefined
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == []
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == '') {
          // console.log('reached me');
            setReactionActive(false);
            setLoadCompleted(true);
            // document.getElementById(`heart-button`).style.color = '#DDD';
            return;
          }
          if (response.data.reactions[0].profile._id === profileData._id) {
          // console.log('match found');
          // document.getElementById(`heart-button`).style.color = '#FF0000';
            setReactionActive(true);
          // console.log('reached ne');
          } else {
          // document.getElementById(`heart-button`).style.color = '#DDD';
            setReactionActive(false);
          }
          // console.log('finally');
          setLoadCompleted(true);
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
        })
        .catch((err) => {
          setError(err);
        });
    } else {
      axios({
        url: '/api/video/getAllReactions',
        method: 'POST',
        data: {
          videoId: post.video._id,
          profile: profileData,
        },
      })
        .then((response) => {
        // console.log(response.data.reactions[0]);
          setImageReactions(response.data.reactions);
          setViewReactions(response.data.reactions?.slice(0, 4));
          // console.log(`result: ${response.data.reactions}`);
          // eslint-disable-next-line eqeqeq
          if (response.data.reactions === undefined
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == []
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == '') {
          // console.log('reached me');
            setReactionActive(false);
            setLoadCompleted(true);
            // document.getElementById(`heart-button`).style.color = '#DDD';
            return;
          }
          if (response.data.reactions[0].profile._id === profileData._id) {
          // console.log('match found');
          // document.getElementById(`heart-button`).style.color = '#FF0000';
            setReactionActive(true);
          // console.log('reached ne');
          } else {
          // document.getElementById(`heart-button`).style.color = '#DDD';
            setReactionActive(false);
          }
          // console.log('finally');
          setLoadCompleted(true);
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
        })
        .catch((err) => {
          setError(err);
        });
    }
  };
  const reactionHandler = () => {
    // console.log(imageReactions);
    if (viewPost.image) {
      if (reactionActive === false) {
        axios({
          url: '/api/image/addImageReaction',
          method: 'POST',
          data: {
            imageId: viewPost.image._id,
            profile,
            reactionType: 'Love',
          },
        })
          .then((reactionData) => {
            // console.log(`reached? ${reactionData.data.reactionData.profile._id}`);
            const newReaction = viewReactions;
            const allreactions = imageReactions;
            newReaction.unshift(reactionData.data.reactionData);
            allreactions.unshift(reactionData.data.reactionData);
            //  document.getElementById(`heart-button`).style.color = '#FF0000';
            setViewReactions(newReaction);
            setImageReactions(allreactions);
            setReactionActive(true);
            // console.log(profile.image);
          })
          .catch((err) => {
            setError(err);
          });
      } else {
        axios({
          url: '/api/image/removeImageReaction',
          method: 'POST',
          data: {
            profile,
            imageId: viewPost.image._id,
          },
        })
          .then(() => {
            // console.log(imageReactions);
            const allReactions = imageReactions;
            const newReaction = viewReactions;
            allReactions.shift();
            newReaction.shift();
            // console.log(`new ${newReactions.length}`);
            setImageReactions(allReactions);
            setViewReactions(newReaction);
            setReactionActive(false);
          });
      }
    } else {
      if (reactionActive === false) {
        axios({
          url: '/api/video/addVIdeoReaction',
          method: 'POST',
          data: {
            videoId: viewPost.video._id,
            profile,
            reactionType: 'Love',
          },
        })
          .then((reactionData) => {
            // console.log(`reached? ${reactionData.data.reactionData.profile._id}`);
            const newReaction = viewReactions;
            const allreactions = imageReactions;
            newReaction.unshift(reactionData.data.reactionData);
            allreactions.unshift(reactionData.data.reactionData);
            //  document.getElementById(`heart-button`).style.color = '#FF0000';
            setViewReactions(newReaction);
            setImageReactions(allreactions);
            setReactionActive(true);
            // console.log(profile.image);
          })
          .catch((err) => {
            setError(err);
          });
      } else {
        axios({
          url: '/api/video/removeVideoReaction',
          method: 'POST',
          data: {
            profile,
            videoId: viewPost.video._id,
          },
        })
          .then(() => {
            // console.log(imageReactions);
            const allReactions = imageReactions;
            const newReaction = viewReactions;
            allReactions.shift();
            newReaction.shift();
            // console.log(`new ${newReactions.length}`);
            setImageReactions(allReactions);
            setViewReactions(newReaction);
            setReactionActive(false);
          });
      }
    }
  };
  useEffect(() => {
    // console.log('test');
    if (!user.userData) {
      return;
    }
    axios({
      method: 'POST',
      url: '/api/users/getProfile',
      data: {
        username: user.userData.username,
      },
    })
      .then((profResponse) => {
        // console.log('hello?');
        axios.post('/api/post/posts-by-album', { albumId, profileId: profResponse.data._id }).then((response) => {
          if (response.data.success) {
            // console.log('got data: ' + response.data.video.filePath);
            // console.log(response.data.video)
            // console.log(imgResponse.data.images);
            // setImage(response.data.posts[0]);
            setViewPost(response.data.posts[0]);
            setProfile(profResponse.data);
            setMyId(profResponse.data._id);
            // console.log(response.data.posts);
            setAllPosts(response.data.posts);
            getReactions(profResponse.data, response.data.posts[0]);
            window.scrollTo(0, 0);
          }
        }).catch((err) => {
        // console.log(JSON.stringify(err));
          if (err.response && err.response.status === 403) {
            setError('ACCESS_DENIED');
          }
        });
      })
      .catch((err) => {
        setError('Cannot fetch user profile');
      });
  }, [user, albumId]);
  const changePost = (newPost) => {
    setPostLoaded(false);
    setViewPost(newPost);
    getReactions(profile, newPost);
    setPostLoaded(true);
  };
  const renderLoader = () => (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={{
        base: '100vw', sm: '100vw', md: '520px', lg: '770px', xl: '1200px',
      }}
      height="90vh"
    >
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FFF"
        size="xl"
        mb="8px"
      />
    </Flex>
  );
  // author={<Text fontSize={{base:".7rem", sm:".7rem", md:".9rem"}} fontWeight="500" color="#000">{video.writer.username}</Text>}
  if (error) {
    if (error === 'ACCESS_DENIED') {
      return (
        <Flex className="app" width="100%" minHeight="90vh">
          <Image src={ErrorImage} width="35%" />
          <Text
            fontSize="1.3em"
            fontWeight="600"
          >
            You do not have permission to view this album.
          </Text>
        </Flex>
      );
    }
    return (
      <Flex className="app" width="100%" minHeight="90vh">
        <Image src={ErrorImage} width="35%" />
        <Text
          fontSize="1.3em"
          fontWeight="600"
        >
          An error occurred while loading the album.
        </Text>
      </Flex>
    );
  }
  return (
    <Box minHeight="90vh" width="100vw">
      {loadCompleted
        ? (
          <SimpleGrid gridTemplateColumns={{ base: '1fr', lg: '3fr 1fr' }}>
            {viewPost.image
              ? (
                <Box
                  className="postPage"
                  width="100%"
                  marginTop="1rem"
                  paddingX="1.5rem"
                >
                  {postLoaded
                    ? (
                      <AspectRatio
                        ratio={16 / 9}
                        backgroundColor="#111"
                      >
                        <Flex justifyContent="center">
                          <Box
                            as="img"
                            crossOrigin="anonymous"
                            src={viewPost.image.filePath}
                            alignSelf="center"
                            width="auto"
                            height="100%"
                          />
                        </Flex>
                      </AspectRatio>
                    )
                    : <Box>{renderLoader}</Box>}
                  <Box marginTop="0.5rem">
                    <Flex justifyContent="space-between">
                      <Box
                        marginBottom="0.5rem"
                      >
                        <Flex>
                          <Box paddingTop="5px" paddingRight="5px">
                            <Box
                              as="img"
                              crossOrigin="anonymous"
                              src={viewPost.image.writer && viewPost.image.writer.image}
                              width="50px"
                              height="50px"
                              objectFit="cover"
                              borderRadius="50%"
                              verticalAlign="middle"
                            />
                          </Box>
                          <Box marginBottom="1rem" marginLeft="0.5rem">
                            <Text
                              color="#003971"
                              fontWeight="bold"
                              fontSize={{ base: '1.3em', sm: '1.4em' }}
                              whiteSpace="wrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              marginTop="0.5em"
                            >
                              {viewPost.image.description}
                            </Text>
                            <Text
                              color="#AAA"
                              fontWeight="bold"
                              fontSize={{ base: '1.1em', sm: '1.2em' }}
                              whiteSpace="wrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {viewPost.image.writer.username}
                            </Text>
                            <Text
                              color="#AAA"
                              fontWeight="bold"
                              fontSize={{ base: '1em', sm: '1.1em' }}
                            >
                              {moment(viewPost.image.createdAt).format('LL')}
                            </Text>
                            <Box>{viewReactions.length > 0
                              ? (
                                <SimpleGrid
                                  gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                                  width="250px"
                                >
                                  {viewReactions?.map((data) => (
                                    <Flex marginY="8px" height="32px">
                                      <Box
                                        as="img"
                                        crossOrigin="anonymous"
                                        src={data.profile && data.profile.image}
                                        width="32px"
                                        height="32px"
                                        objectFit="cover"
                                        borderRadius="50%"
                                        verticalAlign="middle"
                                        backgroundSize="cover"
                                      />
                                      <HeartActive />
                                    </Flex>
                                  ))}
                                  <Flex marginY="8px" marginLeft="5px" height="32px">
                                    <SeeMoreButton onClick={() => { onOpen(); }} />
                                  </Flex>
                                  <Box>
                                    <Modal isOpen={isOpen} onClose={onClose}>
                                      <ModalOverlay />
                                      <ModalContent>
                                        <ModalCloseButton />
                                        <ModalBody>
                                          <Box marginY="3rem">
                                            {imageReactions?.map((data) => (
                                              <Flex marginY="1rem">
                                                <Box
                                                  as="img"
                                                  crossOrigin="anonymous"
                                                  src={data.profile && data.profile.image}
                                                  width="32px"
                                                  height="32px"
                                                  objectFit="cover"
                                                  borderRadius="50%"
                                                  verticalAlign="middle"
                                                  backgroundSize="cover"
                                                />
                                                <HeartActive />
                                                <Text marginTop="5px" fontWeight="500">{data.profile.username}</Text>
                                              </Flex>
                                            ))}
                                          </Box>
                                        </ModalBody>
                                      </ModalContent>
                                    </Modal>
                                  </Box>
                                </SimpleGrid>
                              )
                              : <Box />}
                            </Box>
                          </Box>
                        </Flex>
                      </Box>
                      <Box marginRight={{ base: '0.5rem', md: '1rem' }} marginTop={{ base: '0.5rem', md: '1rem' }}>
                        <Box color={reactionActive === false ? '#AAA' : '#FF0000'}>
                          <HeartButton id="heart-button" onClick={() => reactionHandler()} />
                        </Box>
                      </Box>
                    </Flex>
                    <Comments
                      post={viewPost}
                      profile={profile}
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  className="postPage"
                  width="100%"
                  marginTop="1rem"
                  paddingX="1.5rem"
                >
                  {postLoaded
                    ? (
                      <AspectRatio
                        ratio={16 / 9}
                        backgroundColor="#111"
                        objectFit="contain"
                      >
                        <Box>
                          <Box
                            as="video"
                            crossOrigin="anonymous"
                            width="auto"
                            height="100%"
                            backgroundColor="#111"
                            src={viewPost.video.filePath}
                            controls
                          />
                        </Box>
                      </AspectRatio>
                    )
                    : <Box>{renderLoader}</Box>}

                  <Box marginTop="0.5rem">
                    <Flex justifyContent="space-between">
                      <Box
                        marginBottom="0.5rem"
                      >
                        <Flex>
                          <Box paddingTop="5px" paddingRight="5px">
                            <Box
                              as="img"
                              crossOrigin="anonymous"
                              src={viewPost.video.writer && viewPost.video.writer.image}
                              width="50px"
                              height="50px"
                              objectFit="cover"
                              borderRadius="50%"
                              verticalAlign="middle"
                            />
                          </Box>
                          <Box marginBottom="1rem" marginLeft="0.5rem">
                            <Text
                              color="#003971"
                              fontWeight="bold"
                              fontSize={{ base: '1.3em', sm: '1.4em' }}
                              whiteSpace="wrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              marginTop="0.5em"
                            >
                              {viewPost.video.description}
                            </Text>
                            <Text
                              color="#AAA"
                              fontWeight="bold"
                              fontSize={{ base: '1.1em', sm: '1.2em' }}
                              whiteSpace="wrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {viewPost.video.writer.username}
                            </Text>
                            <Text
                              color="#AAA"
                              fontWeight="bold"
                              fontSize={{ base: '1em', sm: '1.1em' }}
                            >
                              {moment(viewPost.video.createdAt).format('LL')}
                            </Text>
                            <Box>{viewReactions.length > 0
                              ? (
                                <SimpleGrid
                                  gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                                  width="250px"
                                >
                                  {viewReactions?.map((data) => (
                                    <Flex marginY="8px" height="32px">
                                      <Box
                                        as="img"
                                        crossOrigin="anonymous"
                                        src={data.profile && data.profile.image}
                                        width="32px"
                                        height="32px"
                                        objectFit="cover"
                                        borderRadius="50%"
                                        verticalAlign="middle"
                                        backgroundSize="cover"
                                      />
                                      <HeartActive />
                                    </Flex>
                                  ))}
                                  <Flex marginY="8px" marginLeft="5px" height="32px">
                                    <SeeMoreButton onClick={() => { onOpen(); }} />
                                  </Flex>
                                  <Box>
                                    <Modal isOpen={isOpen} onClose={onClose}>
                                      <ModalOverlay />
                                      <ModalContent>
                                        <ModalCloseButton />
                                        <ModalBody>
                                          <Box marginY="3rem">
                                            {imageReactions?.map((data) => (
                                              <Flex marginY="1rem">
                                                <Box
                                                  as="img"
                                                  crossOrigin="anonymous"
                                                  src={data.profile && data.profile.image}
                                                  width="32px"
                                                  height="32px"
                                                  objectFit="cover"
                                                  borderRadius="50%"
                                                  verticalAlign="middle"
                                                  backgroundSize="cover"
                                                />
                                                <HeartActive />
                                                <Text marginTop="5px" fontWeight="500">{data.profile.username}</Text>
                                              </Flex>
                                            ))}
                                          </Box>
                                        </ModalBody>
                                      </ModalContent>
                                    </Modal>
                                  </Box>
                                </SimpleGrid>
                              )
                              : <Box />}
                            </Box>
                          </Box>
                        </Flex>
                      </Box>
                      <Box marginRight={{ base: '0.5rem', md: '1rem' }} marginTop={{ base: '0.5rem', md: '1rem' }}>
                        <Box color={reactionActive === false ? '#AAA' : '#FF0000'}>
                          <HeartButton id="heart-button" onClick={() => reactionHandler()} />
                        </Box>
                      </Box>
                    </Flex>
                    <Comments
                      post={viewPost}
                      profile={profile}
                    />
                  </Box>
                </Box>
              )}
            <SidePosts
              profileId={myId}
              allPosts={allPosts}
              changePost={changePost}
            />
          </SimpleGrid>
        )
        : <Flex className="app"><h1>Loading...</h1></Flex>}
    </Box>
  );
}

DetailAlbumPage.propTypes = propTypes;

export default DetailAlbumPage;
