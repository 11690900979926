/* eslint-disable no-await-in-loop */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import * as Yup from 'yup';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
// import Amplify, { Auth, Storage } from 'aws-amplify';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import {
  Avatar,
  Button,
  Heading,
  Flex,
  Spinner,
  Stack,
  Box,
  SimpleGrid,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  FormControl,
  FormLabel,
  Select,
  AspectRatio,
} from '@chakra-ui/react';
import moment from 'moment';
import { Formik, Field } from 'formik';
import axios from 'axios';
import { Settings2Outline } from '@styled-icons/evaicons-outline/Settings2Outline';
import SettingIcon from '../../../../assets/images/settings.png';
import ImageReaction from '../../../sections/ImageReaction';

// zip.folder('test_folder');
// const photoZip = zip.folder('test_folder');

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',

  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#111',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#F1F1F1',
    fontSize: '30px',
    fontWeight: 'bold',
    transition: '0.3s',
    cursor: 'pointer',
    padding: '0 14px',
    borderRadius: '50%',
    '&:hover': {
      color: '#bbb',
      textDecoration: 'none',
    },
  },
  leftButton: {
    position: 'absolute',
    top: '50%',
    left: '24px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  rightButton: {
    position: 'absolute',
    top: '50%',
    right: '-26px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  privacyButton: {
    backgroundColor: '#222',
    borderRadius: '5px',
    padding: '6px 12px',
    color: '#FFF',
    marginTop: '1rem',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const propTypes = {
  posts: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
};
function DownloadModal({
  posts,
  profile,
}) {
  const classes = useStyles();
  const {
    isOpen: isDownloadModal,
    onOpen: onDownloadeModal,
    onClose: onCloseDownloadModal,
  } = useDisclosure();

  const [isDownloading, setIsDownloading] = useState(false);
  // const { isOpenEdit, onOpen, onClose } = useDisclosure();
  /*
  const downloadFromS3 = async (fileKey) => {
    const result = await Storage.get(fileKey, { download: true });

    const mimeType = result.ContentType;
    const fileName = fileKey;
    const blob = new Blob([result.Body], { type: mimeType });

    photoZip.file(fileName[1], blob);
  };
  */
  const cancelDownload = () => {
    window.location.reload();
  };
  const downloadAllFiles = async () => {
    const zip = new JSZip();
    let count = 0;
    posts.forEach((post) => {
      const filename = post.video ? post.video.fileName : post.image.fileName;
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(post.video ? post.video.filePath : post.image.filePath, (err, data) => {
        if (err) {
          throw err; // or handle the error
        }
        // console.log(data);
        zip.file(filename, data, { binary: true });
        count += 1;
        if (count === posts.length) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, 'test_zip');
            onCloseDownloadModal();
          });
        }
      });
    });
    // for (let i = 0; i < posts.length; i += 1) {
    //   await downloadFromS3(posts[i].video ? posts[i].video.filePath : posts[i].image.filePath);
    // }
    // /// waits for "await DownloadFileFromS3, then executes the save as which saves the zipped folder created in "DownloadFileFromS3"
    // zip.generateAsync({ type: 'blob' })
    //   .then((content) => {
    //     saveAs(content, 'zipFolder');
    //   });
    /*
    const zip = new JSZip();
    const a = document.querySelector('a');
    const urls = ['a.html', 'b.html'];

    function request(url) {
      return new Promise((resolve) => {
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('GET', url);
        httpRequest.onload = function () {
          zip.file(url, this.responseText);
          resolve();
        };
        httpRequest.send();
      });
    }

    Promise.all(urls.map((url) => request(url)))
      .then(() => {
        console.log(zip);
        zip.generateAsync({
          type: 'blob',
        })
          .then((content) => {
            a.download = `folder${new Date().getTime()}`;
            a.href = URL.createObjectURL(content);
            a.innerHTML = `download ${a.download}`;
          });
      });
    */
    // console.log(posts);
    // const response = await axios.post(
    //   '/api/post/download-all-posts', { allPosts: posts },
    // );
    // const link = document.createElement('a');
    // link.href = response.data.fileUrl;
    // link.setAttribute('download', 'mass_media');
    // document.body.appendChild(link);
    // link.click();
    /*
    const response = await axios.post(
      '/api/post/download-all-posts', { allPosts: posts },
    );
    const { fileUrls } = response.data;
    console.log(fileUrls);
    const link = document.createElement('a');

    link.setAttribute('download', null);
    link.style.display = 'none';

    document.body.appendChild(link);
    for (let i = 0; i < fileUrls.length; i += 1) {
      link.setAttribute('href', fileUrls[i]);
      link.click();
    }
    document.body.removeChild(link);
    */
  };
  const downloadAllImages = async () => {
    const zip = new JSZip();
    const imagePosts = [];
    posts.forEach((post) => {
      if (post.image) imagePosts.push(post);
    });
    let count = 0;
    imagePosts.forEach((post) => {
      if (post.image) {
        // console.log(post.image);
        const filename = post.image.fileName;
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(post.image.filePath, (err, data) => {
          if (err) {
            throw err; // or handle the error
          }
          // console.log(data);
          zip.file(filename, data, { binary: true });
          count += 1;
          if (count === imagePosts.length) {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              saveAs(content, 'test_zip');
              onCloseDownloadModal();
            });
          }
        });
      }
    });
  };
  const downloadAllVideos = async () => {
    const zip = new JSZip();
    const videoPosts = [];
    posts.forEach((post) => {
      if (post.video) videoPosts.push(post);
    });
    let count = 0;
    videoPosts.forEach((post) => {
      if (post.video) {
        // console.log(post.image);
        const filename = post.video.fileName;
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(post.video.filePath, (err, data) => {
          if (err) {
            throw err; // or handle the error
          }
          // console.log(data);
          zip.file(filename, data, { binary: true });
          count += 1;
          if (count === videoPosts.length) {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              saveAs(content, 'test_zip');
              onCloseDownloadModal();
            });
          }
        });
      }
    });
  };
  return (
    <Box>
      <Menu>
        <MenuButton>
          <Text
            marginTop="4px"
            paddingY="10px"
            color="#FFF"
            fontSize={{ sm: '1em', md: '1.2em' }}
            backgroundColor="#3388FF"
            padding="4px 8px"
            borderRadius="3px"
            cursor="pointer"
            width={{ sm: '125px', md: '150px' }}
            textAlign="center"
          >
            Download Files
          </Text>
        </MenuButton>
        <MenuList
          placement="bottom-start"
          zIndex="9999"
          backgroundColor="#EEE"
          width="125px"
        >
          <MenuItem onClick={() => { downloadAllFiles(); onDownloadeModal(); }}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Download All
            </Text>
          </MenuItem>
          <MenuItem onClick={() => { downloadAllImages(); onDownloadeModal(); }}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Download Images
            </Text>
          </MenuItem>
          <MenuItem onClick={() => { downloadAllVideos(); onDownloadeModal(); }}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Download Videos
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal isOpen={isDownloadModal} onClose={() => { onCloseDownloadModal(); cancelDownload(); }} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent
          height="250px"
        >
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
              <Spinner
                thickness="4px"
                emptyColor="gray.200"
                color="#FF1A75"
                size="xl"
                mb="8px"
              />
              <Text fontSize="1.2em" fontWeight="500">Downloading and Preparing Files...</Text>
              <Button
                type="cancel"
                onClick={() => { onCloseDownloadModal(); cancelDownload(); }}
                display="block"
                borderRadius="24px"
                mt="16px"
                mr="auto"
                ml="auto"
                width="200px"
                color="white"
                bg="#FF1A75"
                _hover={{
                  bg: '#FFE6EE',
                  color: '#FF1A75',
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {
          /*
      <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Image Properties</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderDownloadForm()}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p1>Are you sure you want to Delete this image?</p1>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseDeleteModal}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={deleteImage}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      */
    }
    </Box>
  );
}
DownloadModal.propTypes = propTypes;
export default DownloadModal;
