/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import {
  Avatar,
  Button,
  Heading,
  Flex,
  Spinner,
  Stack,
  Box,
  SimpleGrid,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Divider,

} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { compress, compressAccurately } from 'image-conversion';
import axios from 'axios';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import { Videos } from '@styled-icons/boxicons-solid/Videos';
import { Images } from '@styled-icons/bootstrap/Images';
import { PhotoAlbum } from '@styled-icons/boxicons-solid/PhotoAlbum';
import ProfileLibrary from './sections/ProfileLibrary';
import EmptyLibrary from './sections/EmptyLibrary';
import ProfileAlbum from './sections/ProfileAlbum';
import Albums from './sections/Albums';

const StyledTab = styled(Tab)`
  margin: 0;
  &:focus {
    outline:0 !important;
    box-shadow: none;
    border: none border-color: #FFF !important;
  }
`;
const VideoButton = styled(Videos)`
  color: ##1890ff;
  width: 36px;
`;

const ImageButton = styled(Images)`
  color: ##1890ff;
  width: 36px;
`;
const AlbumButton = styled(PhotoAlbum)`
  color: ##1890ff;
  width: 36px;
`;
const baseDropzoneStyle = {
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: '50%',
  borderColor: '#EEEEEE',
  padding: '2px',
  cursor: 'pointer',
};

const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});
const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      profileName: PropTypes.string,
    }),
  }).isRequired,
};
function ProfilePage2({
  match,
}) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const { profileName } = match.params;
  const [avatarImg, setAvatarImg] = useState();
  const [profile, setProfile] = useState({});
  const [profileLoading, setProfileLoading] = useState(true);
  const [deleteImage, setDeleteImage] = useState();
  const [errorMessage, setFormErrorMessage] = useState();
  const [albumsPerPage, setAlbumsPerPage] = useState(4);
  const [initializedAlbums, setInitializedAlbums] = useState();
  const [albums, setAlbums] = useState([]);
  const [lastAlbumPage, setLastAlbumPage] = useState(false);
  const [loadingAlbums, setLoadingAlbums] = useState(true);
  const [albumsPage, setAlbumePage] = useState(0);
  const [emptyProfile, setEmptyProfile] = useState(true);

  const getAlbums = (profileId) => {
    axios({
      method: 'POST',
      url: '/api/image/album-by-profile',
      data: {
        profileId,
      },
    })
      .then((data) => {
        if (!initializedAlbums) {
          setInitializedAlbums(true);
        }
        // console.log(data.data.albums);
        setAlbums((data.data.albums));
        if (data.data.albums.length === 0) {
          setLastAlbumPage(true);
          setLoadingAlbums(false);
          return;
        }

        setAlbumePage(albumsPage + 1);
        setLoadingAlbums(false);
      })
      .catch((err) => {
        // console.log(err);
        let errMsg;

        switch (err?.response?.status) {
          case 403:
            errMsg = 'You do Not have access to these images';
            break;
          case 500:
            errMsg = 'Server is currently down';
            break;
          default:
            errMsg = 'Something has went wrong';
            break;
        }
        setFormErrorMessage(errMsg);
        setTimeout(() => {
          setFormErrorMessage('');
        }, 3000);
      });
  };
  const fetchProfile = () => {
    axios({
      method: 'POST',
      url: '/api/profile/get-posts-profile',
      data: {
        username: profileName,
      },
    })
      .then((data) => {
        // console.log(`profile data: ${data.data.posts}`);
        setProfile(data.data.profile);
        setDeleteImage(data.data.profile.image.split('/').pop());
        setProfileLoading(false);
        if (data.data.profile.posts.length <= 0) {
          setEmptyProfile(true);
        } else {
          setEmptyProfile(false);
        }
        window.scrollTo(0, 0);
        getAlbums(data.data.profile._id);
      })
      .catch((err) => {
        setFormErrorMessage('Cannot fetch user profile');
      });
  };

  useEffect(() => {
    // console.log("running");
    if (profileName) {
      fetchProfile();
    }
  }, [profileName]);

  if (!user.userData) {
    return (
      <Flex className="app" mt="128px">
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="#FF1A75"
          size="xl"
        />
      </Flex>
    );
  }
  return (
    <Flex
      marginTop="4rem"
      minHeight="90vh"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      {profileLoading
        ? (
          <Box>
            <Spinner
              thickness="4px"
              emptyColor="gray.200"
              color="#FF1A75"
              size="xl"
              mb="8px"
            />
            <Text>Updating</Text>
          </Box>
        )
        : (
          <Flex className="app">
            <Box
              as="img"
              crossOrigin="anonymous"
              src={avatarImg?.preview ?? profile?.image}
              width="125px"
              height="125px"
              objectFit="cover"
              borderRadius="50%"
              verticalAlign="middle"
            />
            <Heading fontWeight="300">{profile?.username}</Heading>
          </Flex>
        )}
      <Flex
        width="90%"
        marginX="auto"
      >
        {emptyProfile
          ? <EmptyLibrary />
          : (
            <Box
              width={{
                base: '100%',
              }}
              margin="auto"
            >
              <ProfileLibrary
                key={`post_${profile._id}`}
                posts={profile.posts}
                profile={profile}
              />
            </Box>
          )}
      </Flex>
      <Flex
        width="90%"
        marginX="auto"
      >
        {loadingAlbums
          ? <Box />
          : (
            <Box
              width={{
                base: '100%',
              }}
              margin="auto"
            >
              <ProfileAlbum
                key={`post_${profile._id}`}
                albums={albums}
                profile={profile}
              />
            </Box>
          )}
      </Flex>
    </Flex>
  );
}
ProfilePage2.propTypes = propTypes;
export default ProfilePage2;
