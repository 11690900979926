/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import styled from 'styled-components';
import {
  Box,
  Flex,
  Avatar,
  Text,
  List,
  SimpleGrid,
  AspectRatio,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  Button,
  Heading,
  Input,
  Textarea,
  Stack,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  ModalHeader,
  ModalFooter,
} from '@chakra-ui/react';

const StyledTab = styled(Tab)`
  margin: 0;
  &:focus {
    outline:0 !important;
    box-shadow: none;
    border: none;
    border-color: #FFF !important;
  }
`;

const propTypes = {
  groupData: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isMember: PropTypes.bool.isRequired,
  isRequester: PropTypes.bool.isRequired,
};
const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  familyPostGrid: {
    display: 'flex',
    overflowX: 'scroll',
    gridGap: '1em',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  buttonStyle: {
    justifyContent: 'center',
    flexDir: 'column',
    backgroundColor: '#2C6DAD',
    color: '#FFF',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px',
    textAlign: 'center',
    fontSize: '1.3em',
    fontWeight: '600',
    outline: '0',
    height: '50px',
  },
});
function GroupMembers({
  groupData,
  profile,
  isMember,
  isAdmin,
  isRequester,
}) {
  const {
    isOpen: isMembersOpen,
    onOpen: onMembersOpen,
    onClose: onMembersClose,
  } = useDisclosure();
  const {
    isOpen: isInvitessOpen,
    onOpen: onInvitesOpen,
    onClose: onInvitesClose,
  } = useDisclosure();
  const {
    isOpen: isGroupRemoveOpen,
    onOpen: onGroupRemoveOpen,
    onClose: onGroupRemoveClose,
  } = useDisclosure();
  const {
    isOpen: isAdminRemoveOpen,
    onOpen: onAdminRemoveOpen,
    onClose: onAdminRemoveClose,
  } = useDisclosure();
  const {
    isOpen: isGroupKickOpen,
    onOpen: onGroupKickOpen,
    onClose: onGroupKickClose,
  } = useDisclosure();
  const {
    isOpen: isGroupAdminOpen,
    onOpen: onGroupAdminOpen,
    onClose: onGroupAdminClose,
  } = useDisclosure();
  const [bannerError, setBannerError] = useState();
  const [bannerimage, setBannerImage] = useState();
  const [imageList, setImageList] = useState([]);
  const [imageListRetrieved, setImageListRetrieved] = useState(false);
  const [selectedImage, setSeletedImage] = useState();
  const [imageObject, setImageObject] = useState();
  const [inviteFormLoaded, setInviteFormLoaded] = useState(true);
  const [membersLoaded, setMembersLoaded] = useState(false);
  const [searchResultsLoaded, setSearchResultsLoaded] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [displayMemberList, setDisplayMemberList] = useState([]);
  const [invitedIdList, setInvitedIdList] = useState([]);
  const [invitedObjectList, setInvitedObjectList] = useState([]);
  const [doNotInviteList, setDoNotInviteList] = useState([]);
  const [members, setMembers] = useState([]);
  const [requester, setRequester] = useState(false);
  const [memberModalLoaded, setMemberModalLoaded] = useState(true);
  const [requestFormLoaded, setRequestFormLoaded] = useState(true);
  const [requestsLoaded, setRequestsLoaded] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [requestList, setRequestList] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (groupData.owner._id === profile._id) {
      setIsOwner(true);
    }
    setBannerImage(groupData.banner);
    setMembers(groupData.members);
    setRequestList(groupData.requests);
    setInvitedObjectList(groupData.invited);
    setMembersLoaded(true);
    setRequester(isRequester);
    setRequestsLoaded(true);
  }, [groupData, isRequester]);

  const setInviteForm = () => {
    setSearchResultsLoaded(false);
    setSearchResults([]);
    setDisplayMemberList([]);
    setInvitedIdList([]);
    const alreadyMembers = groupData.members.concat(groupData.admins);
    const allMembers = alreadyMembers.concat(groupData.invited);
    setDoNotInviteList(allMembers);
    setSearchResultsLoaded(true);
  };

  const onFamilySearch = (event) => {
    // console.log('I keep getting called too');
    const recipientList = [];
    doNotInviteList.forEach((user) => {
      recipientList.push(user._id);
    });
    // console.log(doNotInviteList);
    axios({
      method: 'POST',
      url: '/api/groups/search-profiles',
      data: {
        query: String(event.target.value),
        recipientIds: recipientList,
        groupId: groupData._id,
        profileId: profile._id,
      },
    })
      .then((response) => {
        const profileResults = [].concat(response.data.profiles);
        setSearchResults(profileResults);
        // console.log(profileResults);
        setSearchResultsLoaded(true);
        // document.getElementById('family-results').style.display = 'block';
        // console.log('what');
      })
      .catch((profErr) => {
        setSearchResultsLoaded(profErr);
      });
  };
  const removeRecipient = (currentProfile) => {
    const newRecipientList = [];
    const newMemberList = [];
    const newDoNotInviteList = [];
    displayMemberList.forEach((recipient) => {
      if (recipient._id !== currentProfile._id) {
        newRecipientList.push(recipient);
        newMemberList.push(recipient._id);
        newDoNotInviteList.push(recipient);
      }
    });
    setDoNotInviteList(newDoNotInviteList);
    setInvitedIdList(newMemberList);
    setDisplayMemberList(newRecipientList);
    // console.log(newRecipientList);
  };
  const addRecipient = (newProfile) => {
    const newRecipientList = displayMemberList;
    const newMemberList = invitedIdList;
    const removeInviteUpdate = doNotInviteList;
    removeInviteUpdate.unshift(newProfile);
    newRecipientList.push(newProfile);
    newMemberList.push(newProfile._id);
    setDisplayMemberList(newRecipientList);
    setInvitedIdList(newMemberList);
    setDoNotInviteList(removeInviteUpdate);
    document.getElementById('input-search').value = '';
    setSearchResultsLoaded(false);
  };
  const handleSubmit = () => {
    if (invitedIdList.length === 0) return;
    setInviteFormLoaded(false);
    axios({
      method: 'PUT',
      url: '/api/groups/update-group-invite',
      data: {
        invitedIdList,
        profile,
        groupId: groupData._id,
      },
    })
      .then((res) => {
        // console.log(`/group/${res.data.savedGroup._id}`);
        const updatedInviteList = displayMemberList.concat(invitedObjectList);
        setInvitedObjectList(updatedInviteList);
        setDisplayMemberList([]);
        setInviteFormLoaded(true);
        alert('Invites have been successfully sent!');
      })
      .catch((err) => {
        setBannerError(err);
      });
  };
  const requestMembership = () => {
    setRequestsLoaded(false);
    axios({
      method: 'PUT',
      url: '/api/groups/request-group-membership',
      data: {
        profileId: profile._id,
        groupId: groupData._id,
      },
    })
      .then((res) => {
        setRequester(res.data.isRequesting);
        setRequestsLoaded(true);
        window.location.reload();
      })
      .catch((err) => {
        setBannerError(err);
      });
  };
  const acceptRequestMembership = (userProfile) => {
    setRequestsLoaded(false);
    setRequestFormLoaded(false);
    axios({
      method: 'PUT',
      url: '/api/groups/accept-group-request',
      data: {
        profileId: userProfile._id,
        groupId: groupData._id,
      },
    })
      .then((res) => {
        setRequester(res.data.isRequesting);
        const updateRequests = [];
        requestList.forEach((request) => {
          if (request._id !== userProfile._id) {
            updateRequests.push(request);
          }
        });
        const updateMembers = members;
        updateMembers.unshift(userProfile);
        setMembers(updateMembers);
        setRequestList(updateRequests);
        setRequestFormLoaded(true);
        setRequestsLoaded(true);
      })
      .catch((err) => {
        setBannerError(err);
      });
  };
  const rejectRequestMembership = (userProfile) => {
    setRequestsLoaded(false);
    setRequestFormLoaded(false);
    axios({
      method: 'PUT',
      url: '/api/groups/cancel-group-request',
      data: {
        profileId: userProfile._id,
        groupId: groupData._id,
      },
    })
      .then((res) => {
        setRequester(res.data.isRequesting);
        const updateRequests = [];
        requestList.forEach((request) => {
          if (request._id !== userProfile._id) {
            updateRequests.push(request);
          }
        });
        setRequestList(updateRequests);
        setRequestsLoaded(true);
        setRequestFormLoaded(true);
      })
      .catch((err) => {
        setBannerError(err);
      });
  };
  const cancelRequestMembership = () => {
    setRequestsLoaded(false);
    axios({
      method: 'PUT',
      url: '/api/groups/cancel-group-request',
      data: {
        profileId: profile._id,
        groupId: groupData._id,
      },
    })
      .then((res) => {
        setRequester(res.data.isRequesting);
        setRequestsLoaded(true);
        window.location.reload();
      })
      .catch((err) => {
        setBannerError(err);
      });
  };
  const removeFromGroup = (currentProfile) => {
    setRequestsLoaded(false);
    setMemberModalLoaded(false);
    axios({
      method: 'PUT',
      url: '/api/groups/remove-group-membership',
      data: {
        profileId: currentProfile._id,
        groupId: groupData._id,
      },
    })
      .then(() => {
        const updatedMembers = [];
        members.forEach((member) => {
          if (member._id !== currentProfile._id) {
            updatedMembers.push(member);
          }
        });
        setMembers(updatedMembers);
        setMemberModalLoaded(true);
        // window.location.reload();
      })
      .catch((err) => {
        setBannerError(err);
      });
  };
  const promoteToAdmin = (potentialAdmin) => {
    axios({
      method: 'PUT',
      url: '/api/groups/give-admin-auth',
      data: {
        profileId: potentialAdmin._id,
        groupId: groupData._id,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setBannerError(err);
      });
  };
  const demoteAdmin = (targetedAdmin) => {
    axios({
      method: 'PUT',
      url: '/api/groups/lose-admin-auth',
      data: {
        profileId: targetedAdmin._id,
        groupId: groupData._id,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setBannerError(err);
      });
  };
  const loadSearchResults = () => searchResults.map((familyProfile, index) => (
    <Box>
      <Flex
        margin="8px"
        cursor="pointer"
        onClick={() => { addRecipient(familyProfile); }}
      >
        <Box
          as="img"
          marginRight="8px"
          marginTop="4px"
          crossOrigin="anonymous"
          src={familyProfile.image}
          width="42px"
          height="42px"
          objectFit="cover"
          borderRadius="50%"
          verticalAlign="middle"
          alignItems="center"
        />
        <Box>
          <Text
            color="#34495E"
            fontWeight="500"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            marginTop="4px"
          >
            {familyProfile.username}
          </Text>
          <Text
            color="#34495E"
            fontWeight="400"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {familyProfile.email}
          </Text>
        </Box>
      </Flex>
    </Box>
  ));
  const renderInvited = () => invitedObjectList.map((invite, index) => (
    <Box>
      <Flex
        margin="8px"
        cursor="pointer"
      >
        <Box
          as="img"
          marginRight="8px"
          marginTop="4px"
          crossOrigin="anonymous"
          src={invite.image}
          width="42px"
          height="42px"
          objectFit="cover"
          borderRadius="50%"
          verticalAlign="middle"
          alignItems="center"
        />
        <Box>
          <Text
            color="#34495E"
            fontWeight="500"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            marginTop="4px"
          >
            {invite.username}
          </Text>
          <Text
            color="#34495E"
            fontWeight="400"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {invite.email}
          </Text>
        </Box>
      </Flex>
    </Box>
  ));
  const renderRequest = () => requestList.map((request, index) => (
    <Box>
      <Flex
        margin="8px"
        cursor="pointer"
      >
        <Box
          as="img"
          marginRight="8px"
          marginTop="4px"
          crossOrigin="anonymous"
          src={request.image}
          width="42px"
          height="42px"
          objectFit="cover"
          borderRadius="50%"
          verticalAlign="middle"
          alignItems="center"
        />
        <Box>
          <Text
            color="#34495E"
            fontWeight="500"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            marginTop="4px"
          >
            {request.username}
          </Text>
          <Text
            color="#34495E"
            fontWeight="400"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {request.email}
          </Text>
          <Stack className={classes.friendCardButtonsContainer} isInline>
            <Button
              onClick={() => acceptRequestMembership(request)}
              display="block"
              width="110px"
              color="white"
              borderRadius="24px"
              bg="#FF1A75"
              _hover={{
                bg: '#FFE6EE',
                color: '#FF1A75',
              }}
            >
              Accept
            </Button>
            <Button
              onClick={() => rejectRequestMembership(request)}
              isLoading={false}
              variant="outline"
              display="block"
              width="110px"
              borderRadius="24px"
              color="#FF1A75"
              borderColor="#FF1A75"
              _hover={{
                bg: '#FFE6EE',
                color: '#FF1A75',
              }}
            >
              Decline
            </Button>
          </Stack>
        </Box>
      </Flex>
    </Box>
  ));
  const renderInviteForm = () => (
    <Box>
      <Box
        borderRadius="5px"
      >
        <Input
          id="input-search"
          onChange={onFamilySearch}
          placeholder="Search by Username or Email"
        />
      </Box>
      <Box
        marginY="0.5rem"
        height="100px"
        overflowY="scroll"
        width={{ base: '100%' }}
      >
        {displayMemberList.length > 0
          ? (
            <Flex
              flexWrap="wrap"
            >
              { displayMemberList.map((recpient) => (
                <Box
                  margin="5px"
                  id={`username${recpient._id}`}
                  backgroundColor="#DDD"
                >
                  <Flex>
                    <Box>
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        marginLeft="0.5rem"
                      >
                        <Text
                          color="#000"
                          fontWeight="500"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {recpient.username}
                        </Text>
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        marginX="0.5rem"
                      >
                        <Text
                          as="span"
                          fontSize="1.3rem"
                          cursor="pointer"
                          onClick={() => { removeRecipient(recpient); }}
                        >
                          &times;
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              ))}
            </Flex>
          ) : <Box />}
      </Box>
      <Box
        id="search-results"
        height="300px"
        width="100%"
        marginY="1rem"
        overflowY={{ base: 'none', md: 'auto' }}
      >
        {searchResultsLoaded
          ? (
            <Box>
              {loadSearchResults()}
            </Box>
          ) : <Box />}
      </Box>
      <Button
        width={{ base: '90%', md: '150px' }}
        backgroundColor="#2C6DAD"
        color="#FFF"
        borderRadius="50px"
        _hover={{ bg: '#DDD', color: '#2C6DAD' }}
        cursor="pointer"
        boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
        textAlign="center"
        fontSize="1.3em"
        fontWeight="600"
        outline="0"
        loadingText="Publishing..."
        onClick={handleSubmit}
      >
        <Text>Submit</Text>
      </Button>
    </Box>
  );

  return (
    <Box>
      <Box>
        {groupData.members
          ? (
            <Flex
              justifyContent="center"
              width={{
                base: '100%', sm: '100%', md: '100%', lg: '770px', xl: '1200px',
              }}
            >
              {isMember || isAdmin
                ? (
                  <Box
                    color="#FFF"
                    padding="10px 16px"
                    cursor="pointer"
                    height="60px"
                  >
                    <Text
                      textAlign="center"
                      fontWeight="600"
                      fontSize={{ base: '1.2rem' }}
                      color="#0B67C2"
                      cursor="pointer"
                      onClick={() => { onMembersOpen(); }}
                    >
                      {members.length + groupData.admins.length} members
                    </Text>
                  </Box>
                ) : (
                  <Box>
                    { requestsLoaded
                      ? (
                        <Box>
                          {isRequester ? (
                            <Box
                              color="#FFF"
                              padding="10px 16px"
                              cursor="pointer"
                            >
                              <Button
                                width={{ base: '250px' }}
                                backgroundColor="#2C6DAD"
                                color="#FFF"
                                borderRadius="50px"
                                _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                                cursor="pointer"
                                boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                                textAlign="center"
                                fontSize="1.3em"
                                fontWeight="600"
                                outline="0"
                                onClick={() => { cancelRequestMembership(); }}
                              >
                                Cancel Request
                              </Button>
                            </Box>
                          ) : (
                            <Box
                              color="#FFF"
                              padding="10px 16px"
                              cursor="pointer"
                            >
                              <Button
                                width={{ base: '90%', md: '250px' }}
                                backgroundColor="#2C6DAD"
                                color="#FFF"
                                borderRadius="50px"
                                _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                                cursor="pointer"
                                boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                                textAlign="center"
                                fontSize="1.3em"
                                fontWeight="600"
                                outline="0"
                                onClick={() => { requestMembership(); }}
                              >
                                Request Membership
                              </Button>
                            </Box>
                          )}
                        </Box>
                      ) : <Box />}
                  </Box>
                )}
              {
                isMember && !isAdmin ? (
                  <Box>
                    <Menu>
                      <MenuButton
                        width={{ base: '100px' }}
                        backgroundColor="#2C6DAD"
                        color="#FFF"
                        borderRadius="5px"
                        _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                        cursor="pointer"
                        boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                        textAlign="center"
                        fontSize="1.3em"
                        fontWeight="600"
                        outline="0"
                        height="50px"
                      >
                        <Text
                          color="inherit"
                          fontSize="sm"
                          cursor="pointer"
                          textAlign="center"
                        >
                          Settings
                        </Text>
                      </MenuButton>
                      <MenuList
                        placement="bottom-start"
                        zIndex="9999"
                        backgroundColor="#EEE"
                        width="125px"
                      >
                        <MenuItem onClick={onGroupRemoveOpen}>
                          <Text
                            fontWeight="500"
                            fontSize="1rem"
                            padding="12px"
                          >
                            Leave Group
                          </Text>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    <Modal isOpen={isGroupRemoveOpen} onClose={onGroupRemoveClose}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Leave Group</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <p1>Are you sure you want to Leave this Group?</p1>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            colorScheme="blue"
                            width={{ base: '100px' }}
                            bg="#2C6DAD"
                            color="#DDD"
                            _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                            boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                            cursor="pointer"
                            outline="0"
                            borderRadius="50px"
                            marginX="5px"
                            mr={3}
                            onClick={onGroupRemoveClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            colorScheme="blue"
                            width={{ base: '100px' }}
                            bg="#2C6DAD"
                            color="#DDD"
                            _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                            boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                            cursor="pointer"
                            outline="0"
                            borderRadius="50px"
                            marginX="5px"
                            onClick={() => { removeFromGroup(profile); }}
                          >
                            Leave
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </Box>
                ) : <Box />
              }
              {isAdmin
                ? (
                  <Button
                    justifyContent="center"
                    flexDir="column"
                    width={{ base: '100px' }}
                    backgroundColor="#2C6DAD"
                    color="#FFF"
                    borderRadius="5px"
                    _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                    cursor="pointer"
                    boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                    textAlign="center"
                    fontSize="1.3em"
                    fontWeight="600"
                    outline="0"
                    height="50px"
                    onClick={() => { setInviteForm(); onInvitesOpen(); }}
                  >
                    <Text
                      color="inherit"
                      fontSize="1em"
                      cursor="pointer"
                      textAlign="center"
                    >
                      Invite +
                    </Text>

                  </Button>
                ) : <Box />}
            </Flex>
          ) : (
            <Box />
          )}
      </Box>
      <Box>
        <Modal isOpen={isMembersOpen} onClose={onMembersClose} width={{ base: '90%', md: '25%' }}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Box marginY="3rem">
                  <Text
                    textAlign="center"
                    fontWeight="600"
                    fontSize={{ base: '1.2rem' }}
                    color="#0B67C2"
                  >
                    Owner
                  </Text>
                  <Flex>
                    <Box
                      as="img"
                      marginRight="8px"
                      crossOrigin="anonymous"
                      src={groupData.owner.image}
                      width="32px"
                      height="32px"
                      objectFit="cover"
                      borderRadius="50%"
                      verticalAlign="middle"
                      alignItems="center"
                    />
                    <Text marginTop="5px" fontWeight="500">{groupData.owner.username}</Text>
                  </Flex>

                </Box>
                <Box marginY="3rem">
                  <Text
                    textAlign="center"
                    fontWeight="600"
                    fontSize={{ base: '1.2rem' }}
                    color="#0B67C2"
                  >
                    Admins
                  </Text>
                  {groupData.admins.map((member) => (
                    <Flex marginY="1rem" justifyContent="space-between">
                      <Box>
                        <Flex>
                          <Box
                            as="img"
                            marginRight="8px"
                            crossOrigin="anonymous"
                            src={member.image}
                            width="32px"
                            height="32px"
                            objectFit="cover"
                            borderRadius="50%"
                            verticalAlign="middle"
                            alignItems="center"
                          />
                          <Text marginTop="5px" fontWeight="500">{member.username}</Text>
                        </Flex>
                      </Box>
                      {isOwner && member._id !== profile._id
                        ? (
                          <Button
                            width={{ base: '100px' }}
                            color="#2C6DAD"
                            _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                            boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                            cursor="pointer"
                            outline="0"
                            borderRadius="50px"
                            onClick={() => { demoteAdmin(member); }}
                          >
                            <Text
                              textAlign="center"
                              fontSize="1.3em"
                              fontWeight="600"
                            >
                              Demote
                            </Text>
                          </Button>
                        ) : <Box />}
                    </Flex>
                  ))}
                </Box>
                <Box marginY="3rem">
                  <Text
                    textAlign="center"
                    fontWeight="600"
                    fontSize={{ base: '1.2rem' }}
                    color="#0B67C2"
                  >
                    Members
                  </Text>
                  { memberModalLoaded
                    ? (
                      <Box>
                        {members.map((member) => (
                          <Flex justifyContent="space-between" marginY="1rem">
                            <Box>
                              <Flex>
                                <Box
                                  as="img"
                                  marginRight="8px"
                                  crossOrigin="anonymous"
                                  src={member.image}
                                  width="32px"
                                  height="32px"
                                  objectFit="cover"
                                  borderRadius="50%"
                                  verticalAlign="middle"
                                  alignItems="center"
                                />
                                <Text marginTop="5px" fontWeight="500">{member.username}</Text>
                              </Flex>
                            </Box>
                            { isAdmin
                              ? (
                                <Box>
                                  <Flex>
                                    <Button
                                      width={{ base: '100px' }}
                                      bg="#2C6DAD"
                                      color="#DDD"
                                      _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                                      boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                                      cursor="pointer"
                                      outline="0"
                                      borderRadius="50px"
                                      marginX="5px"
                                      onClick={() => { onGroupAdminOpen(); }}
                                    >
                                      <Text
                                        textAlign="center"
                                        fontSize="1.1em"
                                        fontWeight="600"
                                      >
                                        Admin
                                      </Text>
                                    </Button>
                                    <Button
                                      width={{ base: '100px' }}
                                      bg="#2C6DAD"
                                      color="#DDD"
                                      _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                                      boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                                      cursor="pointer"
                                      outline="0"
                                      borderRadius="50px"
                                      marginX="5px"
                                      onClick={() => { onGroupKickOpen(); }}
                                    >
                                      <Text
                                        textAlign="center"
                                        fontSize="1.1em"
                                        fontWeight="600"
                                      >
                                        Remove
                                      </Text>
                                    </Button>
                                  </Flex>
                                </Box>
                              ) : <Box />}
                            <Modal isOpen={isGroupKickOpen} onClose={onGroupKickClose}>
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader>Remove User</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                  <Text>
                                    Are you sure you want to remove {member.username} from this Group?
                                  </Text>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    colorScheme="blue"
                                    width={{ base: '100px' }}
                                    bg="#2C6DAD"
                                    color="#DDD"
                                    _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                                    boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                                    cursor="pointer"
                                    outline="0"
                                    borderRadius="50px"
                                    marginX="5px"
                                    mr={3}
                                    onClick={onGroupKickClose}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    colorScheme="blue"
                                    width={{ base: '100px' }}
                                    bg="#2C6DAD"
                                    color="#DDD"
                                    _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                                    boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                                    cursor="pointer"
                                    outline="0"
                                    borderRadius="50px"
                                    marginX="5px"
                                    onClick={() => { removeFromGroup(member); }}
                                  >
                                    Remove
                                  </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal>
                            <Modal isOpen={isGroupAdminOpen} onClose={onGroupAdminClose}>
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader>Admin Status</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                  <Text>
                                    Are you sure you want to give {member.username} admin status?
                                  </Text>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    colorScheme="blue"
                                    width={{ base: '100px' }}
                                    bg="#2C6DAD"
                                    color="#DDD"
                                    _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                                    boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                                    cursor="pointer"
                                    outline="0"
                                    borderRadius="50px"
                                    marginX="5px"
                                    mr={3}
                                    onClick={onGroupAdminClose}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    colorScheme="blue"
                                    width={{ base: '100px' }}
                                    bg="#2C6DAD"
                                    color="#DDD"
                                    _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                                    boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                                    cursor="pointer"
                                    outline="0"
                                    borderRadius="50px"
                                    marginX="5px"
                                    onClick={() => { promoteToAdmin(member); }}
                                  >
                                    Confirm
                                  </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal>
                            <Modal isOpen={isAdminRemoveOpen} onClose={onAdminRemoveClose}>
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader>Admin Status</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                  <Text>
                                    Are you sure you want to remove {member.username}`s admin status?
                                  </Text>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    colorScheme="blue"
                                    width={{ base: '100px' }}
                                    bg="#2C6DAD"
                                    color="#DDD"
                                    _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                                    boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                                    cursor="pointer"
                                    outline="0"
                                    borderRadius="50px"
                                    marginX="5px"
                                    mr={3}
                                    onClick={onAdminRemoveClose}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    colorScheme="blue"
                                    width={{ base: '100px' }}
                                    bg="#2C6DAD"
                                    color="#DDD"
                                    _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                                    boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                                    cursor="pointer"
                                    outline="0"
                                    borderRadius="50px"
                                    marginX="5px"
                                    onClick={() => { demoteAdmin(member); }}
                                  >
                                    Confirm
                                  </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal>
                          </Flex>
                        ))}
                      </Box>
                    ) : <Box />}
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
      <Box>
        <Modal isOpen={isInvitessOpen} onClose={onInvitesClose} size={{ base: '90%', md: '25%' }}>
          <ModalOverlay />
          <ModalContent width={{ base: '90%', md: '25%' }}>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Tabs>
                  <TabList>
                    <StyledTab>
                      <Box>
                        <Text
                          fontWeight="500"
                          fontSize="1.1rem"
                        >
                          Add Invite
                        </Text>
                      </Box>
                    </StyledTab>
                    <StyledTab>
                      <Box>
                        <Text
                          fontWeight="500"
                          fontSize="1.1rem"
                        >
                          Invited
                        </Text>
                      </Box>
                    </StyledTab>
                    <StyledTab>
                      <Box>
                        <Text
                          fontWeight="500"
                          fontSize="1.1rem"
                        >
                          Requests
                        </Text>
                      </Box>
                    </StyledTab>
                  </TabList>
                  <TabPanels>
                    <TabPanel
                      minHeight="45vh"
                      className={classes.pageView}
                    >
                      {inviteFormLoaded
                        ? (
                          <Box>
                            {renderInviteForm()}
                          </Box>
                        ) : <Box />}
                    </TabPanel>
                    <TabPanel
                      minHeight="45vh"
                      className={classes.pageView}
                    >
                      <Box>
                        {renderInvited()}
                      </Box>
                    </TabPanel>
                    <TabPanel
                      minHeight="45vh"
                      className={classes.pageView}
                    >
                      {requestFormLoaded
                        ? (
                          <Box>
                            {renderRequest()}
                          </Box>
                        ) : <Box />}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}
GroupMembers.propTypes = propTypes;
export default GroupMembers;
