/* eslint-disable react/forbid-prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { createUseStyles } from 'react-jss';
import { FaCheckCircle } from 'react-icons/fa';

const PriceWrapperPropTypes = {
  children: PropTypes.object.isRequired,
};
const PricePropTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius="xl"
    >
      {children}
    </Box>
  );
}

function Pricing({
  history,
}) {
  const goRegister = () => {
    history.push('/register');
  };
  return (
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Plans that fit your need
        </Heading>
        <Text fontSize="lg" color="gray.500">
          No credit card needed. Cancel at anytime.
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              On The House
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="5xl" fontWeight="900">
                Free
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius="xl"
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                unlimited views of shared files
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                upload your treasured memories
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                2GB of free space
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button w="full" bg="#3480E3" variant="outline" color="#ffff" onClick={() => { goRegister(); }}>
                Start Kloaking For Free
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}
            />
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                500GB of Storage
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  5.95
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius="xl"
            >
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  unlimited views of shared files
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  single download of shared treasures
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  mass upload of photos and videos
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button w="full" bg="#3480E3" variant="outline" color="#ffff" onClick={() => { goRegister(); }}>
                  Start Kloaking Your Memories
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              1TB of Storage
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                9.95
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius="xl"
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                unlimited views of shared files
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                mass download of shared treasures
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                mass upload of photos and videos
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button w="full" bg="#3480E3" variant="outline" color="#ffff" onClick={() => { goRegister(); }}>
                Start Kloaking Your Memories
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>
    </Box>
  );
}
PriceWrapper.propTypes = PriceWrapperPropTypes;
Pricing.propTypes = PricePropTypes;
export default withRouter(Pricing);
