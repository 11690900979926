/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Stack,
  Text,
  Box,
  Input,
} from '@chakra-ui/react';

const useStyles = createUseStyles({
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
  profileCard: {
    paddingY: '2rem',
    paddingX: '16px',
    marginTop: '16px',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',

  },
});

function ForgotPasswordPage() {
  const classes = useStyles();

  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [foundUser, setFoundUser] = useState();
  const [sendingPasswordReset, setSendingPasswordReset] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const sendPasswordReset = async () => {
    if (!foundUser.username) {
      return;
    }
    setSendingPasswordReset(true);
    try {
      const res = await axios.post('/api/users/send-password-reset', { username: foundUser.username });
      if (res.data.success) {
        setResetEmailSent(true);
      }
    } catch (err) {
      // TODO: Handle error
    } finally {
      setSendingPasswordReset(false);
    }
  };

  const renderProfileCard = () => (
    <Flex
      paddingY="2rem"
      paddingX="16px"
      className={classes.profileCard}
      width={{ base: '94%', md: '460px' }}
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack isInline>
        <Avatar src={foundUser.image} />
        <Stack spacing={1}>
          <Text fontWeight="bold" m="0">{foundUser.username}</Text>
          <Text m="0">{foundUser.email}</Text>
        </Stack>
      </Stack>
      <Button
        onClick={sendPasswordReset}
        isLoading={sendingPasswordReset}
        loadingText="Submitting..."
        display="block"
        width="160px"
        color="white"
        borderRadius="24px"
        bg="#FF1A75"
        _hover={{
          bg: '#FFE6EE',
          color: '#FF1A75',
        }}
        marginTop={{ base: '1rem', md: '0' }}
      >
        Reset Password
      </Button>
    </Flex>
  );

  if (resetEmailSent) {
    return (
      <Box className="app" width="100%" minHeight="90vh" paddingX={{ base: '1rem', md: '0' }}>
        <Icon name="email" size="64px" color="#FF1A75" />
        <Heading fontWeight="350" fontSize={{ base: '1.4rem', md: '1.87rem' }} marginTop="2rem">A link to reset your password has been sent to your email.</Heading>
        <Text fontSize="lg" fontWeight="350" marginTop="2rem">You&apos;ll have 24 hours to pick your new password.</Text>
      </Box>
    );
  }

  return (
    <Box paddingY="6rem" minHeight="90vh" width="100%">
      <Formik
        initialValues={{
          query: '',
        }}
        validationSchema={Yup.object().shape({
          query: Yup.string()
            .required('Email or username is required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(async () => {
            try {
              const dataToSubmit = {
                query: values.query,
              };
              const res = await axios.post('/api/users/find-account', dataToSubmit);
              if (res.data.success) {
                setFoundUser(res.data.user);
              }
            } catch (err) {
              const errMsg = err.response.status === 404
                ? 'Check that your email address or username is correct'
                : 'Something went wrong';
              setFormErrorMessage(errMsg);
              setFoundUser(undefined);
              setTimeout(() => {
                setFormErrorMessage('');
              }, 3000);
            }
            setSubmitting(false);
          }, 500);
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Flex className="app" width="100%">
              <Heading fontSize={{ base: '1.4rem', md: '1.87rem' }} fontWeight="400" marginY="2rem">Find your Kloak account</Heading>
              <Box onSubmit={handleSubmit} width={{ base: '94%', md: '460px' }}>
                <Field name="query">
                  {() => (
                    <FormControl
                      isRequired
                      isInvalid={errors.query && touched.query}
                      mb="4px"
                    >
                      <FormLabel htmlFor="query" ml="4px" fontWeight="300">
                        Email address or username
                      </FormLabel>
                      <br />
                      <Input
                        id="query"
                        placeholder="Enter your email address or username"
                        type="text"
                        value={values.query}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="auto"
                      />
                      <div className={classes.formErrorMessage}>
                        {touched.query && errors.query}
                      </div>
                    </FormControl>
                  )}
                </Field>
                {formErrorMessage && (
                <label>
                  <p
                    style={{
                      color: '#ff0000bf',
                      fontSize: '0.7rem',
                      border: '1px solid',
                      padding: '1rem',
                      borderRadius: '10px',
                    }}
                  >
                    {formErrorMessage}
                  </p>
                </label>
                )}
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Submitting..."
                  onClick={handleSubmit}
                  display="block"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="auto"
                  width="200px"
                  color="white"
                  borderRadius="8px"
                  bg="#3480E3"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Find Account
                </Button>
              </Box>
              {foundUser && renderProfileCard()}
            </Flex>
          );
        }}
      </Formik>
    </Box>
  );
}

export default ForgotPasswordPage;
