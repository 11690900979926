/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Flex,
  Text,
  Tab,
  Button,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import {
  Badge,
} from '@material-ui/core';
import { useLocation, withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { createUseStyles } from 'react-jss';
import styled from 'styled-components';
import { Home } from '@styled-icons/boxicons-solid/Home';
import { Videos } from '@styled-icons/boxicons-solid/Videos';
import { FamilyRestroom } from '@styled-icons/material/FamilyRestroom';
import { Images } from '@styled-icons/bootstrap/Images';
import { Group2 } from '@styled-icons/remix-line/Group2';
import { ClockFill } from '@styled-icons/bootstrap/ClockFill';
import { Plus } from '@styled-icons/boxicons-regular/Plus';
import { Group } from '@styled-icons/boxicons-solid/Group';
import PropTypes from 'prop-types';
import { Notifications } from '@styled-icons/material/Notifications';

const StyledTab = styled(Tab)`
  margin: 0;
  &:focus {
    outline:0 !important;
    box-shadow: none;
    border: none border-color: #FFF !important;
    background-color: transparent;
  }
  color: #1890ff;
`;
const PlusButton = styled(Plus)`
  width: 36px;
  outline: none;
  border: none;
  color: #156BBF;
  cursor: pointer;
  &:focus {
    outline:0 !important;
    border: 0 !important;
    color: #1890ff !important;
  }
  @media (max-width: 350px) {
    width: 24px;
  }
`;
const HomeButton = styled(Home)`
  width: 28px;
  outline: none;
  border: none;
  color: #156BBF;
  @media (max-width: 350px) {
    width: 24px;
  }
`;
const GroupButton = styled(Group)`
  width: 28px;
  outline: none;
  border: none;
  @media (max-width: 350px) {
    width: 24px;
  }
`;
const ClockButton = styled(ClockFill)`
  width: 28px;
  outline: none;
  border: none;
  color: #888;
  &:focus {
    outline:0 !important;
    border: 0 !important;
    color: ##156BBF;
  }
  @media (max-width: 350px) {
    width: 24px;
  }
`;

const FamilyButton = styled(FamilyRestroom)`
  width: 28px;
  outline: none;
  border: none;
  color: #888;
  &:focus {outline:0 !important ; border: 0 !important; color: #156BBF;}
  @media (max-width: 350px) {
    width: 24px;
  }
`;

const NotificationButton = styled(Notifications)`
  width: 28px;
  outline: none;
  border: none;
  @media (max-width: 350px) {
    width: 24px;
  }
`;

const useStyles = createUseStyles({
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  tabHover: {
    '&:hover': {
      backgroundColor: '#DDD',
    },
  },
});
const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function SideNav({
  history,
}) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [profile, setProfile] = useState();
  const [errorMessage, setFormErrorMessage] = useState('');
  const [perVideoPage, setPerVideoPage] = useState(9);
  /// image sections
  // rec videosvv
  const [recVideos, setRecVideos] = useState([]);
  const [recommendedLoading, setRecommendedLoading] = useState(true);
  const [lastRecPageReached, setLastRecPageReached] = useState(false);
  const [initializedRecommended, setInitializedRecommended] = useState(false);
  const [recPage, setRecPage] = useState(0);
  // Family images
  const [connectionsFetched, setConnectionsFetched] = useState(false);
  const [connections, setConnections] = useState([]);
  const [connectionCount, setConnectionCount] = useState([]);
  const { onOpen: onHomeOpen, onClose: onHomeClose, isOpen: isHomeOpen } = useDisclosure();
  const { onOpen: onFamilyOpen, onClose: onFamilyClose, isOpen: isFamilyOpen } = useDisclosure();
  const { onOpen: onGroupOpen, onClose: onGroupClose, isOpen: isGroupOpen } = useDisclosure();
  const { onOpen: onProfileOpen, onClose: onProfileClose, isOpen: isProfileOpen } = useDisclosure();
  const { onOpen: onNotificationOpen, onClose: onNotificationClose, isOpen: isNotificationOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);
  // Badges
  const [groupInvites, setGroupInvites] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [errMessage, setErrMessage] = useState();
  const [groupCount, setGroupCount] = useState();

  const setPopoverNotVisited = (popoverType) => {
    localStorage.setItem(popoverType, 'false');
  };
  const checkPopover = (popoverType, callback) => {
    const check = localStorage.getItem(popoverType);
    if (check !== 'true') {
      callback();
    }
  };
  const setPopoverVisited = (popoverType) => {
    localStorage.setItem(popoverType, 'true');
  };
  useEffect(() => {
    if (user.userData && user.userData.isAuth) {
      const url = window.location.href;
      axios({
        method: 'POST',
        url: '/api/users/getProfile',
        data: {
          username: user.userData.username,
        },
      })
        .then((data) => {
          axios.get(`/api/connection/all/${data.data._id}`).then((response) => {
            setConnections(response.data.connections);
            setConnectionCount(response.data.connections.pending.length);
            if (response.data.connections.friends.length > 0) {
              setConnectionsFetched(true);
            }
            setProfile(data.data);
            // checkPopover('kloakHomePopover', onHomeOpen);
            // setPopoverNotVisited('kloakGroupsPopover');
            axios({
              method: 'POST',
              url: '/api/groups/groups-by-profile',
              data: {
                profileId: data.data._id,
              },
            })
              .then((res) => {
                setGroupInvites(res.data.groupInvites);
                setGroupCount(res.data.groupInvites.length);
                setTotalCount(res.data.groupInvites.length + response.data.connections.pending.length);
              })
              .catch((groupErr) => {
                setErrMessage(groupErr);
              });
          });
        })
        .catch((err) => {
          setFormErrorMessage('Cannot fetch user profile');
        });
    }
  }, [user.userData]);

  useEffect(() => {
    window.onscroll = debounce(() => {
      if ((window.innerHeight + document.documentElement.scrollTop
         === document.scrollingElement.scrollHeight)
        && (lastRecPageReached === false) && (initializedRecommended)) {
        // Do awesome stuff like loading more content!
        // Do load more content here!
        // console.log("hello 1")
        setRecommendedLoading(true);
        getRecVideos();
      }
    }, 300);
  });
  const goToLink = (type, link, username) => {
    // console.log(event.target);
    switch (type) {
      case 'home':
        document.getElementById('home-side').style.color = '#156BBF';
        document.getElementById('families-side').style.color = '#888888';
        document.getElementById('myprofile-side').style.color = '#888888';
        document.getElementById('groups-side').style.color = '#888888';
        document.getElementById('notifications-side').style.color = '#888888';
        onFamilyClose();
        onGroupClose();
        onProfileClose();
        onNotificationClose();
        // if (selectedUsername) document.getElementById(`sidefamily_${selectedUsername}`).style.color = '#333333';
        break;
      case 'families':
        document.getElementById('families-side').style.color = '#156BBF';
        document.getElementById('home-side').style.color = '#888888';
        document.getElementById('myprofile-side').style.color = '#888888';
        document.getElementById('groups-side').style.color = '#888888';
        document.getElementById('notifications-side').style.color = '#888888';
        onHomeClose();
        onGroupClose();
        onProfileClose();
        onNotificationClose();
        // if (selectedUsername) document.getElementById(`sidefamily_${selectedUsername}`).style.color = '#333333';
        break;
      case 'myprofile':
        document.getElementById('myprofile-side').style.color = '#156BBF';
        document.getElementById('families-side').style.color = '#888888';
        document.getElementById('home-side').style.color = '#888888';
        document.getElementById('groups-side').style.color = '#888888';
        document.getElementById('notifications-side').style.color = '#888888';
        onFamilyClose();
        onHomeClose();
        onGroupClose();
        onNotificationClose();
        // if (selectedUsername) document.getElementById(`sidefamily_${selectedUsername}`).style.color = '#333333';
        break;
      case 'familyprofile':
        document.getElementById('myprofile-side').style.color = '#888888';
        document.getElementById('families-side').style.color = '#156BBF';
        document.getElementById('home-side').style.color = '#888888';
        document.getElementById('groups-side').style.color = '#888888';
        document.getElementById('notifications-side').style.color = '#888888';
        onHomeClose();
        onProfileClose();
        onGroupClose();
        onNotificationClose();
        // if (selectedUsername) document.getElementById(`sidefamily_${selectedUsername}`).style.color = '#333333';
        // setSelectedUsername(username);
        // document.getElementById(`sidefamily_${username}`).style.color = '#FF0000';
        break;
      case 'groups':
        document.getElementById('groups-side').style.color = '#156BBF';
        document.getElementById('myprofile-side').style.color = '#888888';
        document.getElementById('families-side').style.color = '#888888';
        document.getElementById('home-side').style.color = '#888888';
        document.getElementById('notifications-side').style.color = '#888888';
        onFamilyClose();
        onHomeClose();
        onProfileClose();
        onNotificationClose();
        break;
      case 'notification':
        document.getElementById('notifications-side').style.color = '#156BBF';
        document.getElementById('groups-side').style.color = '#888888';
        document.getElementById('myprofile-side').style.color = '#888888';
        document.getElementById('families-side').style.color = '#888888';
        document.getElementById('home-side').style.color = '#888888';
        onFamilyClose();
        onHomeClose();
        onProfileClose();
        onGroupClose();
        break;
      default:
        document.getElementById('home-side').style.color = '#156BBF';
    }
    history.push(link);
  };
  const getRecVideos = () => {
    // console.log("called: " + profile._id)
    axios({
      method: 'POST',
      url: '/api/video/latestVideoQueryList',
      data: {
        profileId: profile._id,
        page: recPage,
        perPage: perVideoPage,
      },
    })
      .then((data) => {
      // console.log(data.data.videos)
        if (!initializedRecommended) {
          setInitializedRecommended(true);
        }
        setRecVideos(recVideos.concat(data.data.videos));
        if (data.data.videos.length === 0) {
          setLastRecPageReached(true);
          setRecommendedLoading(false);
          return;
        }

        setRecPage(recPage + 1);
        setRecommendedLoading(false);
      })
      .catch((err) => {
      // console.log(err);
        let errMsg;

        switch (err?.response?.status) {
          case 403:
            errMsg = 'You do Not have access to these images';
            break;
          case 500:
            errMsg = 'Server is currently down';
            break;
          default:
            errMsg = 'Something has went wrong';
            break;
        }
        setFormErrorMessage(errMsg);
        setTimeout(() => {
          setFormErrorMessage('');
        }, 3000);
      });
  };
  const renderFriendsCards = () => connections.friends.map((family) => (
    <Flex
      key={family._id}
      alignItems="left"
      cursor="pointer"
      className={classes.tabHover}
      paddingX="1.5rem"
      paddingY="0.5rem"
      onClick={() => { goToLink('familyprofile', `/profile/${family.username}`, family.username); }}
    >
      <Box>
        <Flex
          justifyContent="center"
          flexDir="column"
          alignItems="left"
        >
          <Box
            as="img"
            crossOrigin="anonymous"
            src={family.image}
            width="42px"
            height="42px"
            objectFit="cover"
            borderRadius="50%"
            verticalAlign="middle"
            cursor="pointer"
          />
        </Flex>
      </Box>
      <Box>
        <Flex
          justifyContent="center"
          flexDir="column"
          alignItems="left"
          height="100%"
        >
          <Text
            id={`sidefamily_${family.username}`}
            fontSize="1.1em"
            fontWeight="semibold"
            marginLeft="0.5rem"
            cursor="pointer"
            color="#777"
            onClick={() => { goToLink('familyprofile', `/profile/${family.username}`, family.username); }}
          >
            {family.username}
          </Text>
        </Flex>
      </Box>
    </Flex>
  ));

  if (user.userData && user.userData.isAuth) {
    return (
      <Box
        backgroundColor="#F1F1F1"
        width="250px"
        display={{ base: 'none', lg: 'block' }}
        paddingTop="6rem"
      >
        <Box
          marginY="0.5rem"
        >
          <Text
            as="span"
            fontSize="1.2em"
            fontWeight="semibold"
            color="#AAA"
            paddingX="1.5rem"
          >
            MENU
          </Text>
        </Box>
        <Box width="250px">
          <Popover
            isOpen={isHomeOpen}
            initialFocusRef={firstFieldRef}
            onOpen={() => { checkPopover('kloakHomePopover', onHomeOpen); }}
            onClose={onHomeClose}
            placement="right"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Flex
                height="50px"
                width="100%"
                cursor="pointer"
                paddingX="1.5rem"
                className={classes.tabHover}
                onClick={() => { goToLink('home', '/home'); }}
              >
                <Box>
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    alignItems="left"
                    height="100%"
                  >
                    <HomeButton
                      id="home-side"
                      cursor="pointer"
                    />
                  </Flex>
                </Box>
                <Flex
                  marginLeft="1rem"
                  justifyContent="center"
                  flexDir="column"
                  alignItems="left"
                  height="100%"
                >
                  <Text
                    fontWeight="bold"
                    cursor="pointer"
                    color="#156BBF"
                    fontSize="1.1rem"
                  >
                    Home
                  </Text>
                </Flex>
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              p={6}
              zIndex="999"
            >
              <PopoverArrow />
              <PopoverCloseButton onClick={() => { setPopoverVisited('kloakHomePopover'); }} />
              <Text
                color="#000"
                fontWeight="500"
              >
                Home is where you can see all the posts that your family has made visible
              </Text>
            </PopoverContent>
          </Popover>
        </Box>
        <Box color="#1890ff" to="/families">
          <Popover
            isOpen={isFamilyOpen}
            initialFocusRef={firstFieldRef}
            onOpen={() => { checkPopover('kloakFamilyPopover', onFamilyOpen); }}
            onClose={onFamilyClose}
            placement="right"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Flex
                height="50px"
                className={classes.tabHover}
                paddingX="1.5rem"
                cursor="pointer"
                onClick={() => { goToLink('families', '/families'); }}
              >
                <Box>
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    alignItems="left"
                    height="100%"
                  >
                    <FamilyButton
                      cursor="pointer"
                      id="families-side"
                      onClick={() => { goToLink('families', '/families'); }}
                    />
                  </Flex>
                </Box>
                <Box>
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    alignItems="center"
                    height="100%"
                    marginLeft="1rem"
                  >
                    <Text
                      fontWeight="bold"
                      cursor="pointer"
                      fontSize="1.1rem"
                      color="#156BBF"
                    >
                      Family
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              p={6}
              zIndex="999"
            >
              <PopoverArrow />
              <PopoverCloseButton onClick={() => { setPopoverVisited('kloakFamilyPopover'); }} />
              <Text
                color="#000"
                fontWeight="500"
              >
                Seek out your family and invites your friends to join us!
              </Text>
            </PopoverContent>
          </Popover>
        </Box>
        <Box color="#1890ff" to="/groups">
          <Popover
            isOpen={isGroupOpen}
            initialFocusRef={firstFieldRef}
            onOpen={() => { checkPopover('kloakGroupsPopover', onGroupOpen); }}
            onClose={onGroupClose}
            placement="right"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Flex
                height="50px"
                className={classes.tabHover}
                paddingX="1.5rem"
                cursor="pointer"
                onClick={() => { goToLink('groups', '/groups'); }}
              >
                <Box>
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    alignItems="left"
                    height="100%"
                  >
                    <GroupButton
                      cursor="pointer"
                      id="groups-side"
                      color="#888888"
                      onClick={() => { goToLink('groups', '/groups'); }}
                    />
                  </Flex>
                </Box>
                <Box>
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    alignItems="center"
                    height="100%"
                    marginLeft="1rem"
                  >
                    <Text
                      fontWeight="bold"
                      cursor="pointer"
                      fontSize="1.1rem"
                      color="#156BBF"
                      onClick={() => { goToLink('groups', '/groups'); }}
                    >
                      Groups
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              p={6}
              zIndex="999"
            >
              <PopoverArrow />
              <PopoverCloseButton onClick={() => { setPopoverVisited('kloakGroupsPopover'); }} />
              <Text
                color="#000"
                fontWeight="500"
              >
                Create and join groups so you can share with your fellow friends, families and acquaintances!
              </Text>
            </PopoverContent>
          </Popover>
        </Box>
        {/* new changes */}
        <Box color="#1890ff" to="/notification">
          <Popover
            isOpen={isNotificationOpen}
            initialFocusRef={firstFieldRef}
            onOpen={() => { checkPopover('kloakNotificationPopover', onNotificationOpen); }}
            onClose={onNotificationClose}
            placement="right"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Flex
                height="50px"
                className={classes.tabHover}
                paddingX="1.5rem"
                cursor="pointer"
                // fix after
                onClick={() => { goToLink('notification', '/notification'); }}
              >
                <Box>
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    alignItems="left"
                    height="100%"
                  >
                    <Badge badgeContent={totalCount !== undefined ? totalCount : 0} color="error">
                      <NotificationButton
                        cursor="pointer"
                        id="notifications-side"
                        color="#888888"
                        // fix after
                        onClick={() => { goToLink('notification', '/notification'); }}
                      />
                    </Badge>
                  </Flex>
                </Box>
                <Box>
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    alignItems="center"
                    height="100%"
                    marginLeft="1rem"
                  >
                    <Text
                      fontWeight="bold"
                      cursor="pointer"
                      fontSize="1.1rem"
                      color="#156BBF"
                      onClick={() => { goToLink('notification', '/notification'); }}
                    >
                      Notifications
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              p={6}
              zIndex="999"
            >
              <PopoverArrow />
              <PopoverCloseButton onClick={() => { setPopoverVisited('kloakNotificationPopover'); }} />
              <Text
                color="#000"
                fontWeight="500"
              >
                Keep up to date with my most recent notifications here.
              </Text>
            </PopoverContent>
          </Popover>
        </Box>
        <Box
          textAlign="left"
          width="140px"
          marginTop="1.5rem"
          marginBottom="1rem"
        >
          <Text
            as="span"
            fontSize="1.2em"
            fontWeight="semibold"
            color="#AAA"
            paddingX="1.5rem"
          >
            LIBRARY
          </Text>
        </Box>
        <Box color="#1890ff" to="/profile">
          <Popover
            isOpen={isProfileOpen}
            initialFocusRef={firstFieldRef}
            onOpen={() => { checkPopover('kloakProfilePopover', onProfileOpen); }}
            onClose={onProfileClose}
            placement="right"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Flex
                width="100%"
                height="50px"
                cursor="pointer"
                className={classes.tabHover}
                paddingX="1.5rem"
                onClick={() => { goToLink('myprofile', '/my-profile'); }}
              >
                <Box>
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    alignItems="left"
                    height="100%"
                  >
                    <ClockButton
                      cursor="pointer"
                      id="myprofile-side"
                    />
                  </Flex>
                </Box>
                <Box>
                  <Flex
                    justifyContent="center"
                    flexDir="column"
                    alignItems="center"
                    height="100%"
                    marginLeft="1rem"
                  >
                    <Text
                      fontWeight="bold"
                      cursor="pointer"
                      fontSize="1.1rem"
                      color="#156BBF"
                      onClick={() => { goToLink('myprofile', '/my-profile'); }}
                    >
                      Profile
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              p={6}
              zIndex="999"
            >
              <PopoverArrow />
              <PopoverCloseButton onClick={() => { setPopoverVisited('kloakProfilePopover'); }} />
              <Text
                color="#000"
                fontWeight="500"
              >
                Your library contains all your uploaded files and you can edit them here
              </Text>
            </PopoverContent>
          </Popover>
        </Box>
        <Flex
          textAlign="left"
          marginY="0.5rem"
          paddingX="1.5rem"
          justifyContent="space-between"
        >
          <Box>
            <Flex
              justifyContent="center"
              flexDirection="column"
              height="50px"
            >
              <Text
                as="span"
                fontSize="1.2em"
                fontWeight="bold"
                color="#888"
              >
                FAMILY
              </Text>
            </Flex>
          </Box>
          <Box>
            <Flex
              justifyContent="center"
              flexDirection="column"
              height="50px"
            >
              <PlusButton
                cursor="pointer"
                onClick={() => { goToLink('families', '/families'); }}
              />
            </Flex>
          </Box>
        </Flex>
        {connectionsFetched
          ? (
            <Box
              height="450px"
              overflowY={{ base: 'none', md: 'auto' }}
            >
              {renderFriendsCards()}
            </Box>
          ) : (
            <Box
              paddingLeft="1.5rem"
            >
              <Button
                backgroundColor="#2C6DAD"
                color="#FFF"
                padding="8px 20px"
                _hover={{ backgroundColor: '#5C8DFF' }}
                onClick={() => { goToLink('families', '/families'); }}
              >
                Add Family
              </Button>
            </Box>
          )}
      </Box>
    );
  }
  return (
    <Box />
  );
}
SideNav.propTypes = propTypes;
export default withRouter(SideNav);
