/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import {
  Box,
  Flex,
  Avatar,
  Text,
  List,
  SimpleGrid,
  AspectRatio,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  Button,
  Heading,
  Input,
  Textarea,
  Stack,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
} from '@chakra-ui/react';

const propTypes = {
  groupData: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  familyPostGrid: {
    display: 'flex',
    overflowX: 'scroll',
    gridGap: '1em',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
});
function ImageBanner({
  groupData,
  profile,
  isAdmin,
}) {
  const {
    isOpen: isBannerOpen,
    onOpen: onBannerOpen,
    onClose: onBannerClose,
  } = useDisclosure();
  const [bannerError, setBannerError] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [imageList, setImageList] = useState([]);
  const [imageListRetrieved, setImageListRetrieved] = useState(false);
  const [selectedImage, setSeletedImage] = useState();
  const [imageObject, setImageObject] = useState();
  const [newGroupName, setNewGroupName] = useState('');
  const [nameError, setNameError] = useState();
  const [newGroupDescription, setNewGroupDescription] = useState('');
  const [changeMade, setChangeMade] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (groupData.banner) {
      setBannerImage(groupData.banner);
      // (`data: ${groupData.banner}`);
      setImageLoaded(true);
    }
  }, [groupData, profile]);
  const clearForm = () => {
    setNewGroupName(groupData.name);
    setNewGroupDescription(groupData.description);
    setBannerImage(groupData.banner);
    setNameError();
  };
  const updateGroup = () => {
    if (newGroupName === '') {
      setNameError('Group Name cannot by empty!');
      return;
    }
    const saveData = imageObject ? {
      groupId: groupData._id,
      imageId: imageObject._id,
      groupName: newGroupName,
      groupDescription: newGroupDescription,
    } : {
      groupId: groupData._id,
      groupName: newGroupName,
      groupDescription: newGroupDescription,
    };
    setImageListRetrieved(false);
    axios({
      method: 'put',
      url: '/api/groups/update-group',
      data: saveData,
    })
      .then((res) => {
        if (res.data.success) {
          window.location.reload();
        }
      })
      .catch((err) => {
        setBannerError(err);
      });
  };
  const retrieveImages = () => {
    // console.log(profile);
    axios({
      method: 'post',
      url: '/api/image/imagesByProfile',
      data: {
        profileId: profile._id,
      },
    })
      .then((imageResponse) => {
        // console.log(imageResponse.data.images);
        setImageList(imageResponse.data.images);
        setImageListRetrieved(true);
      })
      .catch((imageErr) => {
        setBannerError(imageErr);
      });
  };
  const selectImage = (image) => {
    setChangeMade(false);
    const borderStyle = document.getElementById(`image_banner_${image._id}`).style.border;
    const selectedStyle = document.getElementById(selectedImage);
    if (selectedStyle !== '' && selectedStyle !== null) {
      document.getElementById(selectedImage).style.border = '';
    }
    setImageObject(image);
    setSeletedImage(`image_banner_${image._id}`);
    if (borderStyle === '') {
      document.getElementById(`image_banner_${image._id}`).style.border = '1px solid blue';
    } else {
      document.getElementById(`image_banner_${image._id}`).style.border = '';
    }
    setChangeMade(true);
  };
  const renderImages = () => imageList.map((image) => (
    <Box
      key={image._id}
    >
      <Box
        id={`image_banner_${image._id}`}
        height="250px"
        padding="10px"
        cursor="pointer"
        borderRadius="10px"
        onClick={() => { selectImage(image); }}
      >
        <AspectRatio
          ratio={16 / 9}
          width="250px"
          borderRadius="1rem"
          border="1px solid blue"
        >
          <Box
            as="img"
            crossOrigin="anonymous"
            alt="thumbnail"
            objectFit="cover"
            src={image.thumbNail}
            cursor="pointer"
            borderRadius=".70rem"
            boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
          />
        </AspectRatio>
        <Flex>
          <Box marginTop={{ base: '0', md: '4px' }}>
            <Text
              color="#003971"
              fontWeight="500"
              fontSize={{ base: '1.1em' }}
              whiteSpace="wrap"
              overflow="hidden"
              textOverflow="ellipsis"
              marginTop="0.5em"
            >
              {image.description}
            </Text>
            <Text
              color="#AAA"
              fontWeight="500"
              fontSize={{ base: '1em' }}
            >
              { moment(image.createdAt).format('LL') }
            </Text>
            <Text
              color="#AAA"
              fontWeight="500"
              fontSize={{ base: '1em' }}
            >
              {image.privacyLabel}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  ));
  return (
    <Box
      width="100%"
    >
      <Box>
        {groupData.banner && imageLoaded
          ? (
            <Box
              width={{
                base: '100%',
              }}
              height="350px"
              borderRadius="10px"
            >
              <Box
                as="img"
                crossOrigin="anonymous"
                width="100%"
                height="100%"
                objectFit="cover"
                src={bannerImage.filePath}
              />
            </Box>
          ) : (
            <Box
              width={{
                base: '100%',
              }}
              height="300px"
              backgroundColor="#000"
            />
          )}
      </Box>
      <Box>
        {
        isAdmin ? (
          <Button
            width={{ md: '150px' }}
            backgroundColor="#2C6DAD"
            color="#FFF"
            padding="10px 16px"
            borderRadius="5px"
            cursor="pointer"
            _hover={{ bg: '#DDD', color: '#2C6DAD' }}
            boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
            onClick={() => { onBannerOpen(); clearForm(); retrieveImages(); }}
          >
            <Text
              textAlign="center"
              fontSize="1.3em"
              fontWeight="600"
            >
              Edit Group
            </Text>
          </Button>
        ) : <Box />
      }
      </Box>
      <Box>
        <Modal isOpen={isBannerOpen} onClose={onBannerClose} size={{ base: '90%', md: '50%' }}>
          <ModalOverlay />
          <ModalContent width={{ base: '90%', md: '50%' }}>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Box margin="auto">
                  <Heading
                    fontWeight="500"
                    fontSize={{ base: '1.6rem', sm: '2rem' }}
                    color="#0B67C2"
                    marginY="1rem"
                  >
                    Edit Group
                  </Heading>
                </Box>
                <Box>
                  <Box margin="auto">
                    <Heading
                      fontWeight="500"
                      fontSize={{ base: '1.3rem' }}
                      color="#0B67C2"
                      marginTop="1rem"
                    >
                      Change Group Name
                    </Heading>
                  </Box>
                  <Input
                    value={newGroupName}
                    onChange={(event) => { setNewGroupName(event.target.value); }}
                  />
                  {
                    nameError === '' ? <Box />
                      : (
                        <Box>
                          <Text
                            color="FF0000"
                          >
                            {nameError}
                          </Text>
                        </Box>
                      )
                  }
                </Box>
                <Box>
                  <Box margin="auto">
                    <Heading
                      fontWeight="500"
                      fontSize={{ base: '1.3rem' }}
                      color="#0B67C2"
                      marginTop="1rem"
                    >
                      Group Description
                    </Heading>
                  </Box>
                  <Input
                    value={newGroupDescription}
                    onChange={(event) => { setNewGroupDescription(event.target.value); }}
                  />
                </Box>
                <Box margin="auto">
                  <Heading
                    fontWeight="500"
                    fontSize={{ base: '1.3rem' }}
                    color="#0B67C2"
                    marginTop="1rem"
                  >
                    Change Group Banner
                  </Heading>
                </Box>
                <Box>
                  {imageListRetrieved ? (
                    <Box
                      className={classes.familyPostGrid}
                      spacing="10px"
                      mb="24px"
                      maxWidth={{
                        base: '100%',
                      }}
                      height="300px"
                    >
                      {renderImages()}
                    </Box>
                  ) : <Box />}
                </Box>
                <Flex
                  width="100%"
                  justifyContent="flex-end"
                >
                  <Button
                    width={{ base: '200px' }}
                    _hover={{ bg: '#DDD', color: '#2C6DAD' }}
                    backgroundColor="#2C6DAD"
                    color="#FFF"
                    padding="10px 16px"
                    borderRadius="50px"
                    cursor="pointer"
                    boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                    onClick={() => { updateGroup(); }}
                  >
                    <Text
                      textAlign="center"
                      fontSize="1.3em"
                      fontWeight="600"
                    >
                      Update Group
                    </Text>
                  </Button>
                </Flex>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}
ImageBanner.propTypes = propTypes;
export default ImageBanner;
