/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SingleComment from './SingleComment';
import ReplyComment from './ReplyComment';
import debounce from "lodash.debounce";
import { Box, Flex, Spinner, Text, Button } from "@chakra-ui/react";
const { TextArea } = Input;

const propTypes = {
  post: PropTypes.object.isRequired,
  imageId: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
};

function Comments({
  post,
  imageId,
  profile
}) {
  const user = useSelector((state) => state.user);
  const [CommentLists, setCommentLists] = useState([]);
  const [Comment, setComment] = useState('');
  const [loading, setLoading] = useState('');
  const [lastPage, setLastPage] = useState(false);
  const [initPage, setInitPage] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (post.image) {
      setPage(0);
      axios.post('/api/comment/getImageComments', { imageId: post.image._id , page: 0, perPage }).then((response) => {
        if (response.data.success) {
          if (!initPage) {
            setInitPage(true);
          }
          // console.log('response.data.comments', response.data.comments);
          setCommentLists(response.data.comments);
          if (response.data.comments.length === 0) {
            setLastPage(true);
            setLoading(false);
            return;
          }
          setPage(1);
          setLoading(false);
        } else {
          alert('Failed to get video Info');
        }
      });
    } else {
      setPage(0);
      axios.post('/api/comment/getComments', { videoId: post.video._id, page: 0, perPage }).then((response) => {
        if (response.data.success) {
          if (!initPage) {
            setInitPage(true);
          }
          // console.log('response.data.comments', response.data.comments);
          setCommentLists(response.data.comments);
          if (response.data.comments.length === 0) {
            setLastPage(true);
            setLoading(false);
            return;
          }
          setPage(1);
          setLoading(false);
        } else {
          alert('Failed to get video Info');
        }
      });
    }
  }, [post])

  const handleChange = (e) => {

    setComment(e.currentTarget.value);
  };
  const getComments = () => {
    if (post.image) {
    axios.post('/api/comment/getImageComments', { imageId: post.image._id , page, perPage }).then((response) => {
      if (response.data.success) {
        if (!initPage) {
          setInitPage(true);
        }
        // console.log('response.data.comments', response.data.comments);
        setCommentLists(CommentLists.concat(response.data.comments));
        if (response.data.comments.length === 0) {
          setLastPage(true);
          setLoading(false);
          return;
        }
        setPage(page + 1);
        setLoading(false);
      } else {
        alert('Failed to get video Info');
      }
    });
  } else {
    axios.post('/api/comment/getComments', { videoId: post.video._id , page, perPage }).then((response) => {
      if (response.data.success) {
        if (!initPage) {
          setInitPage(true);
        }
        // console.log('response.data.comments', response.data.comments);
        setCommentLists(CommentLists.concat(response.data.comments));
        if (response.data.comments.length === 0) {
          setLastPage(true);
          setLoading(false);
          return;
        }
        setPage(page + 1);
        setLoading(false);
      } else {
        alert('Failed to get video Info');
      }
    });
  }
  }
  const updateComment = (newComment) => {
    setCommentLists(CommentLists.concat(newComment));
  };
  useEffect(() => {
    window.onscroll = debounce((event) => {
        if (( window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) && 
            (lastPage === false) && (initPage)) {
            // Do awesome stuff like loading more content!
              setLoading(true);
              getComments();
        }
      
    }, 300);
  });

  
  const onSubmit = (e) => {
    if (Comment ===null || Comment === '') {
      return;
    }
    e.preventDefault();
    let variables;
    if (post.image) {
      variables = {
        content: Comment,
        writer: profile._id,
        imagePostId: post.image._id,
      };
    } else {
      variables = {
        content: Comment,
        writer: profile._id,
        postId: post.video._id,
      };
    }
    console.log(variables);
    axios.post('/api/comment/saveComment', variables).then((response) => {
      if (response.data.success) {
        setComment('');
        updateComment(response.data.result);
      } else {
        alert('Failed to save Comment');
      }
    });
  };

  return (
    <Box>
      <Box>
        <Box display={{base:"block", sm:"block", md:"block"}} onSubmit={onSubmit}>
          <TextArea
            style={{ width: '100%', borderRadius: '5px' }}
            onChange={handleChange}
            value={Comment}
            placeholder="write some comments"
          />
          <Flex justifyContent="flex-end" width="100%">
            <Button width={{base:"100px", sm:"100px", md: "150px"}} height={{base:"42px", sm:"42px", md:"52px"}} onClick={onSubmit}>
              Submit
            </Button>
          </Flex>
        </Box>
      </Box>
      <br />
      <Text fontSize={{base:"14px", sm:"14px",md: "18px"}}> Replies</Text>
      <hr />
      {/* Comment Lists  */}
      <Box paddingY="1rem">
      {CommentLists
        && CommentLists.map(
          (comment) => !comment.responseTo && (
          <Box>
            <SingleComment
              comment={comment}
              postId={imageId}
              refreshFunction={updateComment}
              profile={profile}
            />
            <ReplyComment
              CommentLists={CommentLists}
              postId={imageId}
              parentCommentId={comment._id}
              refreshFunction={updateComment}
              profile={profile}
            />
          </Box>
          ),
        )}
      </Box>
      {/* Root Comment Form */}
      {loading ?
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <Spinner
              thickness="4px"
              emptyColor="gray.200"
              color="#FF1A75"
              size="xl"
              mb="8px"
            />
            <Text>Loading</Text>
          </Flex> :
          <div></div>
        }

    </Box>
  );
}

Comments.propTypes = propTypes;

export default Comments;
