/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Divider,
  Spinner,
  AspectRatio,
  Select,
  FormControl,
  FormLabel,
  Button,
  Input,
} from '@chakra-ui/react';
import moment from 'moment';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';

const StyledButton = styled(Box)`
color: #FFF;
font-size: 1.1rem;
font-weight: 600;
background-color: #2C6DAD;
padding: 16px 32px;
border-radius: 10px;
cursor: pointer;
text-align: center;
&:hover {
  background-color: #2C8EBD;
}

`;
const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function NewGroup({
  history,
}) {
  const user = useSelector((state) => state.user);
  const [errMessage, setErrMessage] = useState();
  const [profile, setProfile] = useState();
  const [searchResultsLoaded, setSearchResultsLoaded] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [displayMemberList, setDisplayMemberList] = useState([]);
  const [invitedIdList, setInvitedIdList] = useState([]);

  // const [groupName, setGroupName] = useState();

  useEffect(() => {
    if (user.userData) {
      axios({
        method: 'POST',
        url: '/api/users/getProfile',
        data: {
          username: user.userData.username,
        },
      })
        .then((data) => {
          setProfile(data.data);
        })
        .catch((err) => {
          setErrMessage('Cannot fetch user profile');
        });
    }
  }, [user.userData, user]);

  const onFamilySearch = (event) => {
    // console.log('I keep getting called too');
    event.persist();
    if (event.target.value === '') {
      setSearchResults([]);
      setSearchResultsLoaded(false);
      return;
    }
    const recipientIds = [];
    displayMemberList.forEach((eachProfile) => {
      recipientIds.push(eachProfile._id);
    });
    axios({
      method: 'POST',
      url: '/api/groups/search-profiles',
      data: {
        query: String(event.target.value),
        profileId: profile._id,
        recipientIds,
      },
    })
      .then((response) => {
        const profileResults = [].concat(response.data.profiles);
        setSearchResults(profileResults);
        // console.log(profileResults);
        setSearchResultsLoaded(true);
        // document.getElementById('family-results').style.display = 'block';
        // console.log('what');
      })
      .catch((profErr) => {
        setSearchResultsLoaded(profErr);
      });
  };
  const removeRecipient = (currentProfile) => {
    const newRecipientList = [];
    const newMemberList = [];
    displayMemberList.forEach((recipient) => {
      if (recipient._id !== currentProfile._id) {
        newRecipientList.push(recipient);
        newMemberList.push(recipient._id);
      }
    });
    setInvitedIdList(newMemberList);
    setDisplayMemberList(newRecipientList);
    // console.log(newRecipientList);
  };
  const addRecipient = (newProfile) => {
    const newRecipientList = displayMemberList;
    const newMemberList = invitedIdList;
    newRecipientList.push(newProfile);
    newMemberList.push(newProfile._id);
    setDisplayMemberList(newRecipientList);
    setInvitedIdList(newMemberList);
    document.getElementById('input-search').value = '';
    setSearchResultsLoaded(false);
  };
  const loadSearchResults = () => searchResults.map((familyProfile, index) => (
    <Box>
      <Flex
        margin="8px"
        cursor="pointer"
        onClick={() => { addRecipient(familyProfile); }}
      >
        <Avatar
          marginRight="8px"
          marginTop="4px"
          size="md"
          src={familyProfile.image}
          alignItems="center"
        />
        <Box>
          <Text
            color="#34495E"
            fontWeight="500"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            marginTop="4px"
          >
            {familyProfile.username}
          </Text>
          <Text
            color="#34495E"
            fontWeight="400"
            width={{
              base: '100%',
            }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {familyProfile.email}
          </Text>
        </Box>
      </Flex>
    </Box>
  ));
  const renderDetailsForm = () => (
    <Formik
      initialValues={{
        name: '',
        description: '',
        privacy: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Gruop must have a name'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        // console.log(invitedIdList);
        axios({
          method: 'POST',
          url: '/api/groups/create-new-group',
          data: {
            name: values.name,
            description: values.description,
            invitedIdList,
            profile,
          },
        })
          .then((res) => {
            history.push(`/group/${res.data.savedGroup._id}`);
          })
          .catch((err) => {
            setErrMessage(err);
          });
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <Box onSubmit={handleSubmit} width="100%">
            <Field name="name">
              {() => (
                <FormControl mb="25px">
                  <FormLabel
                    ml="4px"
                    fontWeight="500"
                    fontSize="1.1rem"
                  >
                    Group Name
                  </FormLabel>
                  <br />
                  <Input
                    id="name"
                    placeholder="Group Name "
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '100%' }}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {() => (
                <FormControl mb="25px">
                  <FormLabel
                    htmlFor="description"
                    ml="4px"
                    fontWeight="500"
                    fontSize="1.1rem"
                  >
                    Description
                  </FormLabel>
                  <br />
                  <Input
                    id="description"
                    placeholder="What is this group about?"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '100%' }}
                  />
                </FormControl>
              )}
            </Field>
            <Box>
              <Text
                fontWeight="500"
                fontSize="1.1rem"
              >
                Add Recipients
              </Text>
            </Box>
            <Box
              borderRadius="5px"
            >
              <Input
                id="input-search"
                onChange={onFamilySearch}
                placeholder="Search by Username or Email"
              />
            </Box>
            <Box
              marginY="0.5rem"
              height="100px"
              overflowY="scroll"
              width={{ base: '90%', md: '500px' }}
            >
              {displayMemberList.length > 0
                ? (
                  <Flex
                    flexWrap="wrap"
                  >
                    { displayMemberList.map((recpient) => (
                      <Box
                        margin="5px"
                        id={`username${recpient._id}`}
                        backgroundColor="#DDD"
                      >
                        <Flex>
                          <Box>
                            <Flex
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              height="100%"
                              marginLeft="0.5rem"
                            >
                              <Text
                                color="#000"
                                fontWeight="500"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {recpient.username}
                              </Text>
                            </Flex>
                          </Box>
                          <Box>
                            <Flex
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              height="100%"
                              marginX="0.5rem"
                            >
                              <Text
                                as="span"
                                fontSize="1.3rem"
                                cursor="pointer"
                                onClick={() => { removeRecipient(recpient); }}
                              >
                                &times;
                              </Text>
                            </Flex>
                          </Box>
                        </Flex>
                      </Box>
                    ))}
                  </Flex>
                ) : <Box />}
            </Box>
            <Box
              id="search-results"
              height="300px"
              width="100%"
              marginY="1rem"
              overflowY={{ base: 'none', md: 'auto' }}
            >
              {searchResultsLoaded
                ? (
                  <Box>
                    {loadSearchResults()}
                  </Box>
                ) : <Box />}
            </Box>
            <StyledButton
              isLoading={isSubmitting}
              loadingText="Publishing..."
              onClick={handleSubmit}
            >
              <Text>Submit</Text>
            </StyledButton>
          </Box>
        );
      }}
    </Formik>
  );
  return (
    <Flex className="app" width="100%">
      <Box>
        <Box
          marginY="1rem"
          fontSize="1.5rem"
          fontWeight="600"
        >
          <Text>Create New Group</Text>
        </Box>
        <Box>
          {renderDetailsForm()}
        </Box>
      </Box>
    </Flex>
  );
}
NewGroup.propTypes = propTypes;
export default withRouter(NewGroup);
