/* eslint-disable array-callback-return */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import PropTypes, { checkPropTypes } from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import heic2any from 'heic2any';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import io from 'socket.io-client';
import { compress, compressAccurately } from 'image-conversion';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Select,
  Spinner,
  Text,
  Input,
  ModalFooter,
  AspectRatio,
  SimpleGrid,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/react';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import s3BucketUrl from '../../../environmentVar';
import UploadImg from '../../../../assets/images/upload.png';
import AlertBanner from '../../../sections/AlertBanner';
import InviteFamilyModal from './InviteFamilyModal';

const SeeMoreButton = styled(EyeFill)`
          width: 58px;
          color: #BBB;
          cursor: pointer;
        `;

const UploadStatus = Object.freeze({
  AWAITING_FILE: 'AWAITING_FILE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  ERROR: 'ERROR',
});

const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];

const Category = [
  { value: 'FILM', label: 'Film & Animation' },
  { value: 'AUTOS', label: 'Autos & Vehicles' },
  { value: 'MUSIC', label: 'Music' },
  { value: 'PETS', label: 'Pets & Animals' },
  { value: 'SPORTS', label: 'Sports' },
  { value: 'FAMILY', label: 'Family' },
  { value: 'OTHER', label: 'Other' },
];

const baseDropzoneStyle = {
  height: '65vh',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderRadius: '8px',
  borderColor: '#EEEEEE',
  padding: '32px',
  cursor: 'pointer',
  marginBottom: '128px',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '95%',
};
const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

const useStyles = createUseStyles({
  uploadIcon: {
    margin: '32px auto',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  maxSpace: PropTypes.number.isRequired,
  currentSpace: PropTypes.number.isRequired,
  profile: PropTypes.object.isRequired,
};

function MassUploadStable({
  history,
  maxSpace,
  currentSpace,
  profile,
}) {
  const classes = useStyles();

  const user = useSelector((state) => state.user);

  const [status, setStatus] = useState();
  const [formMessage, setFormErrorMessage] = useState();
  const [loadcomplete, setLoadComplete] = useState(false);
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [thumbFiles, setThumbFiles] = useState();
  const [filesToUpload, setFilesToUpload] = useState();
  const [viewThumbnails, setViewThumbnails] = useState([]);
  const [existingAlbums, setExistingAlbums] = useState([{ _id: '', name: '' }]);
  const [videoConverting, setVideoConverting] = useState('');
  const [videoProgress, setVideoProgress] = useState(0);
  const [videoUploadId, setVideoUploadId] = useState();
  const [newUserUploader, setNewUserUploader] = useState(false);
  const [convertingVideo, setConvertingVideo] = useState(false);
  const [newPath, setNewPath] = useState();
  const [socket, setSocket] = useState();
  const [processMessage, setProcessMessage] = useState('Processing...');
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedPosts, setUploadedPosts] = useState([]);
  // const [alertMessage, setAlertMessage] = useState('Warning: Safari users can only upload mp4 format for videos at this time. Please use a different browser for other video formats');
  /*
    const ffmpeg = createFFmpeg({
      corePath: window.location.hostname === 'localhost' ? 'http://localhost:3000/ffmpeg-core.js' : 'https://www.gokloak.com/ffmpeg-core.js',
      log: false,
    });
    */
  // const cloudConverUrl = window.location.hostname === 'localhost' ? 'https://api.sandbox.cloudconvert.com' : 'https://api.cloudconvert.com';
  const cloudConverUrl = 'https://api.cloudconvert.com';
  useEffect(() => {
    // console.log('running')
    if (profile._id) {
      // loadFFMpeg();
      axios({
        method: 'POST',
        url: '/api/image/my-albums',
        data: {
          profileId: profile._id,
        },
      })
        .then((data) => {
          const getAlbums = existingAlbums.concat(data.data.albums);
          setExistingAlbums(getAlbums);
          setStatus(UploadStatus.AWAITING_FILE);
          setLoadComplete(true);
        })
        .catch((albumErr) => {
          // console.log(err);
          let errMsg;
          switch (albumErr?.response?.status) {
            case 403:
              errMsg = 'You do Not have access to these images';
              break;
            case 500:
              errMsg = 'Server is currently down';
              break;
            default:
              errMsg = 'Something has went wrong';
              break;
          }
          setFormErrorMessage(errMsg);
          setTimeout(() => {
            setFormErrorMessage('');
          }, 3000);
        });
    }
  }, [profile]);

  // A much more higher quality image and thumbnail compression
  // https://github.com/WangYuLue/image-conversion
  const compressImages = async (files) => {
    // console.log(files)
    const imageFiles = Array.from(files);

    const options = {
      size: 900,
      accuracy: 0.9,
      scale: 0.4,
    };
    try {
      // converts files to compressed blobs
      const returnImages = [];
      const compressedBlobs = await Promise.all(
        imageFiles.map((imageFile) => compressAccurately(imageFile, options)),
      );
      for (let i = 0; i < compressedBlobs.length; i += 1) {
        const newFile = new File([compressedBlobs[i]], `${nanoid()}.png`, {
          type: 'image/png',
        });
        returnImages.push(newFile);
      }
      return returnImages;
    } catch (error) {
      setFormErrorMessage(error);
      return error;
    }
  };
  const convertHeicImages = async (file) => {
    const filePath = URL.createObjectURL(file);
    const res = await fetch(filePath);
    const blob = await res.blob();
    const conversionResult = await heic2any({ blob, toType: 'image/png' });
    const newFile = new File([conversionResult], `${nanoid()}.png`, {
      type: 'image/png',
    });
    return newFile;
  };
  const thumbnailImages = async (files) => {
    // console.log(files)
    const imageFiles = Array.from(files);

    const options = {
      size: 400,
      accuracy: 1,
      scale: 0.2,
    };
    try {
      // converts files to compressed blobs
      const compressedBlobs = await Promise.all(
        imageFiles.map((imageFile) => compressAccurately(imageFile, options)),
      );
      const imageThumbs = [];
      for (let i = 0; i < compressedBlobs.length; i += 1) {
        const imageThumb = new File([compressedBlobs[i]], `thumb_${nanoid()}${files[i].name}`);
        imageThumbs.push(imageThumb);
      }
      return imageThumbs;
    } catch (error) {
      setFormErrorMessage(error);
      return error;
    }
  };

  const postAllMedia = async (formData) => axios.post(s3BucketUrl, formData, { timeout: 0 });

  // see https://stackoverflow.com/questions/52669596/promise-all-with-axios
  // https://github.com/axios/axios/blob/d35b5b5902f72305216f245cc58bc2231470fb19/README.md#example
  const postAllData = (fileList, files) => {
    const formArray = [];
    fileList.map((file) => {
      // console.log(`every file: ${file.fileName}`);
      const formData = new FormData();
      formData.append('Content-Type', file.type);
      Object.entries(file.promise.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      files.map((eachFile) => {
        // console.log(file.fileName);
        // console.log(eachFile.name);
        if (String(file.fileName) === String(eachFile.name)) {
          // console.log('match fonud');
          formData.append('file', eachFile);
        }
      });
      formArray.push(formData);
    });
    // console.log(formArray);
    setProcessMessage('Uploading File(s) to Storage...');
    return Promise.all(formArray.map(postAllMedia));
  };
  const cancelPublish = () => {
    setThumbnailSrc();
    setThumbFiles([]);
    setFilesToUpload();
    setViewThumbnails([]);
    setStatus(UploadStatus.AWAITING_FILE);
  };
  const uploadVideoToConvert = async (video, keys) => {
    setProcessMessage('Uploading File(s) to the Cloud...');
    const taskConfig = {
      headers: {
        Authorization: `Bearer ${keys.convertKey}`,
        'Content-type': 'application/json',
      },
    };
    const uploadConfig = {
      headers: {
        Authorization: `Bearer ${keys.convertKey}`,
        'Content-type': 'multipart/form-data',
      },
    };
    // start the upload task process by making this simple post call
    const response = await axios.post(`${cloudConverUrl}/v2/import/upload`, null, taskConfig);
    const uploadUrl = response.data.data.result.form.url;
    // console.log(`reached: ${uploadUrl}`);
    // console.log(`reached: ${response.data.data.result.form.url}`);
    // console.log(`reached: ${response.data.data.result.form.parameters}`);
    // console.log(`task id: ${response.data.data.id}`);
    // create form data
    const formData = new FormData();

    // get all the keys of paraemter object
    const parameterObject = response.data.data.result.form.parameters;
    const parameterKeys = Object.keys(parameterObject);

    // get all parameters and append them to the upload form
    for (let i = 0; i < parameterKeys.length; i += 1) {
      // appending the kvalue of the object to the key of the object
      // console.log(`key: ${parameterKeys[i]}; value: ${parameterObject[parameterKeys[i]]}`);
      formData.append(parameterKeys[i], parameterObject[parameterKeys[i]]);
    }
    // append the file
    formData.append('file', video);
    // the actual upload
    const uploadResponse = await axios.post(uploadUrl, formData, uploadConfig);
    // console.log(uploadResponse);
    return {
      uploadResponse,
      taskId: response.data.data.id,
    };
  };
  const convertVideo = async (videoObject, keys, taskId) => {
    // creating names and file paths
    setProcessMessage('Converting File(s)...');
    const taskConfig = {
      headers: {
        Authorization: `Bearer ${keys.convertKey}`,
        'Content-type': 'application/json',
      },
    };
    const requestData = {
      input: taskId,
      output_format: 'mp4',
      filename: videoObject.fileName,
    };
    const convertRes = await axios.post(`${cloudConverUrl}/v2/convert`, requestData, taskConfig);
    // console.log(`convert result: ${convertRes}`);

    return {
      taskId: convertRes.data.data.id,
    };
  };

  const uploadToS3Task = async (video, keys, taskId) => {
    setProcessMessage('Pushing File(s) to Storage...');
    const taskConfig = {
      headers: {
        Authorization: `Bearer ${keys.convertKey}`,
        'Content-type': 'application/json',
      },
    };
    const uploadData = {
      input: taskId,
      access_key_id: keys.accessKeyId,
      secret_access_key: keys.secretAccessKey,
      bucket: keys.bucket,
      region: keys.region,
      key: video.fileName,
      acl: keys.acl,
      endpoint: keys.endpoint,
    };
    const uploadRes = await axios.post(`${cloudConverUrl}/v2/export/s3`, uploadData, taskConfig);
    // console.log(`upload result: ${uploadRes.data.data}`);
    return {
      taskId: uploadRes.data.data.id,
    };
  };
  // create thumbnail after video has been successfully uploaded into s3
  const getVideoCover = (videoObject, seekTo = 1.1) => new Promise((resolve, reject) => {
    setProcessMessage('Creating Video Screenshot...');
    // console.log(`video cover: ${file.name}`);
    // load the file to a video player
    let duration;
    // console.log(videoObject);
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('crossOrigin', 'Anonymous');
    // console.log(`video player: ${videoPlayer}`);
    const fileUrl = videoObject.filePath;
    // console.log(`url: ${fileUrl}`);
    videoPlayer.setAttribute('src', fileUrl);
    // console.log(`player: ${videoPlayer}`);
    videoPlayer.load();
    videoPlayer.addEventListener('error', (ex) => {
      // console.log(`error when loading video file: ${ex}`);
      reject('error when loading video file', ex);
    });
    videoPlayer.onloadedmetadata = () => {
      // load metadata of the video to get video duration and dimensions
      // seek to user defined timestamp (in seconds) if possible
      // console.log(`loaded video duration: ${videoPlayer.duration}`);
      if (videoPlayer.duration < seekTo) {
        // console.log('video is too short');
        reject('video is too short.');
        return;
      }
      duration = videoPlayer.duration;
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        // console.log(`seek to: ${seekTo}`);
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.onseeked = () => {
        // console.log('reached');
        // console.log('video is now paused at %ss.', seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        // console.log(videoPlayer.videoWidth);
        if (videoPlayer.videoWidth > 2000) {
          canvas.width = videoPlayer.videoWidth / 3;
          canvas.height = videoPlayer.videoHeight / 3;
        } else if (videoPlayer.videoWidth > 1000) {
          canvas.width = videoPlayer.videoWidth / 2;
          canvas.height = videoPlayer.videoHeight / 2;
        } else {
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
        }
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        // your code goes here
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        // console.log(`videoplayer: ${videoPlayer}`);
        // console.log(`canvas: ${ctx}`);
        // console.log(`canvas width: ${canvas.width}`);
        // console.log(`canvas height: ${canvas.height}`);
        ctx.canvas.toBlob(
          (blob) => {
            // console.log(`new blob: ${blob.name}`);
            // console.log(`new blob: ${duration}`);
            const thumbNail = new File([blob], videoObject.thumbName, {
              type: 'image/png',
            });
            resolve({ thumbNail, duration: videoPlayer.duration, thumbSize: thumbNail.size });
          },
          'image/png',
          0.9, /* quality */
        );
      };
    };
  });
  const getFileSize = async (videoObject) => {
    // working on it
    const data = {
      key: videoObject.fileName,
    };
    const response = await axios.post('/api/upload/checkFileSize', data);
    return response.data.fileSize;
  };

  const waitForTaskCompletion = (taskId, keys) => new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: `Bearer ${keys.convertKey}` },
    };
    const task = setInterval(async () => {
      const res = await axios.get(`${cloudConverUrl}/v2/tasks/${taskId}`, config);
      // console.log(`get it: ${res.data.data.status}`);
      if (res.data.data.status === 'finished' || res.data.data.status === 'error') {
        clearInterval(task);
        resolve(res.data.data);
      }
    }, 5000);
  });

  const onDropAccepted = async (files) => {
    setStatus(UploadStatus.PROCESSING);
    const everyImageFiles = [];
    const standardVideos = [];
    const videosToConvert = [];
    let allRecordedPosts = [];
    const allFileObjects = [];
    // categorize files based on type
    // console.log(files[0]);
    for (let i = 0; i < files.length; i += 1) {
      const extension = files[i].path.split('.').pop();
      if (files[i].type === 'image/png'
        || files[i].type === 'image/jpg' || files[i].type === 'image/jpeg') {
        // console.log(`image: ${files[i].type}`);
        everyImageFiles.push(files[i]);
      } else if (files[i].type === 'video/mp4' || files[i].type === 'video/avi') {
        // console.log(`okay: ${files[i].type}`);
        standardVideos.push(files[i]);
      } else if (extension === 'heic' || extension === 'HEIC') {
        const imageFile = await convertHeicImages(files[i]);
        // console.log(imageFile);
        everyImageFiles.push(imageFile);
      } else {
        // fix this tommorow to account for more files
        videosToConvert.push(files[i]);
        // console.log(`nokay: ${file.type}`);
        // alert('File type not recognized or supported by the application');
        // setStatus(UploadStatus.AWAITING_FILE);
        // return;
      }
    }
    const imageFiles = Array.from(everyImageFiles);
    // const videoFiles = Array.from(everyVideoFiles);
    const videoFiles = Array.from(standardVideos);
    // series of checks
    if (everyImageFiles.length > 100) {
      alert('You cannot upload more than 100 images at once!');
      setStatus(UploadStatus.AWAITING_FILE);
      // return;
    }
    if (standardVideos.length > 10) {
      alert('You cannot upload more than 10 MP4 videos at once!');
      setStatus(UploadStatus.AWAITING_FILE);
      // return;
    }
    if (videosToConvert.length > 1) {
      alert('You cannot upload more than 1 non MP4 videos at once!');
      setStatus(UploadStatus.AWAITING_FILE);
      // return;
    }
    // get file sizes
    let totalFileSize = 0;
    files.forEach((file) => {
      totalFileSize += file.size;
    });
    if (totalFileSize + currentSpace > maxSpace) {
      alert('Ran out of File space! Please either delete existing files or upgrade your account.');
      setStatus(UploadStatus.AWAITING_FILE);
      return;
    }
    // create thumbnail for each video file
    const nonConvertedVideoObjects = [];
    let updatedVideoFiles = [];
    if (videoFiles.length > 0) {
      const videoThumbs = [];
      for (let i = 0; i < videoFiles.length; i += 1) {
        const baseName = nanoid();
        const uploadFileName = `${baseName}.mp4`;
        const renamedFile = new File([videoFiles[i]], uploadFileName);
        const uploadThumbName = `thumb_${baseName}.png`;
        const filePath = `${s3BucketUrl}${uploadFileName}`;
        const thumbNailPath = `${s3BucketUrl}${uploadThumbName}`;
        const videoObject = {
          writer: profile._id,
          filePath,
          fileSize: videoFiles[i].size,
          fileName: uploadFileName,
          thumbNail: thumbNailPath,
          thumbName: uploadThumbName,
          // thumbSize: thumbFiles[index].size,
          type: 'video/mp4',
          // duration: thumbFiles[index].duration,
        };
        nonConvertedVideoObjects.push(videoObject);
        allFileObjects.push(videoObject);
        updatedVideoFiles.push(renamedFile);
      }
      const videoPromisesRes = await axios.post('/api/upload/uploadFilesV2', { allFiles: nonConvertedVideoObjects, profileId: profile._id });
      // eslint-disable-next-line no-unreachable
      const videoUploadRes = await postAllData(videoPromisesRes.data.promises, updatedVideoFiles);
      // console.log(videoUploadRes);
      updatedVideoFiles = videoPromisesRes.data.allVideos;
      const updatedVideoPosts = videoPromisesRes.data.allPosts;
      if (videoUploadRes[0].status === 204) {
        for (let i = 0; i < updatedVideoFiles.length; i += 1) {
          const videoThumb = await getVideoCover(updatedVideoFiles[i]);
          // console.log(videoThumb.duration);
          updatedVideoFiles[i].thumbSize = videoThumb.thumbSize;
          updatedVideoFiles[i].duration = videoThumb.duration;
          updatedVideoPosts[i].thumbSize = videoThumb.thumbSize;
          updatedVideoPosts[i].duration = videoThumb.duration;
          videoThumbs.push(videoThumb.thumbNail);
        }
        allRecordedPosts = allRecordedPosts.concat(updatedVideoPosts);
        const uploadResponse = await axios.post('/api/upload/uploadVideoThumbs', { allVideos: updatedVideoFiles, allPosts: updatedVideoPosts, profileId: profile._id });
        // console.log(uploadResponse);
        // const thumbRes = await postAllData(uploadResponse.data.promises, allThumbFiles);
        await postAllData(uploadResponse.data.promises, videoThumbs);
      }
    }
    // mp4 videos processed here
    // process images here
    let allImageObjects = [];
    if (imageFiles.length > 0) {
      const newCompressedImages = await compressImages(imageFiles);
      const newImageThumbnails = await thumbnailImages(imageFiles);
      for (let i = 0; i < newCompressedImages.length; i += 1) {
        const uploadFileName = `${newCompressedImages[i].name}`;
        // const uploadThumbName = `thumb_${baseName}.png`;
        const filePath = `${s3BucketUrl}${uploadFileName}`;
        const thumbNailPath = `${s3BucketUrl}${newImageThumbnails[i].name}`;
        const imageObject = {
          writer: profile._id,
          filePath,
          fileSize: newCompressedImages[i].size,
          fileName: uploadFileName,
          thumbNail: thumbNailPath,
          thumbName: newImageThumbnails[i].name,
          thumbSize: newImageThumbnails[i].size,
          type: newCompressedImages[i].type,
        };
        // console.log(imageObject);
        allImageObjects.push(imageObject);
        allFileObjects.push(imageObject);
      }
      const imagePromisesRes = await axios.post('/api/upload/uploadFilesV2', { allFiles: allImageObjects, profileId: profile._id });
      const imageUploadRes = await postAllData(imagePromisesRes.data.promises, newCompressedImages);
      if (imageUploadRes[0].status === 204) {
        allImageObjects = imagePromisesRes.data.allImages;
        allRecordedPosts = allRecordedPosts.concat(imagePromisesRes.data.allPosts);
        const uploadResponse = await axios.post('/api/upload/uploadImageThumbs', { allFiles: allFileObjects, allImages: allImageObjects, profileId: profile._id });
        // console.log(uploadResponse);
        const thumbRes = await postAllData(uploadResponse.data.promises, newImageThumbnails);
        // create screenshot blobs for all videos
      }
    }
    // convert videos
    const allConvertedVideoObjects = [];
    const allConvertedThumbFiles = [];
    if (videosToConvert.length > 0) {
      // const newSocket = io.connect('https://socketio.cloudconvert.com');
      // setSocket(newSocket);
      // newSocket.disconnect();
      // console.log('test');
      const response = await axios.post('/api/upload/retrieve-convert-key', { profileId: profile._id });
      const { keys } = response.data;
      // console.log(`keys: ${keys}`);
      for (let i = 0; i < videosToConvert.length; i += 1) {
        // start video object
        const baseName = nanoid();
        const uploadFileName = `${baseName}.mp4`;
        const uploadThumbName = `thumb_${baseName}.png`;
        const filePath = `${s3BucketUrl}${uploadFileName}`;
        const thumbNailPath = `${s3BucketUrl}${uploadThumbName}`;
        const videoObject = {
          writer: profile._id,
          filePath,
          // fileSize: file.size,
          fileName: uploadFileName,
          thumbNail: thumbNailPath,
          thumbName: uploadThumbName,
          // thumbSize: thumbFiles[index].size,
          type: 'video/mp4',
          // duration: thumbFiles[index].duration,
        };

        const uploadTask = await uploadVideoToConvert(videosToConvert[i], keys);
        // const uploadJobResult = await waitForTaskCompletion(uploadJob.taskId, keys);
        // console.log(`help: ${uploadTask.taskId}`);
        const convertTask = await convertVideo(videoObject, keys, uploadTask.taskId);
        const convertTaskResult = await waitForTaskCompletion(convertTask.taskId, keys);
        if (convertTaskResult) {
          const uploadS3Task = await uploadToS3Task(videoObject, keys, convertTask.taskId);
          const s3UploadTaskResult = await waitForTaskCompletion(uploadS3Task.taskId, keys);
          // console.log(s3UploadTaskResult);
          if (s3UploadTaskResult) {
            const videoSize = await getFileSize(videoObject);
            const convertedVideoData = await getVideoCover(videoObject);
            videoObject.thumbSize = convertedVideoData.thumbSize;
            videoObject.duration = convertedVideoData.duration;
            videoObject.fileSize = videoSize;
            allConvertedThumbFiles.push(convertedVideoData.thumbNail);
            allConvertedVideoObjects.push(videoObject);
            allFileObjects.push(videoObject);
          }
        }
      }
      const uploadResponse = await axios.post('/api/upload/uploadConvertedFiles', { allFiles: allConvertedVideoObjects, profileId: profile._id });
      allRecordedPosts = allRecordedPosts.concat(uploadResponse.data.allPosts);
      // cconsole.log(uploadResponse);
      updatedVideoFiles = updatedVideoFiles.concat(uploadResponse.data.allVideos);
      const thumbRes = await postAllData(uploadResponse.data.promises, allConvertedThumbFiles);
    }
    // console.log(`recorded posts: ${allRecordedPosts[0].thumbnail}`);
    setThumbnailSrc(allFileObjects);
    // console.log(allFileObjects[0].thumbNail);
    setViewThumbnails(allFileObjects.slice(0, 8));
    setUploadedImages(allImageObjects);
    setUploadedVideos(updatedVideoFiles);
    setUploadedPosts(allRecordedPosts);
    setThumbFiles(allFileObjects);
    setProcessMessage('Processing...');
    setStatus(UploadStatus.FILE_ACCEPTED);
    // setVideoUploadId(uploadTask.taskId);
    // if (allConvertedVideosData.length > 0) return;
  };
  const onDropRejected = () => alert('One or more of your file`s format is not supported by Kloak!');

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4', '.MOV', '.MPEG', '.mpg', '.wmv', '.HEIC'], // TODO: Accept other video types
    multiple: true,
    maxFiles: 100,
    onDropAccepted,
    onDropRejected,
  });

  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);

  const renderDropzone = () => (
    <Flex {...getRootProps({ style: dropzoneStyle })}>
      <input {...getInputProps()} />
      <Flex
        flexDirection="column"
        width={{
          base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1200px',
        }}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Image className={classes.uploadIcon} size="32px" objectFit="contain" src={UploadImg} />
        <Text fontSize={{ base: '1.2em', md: '1.5em' }} fontWeight="300" textAlign="center">Drag and drop your files here to upload</Text>
        <Text>or click here</Text>
      </Flex>
    </Flex>
  );

  const renderUploadProgressIndicator = () => (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={{
        base: '100vw', sm: '100vw', md: '520px', lg: '770px', xl: '1200px',
      }}
    >
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FF1A75"
        size="xl"
        mb="8px"
      />
      {
          videoConverting !== ''
            ? (
              <Box>
                <Text
                  textAlign="center"
                  fontSize="1.2em"
                  fontWeight="500"
                >
                  {videoProgress}%
                </Text>
                <Text
                  textAlign="center"
                  fontSize="1.2em"
                  fontWeight="500"
                >
                  {videoConverting}
                </Text>
              </Box>
            ) : <Box />
        }
      {
          status === UploadStatus.UPLOADING
            ? (
              <Text
                textAlign="center"
                fontSize="1.1em"
                fontWeight="400"
              >
                Uploading...
              </Text>
            )
            : <Box />
        }
      {
          status === UploadStatus.PROCESSING
            ? (
              <Text
                textAlign="center"
                fontSize="1.1em"
                fontWeight="400"
              >
                {processMessage}
              </Text>
            )
            : <Box />
        }
      {
          status === UploadStatus.PAGE_LOADING
            ? (
              <Text
                textAlign="center"
                fontSize="1.1em"
                fontWeight="400"
              >
                Loading...
              </Text>
            )
            : <Box />
        }
      {
          newUserUploader
            ? (
              <Box>
                <InviteFamilyModal profileId={profile._id} />
              </Box>
            ) : <Box />
        }
    </Flex>
  );

  const renderDetailsForm = () => (
    <Box>
      <Formik
        initialValues={{
          title: '',
          album: '',
          albumDescription: '',
          description: '',
          category: 'FILM',
          privacy: '',
        }}
        validationSchema={Yup.object().shape({
          privacy: Yup.string()
            .required('Must select a privacy option'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setStatus(UploadStatus.UPLOADING);
          let label = '';
          Privacy.forEach((item) => {
            if (item.value === values.privacy) label = item.label;
          });
          const updateData = {
            title: values.title,
            privacy: values.privacy,
            privacyLabel: label,
            description: values.description,
          };
          let album;
          if (values.album) {
            // console.log(`posts: ${uploadedPosts}`);
            album = {
              writer: profile._id,
              name: values.album,
              description: values.albumDescription,
              thumbnail: viewThumbnails[0].thumbNail,
              privacy: values.privacy,
              privacyLabel: label,
            };
          }
          // console.log(`all files including thumbs: ${allFiles}`);
          // console.log(`all thumbs: ${thumbFiles}`);
          axios({
            method: 'POST',
            url: '/api/upload/updateAll',
            data: {
              mediaData: updateData,
              allVideos: uploadedVideos,
              allImages: uploadedImages,
              allPosts: uploadedPosts,
              profileId: profile._id,
              album,
            },
            timeout: 0,
          })
            .then((response) => {
              // console.log(response.data.promises)
              if (response.data.success) {
                if (profile.uploadCount < 2) {
                  setNewUserUploader(true);
                } else {
                  alert('File(s) have been sucessfully uploaded!');
                  history.push('/my-profile');
                }
              }
            })
            .catch((err) => {
              setFormErrorMessage(err);
            });
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box
              onSubmit={handleSubmit}
            >
              <Image className={classes.uploadIcon} size="32px" objectFit="contain" src={newPath} />
              {thumbFiles && (
              <>
                {/* TODO: Add support for custom thumbnails */}
                {viewThumbnails.length > 1
                  ? (
                    <SimpleGrid
                      gridTemplateColumns={{
                        base: '1fr 1fr 1fr',
                        md: '1fr 1fr 1fr',
                      }}
                      gridTemplateRows={{
                        base: '1fr 1fr',
                      }}
                      spacing="10px"
                      width={{
                        base: '90vw', sm: '90vw', md: '90vw', lg: '500px', xl: '700px',
                      }}
                      marginX="auto"
                      marginY="1rem"
                    >
                      {thumbnailSrc.map((thumbnail) => (
                        <AspectRatio ratio={16 / 9}>
                          <Image
                            src={thumbnail.thumbNail}
                            objectFit="cover"
                            crossOrigin="anonymous"
                          />
                        </AspectRatio>
                      ))}
                      <ImageViewModal imageList={viewThumbnails} />
                    </SimpleGrid>
                  ) : (
                    <Box
                      width={{
                        base: '90vw', sm: '90vw', md: '90vw', lg: '500px', xl: '500px',
                      }}
                    >
                      <AspectRatio ratio={16 / 9}>
                        <Image
                          src={thumbnailSrc[0].thumbNail}
                          objectFit="cover"
                          crossOrigin="anonymous"
                        />
                      </AspectRatio>
                    </Box>
                  )}
              </>
              )}
              {viewThumbnails?.length <= 1
                ? (
                  <Box>
                    <Field name="title">
                      {() => (
                        <FormControl marginY="25px">
                          <FormLabel htmlFor="title" ml="4px" fontWeight="450">
                            Title
                          </FormLabel>

                          <Input
                            id="title"
                            placeholder="Add a title to your file"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="description">
                      {() => (
                        <FormControl mb="25px">
                          <FormLabel htmlFor="description" ml="4px" fontWeight="450">
                            Description
                          </FormLabel>

                          <Input
                            id="description"
                            placeholder="Add a description to your file"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                ) : <Box />}
              <Field
                name="album"
              >
                {() => (
                  <FormControl
                    mb="4px"
                  >
                    <FormLabel htmlFor="album" ml="4px" fontWeight="450">
                      Album Name (Optional)
                    </FormLabel>

                    <Input
                      id="album"
                      placeholder="Create an album by adding an album name"
                      type="text"
                      value={values.album}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="albumDescription">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel htmlFor="albumDescription" ml="4px" fontWeight="450">
                      Album Description (Optional)
                    </FormLabel>

                    <Textarea
                      id="albumDescription"
                      placeholder="Add a album description"
                      value={values.albumDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              {
                  /*
                <Field name="existing_album">
                  {() => (
                    <FormControl
                      isInvalid={errors.privacy && touched.privacy}
                      mb="25px"
                    >
                      <FormLabel htmlFor="existing_album" ml="4px" fontWeight="450">
                        Add to an existing Album
                      </FormLabel>

                      <Select id="existing_album" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                        {existingAlbums.map((album) => (
                          <option key={album} value={album._id}>
                            {album.name}
                          </option>
                        ))}
                      </Select>
                      <div className={classes.formErrorMessage}>
                        {touched.privacy && errors.privacy}
                      </div>
                    </FormControl>
                  )}
                </Field>
                */
                }
              <Field name="privacy">
                {() => (
                  <FormControl
                    isRequired
                    isInvalid={errors.privacy && touched.privacy}
                    mb="25px"
                  >
                    <FormLabel htmlFor="privacy" ml="4px" fontWeight="450">
                      Set privacy of your File(s)
                    </FormLabel>

                    <Select id="privacy" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                      {Privacy.map((item) => (
                        <option key={item} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                    <div className={classes.formErrorMessage}>
                      {touched.privacy && errors.privacy}
                    </div>
                  </FormControl>
                )}
              </Field>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                marginY="1rem"
              >
                {
                /*
                <Button
                  type="cancel"
                  onClick={cancelPublish}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="auto"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Cancel
                </Button>
                */
                }
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Publishing..."
                  onClick={handleSubmit}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="auto"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Publish
                </Button>
              </Flex>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
  const renderFirstTimeUpload = () => (
    <Box>
      <InviteFamilyModal />
    </Box>
  );
  const renderContent = () => {
    switch (status) {
      case UploadStatus.AWAITING_FILE:
        return renderDropzone();
      case UploadStatus.FILE_ACCEPTED:
        return renderDetailsForm();
      case UploadStatus.UPLOADING:
        return renderUploadProgressIndicator();
      case UploadStatus.PROCESSING:
        return renderUploadProgressIndicator();
      case UploadStatus.PROCESSING_COMPLETE:
        return renderDetailsForm();
      case UploadStatus.LOADING:
        return renderUploadProgressIndicator();
      case UploadStatus.UPLOAD_SUCCESS:
        return renderFirstTimeUpload();
      default:
        return renderDetailsForm();
    }
  };

  return (
    <Flex
      className="app"
    >
      { /* <AlertBanner message={alertMessage} /> */ }
      <Heading fontWeight="500" fontSize={{ base: '1.3em', sm: '1.5em', lg: '2em' }} margin="2rem">Upload Media File(s)</Heading>
      {loadcomplete
        ? (
          <Box>
            {renderContent()}
          </Box>
        )
        : (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={{
              base: '100vw', sm: '100vw', md: '520px', lg: '770px', xl: '1200px',
            }}
          >
            <Spinner
              thickness="4px"
              emptyColor="gray.200"
              color="#FF1A75"
              size="xl"
              mb="8px"
            />
            <Text>Loading...</Text>
          </Flex>
        )}
    </Flex>
  );
}

function ImageViewModal(props) {
  const {
    imageList,
  } = props;
  const {
    isOpen: isOpenViewModal,
    onOpen: onOpenViewModal,
    onClose: onCloseViewModal,
  } = useDisclosure();

  const [errMessage, setErrMessage] = useState();
  const classes = useStyles();

  return (
    <Box>
      <AspectRatio ratio={16 / 9}>
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          backgroundColor="#DDD"
          cursor="pointer"
          onClick={onOpenViewModal}
        >
          <SeeMoreButton />
        </Flex>
      </AspectRatio>
      <Modal
        isOpen={isOpenViewModal}
        onClose={onCloseViewModal}
        size={{
          base: '95%', sm: '95%', md: '95%', xl: '75%',
        }}
        margin="auto"
      >
        <ModalOverlay />
        <ModalContent
          width={{
            base: '95%', sm: '95%', md: '95%', xl: '75%',
          }}
        >
          <ModalHeader>Mass Upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding="10px">
            <SimpleGrid
              gridTemplateColumns={{
                base: '1fr',
                sm: '1fr',
                md: '1fr 1fr 1fr',
                xl: '1fr 1fr 1fr 1fr 1fr',
              }}
              gridTemplateRows={{
                base: '1fr 1fr',
              }}
              spacing="10px"
              marginX="auto"
              marginY="1rem"
            >
              {imageList.map((thumbnail) => (
                <AspectRatio ratio={16 / 9}>
                  <Image
                    src={thumbnail.thumbNail}
                    objectFit="cover"
                  />
                </AspectRatio>
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

MassUploadStable.propTypes = propTypes;

export default withRouter(MassUploadStable);
