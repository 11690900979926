/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Divider,
  Spinner,
  Button,
  AspectRatio,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { createUseStyles } from 'react-jss';
import FamilyPosts from './sections/FamilyPosts';
import EmptyHome from './sections/EmptyHome';

const useStyles = createUseStyles({
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});
function LandingPage() {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const [profile, setProfile] = useState();
  const [errorMessage, setFormErrorMessage] = useState('');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [perVideoPage, setPerVideoPage] = useState(9);
  /// image sections
  const [imagesLoading, setImagesLoading] = useState(true);

  const [lastImagePageReached, setLastImagePageReached] = useState(false);
  const [initializedImages, setInitializedImages] = useState(false);
  // rec videos
  const [recVideos, setRecVideos] = useState([]);
  const [recommendedLoading, setRecommendedLoading] = useState(true);
  const [lastRecPageReached, setLastRecPageReached] = useState(false);
  const [initializedRecommended, setInitializedRecommended] = useState(false);
  const [recPage, setRecPage] = useState(0);

  // Family images
  const [connectionsFetched, setConnectionsFetched] = useState(false);
  const [connections, setConnections] = useState([]);
  const [displayErr, setDisplayErr] = useState();
  const [familyPosts, setFamilyPosts] = useState();
  const [familyPostsLoaded, setFamilypostsLoaded] = useState(false);
  const [emptyHome, setEmptyHome] = useState(true);

  const getFamilyMedia = (profileId) => {
    axios({
      method: 'POST',
      url: '/api/profile/find-media-byfamily',
      data: {
        profileId,
      },
    })
      .then((res) => {
        // console.log(res.data.profiles);
        setFamilyPosts(res.data.profiles);
        setFamilypostsLoaded(true);
        let countPosts = 0;
        res.data.profiles.forEach((friend) => {
          countPosts += friend.posts.length;
        });
        if (countPosts > 0) {
          setEmptyHome(false);
        } else {
          setEmptyHome(true);
        }
        window.scrollTo(0, 0);
      })
      .catch((profilesErr) => {
        setDisplayErr(profilesErr);
      });
  };
  useEffect(() => {
    if (user.userData) {
      axios({
        method: 'POST',
        url: '/api/users/getProfile',
        data: {
          username: user.userData.username,
        },
      })
        .then((data) => {
          axios.get(`/api/connection/all/${data.data._id}`).then((response) => {
            // console.log(response.data.connections);
            setConnections(response.data.connections);
            if (response.data.connections.friends.length > 0) {
              setConnectionsFetched(true);
            }
            setProfile(data.data);
            getFamilyMedia(data.data._id);
          });
        })
        .catch((err) => {
          setFormErrorMessage('Cannot fetch user profile');
        });
    }
  }, [user.userData, user]);

  useEffect(() => {
    window.onscroll = debounce(() => {
      if ((window.innerHeight + document.documentElement.scrollTop
         === document.scrollingElement.scrollHeight)
        && (lastRecPageReached === false) && (initializedRecommended)) {
        // Do awesome stuff like loading more content!
        // Do load more content here!
        // console.log("hello 1")
        setRecommendedLoading(true);
        getRecVideos();
      }
    }, 300);
  });
  const getRecVideos = () => {
    // console.log("called: " + profile._id)
    axios({
      method: 'POST',
      url: '/api/video/latestVideoQueryList',
      data: {
        profileId: profile._id,
        page: recPage,
        perPage: perVideoPage,
      },
    })
      .then((data) => {
      // console.log(data.data.videos)
        if (!initializedRecommended) {
          setInitializedRecommended(true);
        }
        setRecVideos(recVideos.concat(data.data.videos));
        if (data.data.videos.length === 0) {
          setLastRecPageReached(true);
          setRecommendedLoading(false);
          return;
        }

        setRecPage(recPage + 1);
        setRecommendedLoading(false);
      })
      .catch((err) => {
      // console.log(err);
        let errMsg;

        switch (err?.response?.status) {
          case 403:
            errMsg = 'You do Not have access to these images';
            break;
          case 500:
            errMsg = 'Server is currently down';
            break;
          default:
            errMsg = 'Something has went wrong';
            break;
        }
        setFormErrorMessage(errMsg);
        setTimeout(() => {
          setFormErrorMessage('');
        }, 3000);
      });
  };

  const renderFamilyPosts = () => familyPosts.map((freindProfile) => (
    <FamilyPosts
      key={`family_post_${freindProfile._id}`}
      posts={freindProfile.posts}
      profile={freindProfile}
    />
  ));
  return (
    <Box
      width="100%"
    >
      <Box position="relative" width="95%" left="5%" top="5%">
        <Heading
          fontWeight="600"
          fontSize={{ base: '1.6em', sm: '2.5em' }}
          color="#0B67C2"
        >
          Home
        </Heading>
      </Box>
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignSelf="flex-start"
        paddingLeft={{ base: '0' }}
        minHeight="90vh"
      >
        <Box
          width="90%"
          margin="auto"
        >
          {familyPostsLoaded
            ? (
              <Box
                width="100%"
              >
                <Box
                  width={{
                    base: '100%',
                  }}
                  margin="auto"
                >
                  {emptyHome
                    ? <EmptyHome />
                    : (
                      <Box
                        width={{
                          base: '100%',
                        }}
                        marginLeft="auto"
                        marginRight="auto"
                        marginTop="6em"
                      >
                        {renderFamilyPosts()}
                      </Box>
                    )}
                  <Divider width="100%" marginY="48px" />
                </Box>
              </Box>
            ) : (
              <Flex flexDirection="column" justifyContent="center" alignItems="center">
                <Spinner
                  thickness="4px"
                  emptyColor="gray.200"
                  color="#FF1A75"
                  size="xl"
                  mb="8px"
                  width="50px"
                  margin="auto"
                />
                <Text>Loading</Text>
              </Flex>
            )}
        </Box>
      </Flex>
    </Box>
  );
}
export default LandingPage;
