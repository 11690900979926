/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import heic2any from 'heic2any';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Heading,
  Flex,
  Spinner,
  Stack,
  Box,
  SimpleGrid,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Divider,
} from '@chakra-ui/react';
import {
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { nanoid } from 'nanoid';
import { compress, compressAccurately } from 'image-conversion';
import axios from 'axios';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import { Videos } from '@styled-icons/boxicons-solid/Videos';
import { Images } from '@styled-icons/bootstrap/Images';
import { PhotoAlbum } from '@styled-icons/boxicons-solid/PhotoAlbum';
import VideoModal from './sections/VideoModal';
import ImageModalB from './sections/ImageModalB';
import EmptyLibrary from './sections/EmptyLibrary';
import ProfileLibrary from './sections/ProfileLibrary';
import ProfileAlbums from './sections/ProfileAlbums';
import Albums from './sections/Albums';

const StyledTab = styled(Tab)`
  margin: 0;
  &:focus {
    outline:0 !important;
    box-shadow: none;
    border: none border-color: #FFF !important;
  }
`;
const VideoButton = styled(Videos)`
  color: ##1890ff;
  width: 36px;
`;

const ImageButton = styled(Images)`
  color: ##1890ff;
  width: 36px;
`;
const AlbumButton = styled(PhotoAlbum)`
  color: ##1890ff;
  width: 36px;
`;
const baseDropzoneStyle = {
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: '50%',
  borderColor: '#EEEEEE',
  padding: '2px',
  cursor: 'pointer',
};

const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});
const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
function ProfilePage2({
  history,
}) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [avatarImg, setAvatarImg] = useState();
  const [profile, setProfile] = useState({});
  const [formErrorMessage, setFormErrorMessage] = useState();
  const [updateMessage, setUpdateMessage] = useState();
  const [profileLoading, setProfileLoading] = useState(true);
  const [deleteImage, setDeleteImage] = useState();
  const [images, setImages] = useState([]);
  const [albumsPerPage, setAlbumsPerPage] = useState(15);
  const [initializedAlbums, setInitializedAlbums] = useState();
  const [albums, setAlbums] = useState([]);
  const [albumsPage, setAlbumePage] = useState(0);
  const [emptyProfile, setEmptyProfile] = useState(true);
  const [lastPageAlbumReached, setLastPageAlbumReached] = useState(false);
  const [itemsPerAlbumPage, setAlbumsPerMediaPage] = useState(4);
  const [currentAlbumIndex, setCurrenAlbumIndex] = useState(0);

  const loadMoreAlbums = () => {
    const albumArr = [];
    for (let i = currentAlbumIndex; i < albums.length; i += 1) {
      if (i === albums.length - 1) {
        setLastPageAlbumReached(true);
        setCurrenAlbumIndex(i);
        albumArr.push(albums[i]);
        break;
      }
      if (i === currentAlbumIndex + itemsPerAlbumPage) {
        setCurrenAlbumIndex(i);
        break;
      }
      albumArr.push(albums[i]);
    }
    setAlbums(albums.concat(albumArr));
    // console.log('this videos' + video.filePath)

    // console.log(`here I am ${displayAlbums}`);
  };

  const fetchProfile = () => {
    axios({
      method: 'POST',
      url: '/api/profile/get-my-profile',
      data: {
        username: user.userData.username,
      },
    })
      .then((data) => {
        // console.log(`profile data: ${data.data.posts}`);
        setProfile(data.data);
        setDeleteImage(data.data.image.split('/').pop());
        if (data.data.posts.length <= 0) {
          setEmptyProfile(true);
        } else {
          setEmptyProfile(false);
        }
        setProfileLoading(false);
      })
      .catch((err) => {
        setFormErrorMessage('Cannot fetch user profile');
      });
  };
  useEffect(() => {
    if (user.userData) {
      fetchProfile();
    }
  }, [user, user.userData]);

  const convertHeicImages = async (file) => {
    const filePath = URL.createObjectURL(file);
    const res = await fetch(filePath);
    const blob = await res.blob();
    const conversionResult = await heic2any({ blob, toType: 'image/png' });
    const newFile = new File([conversionResult], `${nanoid()}.png`, {
      type: 'image/png',
    });
    return newFile;
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ['image/*', '.HEIC', '.heic'],
    multiple: false,
    onDrop: async (acceptedFile) => {
      // console.log(acceptedFile);
      const extension = acceptedFile[0].path.split('.').pop();
      // console.log(extension);
      if (extension === 'heic' || extension === 'HEIC') {
        setProfileLoading(true);
        const imageFile = await convertHeicImages(acceptedFile[0]);
        setAvatarImg(
          Object.assign(imageFile, { preview: URL.createObjectURL(imageFile) }),
        );
        setProfileLoading(false);
      } else {
        setAvatarImg(
          Object.assign(acceptedFile[0], { preview: URL.createObjectURL(acceptedFile[0]) }),
        );
      }
    },
  });

  const updateImageList = (newImage) => {
    const updatedImage = newImage;
    const newImageList = [];
    images.forEach((eachImage) => {
      if (eachImage._id === updatedImage._id) {
        updatedImage.writer = eachImage.writer;
        newImageList.push(updatedImage);
      } else {
        newImageList.push(eachImage);
      }
    });
    setImages(newImageList);
  };

  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);

  if (!user.userData) {
    return (
      <Flex className="app" mt="128px">
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="#FF1A75"
          size="xl"
        />
      </Flex>
    );
  }
  // https://github.com/WangYuLue/image-conversion
  /*
  const thumbnailImages =  (file) => {

    const options = {
      size: 200,
      accuracy: .9,
      width: 320,
      height: 240,
    }
    try {
      // converts files to compressed blobs
      compressAccurately(file, options, (result) => {
        // convert back to File
       // console.log(result)
        const newFile = new File([result], file.name)
        return newFile;
      });
    } catch (error) {
      setFormErrorMessage(error);
    }
  } */
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // console.log("location: " + avatarImg.location);
    const options = {
      size: 400,
      accuracy: 1,
      scale: 0.2,
    };
    compressAccurately(avatarImg, options)
      .then(async (result) => {
        // console.log(result)
        const newFile = new File([result], avatarImg.name);
        // console.log(newFile)
        formData.append('file', newFile);
        formData.append('profileId', profile._id);
        formData.append('userId', user.userData._id);
        setProfileLoading(true);
        const config = {
          header: { 'content-type': 'multipart/form-data' },
        };
        try {
          await axios.post('/api/users/updateUserImage', formData, config);
          axios({
            method: 'POST',
            url: '/api/users/deleteImage',
            data: {
              filename: deleteImage,
            },
          })
            .then(() => {
              window.location.reload();
            })
            .catch((err) => {
              setFormErrorMessage(err.message);
            });
        } catch (err) {
          // TODO: Handle exception
          setFormErrorMessage(err.message);
        }
      })
      .catch((err) => {
        setFormErrorMessage(err.message);
      });
  };

  const updateMyAlbum = (updatedAlbum) => {
    const newAlbum = updatedAlbum;
    const newAlbumList = [];
    // console.log(updatedVideo);
    albums.forEach((eachAlbum) => {
      // console.log(eachVideo);
      if (eachAlbum._id === updatedAlbum._id) {
        newAlbum.writer = eachAlbum.writer;
        newAlbumList.push(newAlbum);
      } else {
        newAlbumList.push(eachAlbum);
      }
    });
    setAlbums(newAlbumList);
  };
  const updateAlbumsList = (newlist) => {
    setAlbums(newlist);
  };

  return (
    <Flex
      marginY="1.5rem"
      minHeight="90vh"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      width="95%"
      marginX="auto"
    >
      <Flex className="app" marginTop="2em">
        {profileLoading
          ? (
            <Box>
              <Spinner
                thickness="4px"
                emptyColor="gray.200"
                color="#FF1A75"
                size="xl"
                mb="8px"
              />
              <Text>{updateMessage}</Text>
            </Box>
          )
          : (
            <div {...getRootProps({ style: dropzoneStyle })}>
              <input {...getInputProps()} />
              <Box
                as="img"
                crossOrigin="anonymous"
                src={avatarImg?.preview ?? profile?.image}
                width="125px"
                height="125px"
                objectFit="cover"
                borderRadius="50%"
                verticalAlign="middle"
                nar
              />
            </div>
          )}
        <Heading fontWeight="300">{profile?.username}</Heading>
        <Stack isInline marginTop="1em">
          <Button
            onClick={onSubmit}
            display="block"
            isDisabled={!avatarImg}
            width="110px"
            color="white"
            borderRadius="24px"
            bg="#FF1A75"
            _hover={{
              bg: '#FFE6EE',
              color: '#FF1A75',
            }}
          >
            Update
          </Button>
          <Button
            onClick={() => {}}
            isLoading={false}
            variant="outline"
            display="block"
            width="110px"
            borderRadius="8px"
            color="#FF1A75"
            borderColor="#3480E3"
            _hover={{
              bg: '#FFE6EE',
              color: '#FF1A75',
            }}
          >
            Cancel
          </Button>
        </Stack>
        <Button
          marginY="1em"
          bg="#3480E3"
          color="#FFF"
          borderRadius="24px"
          py="4"
          px="4"
          width="230px"
          lineHeight="1"
          onClick={() => { history.push('/families'); }}
          rightIcon={<ChevronRightIcon />}
          _hover={{ bg: 'blue.300' }}
        >
          Invite Family to share
        </Button>
      </Flex>
      <Box className="app" width="90%">
        <Albums
          albums={albums}
          profile={profile}
          updateAlbum={updateMyAlbum}
          updateAlbumsList={updateAlbumsList}
          loadMoreAlbums={loadMoreAlbums}
          lastPageAlbumReached={lastPageAlbumReached}
        />
      </Box>
      <Box className="app" width="90%">
        {emptyProfile
          ? <EmptyLibrary />
          : (
            <Box
              width={{
                base: '100%',
              }}
            >
              <ProfileLibrary
                key={`post_${profile._id}`}
                posts={profile.posts}
                profile={profile}
              />
            </Box>
          )}
      </Box>
    </Flex>
  );
}
ProfilePage2.propTypes = propTypes;
export default ProfilePage2;
