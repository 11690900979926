/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  cardStyle: {
    height: '60px',
    padding: '10px',
    input: {
      padding: '10px',
    },
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});
function Payment() {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event) => {
    // testing
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
  };
  return (
    <Flex
      justifyContent="center"
      backgroundColor="#DDD"
      height="700px"
      marginY="3rem"
    >
      <Box
        onSubmit={handleSubmit}
        width="90%"
        margin="auto"
      >
        <Text
          fontSize="1.5em"
          fontWeight="600"
          marginBottom="1rem"
        >
          Payment
        </Text>
        <Box
          justifyContent="center"
          alignItems="center"
          backgroundColor="#FFF"
          paddingY="5px"
          paddingX="5px"
        >
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '24px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                  backgroundColor: '#FFF',
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </Box>
        <Button
          type="submit"
          disabled={!stripe}
          marginTop="1rem"
        >
          Submit Payment
        </Button>
      </Box>
    </Flex>
  );
}

export default Payment;
