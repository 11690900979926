/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip, Icon } from 'antd';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { createUseStyles } from 'react-jss';
import styled from 'styled-components';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';

const SeeMoreButton = styled(EyeFill)`
  width: 24px;
  color: #DDD;
  cursor: pointer;
`;
const HeartActive = styled(SuitHeartFill)`
  color: #FF0000; 
  right: 12px;
  top: 12px;
  position: relative;
  width: 16px;
`;
const HeartButton = styled(SuitHeartFill)`
  background-color: inherit;
  width: 24px;
  color: inherit;
  cursor: pointer;
`;


const propTypes = {
  image: PropTypes.bool,
  imageId: PropTypes.string,
  commentId: PropTypes.string,
  userId: PropTypes.string.isRequired,
};

const useStyles = createUseStyles({
  iconLike: {
    fontSize: '20px',
  },
  '@media screen and (max-width: 600px)': {
    iconLike: {
      fontSize: '14px',
    },
  },
});

const defaultProps = {
  image: false,
  imageId: undefined,
  commentId: undefined,
};

function ImageReaction({
  image,
  imageId,
  commentId,
  userId,
}) {
  const classes = useStyles();
  const [Likes, setLikes] = useState(0);
  const [Dislikes, setDislikes] = useState(0);
  const [LikeAction, setLikeAction] = useState(null);
  const [DislikeAction, setDislikeAction] = useState(null);
  const [reactionActive, setReactionActive] = useState(false);
  const variable = useMemo(
    () => (image ? { imageId, userId } : { commentId, userId }),
    [image, imageId, commentId, userId],
  );

  const fetchVideoReactions = (profileData) => {
    axios({
      url: '/api/image/getAllReactions',
      method: 'POST',
      data: {
        imageId,
        profile: profileData,
      },
    })
      .then((response) => {
        setImageReactions(response.data.reactions);
        // console.log(currentImage);
        // eslint-disable-next-line eqeqeq
        if (response.data.reacion === undefined) {
          setReactionActive(false);
        }
        if (response.data.reactions[0].profile._id === profileData._id) {
          // console.log('match found');
          document.getElementById('heart-button').style.color = '#FF0000';
          setReactionActive(true);
          // return;
        } else {
          setReactionActive(false);
        }

        // setReactionActive(false);
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
      })
      .catch((err) => {
        setErrMsg(err);
      });
  };
  useEffect(() => {
    Axios.post('/api/like/getLikes', variable).then((response) => {
      // console.log('getLikes', response.data);

      if (response.data.success) {
        // How many likes does this image or comment have
        setLikes(response.data.likes.length);

        // if I already click this like button or not
        response.data.likes.forEach((like) => {
          if (like.userId === userId) {
            setLikeAction('liked');
          }
        });
      } else {
        alert('Failed to get likes');
      }
    });
  }, [variable, userId]);
  const fetchImageReactions = (profileData, thisImage, nextImage, prevImage) => {
    axios({
      url: '/api/image/getAllReactions',
      method: 'POST',
      data: {
        imageId: thisImage._id,
        profile: profileData,
      },
    })
      .then((response) => {
        setImageReactions(response.data.reactions);
        // console.log(currentImage);
        // eslint-disable-next-line eqeqeq
        if (response.data.reacion === undefined) {
          setReactionActive(false);
        }
        if (response.data.reactions[0].profile._id === profileData._id) {
          // console.log('match found');
          document.getElementById('heart-button').style.color = '#FF0000';
          setReactionActive(true);
          // return;
        } else {
          setReactionActive(false);
        }
        setPrevImageId(prevImage);
        setNextImageId(nextImage);

        // setReactionActive(false);
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
      })
      .catch((err) => {
        setErrMsg(err);
      });
  };
  const fetchImageData = (profileData) => {
    // console.log(profileData);
    axios({
      method: 'POST',
      url: '/api/image/imageById',
      data: {
        imageId,
        profile: profileData,
      },
    })
      .then((response) => {
        // console.log(response.data);
        setImage(response.data.image);
        fetchImageReactions(
          profileData,
          response.data.image,
          response.data.prevImageId,
          response.data.nextImageId,
        );
      })
      .catch((err) => {
        setErrMsg(err);
      });
  };

  const reactionHandler = () => {
    // console.log(imageReactions);
    if (reactionActive === false) {
      axios({
        url: '/api/image/addImageReaction',
        method: 'POST',
        data: {
          imageId,
          profile,
          reactionType: 'Love',
        },
      })
        .then((reactionData) => {
          // console.log(`reached? ${reactionData.data.reactionData.profile._id}`);
          const newReaction = imageReactions;
          newReaction.unshift(reactionData.data.reactionData);
          // document.getElementById(`heart-button`).style.color = '#FF0000';
          setImageReactions(newReaction);
          setReactionActive(true);
          // console.log(profile.image);
        })
        .catch((err) => {
          setErrMsg(err);
        });
    } else {
      axios({
        url: '/api/image/removeImageReaction',
        method: 'POST',
        data: {
          profile,
          imageId,
        },
      })
        .then(() => {
          // console.log(imageReactions);
          const newReactions = imageReactions;
          newReactions.shift();
          // console.log(`new ${newReactions.length}`);
          setReactionActive(false);
          setImageReactions(newReactions);
        });
    }
  };
  const onLike = () => {
    if (LikeAction === null) {
      Axios.post('/api/like/upLike', variable).then((response) => {
        if (response.data.success) {
          setLikes(Likes + 1);
          setLikeAction('liked');

          // If dislike button is already clicked

          if (DislikeAction !== null) {
            setDislikeAction(null);
            setDislikes(Dislikes - 1);
          }
        } else {
          alert('Failed to increase the like');
        }
      });
    } else {
      Axios.post('/api/like/unLike', variable).then((response) => {
        if (response.data.success) {
          setLikes(Likes - 1);
          setLikeAction(null);
        } else {
          alert('Failed to decrease the like');
        }
      });
    }
  };



  return (
    <Box width="100%">
      <Box color={reactionActive === false ? '#DDD' : '#FF0000'}>
        <HeartButton id="heart-button" onClick={() => reactionHandler()} />
      </Box>
    </Box>
  );
}

ImageReaction.propTypes = propTypes;
ImageReaction.defaultProps = defaultProps;

export default ImageReaction;
