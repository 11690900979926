/* eslint-disable no-alert */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import {
  Box, AspectRatio, Avatar, Text, Stack, Image, Flex,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});
const propTypes = {
  profileId: PropTypes.string.isRequired,
  postWriterId: PropTypes.string.isRequired,
  // userChangeMedia: PropTypes.func.isRequired,
  initialPost: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function SidePosts({
  profileId,
  postWriterId,
  // userChangeMedia,
  initialPost,
  history,
}) {
  const [sideImages, setSideImages] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [postsLoaded, setPostsLoaded] = useState(false);
  const [sidePosts, setSidePosts] = useState([]);
  const [currentPost, setCurrentPost] = useState();
  const classes = useStyles();
  useEffect(() => {
    if (profileId && postWriterId && initialPost) {
      axios({
        method: 'POST',
        url: '/api/post/allposts-by-id',
        data: {
          profileId,
          postWriterId,
        },
      })
        .then((response) => {
          setSidePosts(response.data.posts);
          // console.log(response.data.posts);
          // console.log(response.data.images);
          setPostsLoaded(true);
          if (!currentPost) {
            const getPostElement = document.getElementById(`postidpage_${initialPost._id}`);
            getPostElement.style.backgroundColor = '#DDD';
            const topPos = getPostElement.offsetTop;
            document.getElementById('post_scrolling_div').scrollTop = topPos - 110;
          }
          setCurrentPost(initialPost);
        })
        .catch((err) => {
          // console.log(err);
          // alert('Failed to get Videos');
        });
    }
  }, [profileId, postWriterId, initialPost]);

  const goToImage = (post, link) => {
    if (post._id === currentPost._id) return;
    document.getElementById(`postidpage_${post._id}`).style.backgroundColor = '#DDD';
    document.getElementById(`postidpage_${currentPost._id}`).style.backgroundColor = '#FFF';
    setCurrentPost(post);
    history.push(link);
    // userChangeMedia(post.video);
  };

  const goToVideo = (post, link) => {
    if (post._id === currentPost._id) return;
    document.getElementById(`postidpage_${post._id}`).style.backgroundColor = '#DDD';
    document.getElementById(`postidpage_${currentPost._id}`).style.backgroundColor = '#FFF';
    setCurrentPost(post);
    history.push(link);
    // userChangeMedia(post.image);
  };

  const renderPosts = () => sidePosts.map((post) => (
    <Box
      key={`postkeypage_${post._id}`}
      id={`postidpage_${post._id}`}
      marginBottom="0.5rem"
      paddingBottom="0.5rem"
      borderRadius=".70rem"
    >
      {post.video
        ? (
          <Box>
            <Box position="relative" textAlign="right" width="100%">
              <AspectRatio
                ratio={16 / 9}
                position="relative"
              >
                <Box
                  as="img"
                  crossOrigin="anonymous"
                  alt="thumbnail"
                  src={post.video.thumbNail}
                  objectFit="cover"
                  cursor="pointer"
                  borderRadius=".70rem"
                  boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                  onClick={() => { goToVideo(post, `/video/${post.video._id}`); }}
                />
              </AspectRatio>
              <Text
                as="span"
                position="relative"
                top="-30px"
                right="10px"
                zIndex="2"
                color="#FFFFFF"
                backgroundColor="rgb(0, 0, 0, 0.7)"
                borderRadius="4px"
                padding="4px"
              >
                {Math.floor(post.video.duration / 60)}:{`0${Math.floor(post.video.duration - Math.floor(post.video.duration / 60) * 60)}`.slice(-2)}
              </Text>
            </Box>
            <Box>
              <Stack
                isInline
                paddingLeft="0.5rem"
              >
                <Box>
                  <a href={`/video/${post.video._id}`}>
                    <Text
                      className={classes.title}
                      fontWeight="bold"
                      fontSize={{ base: '1.3em', sm: '1.4em' }}
                      color="#003971"
                      whiteSpace="wrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      marginTop="-10px"
                    >
                      {post.video.title}
                    </Text>
                  </a>
                  <Text
                    color="#999"
                    fontWeight="bold"
                    fontSize={{ base: '1.1em', sm: '1.2em' }}
                    whiteSpace="wrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {post.video.description}
                  </Text>
                  <Text
                    color="#999"
                    fontWeight="bold"
                    fontSize={{ base: '1em', sm: '1.1em' }}
                  >
                    {moment(post.video.createdAt).format('LL')}
                  </Text>
                </Box>
              </Stack>
            </Box>
          </Box>
        )
        : (
          <Box>
            <AspectRatio
              ratio={16 / 9}
              borderRadius="1rem"
              onClick={() => { goToImage(post, `/image/${post.image._id}`); }}
            >
              <Box
                as="img"
                crossOrigin="anonymous"
                alt="thumbnail"
                objectFit="cover"
                src={post.image?.thumbNail}
                cursor="pointer"
                borderRadius=".70rem"
                boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
              />
            </AspectRatio>
            <Flex>
              <Box
                marginTop={{ base: '0', md: '4px' }}
                paddingLeft="0.5rem"
              >
                <Text
                  color="#003971"
                  fontWeight="bold"
                  fontSize={{ base: '1.3em', sm: '1.4em' }}
                  whiteSpace="wrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  marginTop="0.4em"
                >
                  {post.image?.description}
                </Text>
                <Text
                  color="#999"
                  fontWeight="bold"
                  fontSize={{ base: '1.1em', sm: '1.1em' }}
                >
                  { moment(post.image?.createdAt).format('LL') }
                </Text>
              </Box>
            </Flex>
          </Box>
        )}
    </Box>
  ));

  const sideImageItem = sideImages.map((image) => (
    <Box
      id={`imageidpage_${image._id}`}
      key={image._id}
      marginBottom="8px"
      onClick={() => { goToImage(image); }}
    >
      <Box position="relative">
        <AspectRatio ratio={16 / 9} position="relative">
          <Box
            as="img"
            crossOrigin="anonymous"
            width="auto"
            alt="thumbnail"
            src={image.thumbNail}
            height="100%"
            margin="auto"
            objectFit="cover"
            cursor="pointer"
          />
        </AspectRatio>
      </Box>
      <Box padding="6px">
        <Stack isInline>
          <Avatar size="sm" src={image.writer && image.writer.image} mt="4px" />
          <Box>
            <a href={`/image/${image._id}`}>
              <Text className={classes.title} fontWeight="semibold" as="h4">{image.title}</Text>
            </a>
            <Text
              m="0"
              color="gray.600"
              fontSize="sm"
              width={{
                base: '20ch',
                sm: '45ch',
                md: '20ch',
                lg: '20ch',
                xl: '32ch',
              }}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {image.writer.username}
            </Text>
            <Text
              m="0"
              color="gray.600"
              fontSize="sm"
              width={{
                base: '20ch',
                sm: '45ch',
                md: '20ch',
                lg: '20ch',
                xl: '32ch',
              }}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {moment(image.createdAt).format('LL')}
            </Text>
          </Box>
        </Stack>
      </Box>
    </Box>
  ));

  return (
    <Box
      marginTop="1rem"
      marginBottom="2rem"
      paddingRight={{ base: '0', md: '2rem' }}
    >
      {
        postsLoaded
          ? (
            <Box
              height="76vh"
              overflowY={{ base: 'auto' }}
              id="post_scrolling_div"
            >
              {renderPosts()}
            </Box>
          )
          : (
            <Box />
          )
      }
    </Box>
  );
}
SidePosts.propTypes = propTypes;
export default withRouter(SidePosts);
