/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  GET_PROFILE,
} from './types';
import { USER_SERVER } from '../components/Config';

export function getProfile(usernameObject) {
  const request = axios
    .post(`${USER_SERVER}/getProfile`, usernameObject)
    .then((response) => response.data);

  return {
    type: GET_PROFILE,
    payload: request,
  };
}
