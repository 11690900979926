/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box, Button, Flex, Heading,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

export default function Hero({
  title,
  subtitle,
  image,
  ctaLink,
  ctaText,
  ...rest
}) {
  return (
    <Flex
      align="center"
      justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
      minH={{
        base: '100vh', sm: '100vh', md: '90vh', lg: '50vh', xL: '50vh',
      }}
      px={{ base: '1rem', sm: '1rem', lg: '7rem' }}
      marginBottom="6rem"
      {...rest}
    >
      <Box
        justifyContent="left"
        alignItems="left"
        width="90%"
        margin="0 auto"
        textAlign="center"
      >
        <Heading as="h1" fontSize={{ base: '1.5rem', sm: '2rem', lg: '3rem' }}>
          Sign Up Now!
        </Heading>
        <Box
          width="90%"
          textAlign="center"
          margin="0 auto"
          mb={5}
          fontSize={{ base: '1.1rem', sm: '1.2rem', md: '1.4rem' }}
        >
          Nothing is more important than your privacy when it comes to your personal information.
        </Box>
        <Link to={ctaLink}>
          <Button
            bg="#3480E3"
            color="white"
            borderRadius="8px"
            py="4"
            px="4"
            lineHeight="1"
            size="md"
            rightIcon={<ChevronRightIcon />}
            _hover={{ bg: '#FFE6EE', color: '#FF1A75' }}
          >
            Sign Up Now!
          </Button>
        </Link>
      </Box>
    </Flex>
  );
}
Hero.propTypes = {
  ctaLink: PropTypes.string,
};

Hero.defaultProps = {
  ctaLink: '/register',
};
