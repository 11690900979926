/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Button,
  Image,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import DataUsage from '../../sections/DataUsage';

const StyledButton = styled(Box)`
color: #006644;
background-color: #BBEFDF;
font-size: 1.2em;
padding: 10px 16px;
border-radius: 10px;
cursor: pointer;
text-align: center;
width: 80%;
&:hover {
  background-color: #BBEFDF;
}
&:focus {
  outline:0 !important;
  box-shadow: none;
  border: none;
}
`;
const DisabledButton = styled(Box)`
color: #AAA;
background-color: #CCC;
font-size: 1.2em;
padding: 10px 16px;
border-radius: 10px;
width: 80%;
text-align: center;
&:focus {
  outline:0 !important;
  box-shadow: none;
  border: none;
}
`;

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function SubConfirm({
  history,
}) {
  const [pageLoaded, setPageLoaded] = useState(false);

  return (
    <Flex
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      paddingLeft={{ base: '0', md: '1.5rem' }}
      minHeight="90vh"
      width="100%"
    >
      <Box>
        <Text
          marginY="0.5rem"
          fontSize="2em"
          fontWeight="600"
        >
          Thank You!
        </Text>
      </Box>
      <Box>
        <Text
          marginY="0.5rem"
          fontSize="1.3em"
          fontWeight="600"
        >
          Your Subscription has been confirmed!.
        </Text>
      </Box>
    </Flex>
  );
}
SubConfirm.propTypes = propTypes;
export default withRouter(SubConfirm);
