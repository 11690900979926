/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import {
  Box, Button, Flex, Text, Textarea,
} from '@chakra-ui/react';
import SingleComment from './SingleComment';
// import LikeDislikes from './LikeDislikes';

const propTypes = {
  CommentLists: PropTypes.arrayOf(PropTypes.object).isRequired,
  postId: PropTypes.string.isRequired,
  parentCommentId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object.isRequired,
};

function ReplyComment({
  CommentLists,
  postId,
  parentCommentId,
  profile,
}) {
  const [ChildCommentNumber, setChildCommentNumber] = useState(0);
  const [openReplyComments, setOpenReplyComments] = useState(false);
  const [replyComments, setReplyComments] = useState([]);
  const [openReply, setOpenReply] = useState(false);
  const [commentValue, setCommentValue] = useState();
  const [replyMessageCount, setReplyMessageCount] = useState('');

  const setViewList = (commentList) => {
    const results = [];

    commentList.forEach((comment) => {
    //  console.log(comment);
      if (comment.responseTo) {
        // console.log('new list: '+ comment)
        // console.log('both ids: ' + comment.responseTo._id + ' ' + parentCommentId);
        if (comment.responseTo._id === parentCommentId || comment.responseTo === parentCommentId) {
          results.push(comment);
        //  console.log('logged: ' + comment)
        }
      }
    });
    if (results.length > 0) {
      setChildCommentNumber(results.length);
      setReplyComments(results);
      if (replyMessageCount !== `Hide ${ChildCommentNumber} comment(s)`) {
        setReplyMessageCount(`View ${results.length} more comment(s)`);
      }
      // console.log('reached')
    }
  };

  useEffect(() => {
    if (CommentLists.length > 0) {
      setViewList(CommentLists);
    }
  }, [CommentLists, parentCommentId]);

  const renderReplyComment = () => replyComments?.map((comment) => (
    <Box paddingTop="1rem">
      <SingleComment
        comment={comment}
        postId={postId}
        profile={profile}
      />
    </Box>
  ));

  const handleTextChange = (e) => {
    setCommentValue(e.currentTarget.value);
  };
  const setReplyList = () => {
    setOpenReplyComments(!openReplyComments);
    if (openReplyComments) {
      setReplyMessageCount(`View ${ChildCommentNumber} more comment(s)`);
    } else {
      setReplyMessageCount(`Hide ${ChildCommentNumber} comment(s)`);
    }
  };
  const handleReply = () => {
    setCommentValue('');
    setOpenReply(!openReply);
  };

  const onSubmit = (e) => {
    if (commentValue === null || commentValue === '' || commentValue === undefined) {
      return;
    }
    e.preventDefault();
    const variables = {
      writer: profile._id,
      postId,
      responseTo: parentCommentId,
      content: commentValue,
    };

    Axios.post('/api/comment/saveComment', variables).then((response) => {
      if (response.data.success) {
        setCommentValue('');
        //  console.log(replyComments);
        //   const newData = replyComments;
        //  newData.push(response.data.result[0])
        // console.log('newData' + newData)

        const newReplies = replyComments.concat(response.data.result);
        setChildCommentNumber(ChildCommentNumber + 1);
        setViewList(newReplies);
        setOpenReplyComments(true);
      } else {
        alert('Failed to save Comment');
      }
    });
  };
  return (
    <Box paddingLeft="38px">
      <Box marginY="0.5rem">
        <Text
          onClick={handleReply}
          key="comment-basic-reply-to"
          fontSize={{ base: '14px', sm: '14px', md: '18px' }}
          color="#1890ff"
          cursor="pointer"
          as="span"
        >
          Reply
        </Text>
      </Box>
      {openReply && (
        <Box display={{ base: 'block', sm: 'block', md: 'block' }} onSubmit={onSubmit}>
          <Textarea
            style={{ width: '100%', borderRadius: '5px' }}
            onChange={handleTextChange}
            value={commentValue}
            placeholder="write some comments"
          />
          <br />
          <Flex justifyContent="flex-end" width="100%">
            <Button width={{ base: '100px', sm: '100px', md: '150px' }} marginRight="0.5rem" height={{ base: '42px', sm: '42px', md: '52px' }} onClick={handleReply}>
              Cancel
            </Button>

            <Button width={{ base: '100px', sm: '100px', md: '150px' }} height={{ base: '42px', sm: '42px', md: '52px' }} onClick={onSubmit}>
              Submit
            </Button>
          </Flex>
        </Box>
      )}
      {ChildCommentNumber > 0 && (
        <Box marginY="0.5rem">
          <Text
            fontSize="14px"
            margin="0"
            color="gray"
            cursor="pointer"
            onClick={setReplyList}
            as="span"
          >
            {replyMessageCount}
          </Text>
        </Box>
      )}

      {openReplyComments ? <Box marginBottom="1rem"> {renderReplyComment()} </Box> : <Box />}
    </Box>
  );
}

ReplyComment.propTypes = propTypes;

export default ReplyComment;
