/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import * as Yup from 'yup';
import styled from 'styled-components';
import {
  Avatar,
  Button,
  Heading,
  Flex,
  Spinner,
  Stack,
  Box,
  SimpleGrid,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Boxider,
  Text,
  FormControl,
  FormLabel,
  Select,
  AspectRatio,
} from '@chakra-ui/react';
import moment from 'moment';
import { Formik, Field } from 'formik';
import axios from 'axios';
import { Settings2Outline } from '@styled-icons/evaicons-outline/Settings2Outline';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import { ImageAdd } from '@styled-icons/boxicons-solid/ImageAdd';
import ImageComments from './ImageComments';

const ImageAddIcon = styled(ImageAdd)`
  width: 28px;
  color: #BBB;
  cursor: pointer;
  width: 100%;
  height: 70%;
`;

const HeartActive = styled(SuitHeartFill)`
  color: #FF0000; 
  right: 12px;
  top: 12px;
  position: relative;
  width: 16px;
`;
const HeartButton = styled(SuitHeartFill)`
  background-color: inherit;
  width: 18px;
  color: inherit;
  cursor: pointer;
`;
const SeeMoreButton = styled(EyeFill)`
  width: 24px;
  color: #DDD;
  cursor: pointer;
`;

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',

  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#111',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#F1F1F1',
    fontSize: '30px',
    fontWeight: 'bold',
    transition: '0.3s',
    cursor: 'pointer',
    padding: '0 14px',
    borderRadius: '50%',
    '&:hover': {
      color: '#bbb',
      textDecoration: 'none',
    },
  },
  leftButton: {
    position: 'absolute',
    top: '50%',
    left: '24px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  rightButton: {
    position: 'absolute',
    top: '50%',
    right: '-26px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  privacyButton: {
    backgroundColor: '#222',
    borderRadius: '5px',
    padding: '6px 12px',
    color: '#FFF',
    marginTop: '1rem',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];

function Albums(props) {
  const {
    isOpen: isOpenAlbumModal,
    onOpen: onOpenAlbumModal,
    onClose: onCloseAlbumModal,
  } = useDisclosure();
  const {
    isOpen: isOpenReactionModal,
    onOpen: onOpenReactionModal,
    onClose: onCloseReactionModal,
  } = useDisclosure();
  const classes = useStyles();
  const {
    albums,
    profile,
  } = props;
  const [err, setErr] = useState('');
  const [viewAlbum, setViewAlbum] = useState();
  const [imageList, setImageList] = useState();
  const [loadedImage, setLoadedImages] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [imageReactions, setImageReactions] = useState([]);
  const [viewReactions, setViewReactions] = useState();
  const [reactionActive, setReactionActive] = useState();
  const [commentsList, setCommentsList] = useState([]);
  const [initCommentsPage, setInitCommentsPage] = useState(false);
  const [perCommentsPage, setPerCommentsPage] = useState(5);
  const [commentsPage, setCommentsPage] = useState(0);
  const [commentsloading, setCommentsLoading] = useState(true);
  const [loadedModal, setLoadedModal] = useState(false);
  const [lastCommentsPage, setLastCommentsPage] = useState(false);
  const [maximumReactions, setMaximumReactions] = useState(9);
  const [renderState, setRenderState] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState();
  const [dataUsageObj, setDataUsageObj] = useState();
  const getReactions = (selectedImage) => {
    axios({
      url: '/api/image/getAllReactions',
      method: 'POST',
      data: {
        imageId: selectedImage._id,
        profile,
      },
    })
      .then((response) => {
        setImageReactions(response.data.reactions);
        setViewReactions(response.data.reactions?.slice(0, 9));
        // console.log(`result: ${response.data.reactions}`);
        // eslint-disable-next-line eqeqeq
        if (response.data.reactions === undefined
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == []
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == '') {
          // console.log('reached me');
          setReactionActive(false);
          // document.getElementById(`heart-button`).style.color = '#DDD';
          return;
        }
        if (response.data.reactions[0].profile._id === profile._id) {
          // console.log('match found');
          // document.getElementById(`heart-button`).style.color = '#FF0000';
          setReactionActive(true);
        } else {
          // document.getElementById(`heart-button`).style.color = '#DDD';
          setReactionActive(false);
        }
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
      })
      .catch((reactionErr) => {
        setErr(reactionErr);
      });
  };
  const updateCommentList = (newComment) => {
    setCommentsList(commentsList.concat(newComment));
  };
  const getComments = (myImageId, reset) => {
    // console.log(myImageId);
    axios.post('/api/comment/getImageComments', { imageId: myImageId, commentsPage, perCommentsPage }).then((response) => {
      if (response.data.success) {
        if (!initCommentsPage) {
          setInitCommentsPage(true);
        }
        // console.log(response.data.comments);
        // console.log('response.data.comments', response.data.comments);
        if (reset === true) {
          // console.log(response.data);
          setCommentsList([].concat(response.data.comments));
        } else {
          setCommentsList(commentsList.concat(response.data.comments));
        }
        if (response.data.comments.length === 0) {
          setLastCommentsPage(true);
          setCommentsLoading(false);
          return;
        }
        // setCommentsPage(commentsPage + 1);
        setCommentsLoading(false);
      } else {
        alert('Failed to get video Info');
      }
    });
  };

  const getAlbumImages = (myAlbum) => {
    const modal = document.getElementById(`${myAlbum._id}`);
    modal.style.display = 'block';
    const body = document.getElementById('root');
    body.style.height = '100vh';
    // // console.log("height: " + body.style.height);
    body.style.overflowY = 'hidden';
    axios({
      method: 'POST',
      url: '/api/image/getImageByAlbum',
      data: {
        albumId: myAlbum._id,
      },
    })
      .then((response) => {
        // console.log(response.data.imageList);
        setImageList(response.data.imageList);
        setViewAlbum(myAlbum);
        setLoadedImages(true);
        document.getElementById(`familyid_${myAlbum._id}`).style.display = 'block';
      })
      .catch((imageErr) => {
        setErr(imageErr);
      });
  };

  const viewImage = (image) => {
    document.getElementById(`familyid_${viewAlbum._id}`).style.display = 'none';
    setCurrentImage(image);
    getComments(image._id, true);
    // console.log('hello?');
    getReactions(image);
    setLoadedModal(true);
  };
  const returnToAlbum = () => {
    document.getElementById(`familyid_${viewAlbum._id}`).style.display = 'block';
    setLoadedModal(false);
    setCurrentImage(currentImage);
  };
  const resetMaximumReactions = () => {
    setMaximumReactions(9);
  };
  const goPrevious = () => {
    imageList.forEach((data, imageindex) => {
      if (data === currentImage) {
        if ((imageindex - 1) === -1) {
          setCurrentImage(imageList[imageList.length - 1]);
          // setImageDescription(images[images.length - 1].description);
          // setImagePrivacy(images[images.length - 1].privacy);
          getReactions(imageList[imageList.length - 1]);
          setCommentsPage(0);
          getComments(imageList[imageList.length - 1]._id, true);
        } else {
          setCurrentImage(imageList[imageindex - 1]);
          // setImageDescription(images[imageindex - 1].description);
          // setImagePrivacy(images[imageindex - 1].privacy);
          getReactions(imageList[imageindex - 1]);
          setCommentsPage(0);
          getComments(imageList[imageindex - 1]._id, true);
        }
      }
    });
    resetMaximumReactions();
  };

  const goNext = () => {
    imageList.forEach((data, imageindex) => {
      if (data === currentImage) {
        if ((imageindex + 1) === imageList.length) {
          setCurrentImage(imageList[0]);
          // setImageDescription(images[0].description);
          // setImagePrivacy(images[0].privacy);
          getReactions(imageList[0]);
          setCommentsPage(0);
          getComments(imageList[0]._id, true);
        } else {
          setCurrentImage(imageList[imageindex + 1]);
          // setImageDescription(images[imageindex + 1].description);
          // setImagePrivacy(images[imageindex + 1].privacy);
          getReactions(imageList[imageindex + 1]);
          setCommentsPage(0);
          getComments(imageList[imageindex + 1]._id, true);
        }
      }
    });
    resetMaximumReactions();
  };
  const reactionHandler = () => {
    // console.log(imageReactions);
    if (reactionActive === false) {
      axios({
        url: '/api/image/addImageReaction',
        method: 'POST',
        data: {
          imageId: currentImage._id,
          profile,
          reactionType: 'Love',
        },
      })
        .then((reactionData) => {
          // console.log(`reached? ${reactionData.data.reactionData.profile._id}`);
          const newReaction = viewReactions;
          const allreactions = imageReactions;
          newReaction.unshift(reactionData.data.reactionData);
          allreactions.unshift(reactionData.data.reactionData);
          //  document.getElementById(`heart-button`).style.color = '#FF0000';
          setViewReactions(newReaction);
          setImageReactions(allreactions);
          setReactionActive(true);
          // console.log(profile.image);
        })
        .catch((reactionErr) => {
          setErr(reactionErr);
        });
    } else {
      axios({
        url: '/api/image/removeImageReaction',
        method: 'POST',
        data: {
          profile,
          imageId: currentImage._id,
        },
      })
        .then(() => {
          // console.log(imageReactions);
          const allReactions = imageReactions;
          const newReaction = viewReactions;
          allReactions.shift();
          newReaction.shift();
          // console.log(`new ${newReactions.length}`);
          setImageReactions(allReactions);
          setViewReactions(newReaction);
          setReactionActive(false);
        });
    }
  };
  const closeAlbum = () => {
    document.getElementById(`${viewAlbum._id}`).style.display = 'none';
    setLoadedModal(false);
    const body = document.getElementById('root');
    body.style.height = '';
    body.style.overflowY = 'auto';
    setViewAlbum([]);
  };

  return (
    <Box
      width={{
        base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1070px',
      }}
      marginTop="1rem"
    >
      <Box padding="0" marginBottom="16px">
        <SimpleGrid
          gridTemplateColumns={{
            base: '1fr',
            md: '1fr 1fr 1fr',
          }}
          spacing="10px"
          marginX="auto"
        >
          {albums.map((album) => (
            <Box>
              <AspectRatio ratio={16 / 9}>
                <Box
                  as="img"
                  crossOrigin="anonymous"
                  src={album.thumbnail}
                  alt="thumbnail"
                  objectFit="cover"
                  width={{
                    base: '100%', sm: '100%', md: '250px', lg: '250px', xl: '350px',
                  }}
                  marginX="auto"
                  heigh="auto"
                  margin="auto"
                  cursor="pointer"
                  onClick={() => { getAlbumImages(album); }}
                />
              </AspectRatio>
              <Flex paddingLeft="6px">
                <Avatar marginTop="12px" size="md" src={album.writer && album.writer.image} alignItems="center" />
                <Box paddingLeft="8px">
                  <Text
                    color="#000"
                    fontWeight="500"
                    width={{
                      base: '70vw',
                      md: '30ch',
                      lg: '30ch',
                      xl: '40ch',
                    }}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    marginTop="4px"
                  >
                    {album.name}
                  </Text>
                  <Text
                    color="#000"
                    fontWeight="400"
                    width={{
                      base: '70vw',
                      md: '30ch',
                      lg: '30ch',
                      xl: '40ch',
                    }}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {album.description}
                  </Text>
                  <Text color="#4A5568">{ moment(album.createdAt).format('LL') }</Text>
                </Box>
              </Flex>
              <Box>
                <Box
                  id={`${album._id}`}
                  className={classes.modal}
                  backgroundColor="#333"
                >
                  <Box display={{ base: 'none', lg: 'block' }}>
                    <span className={classes.close} onClick={closeAlbum}>&times;</span>
                  </Box>
                  {loadedImage
                    ? (
                      <Box
                        backgroundColor="#333"
                      >
                        <Box height={{ base: '70px', lg: '0' }} display={{ base: 'block', lg: 'none' }} backgroundColor="#333">
                          <span className={classes.close} onClick={closeAlbum}>&times;</span>
                        </Box>
                        <Box>
                          <Box
                            id={`familyid_${album._id}`}
                            marginTop="1rem"
                          >
                            <Box
                              backgroundColor="#333"
                              width={{
                                base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1070px',
                              }}
                              paddingLeft={{ base: '0.5rem', md: '0' }}
                              marginX="auto"
                              marginBottom="1rem"
                            >
                              <Text
                                color="#FFF"
                                fontWeight="500"
                                maxWidth={{
                                  base: '20ch',
                                  sm: '45ch',
                                  md: '20ch',
                                  lg: '20ch',
                                  xl: '32ch',
                                }}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                fontSize="1.5rem"
                              >
                                {viewAlbum.name}
                              </Text>
                              <Text
                                color="#FFF"
                                fontWeight="450"
                                maxWidth={{
                                  base: '20ch',
                                  sm: '45ch',
                                  md: '20ch',
                                  lg: '20ch',
                                  xl: '32ch',
                                }}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                fontSize="1rem"
                              >
                                {viewAlbum.description}
                              </Text>
                            </Box>
                            <SimpleGrid
                              gridTemplateColumns={{
                                base: '1fr',
                                sm: '1fr',
                                md: '1fr 1fr ',
                                lg: '1fr 1fr 1fr ',
                              }}
                              width={{
                                base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1070px',
                              }}
                              spacing="10px"
                              marginX="auto"
                              marginTop="2rem"
                            >
                              {imageList.map((image) => (
                                <Box marginBottom="12px">
                                  <AspectRatio ratio={16 / 9}>
                                    <Image
                                      src={image.thumbNail}
                                      objectFit="cover"
                                      cursor="pointer"
                                      onClick={() => { viewImage(image); }}
                                    />
                                  </AspectRatio>
                                  <Flex paddingLeft="6px">
                                    <Avatar marginTop="16px" size="md" src={image.writer && image.writer.image} alignItems="center" />
                                    <Box paddingLeft="8px" marginTop="4px">
                                      <Text
                                        fontWeight="600"
                                        as="h4"
                                        fontSize="0.925rem"
                                        width={{
                                          base: '20ch',
                                          sm: '45ch',
                                          md: '20ch',
                                          lg: '20ch',
                                          xl: '32ch',
                                        }}
                                        whiteSpace="nowrap"
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                        marginBottom={0}
                                        marginLeft={{ base: '0.6rem', md: '0' }}
                                        marginTop={{ base: '0.5rem', md: '0' }}
                                        color="#FFF"
                                      >
                                        {image.writer.username}
                                      </Text>
                                      <Text
                                        color="#DDD"
                                        width={{
                                          base: '20ch',
                                          sm: '45ch',
                                          md: '20ch',
                                          lg: '20ch',
                                          xl: '32ch',
                                        }}
                                        whiteSpace="nowrap"
                                        overflow="hidden"
                                        textOverflow="ellipsis"

                                      >
                                        {image.description}
                                      </Text>
                                      <Text color="#DDD">{ moment(image.createdAt).format('LL') }</Text>
                                    </Box>
                                  </Flex>
                                </Box>
                              ))}
                            </SimpleGrid>
                          </Box>
                          <Box>
                            <Box display={{ base: 'none', lg: 'block' }}>
                              <span className={classes.close} onClick={closeAlbum}>&times;</span>
                            </Box>
                            {loadedModal
                              ? (
                                <Box>
                                  <SimpleGrid
                                    gridTemplateColumns={{ base: '1fr', lg: '1fr 450px' }}
                                    gridTemplateRows={{ base: '1fr 4fr', lg: '1fr' }}
                                    height="80vh"
                                  >
                                    <Box>
                                      <Flex justifyContent="center" flexDirection="column" height={{ base: '100%', lg: '100vh' }} backgroundColor="#111">
                                        <AspectRatio ratio={16 / 9}>
                                          <Flex justifyContent="center" position="relative">
                                            <button type="submit" className={classes.leftButton} onClick={goPrevious}>&lt;</button>
                                            <Image alt="photo" src={currentImage.filePath} alignSelf="center" width="auto" maxHeight="100%" />
                                            <button type="submit" className={classes.rightButton} onClick={goNext}>&gt;</button>
                                          </Flex>
                                        </AspectRatio>
                                      </Flex>
                                    </Box>
                                    <Box
                                      paddingX={{ base: '0.5rem', sm: '1rem', lg: '2rem' }}
                                      paddingTop={{ base: '1rem', lg: '2rem' }}
                                      backgroundColor="#333"
                                    >
                                      <Box marginTop="1rem">
                                        <Flex>
                                          <Box>
                                            <Avatar size="sm" src={currentImage.writer && currentImage.writer.image} />
                                          </Box>
                                          <Box paddingLeft="10px">
                                            <Flex>
                                              <Box marginRight="1rem">
                                                <Flex justifyContent="center" flexDirection="column" height="100%">
                                                  <Text as="span" fontSize={{ base: '.9rem', sm: '.9rem', md: '1.1rem' }} color="#FFF" fontWeight="500">{currentImage.writer.username}</Text>
                                                </Flex>
                                              </Box>
                                              <Box color={reactionActive === false ? '#DDD' : '#FF0000'}>
                                                <HeartButton id="heart-button" onClick={() => reactionHandler()} />
                                              </Box>
                                            </Flex>
                                          </Box>
                                        </Flex>
                                      </Box>
                                      <Box>
                                        <Box marginTop="1rem">
                                          <Text
                                            as="p"
                                            onClick={returnToAlbum}
                                            color="#FFF"
                                            fontWeight="500"
                                            width={{
                                              base: '70vw',
                                              md: '30ch',
                                              lg: '30ch',
                                              xl: '40ch',
                                            }}
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            cursor="pointer"
                                          >
                                            Back to Album
                                          </Text>
                                        </Box>
                                        <Text id="img-description" fontSize={{ base: '.8rem', sm: '.8rem', md: '1rem' }} marginY="1rem" color="#FFF" fontWeight="400">
                                          {currentImage.description || ''}
                                        </Text>
                                      </Box>
                                      <Box>
                                        <Box>{imageReactions.length > 0
                                          ? (
                                            <SimpleGrid
                                              gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                                              width="200px"
                                            >
                                              {viewReactions?.map((data) => (
                                                <Flex marginY="8px">
                                                  <Avatar size="sm" src={data.profile && data.profile.image} />
                                                  <HeartActive />
                                                </Flex>
                                              ))}
                                              <Flex marginY="8px" marginLeft="5px">
                                                <SeeMoreButton onClick={() => { onOpenReactionModal(); }} />
                                              </Flex>
                                              <Box>
                                                <Modal isOpen={isOpenReactionModal} onClose={onCloseReactionModal}>
                                                  <ModalOverlay />
                                                  <ModalContent>
                                                    <ModalCloseButton />
                                                    <ModalBody>
                                                      <Box marginY="3rem">
                                                        {imageReactions?.map((data) => (
                                                          <Flex marginY="1rem">
                                                            <Avatar size="sm" src={data.profile && data.profile.image} />
                                                            <HeartActive />
                                                            <Text marginTop="5px" fontWeight="500">{data.profile.username}</Text>
                                                          </Flex>
                                                        ))}
                                                      </Box>
                                                    </ModalBody>
                                                  </ModalContent>
                                                </Modal>
                                              </Box>
                                            </SimpleGrid>
                                          )
                                          : <Box />}
                                        </Box>
                                        <Box>
                                          {
                                    commentsloading === false
                                      ? (
                                        <ImageComments
                                          imageId={currentImage._id}
                                          profile={profile}
                                          commentsList={commentsList}
                                          updateCommentList={updateCommentList}
                                        />
                                      )
                                      : <Box />
                                  }
                                        </Box>
                                      </Box>
                                    </Box>
                                  </SimpleGrid>
                                </Box>
                              ) : <Box />}
                          </Box>
                        </Box>
                      </Box>
                    )
                    : (
                      <Flex flexDirection="column" justifyContent="center" alignItems="center">
                        <Spinner
                          thickness="4px"
                          emptyColor="gray.200"
                          color="#FF1A75"
                          size="xl"
                          mb="8px"
                        />
                        <Text>Loading</Text>
                      </Flex>
                    )}
                </Box>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>

  );
}

export default Albums;
