/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box, SimpleGrid, Flex, Avatar, Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';

const HeartButton = styled(SuitHeartFill)`
  background-color: inherit;
  width: 18px;
  color: #DDD;
  cursor: pointer;
`;
const HeartActive = styled(SuitHeartFill)`
  color: #FF0000;
  width: 12px;
`;

const propTypes = {
  profile: PropTypes.object.isRequired,
  imageId: PropTypes.string.isRequired,

};

const ImageReaction = ({ profile, imageId }) => {
  const [errMsg, setErrMsg] = useState('');
  const [imageReactions, setImageReactions] = useState([]);
  const [reactionActive, setReactionActive] = useState(false);
  useEffect(() => {
    // console.log(profile);
    if (profile) {
      axios({
        url: '/api/image/getAllReactions',
        method: 'POST',
        data: {
          imageId,
          profile: profile._id,
        },
      })
        .then((response) => {
          setImageReactions(response.data);
          if (response.data[0].profile._id === profile._id) {
            setReactionActive(true);
          }
        })
        .catch((err) => {
          setErrMsg(err);
        });
    }
  }, [profile]);
  const reactionHandler = () => {
    // console.log(reactionActive);
    if (reactionActive === false) {
      setReactionActive(true);
      document.getElementById(`reaction${imageId}`).style.color = '#FF0000';
      const imageReactionObj = {
        imageId,
        profile: profile._id,
        reactionType: 'Love',
      };
      axios({
        url: '/api/image/addImageReaction',
        method: 'POST',
        data: {
          imageId,
          profile: profile._id,
          reactionType: 'Love',
        },
      })
      .then(() => {
        setImageReactions(imageReactions.push(imageReactionObj));
      })
      .catch((err) => {
        setErrMsg(err);
      });
    } else {
      setReactionActive(false);
      document.getElementById(`reaction${imageId}`).style.color = '#DDD';
      axios({
        url: '/api/image/removeImageReaction',
        method: 'POST',
        data: {
            profile: profile._id,
        },
      })
      .then(() => {
        const updatedReactions = imageReactions.shift;
        setImageReactions(updatedReactions);
      });
    }
  };

  return (
    <Box key={imageId}> 
        <Flex>
        {imageReactions.length > 0 ? 
          <Box>
            {imageReactions.map((reaction) => (
              <div>
                <Avatar src={reaction.profileId.iamge} />
                <SuitHeartFill />
              </div>
          ))}
          </Box> :
          <Box />
          }
          <Box>
            <HeartButton id={`reaction${imageId}`} onClick={() => reactionHandler()} />
          </Box>
        </Flex>
    </Box>
  );
};

ImageReaction.propTypes = propTypes;

export default ImageReaction;
