/* eslint-disable linebreak-style */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { auth } from '../_actions/user_actions';

export default (ComposedClass, reload, restricted = true, adminRoute = null) => {
  const propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  function AuthenticationCheck(props) {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const { history } = props;

    useEffect(() => {
      dispatch(auth()).then(async (response) => {
        // console.log(ComposedClass);
        if (await !response.payload.isAuth) {
          if (reload) {
            history.push('/register');
          }
          // route is restricted default so configure restricted to false for a route to allow global and public usage
          if (restricted) {
            history.push('/login');
          }
        // these two check assuming auth is good
        } else if (adminRoute && !response.payload.isAdmin) {
          history.push('/home');
        // prevents loading login or regesiter page when logged in
        } else if (reload === false) {
          history.push('/home');
        }
      });
    }, [dispatch, history, user.googleAuth]);

    return <ComposedClass {...props} user={user} />;
  }

  AuthenticationCheck.propTypes = propTypes;

  return AuthenticationCheck;
};
