/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  AspectRatio,
  Avatar,
  Text,
  Stack,
  Image,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});
const propTypes = {
  profileId: PropTypes.string.isRequired,
  videoWriterProfileId: PropTypes.string.isRequired,
};

function SideVideo({
  profileId,
  videoWriterProfileId,
}) {
  const [SideVideos, setSideVideos] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (profileId) {
      axios({
        method: 'POST',
        url: '/api/video/videos-by-id',
        data: {
          profileId,
          videoWriterProfileId,
        },
      })
        .then((response) => {
          // console.log(response);
          setSideVideos(response.data.videos);
        })
        .catch(() => {
          // console.log('Failed to get Videos');
        });
    }
  }, [profileId, videoWriterProfileId]);
  const goToVideo = (link) => {
    window.location.href = link;
  };
  const sideVideoItem = SideVideos.map((video) => (
    <Box
      marginTop="-20px"
      marginBottom="1rem"
    >
      <Box position="relative" textAlign="right" width="100%">
        <AspectRatio
          ratio={16 / 9}
          position="relative"
        >
          <Image
            alt="thumbnail"
            src={video.thumbNail}
            objectFit="cover"
            cursor="pointer"
            borderRadius=".70rem"
            marginTop="20px"
            boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
            onClick={() => { goToVideo(`/video/${video._id}`); }}
          />
        </AspectRatio>
        <Text
          as="span"
          position="relative"
          top="-10px"
          right="10px"
          zIndex="2"
          color="#FFFFFF"
          backgroundColor="rgb(0, 0, 0, 0.7)"
          borderRadius="4px"
          padding="4px"
          marginLeft="5px"
        >
          {Math.floor(video.duration / 60)}:{`0${Math.floor(video.duration - Math.floor(video.duration / 60) * 60)}`.slice(-2)}
        </Text>
      </Box>
      <Box>
        <Stack isInline>
          <Box>
            <a href={`/video/${video._id}`}>
              <Text
                className={classes.title}
                fontWeight="bold"
                fontSize={{ base: '1.3em', sm: '1.4em' }}
                color="#003971"
                whiteSpace="wrap"
                overflow="hidden"
                textOverflow="ellipsis"
                marginTop="0.4em"
              >
                {video.title}
              </Text>
            </a>
            <Text
              color="#AAA"
              fontWeight="bold"
              fontSize={{ base: '1.1em', sm: '1.2em' }}
              whiteSpace="wrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {video.description}
            </Text>
            <Text
              color="#AAA"
              fontWeight="bold"
              fontSize={{ base: '1em', sm: '1.1em' }}
            >
              {moment(video.createdAt).format('LL')}
            </Text>
          </Box>
        </Stack>
      </Box>
    </Box>
  ));

  return (
    <Box
      paddingTop="1rem"
      marginBottom="2rem"
      paddingRight={{ base: '0', md: '2rem' }}
    >
      <Box
        height="76vh"
        overflowY={{ base: 'none', md: 'auto' }}
      >
        {sideVideoItem}
      </Box>
    </Box>
  );
}
SideVideo.propTypes = propTypes;
export default SideVideo;
