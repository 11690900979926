/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';

import {
  Avatar,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Input,
} from '@chakra-ui/react';
import {
  AddIcon,
} from '@chakra-ui/icons';

const useStyles = createUseStyles({
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
  gridView: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',

  },
  friendCard: {
    position: 'relative',
    padding: '24px',
    width: '300px',

    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 4px 16px 0px',
  },
  searchResult: {
    padding: '16px',
    marginTop: '16px',
    width: '460px',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  friendCardButtonsContainer: {
    position: 'absolute',
    bottom: '24px',
  },
});

const SettingsPage = () => {
  const classes = useStyles();
  const [userEmail, setUserEmail] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const handleSubmit = () => {
    axios({
      method: 'POST',
      url: '/api/users/giveAdminRights',
      data: {
        email: userEmail,
        userId: localStorage.getItem('userId'),
      },
    }).then((data) => {
      alert(`${data.data.email} has been given admin rights.`);
    })
      .catch((err) => {
        // console.log(err);
        let errMsg;

        switch (err?.response?.status) {
          case 400:
            errMsg = 'Something has went wrong.';
            break;

          default:
            errMsg = 'Something has went wrong';
            break;
        }
        setFormErrorMessage(errMsg);
        setTimeout(() => {
          setFormErrorMessage('');
        }, 3000);
      });
  };
  const validateEmail = () => {
    const schema = yup.object().shape({
      email: yup.string().email().required(),
    });

    // check validity
    schema
      .isValid({
        email: userEmail,
      })
      .then((valid) => {
        if (valid) {
          handleSubmit();
        } else {
          setFormErrorMessage('Email is not Valid');
          setTimeout(() => {
            setFormErrorMessage('');
          }, 3000);
        }
      });
  };
  const sendInvite = () => {
    const schema = yup.object().shape({
      email: yup.string().email().required(),
    });

    // check validity
    schema
      .isValid({
        email: inviteEmail,
      })
      .then((valid) => {
        if (valid) {
          axios({
            method: 'POST',
            url: '/api/invites/resendInvite',
            data: {
              email: inviteEmail,
              userId: localStorage.getItem('userId'),
            },
          }).then((data) => {
            alert(`Invite has been resent to ${data.data.email}.`);
          })
            .catch((err) => {
              // console.log(err);
              let errMsg;

              switch (err?.response?.status) {
                case 400:
                  errMsg = 'Something has went wrong.';
                  break;

                default:
                  errMsg = 'Something has went wrong';
                  break;
              }
              setFormErrorMessage(errMsg);
              setTimeout(() => {
                setFormErrorMessage('');
              }, 3000);
            });
        } else {
          setFormErrorMessage('Email is not Valid');
          setTimeout(() => {
            setFormErrorMessage('');
          }, 3000);
        }
      });
  };
  return (
    <Flex flexDirection="column" alignItems="center">
      <Heading
        fontWeight="500"
        fontSize={{ base: '1.3rem', md: '1.7rem' }}
        color="#0B67C2"
        margin="2rem"
      >
        Resend Invite
      </Heading>
      <FormControl mb="4px">
        <FormLabel textAlign="center" htmlFor="query" ml="4px" fontWeight="500">
          Email address or username
        </FormLabel>
        <br />
        <Flex justifyContent="center">
          <Input
            id="query"
            placeholder="Enter an email address "
            type="text"
            onChange={(event) => { setInviteEmail(event.target.value); }}
            width={{ base: '100%', sm: '352px', md: '352px' }}
          />
          <IconButton
            type="submit"
            icon={<AddIcon />}
            onClick={sendInvite}
            ml="12px"
            color="white"
            borderRadius="24px"
            bg="#FF1A75"
            _hover={{
              bg: '#FFE6EE',
              color: '#FF1A75',
            }}
          />
        </Flex>
      </FormControl>
      <Divider width="90%" marginY="28px" />
      <Heading
        fontWeight="500"
        fontSize={{ base: '1.3rem', md: '1.7rem' }}
        color="#0B67C2"
        margin="2rem"
      >
        Give user admin rights
      </Heading>
      <FormControl mb="4px">
        <FormLabel htmlFor="query" ml="4px" fontWeight="500">
          Email address or username
        </FormLabel>
        <br />
        <Flex justifyContent="center" marginBottom="3rem">
          <Input
            id="query"
            placeholder="Enter an email address "
            type="text"
            onChange={(event) => { setUserEmail(event.target.value); }}
            width={{ base: '100%', sm: '352px', md: '352px' }}
          />
          <IconButton
            type="submit"
            icon={<AddIcon />}
            onClick={validateEmail}
            ml="12px"
            color="white"
            borderRadius="24px"
            bg="#FF1A75"
            _hover={{
              bg: '#FFE6EE',
              color: '#FF1A75',
            }}
          />
        </Flex>
      </FormControl>
    </Flex>
  );
};

export default SettingsPage;
