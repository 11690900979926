/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable max-len */
/* eslint-disable no-unreachable */
/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import PropTypes, { checkPropTypes } from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { compress, compressAccurately } from 'image-conversion';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Select,
  Spinner,
  Text,
  Input,
  ModalFooter,
  AspectRatio,
  SimpleGrid,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/react';
// import VideoConverter from 'convert-video';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import s3BucketUrl from '../../../environmentVar';
import UploadImg from '../../../../assets/images/upload.png';
import AlertBanner from '../../../sections/AlertBanner';
import InviteFamilyModal from './InviteFamilyModal';

const SeeMoreButton = styled(EyeFill)`
        width: 58px;
        color: #BBB;
        cursor: pointer;
      `;

const UploadStatus = Object.freeze({
  AWAITING_FILE: 'AWAITING_FILE',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  ERROR: 'ERROR',
});

const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];

const Category = [
  { value: 'FILM', label: 'Film & Animation' },
  { value: 'AUTOS', label: 'Autos & Vehicles' },
  { value: 'MUSIC', label: 'Music' },
  { value: 'PETS', label: 'Pets & Animals' },
  { value: 'SPORTS', label: 'Sports' },
  { value: 'FAMILY', label: 'Family' },
  { value: 'OTHER', label: 'Other' },
];

const baseDropzoneStyle = {
  height: '65vh',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderRadius: '8px',
  borderColor: '#EEEEEE',
  padding: '32px',
  cursor: 'pointer',
  marginBottom: '128px',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '95%',
};
const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

const useStyles = createUseStyles({
  uploadIcon: {
    margin: '32px auto',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  maxSpace: PropTypes.number.isRequired,
  currentSpace: PropTypes.number.isRequired,
  profile: PropTypes.object.isRequired,
};

function MassUploadBrowser({
  history,
  maxSpace,
  currentSpace,
  profile,
}) {
  const classes = useStyles();

  const user = useSelector((state) => state.user);

  const [status, setStatus] = useState();
  const [formMessage, setFormErrorMessage] = useState();
  const [loadcomplete, setLoadComplete] = useState(false);
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [thumbFiles, setThumbFiles] = useState();
  const [filesToUpload, setFilesToUpload] = useState();
  const [viewThumbnails, setViewThumbnails] = useState([]);
  const [existingAlbums, setExistingAlbums] = useState([{ _id: '', name: '' }]);
  const [videoConverting, setVideoConverting] = useState('');
  const [videoProgress, setVideoProgress] = useState(0);
  const [newUserUploader, setNewUserUploader] = useState(false);
  const [newPath, setNewPath] = useState();
  const [alertMessage, setAlertMessage] = useState('Warning: Safari users can only upload mp4 format for videos at this time. Please use a different browser for other video formats');
  /*
  const ffmpeg = createFFmpeg({
    corePath: window.location.hostname === 'localhost' ? 'http://localhost:3000/ffmpeg-core.js' : 'https://www.gokloak.com/ffmpeg-core.js',
    log: false,
  });
  */
  const loadFFMpeg = async () => {
    // setStatus(UploadStatus.LOADING);
    // await ffmpeg.load();
    // console.log(window.location.hostname);
    const myCrossOriginIsolated = window.window.crossOriginIsolated;
    // console.log(myCrossOriginIsolated);
    // setStatus(UploadStatus.AWAITING_FILE);
  };
  useEffect(() => {
    // console.log('running')
    if (profile._id) {
      // loadFFMpeg();
      axios({
        method: 'POST',
        url: '/api/image/my-albums',
        data: {
          profileId: profile._id,
        },
      })
        .then((data) => {
          const getAlbums = existingAlbums.concat(data.data.albums);
          setExistingAlbums(getAlbums);
          setStatus(UploadStatus.AWAITING_FILE);
          setLoadComplete(true);
        })
        .catch((albumErr) => {
          // console.log(err);
          let errMsg;
          switch (albumErr?.response?.status) {
            case 403:
              errMsg = 'You do Not have access to these images';
              break;
            case 500:
              errMsg = 'Server is currently down';
              break;
            default:
              errMsg = 'Something has went wrong';
              break;
          }
          setFormErrorMessage(errMsg);
          setTimeout(() => {
            setFormErrorMessage('');
          }, 3000);
        });
    }
  }, [profile]);

  // A much more higher quality image and thumbnail compression
  // https://github.com/WangYuLue/image-conversion
  const compressImages = async (files) => {
    // console.log(files)
    const imageFiles = Array.from(files);

    const options = {
      size: 900,
      accuracy: 0.9,
      scale: 0.4,
    };
    try {
      // converts files to compressed blobs
      const compressedBlobs = await Promise.all(
        imageFiles.map((imageFile) => compressAccurately(imageFile, options)),
      );
      return compressedBlobs;
    } catch (error) {
      setFormErrorMessage(error);
    }
  };
  const thumbnailImages = async (files) => {
    // console.log(files)
    const imageFiles = Array.from(files);

    const options = {
      size: 400,
      accuracy: 1,
      scale: 0.2,
    };
    try {
      // converts files to compressed blobs
      const compressedBlobs = await Promise.all(
        imageFiles.map((imageFile) => compressAccurately(imageFile, options)),
      );
      return compressedBlobs;
    } catch (error) {
      setFormErrorMessage(error);
    }
  };

  const postAllMedia = async (formData) => axios.post(s3BucketUrl, formData, { timeout: 0 });

  // see https://stackoverflow.com/questions/52669596/promise-all-with-axios
  // https://github.com/axios/axios/blob/d35b5b5902f72305216f245cc58bc2231470fb19/README.md#example
  const postAllData = (fileList, compressedFiles) => {
    const formArray = [];
    fileList.map((file) => {
      // console.log(`every file: ${file.fileName}`);
      const formData = new FormData();
      formData.append('Content-Type', file.type);
      Object.entries(file.promise.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      compressedFiles.map((compfile) => {
        // console.log(`compressed file: ${compfile.name}`);
        if (String(file.fileName) === String(compfile.name)) {
          // console.log('match fonud');
          formData.append('file', compfile);
        }
      });
      formArray.push(formData);
    });
    // console.log(formArray);
    return Promise.all(formArray.map(postAllMedia));
  };

  const getVideoCover = (file, seekTo = 1.1) => new Promise((resolve, reject) => {
    // console.log(`video cover: ${file.name}`);
    // load the file to a video player
    let duration;
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('crossOrigin', 'Anonymous');
    // console.log(`video player: ${videoPlayer}`);
    // const fileUrl = URL.createObjectURL(file);
    const fileUrl = 'https://s3.us-west-1.wasabisys.com/kloak-prod/El51aFN7nGwBCf3mywBzi.mp4';
    // console.log(`url: ${fileUrl}`);
    videoPlayer.setAttribute('src', fileUrl);
    // console.log(`player: ${videoPlayer}`);
    // videoPlayer.load();
    videoPlayer.addEventListener('error', (ex) => {
      // console.log(`error when loading video file: ${ex}`);
      reject('error when loading video file', ex);
    });
    videoPlayer.onloadedmetadata = () => {
      // load metadata of the video to get video duration and dimensions
      // seek to user defined timestamp (in seconds) if possible
      // console.log(`loaded video duration: ${videoPlayer.duration}`);
      if (videoPlayer.duration < seekTo) {
        // console.log('video is too short');
        reject('video is too short.');
        return;
      }
      duration = videoPlayer.duration;
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        // console.log(`seek to: ${seekTo}`);
        videoPlayer.currentTime = seekTo;
        // console.log(videoPlayer.duration);
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.onseeked = () => {
        // console.log('reached');
        if (videoPlayer.readyState === 4) {
          // console.log('video is now paused at %ss.', seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement('canvas');
          // console.log(videoPlayer.videoWidth);
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          setTimeout(() => {
            const ctx = canvas.getContext('2d');
            // your code goes here
            ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
            // return the canvas image as a blob
            // console.log(`videoplayer: ${videoPlayer}`);
            // console.log(`canvas: ${ctx}`);
            // console.log(`canvas width: ${canvas.width}`);
            // console.log(`canvas height: ${canvas.height}`);
            ctx.canvas.toBlob(
              (blob) => {
                // console.log(`new blob: ${blob.name}`);
                // console.log(`new blob: ${duration}`);
                resolve({ blob, duration });
              },
              'image/png',
              0.9, /* quality */
            );
          }, 200);
        }
      };
    };
  });
  /*
  const doTranscode = async (file) => {
    // console.log('this function has been called!!!!!!!!!');
    // console.log(file.type);
    setVideoProgress(0);
    setVideoConverting(file.name);

    if (ffmpeg.isLoaded() === false) {
      await ffmpeg.load();
    }
    // console.log('Loading ffmpeg-core.js');
    // console.log(`file: ${file.name}`);
    // console.log('Start transcoding');

    const newFileURL = URL.createObjectURL(file);
    const fetchedFile = await fetchFile(newFileURL);
    // console.log(fetchedFile);
    // await ffmpeg.setProgress((p) => setVideoProgress(p));
    ffmpeg.FS('writeFile', file.name, fetchedFile);
    // console.log(`fetched: ${fetchedFile}`);
    ffmpeg.setProgress(({ ratio }) => {
      // console.log(ratio);
      if (ratio >= 0) {
        setVideoProgress(Math.floor((ratio / 1) * 100));
      }
      // setVideoProgress(Math.floor((ratio / 1) * 100));
      // ratio is a float number between 0 to 1.
    });
    let newFileName;
    let newType;
    switch (file.type) {
      default:
        newFileName = 'tempName.mp4';
        await ffmpeg.run('-i', file.name, newFileName);
        newType = 'video/mp4';
        break;
    }
    // console.log('Complete transcoding');
    const data = ffmpeg.FS('readFile', newFileName);
    const newBlob = new Blob([data.buffer], { type: newType });
    // console.log(`new blob man: ${newBlob.size}`);

    const newFile = new File([newBlob], newFileName, {
      type: newType,
    });
    // console.log(`new file man: ${newFile.name}`);
    // console.log(`new file man: ${newFile.size}`);
    // console.log(`new file man: ${newFile.type}`);
    // console.log(`new file man: ${newFile.webkitRelativePath}`);
    return newFile;
  };
  */
  const cancelPublish = () => {
    setThumbnailSrc();
    setThumbFiles([]);
    setFilesToUpload();
    setViewThumbnails([]);
    setStatus(UploadStatus.AWAITING_FILE);
  };
  const convertVideo = async (file) => {
    // console.log('hello');
    const sourceVideoFile = file;
    const targetVideoFormat = 'mp4';
    const convertedVideoDataObj = 'await VideoConverter.convert(sourceVideoFile, targetVideoFormat)';
    const a = document.createElement('a');
    a.href = convertedVideoDataObj.data;
    a.download = `${convertedVideoDataObj.name}.${convertedVideoDataObj.format}`;
    a.click();
    // console.log(`converted: ${convertedVideoDataObj}`);
    return convertedVideoDataObj;
  };
  const onDropAccepted = async (files) => {
    setStatus(UploadStatus.PROCESSING);
    const everyImageFiles = [];
    const everyVideoFiles = [];
    const videoToConvert = [];
    files.forEach((file) => {
      if (file.type === 'image/png'
      || file.type === 'image/jpg' || file.type === 'image/jpeg') {
        // console.log(`okay: ${file.type}`);
        everyImageFiles.push(file);
      } else {
        // console.log(`nokay: ${file.type}`);
        everyVideoFiles.push(file);
      }
    });
    const imageFiles = Array.from(everyImageFiles);
    // const videoFiles = Array.from(everyVideoFiles);
    // for (let i = 0; i < videoToConvert.length; i++) {
    //   const newVideo = await convertVideo(videoToConvert[i]);
    //   const newblob = await fetch(newVideo.data).then((r) => r.blob());
    //   console.log(`newblob: ${newblob}`);
    //   const videoFIle = new File([newblob.blob], `${nanoid()}.mp4`, {
    //     type: 'video/mp4',
    //   });
    //   console.log(`test: ${videoFIle}`);
    //   everyVideoFiles.push(videoFIle);
    // }
    const videoFiles = Array.from(everyVideoFiles);
    let totalFileSize = 0;
    if (everyImageFiles.length + everyVideoFiles.length > 100) {
      alert('You cannot upload more than 100 files at once!');
      setStatus(UploadStatus.AWAITING_FILE);
      // return;
    }
    const videoThumbnails = [];

    // create screenshot blobs for all videos
    Promise.all(videoFiles.map(async (blob) => {
      // change images blobs back to files
      // console.log(blob);
      const newblob = await getVideoCover(blob, 1);
      const thumbNail = new File([newblob.blob], `thumb_${nanoid()}.jpg`, {
        type: 'image/png',
      });
      // console.log(thumbNail);
      thumbNail.duration = newblob.duration;
      totalFileSize += blob.size + thumbNail.size;
      // console.log(`what is it ${thumbNail}`);
      videoThumbnails.push(thumbNail);
    }))
      .then(async () => {
        if (totalFileSize + currentSpace > maxSpace) {
          alert('Ran out of File space! Please either delete existing files or upgrade your account.');
          setStatus(UploadStatus.AWAITING_FILE);
          return;
        }
        // const thumbBlob = await getVideoCover(files[0], 0.9);
        /*
    setStatus(UploadStatus.UPLOADING);
    // create array from the file list
    const imageFiles = Array.from(files);
    if (imageFiles.length > 50) {
      alert('Files must be jpg or png format and you cannot upload more than 50 photos at once.');
      setStatus(UploadStatus.AWAITING_FILE);
      return;
    }
    */
        // compress files here
        const compressedImageBlobs = await compressImages(imageFiles);
        await compressedImageBlobs.map((blob) => {
          totalFileSize += blob.size;
        });
        // console.log(totalFileSize);
        if (totalFileSize + currentSpace > maxSpace) {
          alert('Ran out of File space! Please either delete existing files or upgrade your account.');
          setStatus(UploadStatus.AWAITING_FILE);
          return;
        }
        const compressedFiles = [];

        // convert the blobs back to files for images
        // https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
        Promise.all(compressedImageBlobs.map((blob, index) => {
          // change images blobs back to files
          const newFile = new File([blob], `${nanoid()}${imageFiles[index].name}`, {
            type: 'image',
          });
          compressedFiles.push(newFile);
        })).then(async () => {
          // gneerate thumbnail blobs from the same images
          const compressedImageThumbBlobs = await thumbnailImages(imageFiles);

          await compressedImageThumbBlobs.map((blob) => {
            totalFileSize += blob.size;
          });
          // console.log(totalFileSize);
          if (totalFileSize + currentSpace > maxSpace) {
            alert('Ran out of File space! Please either delete existing files or upgrade your account.');
            setStatus(UploadStatus.AWAITING_FILE);
            return;
          }

          const compressedThumbFiles = [];

          // creates compressed image thumbnails
          Promise.all(compressedImageThumbBlobs.map((blob, index) => {
            // convert blobs back to file and then push to array for both
            const imageThumb = new File([blob], `thumb_${nanoid()}${imageFiles[index].name}`);
            compressedThumbFiles.push(imageThumb);
          })).then(async () => {
            // add isThumb property to indicate file is a thumbnail
            const thumbFilesSrc = [];
            // console.log(`all image thunbs: ${compressedThumbFiles}`);
            // console.log(`video thumbnails : ${videoThumbnails}`);

            // concats video thumbnails and image thumbnails as well as the files themselves
            const allThumbs = videoThumbnails.concat(compressedThumbFiles);
            const allFiles = videoFiles.concat(compressedFiles);
            // This just helps create the preview images to show user
            Promise.all(allThumbs.map((thumbFile) => {
              // console.log(thumbFile);
              thumbFilesSrc.push(Object.assign(thumbFile, { preview: URL.createObjectURL(thumbFile) }));
            })).then(() => {
              // console.log(thumbFilesSrc[0].preview);;
              setThumbnailSrc(thumbFilesSrc);
              setThumbFiles(allThumbs);
              setFilesToUpload(allFiles);
              setViewThumbnails(thumbFilesSrc.slice(0, 8));
              setStatus(UploadStatus.FILE_ACCEPTED);
            });
          });
        });
      });
  };
  const onDropRejected = () => alert('One or more of your file`s format is not supported by Kloak!');

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4', '.MOV', '.MPEG', '.mpg', '.wmv'], // TODO: Accept other video types
    multiple: true,
    maxFiles: 100,
    onDropAccepted,
    onDropRejected,
  });

  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);

  const renderDropzone = () => (
    <Flex {...getRootProps({ style: dropzoneStyle })}>
      <input {...getInputProps()} />
      <Flex
        flexDirection="column"
        width={{
          base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1200px',
        }}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Image className={classes.uploadIcon} size="32px" objectFit="contain" src={UploadImg} />
        <Text fontSize={{ base: '1.2em', md: '1.5em' }} fontWeight="300" textAlign="center">Drag and drop your files here to upload</Text>
        <Text>or click here</Text>
      </Flex>
    </Flex>
  );

  const renderUploadProgressIndicator = () => (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={{
        base: '100vw', sm: '100vw', md: '520px', lg: '770px', xl: '1200px',
      }}
    >
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FF1A75"
        size="xl"
        mb="8px"
      />
      {
        videoConverting !== ''
          ? (
            <Box>
              <Text
                textAlign="center"
                fontSize="1.2em"
                fontWeight="500"
              >
                {videoProgress}%
              </Text>
              <Text
                textAlign="center"
                fontSize="1.2em"
                fontWeight="500"
              >
                {videoConverting}
              </Text>
            </Box>
          ) : <Box />
      }
      {
        status === UploadStatus.UPLOADING
          ? (
            <Text
              textAlign="center"
              fontSize="1.1em"
              fontWeight="400"
            >
              Uploading...
            </Text>
          )
          : <Box />
      }
      {
        status === UploadStatus.PROCESSING
          ? (
            <Text
              textAlign="center"
              fontSize="1.1em"
              fontWeight="400"
            >
              Processing...
            </Text>
          )
          : <Box />
      }
      {
        status === UploadStatus.PAGE_LOADING
          ? (
            <Text
              textAlign="center"
              fontSize="1.1em"
              fontWeight="400"
            >
              Loading...
            </Text>
          )
          : <Box />
      }
      {
        newUserUploader
          ? (
            <Box>
              <InviteFamilyModal profileId={profile._id} />
            </Box>
          ) : <Box />
      }
    </Flex>
  );

  const renderDetailsForm = () => (
    <Box>
      <Formik
        initialValues={{
          title: '',
          album: '',
          description: '',
          category: 'FILM',
          privacy: '',
        }}
        validationSchema={Yup.object().shape({
          privacy: Yup.string()
            .required('Must select a privacy option'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setStatus(UploadStatus.UPLOADING);
          const allUploadFiles = [];
          let label = '';
          Privacy.forEach((item) => {
            if (item.value === values.privacy) label = item.label;
          });
          await Promise.all(filesToUpload.map((file, index) => {
            // console.log(file.type);
            allUploadFiles.push({
              title: values.title,
              writer: profile._id,
              filePath: `${s3BucketUrl}${file.name}`,
              fileSize: file.size,
              fileName: file.name,
              thumbNail: `${s3BucketUrl}${thumbFiles[index].name}`,
              thumbName: thumbFiles[index].name,
              thumbSize: thumbFiles[index].size,
              privacy: values.privacy,
              privacyLabel: label,
              type: file.type,
              duration: thumbFiles[index].duration,
              description: values.description,
            });
          }));
          let album;
          if (values.album) {
            album = {
              writer: profile._id,
              name: values.album,
              description: values.description,
              thumbnail: allUploadFiles[0].thumbNail,
              privacy: values.privacy,
              privacyLabel: label,
            };
          }
          const allFiles = allUploadFiles;
          // console.log(`all files including thumbs: ${allFiles}`);
          // console.log(`all thumbs: ${thumbFiles}`);
          axios({
            method: 'POST',
            url: '/api/post/uploadAll',
            data: {
              mediaData: filesToUpload,
              thumbData: thumbFiles,
              profileId: profile._id,
              allFiles,
              album,
              testing: true,
            },
            timeout: 0,
          })
            .then(async (response) => {
              // console.log(response.data.promises)
              postAllData(response.data.promises, filesToUpload.concat(thumbFiles))
                .then((res) => {
                  // console.log(res);
                  if (res[0].status === 204) {
                    if (profile.uploadCount < 2) {
                      setNewUserUploader(true);
                    } else {
                      alert('File(s) have been sucessfully uploaded!');
                      history.push('/my-profile');
                    }
                  }
                })
                .catch((err) => {
                  setFormErrorMessage(err);
                });
            })
            .catch((err) => {
              setFormErrorMessage(err);
            });
        }}
      >
        { /* eslint-disable-next-line no-shadow */ }
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Box
              onSubmit={handleSubmit}
            >
              <Image className={classes.uploadIcon} size="32px" objectFit="contain" src={newPath} />
              {thumbFiles && (
                <>
                  {/* TODO: Add support for custom thumbnails */}
                  {viewThumbnails.length > 1
                    ? (
                      <SimpleGrid
                        gridTemplateColumns={{
                          base: '1fr 1fr 1fr',
                          md: '1fr 1fr 1fr',
                        }}
                        gridTemplateRows={{
                          base: '1fr 1fr',
                        }}
                        spacing="10px"
                        width={{
                          base: '90vw', sm: '90vw', md: '90vw', lg: '500px', xl: '700px',
                        }}
                        marginX="auto"
                        marginY="1rem"
                      >
                        {thumbnailSrc.map((thumbnail) => (
                          <AspectRatio ratio={16 / 9}>
                            <Image
                              src={thumbnail.preview}
                              objectFit="cover"
                            />
                          </AspectRatio>
                        ))}
                        <ImageViewModal imageList={viewThumbnails} />
                      </SimpleGrid>
                    ) : (
                      <Box
                        width={{
                          base: '90vw', sm: '90vw', md: '90vw', lg: '500px', xl: '500px',
                        }}
                      >
                        <AspectRatio ratio={16 / 9}>
                          <Image
                            src={thumbnailSrc[0].preview}
                            objectFit="cover"
                          />
                        </AspectRatio>
                      </Box>
                    )}
                </>
              )}
              {viewThumbnails?.length <= 1
                ? (
                  <Box>
                    <Field name="title">
                      {() => (
                        <FormControl marginY="25px">
                          <FormLabel htmlFor="title" ml="4px" fontWeight="450">
                            Title
                          </FormLabel>

                          <Input
                            id="title"
                            placeholder="Add a title to your file"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Field name="description">
                      {() => (
                        <FormControl mb="25px">
                          <FormLabel htmlFor="description" ml="4px" fontWeight="450">
                            Description
                          </FormLabel>

                          <Input
                            id="description"
                            placeholder="Add a description to your file"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                ) : <Box />}
              <Field
                name="album"
              >
                {() => (
                  <FormControl
                    mb="4px"
                  >
                    <FormLabel htmlFor="album" ml="4px" fontWeight="450">
                      Album Name (Optional)
                    </FormLabel>

                    <Input
                      id="album"
                      placeholder="Create an album by adding an album name"
                      type="text"
                      value={values.album}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel htmlFor="description" ml="4px" fontWeight="450">
                      Album Description (Optional)
                    </FormLabel>

                    <Textarea
                      id="description"
                      placeholder="Add a album description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                )}
              </Field>
              {
                /*
              <Field name="existing_album">
                {() => (
                  <FormControl
                    isInvalid={errors.privacy && touched.privacy}
                    mb="25px"
                  >
                    <FormLabel htmlFor="existing_album" ml="4px" fontWeight="450">
                      Add to an existing Album
                    </FormLabel>

                    <Select id="existing_album" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                      {existingAlbums.map((album) => (
                        <option key={album} value={album._id}>
                          {album.name}
                        </option>
                      ))}
                    </Select>
                    <div className={classes.formErrorMessage}>
                      {touched.privacy && errors.privacy}
                    </div>
                  </FormControl>
                )}
              </Field>
              */
              }
              <Field name="privacy">
                {() => (
                  <FormControl
                    isRequired
                    isInvalid={errors.privacy && touched.privacy}
                    mb="25px"
                  >
                    <FormLabel htmlFor="privacy" ml="4px" fontWeight="450">
                      Set privacy of your File(s)
                    </FormLabel>

                    <Select id="privacy" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                      {Privacy.map((item) => (
                        <option key={item} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                    <div className={classes.formErrorMessage}>
                      {touched.privacy && errors.privacy}
                    </div>
                  </FormControl>
                )}
              </Field>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                marginY="1rem"
              >
                <Button
                  type="cancel"
                  onClick={cancelPublish}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="auto"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Publishing..."
                  onClick={handleSubmit}
                  display="block"
                  borderRadius="24px"
                  mt="16px"
                  mb="16px"
                  mr="auto"
                  ml="auto"
                  width="200px"
                  color="white"
                  bg="#FF1A75"
                  _hover={{
                    bg: '#FFE6EE',
                    color: '#FF1A75',
                  }}
                >
                  Publish
                </Button>
              </Flex>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
  const renderFirstTimeUpload = () => (
    <Box>
      <InviteFamilyModal />
    </Box>
  );
  const renderContent = () => {
    switch (status) {
      case UploadStatus.AWAITING_FILE:
        return renderDropzone();
      case UploadStatus.FILE_ACCEPTED:
        return renderDetailsForm();
      case UploadStatus.UPLOADING:
        return renderUploadProgressIndicator();
      case UploadStatus.PROCESSING:
        return renderUploadProgressIndicator();
      case UploadStatus.PROCESSING_COMPLETE:
        return renderDetailsForm();
      case UploadStatus.LOADING:
        return renderUploadProgressIndicator();
      case UploadStatus.UPLOAD_SUCCESS:
        return renderFirstTimeUpload();
      default:
        return renderDetailsForm();
    }
  };

  return (
    <Flex
      className="app"
    >
      <AlertBanner message={alertMessage} />
      <Heading fontWeight="500" fontSize={{ base: '1.3em', sm: '1.5em', lg: '2em' }} margin="2rem">Upload Media File(s)</Heading>
      {loadcomplete
        ? (
          <Box>
            {renderContent()}
          </Box>
        )
        : (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={{
              base: '100vw', sm: '100vw', md: '520px', lg: '770px', xl: '1200px',
            }}
          >
            <Spinner
              thickness="4px"
              emptyColor="gray.200"
              color="#FF1A75"
              size="xl"
              mb="8px"
            />
            <Text>Loading...</Text>
          </Flex>
        )}
    </Flex>
  );
}

function ImageViewModal(props) {
  const {
    imageList,
  } = props;
  const {
    isOpen: isOpenViewModal,
    onOpen: onOpenViewModal,
    onClose: onCloseViewModal,
  } = useDisclosure();

  const [errMessage, setErrMessage] = useState();
  const classes = useStyles();

  return (
    <Box>
      <AspectRatio ratio={16 / 9}>
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          backgroundColor="#DDD"
          cursor="pointer"
          onClick={onOpenViewModal}
        >
          <SeeMoreButton />
        </Flex>
      </AspectRatio>
      <Modal
        isOpen={isOpenViewModal}
        onClose={onCloseViewModal}
        size={{
          base: '100%', sm: '100%', md: '620px', lg: '870px', xl: '1300px',
        }}
        margin="auto"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mass Upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding="10px">
            <SimpleGrid
              gridTemplateColumns={{
                base: '1fr',
                sm: '1fr',
                md: '1fr 1fr 1fr',
                xl: '1fr 1fr 1fr 1fr 1fr',
              }}
              gridTemplateRows={{
                base: '1fr 1fr',
              }}
              spacing="10px"
              width={{
                base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1200px',
              }}
              marginX="auto"
              marginY="1rem"
            >
              {imageList.map((thumbnail) => (
                <AspectRatio ratio={16 / 9}>
                  <Image
                    src={thumbnail.preview}
                    objectFit="cover"
                  />
                </AspectRatio>
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

MassUploadBrowser.propTypes = propTypes;

export default withRouter(MassUploadBrowser);
