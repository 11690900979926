/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import * as Yup from 'yup';
import styled from 'styled-components';
import {
  Avatar,
  Button,
  Heading,
  Flex,
  Spinner,
  Stack,
  Box,
  SimpleGrid,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  FormControl,
  FormLabel,
  Select,
  AspectRatio,
} from '@chakra-ui/react';
import moment from 'moment';
import { Formik, Field } from 'formik';
import axios from 'axios';
import { Settings2Outline } from '@styled-icons/evaicons-outline/Settings2Outline';
import SettingIcon from '../../../../assets/images/settings.png';
import ImageReaction from '../../../sections/ImageReaction';

const baseDropzoneStyle = {
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: '50%',
  borderColor: '#EEEEEE',
  padding: '2px',
  cursor: 'pointer',
};
const CustomSettings = styled(Settings2Outline)`
  color: #CCC;
  width: 24px;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: #FFF;
  }
`;

const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

const LeftButton = styled.button`
  height: 100vh;
  width: 72px;
  background-color: none;
  color: white;
  font-size: 72px;
  border: none;
  borderRadius: 5px;  
`;
const RightButton = styled.button`
  
  height: 100vh;
  width: 72px;
  background-color: none;
  color: white;
  font-size: 72px;
  border: none;
  borderRadius: 5px;  
`;

const LeftContainer = styled.div`

background-color: #111;
height: 100%;
width: 72px;
cursor: pointer;
&: hover {
  background-color: #222;
}
`;
const RightContainer = styled.div`
height: 100%;
width: 72px;
background-color: #111;
cursor: pointer;
&: hover {
  background-color: #222;
}
`;
const ContainerImage = styled.div`
  display: flex;
  flexDirection: column;
  alignItems: center;
  justifyContent: center;
  backgroundColor: #111;
`;
const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',

  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#111',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#F1F1F1',
    fontSize: '30px',
    fontWeight: 'bold',
    transition: '0.3s',
    cursor: 'pointer',
    padding: '0 14px',
    borderRadius: '50%',
    '&:hover': {
      color: '#bbb',
      textDecoration: 'none',
    },
  },
  leftButton: {
    position: 'absolute',
    top: '50%',
    left: '24px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  rightButton: {
    position: 'absolute',
    top: '50%',
    right: '-26px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  privacyButton: {
    backgroundColor: '#222',
    borderRadius: '5px',
    padding: '6px 12px',
    color: '#FFF',
    marginTop: '1rem',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];
const propTypes = {
  post: PropTypes.object.isRequired,
  deletePosts: PropTypes.func.isRequired,
  updatePosts: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
function EditImage({
  post,
  deletePosts,
  updatePosts,
  profile,
}) {
  const classes = useStyles();
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isOpenEditModal,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();
  const [imagePrivacy, setImagePrivacy] = useState();
  // const { isOpenEdit, onOpen, onClose } = useDisclosure();
  const [errMessage, setErrMessage] = useState();
  const deleteImage = () => {
    // console.log(image);
    // include the path for both thumbnail and actual image
    const files = [{ Key: post.image?.fileName }, { Key: post.image?.thumbName }];
    axios({
      method: 'POST',
      url: '/api/image/deleteImageByProfile',
      data: {
        fileNames: files,
        filePath: post.image?.filePath,
        profileId: profile._id,
        fileSize: post.image?.fileSize,
        thumbSize: post.image?.thumbSize,
        imageId: post.image?._id,
        postId: post._id,
      },
    })
      .then((res) => {
        if (res.data.success) {
          deletePosts(post);
          onCloseDeleteModal();
        }
      })
      .catch((err) => {
        setErrMessage(err.message);
      });
  };
  const downloadFile = async () => {
    // console.log(post.image);
    const response = await axios.post(
      '/api/post/download-post', { fileKey: post.image.fileName },
    );
    const link = document.createElement('a');
    link.href = response.data.fileUrl;
    link.setAttribute('download', `${post.image.fileName}`);
    document.body.appendChild(link);
    link.click();
  };
  const renderDetailsForm = () => (
    <Formik
      initialValues={{
        description: post.image?.description,
        privacy: '',
      }}
      validationSchema={Yup.object().shape({
        privacy: Yup.string()
          .required('Must select a privacy option'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let label = '';
        Privacy.forEach((item) => {
          if (item.value === values.privacy) label = item.label;
        });
        setTimeout(() => {
          const data = {
            description: values.description,
            privacy: values.privacy,
            imageId: post.image._id,
            privacyLabel: label,
          };
          axios.post('/api/image/updateOneImage', data).then((response) => {
            // console.log(response.data);
            updatePosts(response.data.updatedPost);
            onCloseEditModal();
          });
        });
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form onSubmit={handleSubmit} width="100%">
            <Field name="description">
              {() => (
                <FormControl mb="25px">
                  <FormLabel htmlFor="description" ml="4px" fontWeight="300">
                    Description
                  </FormLabel>
                  <br />
                  <textarea
                    id="description"
                    placeholder="What is this image about?"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '100%' }}
                  />
                </FormControl>
              )}
            </Field>
            <Field
              name="privacy"
            >
              {() => (
                <FormControl
                  isRequired
                  isInvalid={errors.privacy && touched.privacy}
                  mb="25px"
                >
                  <FormLabel htmlFor="privacy" ml="4px" fontWeight="300">
                    Privacy
                  </FormLabel>
                  <br />
                  <Select id="privacy" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                    {Privacy.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                  <div className={classes.formErrorMessage}>
                    {touched.privacy && errors.privacy}
                  </div>
                </FormControl>
              )}
            </Field>
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Publishing..."
              onClick={handleSubmit}
              display="block"
              borderRadius="24px"
              mt="16px"
              mb="16px"
              mr="auto"
              ml="auto"
              width="200px"
              color="white"
              bg="#FF1A75"
              _hover={{
                bg: '#FFE6EE',
                color: '#FF1A75',
              }}
            >
              Save
            </Button>
          </form>
        );
      }}
    </Formik>
  );
  return (
    <div>
      <Menu>
        <MenuButton>
          <Text
            marginTop="4px"
            color="#FFF"
            fontSize="1.1em"
            backgroundColor="#3388FF"
            padding="4px 8px"
            borderRadius="3px"
            cursor="pointer"
            width="100px"
            textAlign="center"
          >
            Settings
          </Text>
        </MenuButton>
        <MenuList
          placement="bottom-start"
          zIndex="9999"
          backgroundColor="#EEE"
          width="125px"
        >
          <MenuItem onClick={onOpenEditModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Edit
            </Text>
          </MenuItem>
          <MenuItem onClick={() => { downloadFile(); }}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Download File
            </Text>
          </MenuItem>
          <MenuItem onClick={onOpenDeleteModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Delete
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Image Properties</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderDetailsForm()}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p1>Are you sure you want to Delete this image?</p1>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseDeleteModal}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={deleteImage}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
EditImage.propTypes = propTypes;
export default EditImage;
