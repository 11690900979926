/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box, Button, Flex, Image, Heading, Spinner, Text,
} from '@chakra-ui/react';

export default function DataUsage({
  displayUsage,
  maxUsage,
  availableSpace,
}) {
  const [dataRetrieved, setDataRetrieved] = useState(false);

  useEffect(() => {
    if (displayUsage && maxUsage) setDataRetrieved(true);
  }, [displayUsage, maxUsage]);

  return (
    <Flex
      align="center"
    >
      {dataRetrieved
        ? (
          <Flex justifyContent="center" flexDirection="column" alignItems="center" width="100%" marginY="1rem">
            <Text fontSize="1.1rem" fontWeight="500">Currently Using: {displayUsage}</Text>
            <Text fontSize="1.1rem" fontWeight="500">Max Space: {maxUsage}</Text>
            <Text fontSize="1.1rem" fontWeight="500">Space Available: {availableSpace}</Text>
          </Flex>
        )
        : (
          <Flex className="app" mt="128px" minHeight="90vh">
            <Spinner
              thickness="4px"
              emptyColor="gray.200"
              color="#FF1A75"
              size="xl"
            />
          </Flex>
        )}
    </Flex>
  );
}

DataUsage.propTypes = {
  displayUsage: PropTypes.string.isRequired,
  maxUsage: PropTypes.string.isRequired,
  availableSpace: PropTypes.string.isRequired,
};
