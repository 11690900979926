/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Image,
  AspectRatio,
  Flex,
  SimpleGrid,
  Avatar,
  useDisclosure,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  Spinner,
  Stack,
  Textarea,
  Heading,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import Comments from './Comments';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  groupPosts: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
};
const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',

  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#111',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#F1F1F1',
    fontSize: '30px',
    fontWeight: 'bold',
    transition: '0.3s',
    cursor: 'pointer',
    padding: '0 14px',
    borderRadius: '50%',
    '&:hover': {
      color: '#bbb',
      textDecoration: 'none',
    },
  },
  leftButton: {
    position: 'absolute',
    top: '50%',
    left: '24px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  rightButton: {
    position: 'absolute',
    top: '50%',
    right: '-26px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  privacyButton: {
    backgroundColor: '#222',
    borderRadius: '5px',
    padding: '6px 12px',
    color: '#FFF',
    marginTop: '1rem',
  },
});
function Post({
  groupPosts,
  profile,
}) {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadedPosts, setLoadedPosts] = useState(false);
  const [currentSelectedUrl, setCurrentSelectedUrl] = useState();
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [selectedPost, setSelectedPost] = useState();
  const [mediaType, setMediaType] = useState();
  useEffect(() => {
    if (groupPosts.length > 0) {
      setLoadedPosts(true);
    }
  }, [groupPosts]);
  const openMedia = (post, type) => {
    setMediaLoaded(false);
    setMediaType(type);
    if (type === 'image') {
      setCurrentSelectedUrl(post.image.filePath);
    } else if (type === 'video') {
      setCurrentSelectedUrl(post.video.filePath);
    }
    onOpen();
    setMediaLoaded(true);
  };
  const goPreviousPost = () => {
    console.log(groupPosts);
  };

  const goNextPost = () => {
    console.log(groupPosts);
  };
  const renderSpinner = () => (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FF1A75"
        size="xl"
        mb="8px"
        width="50px"
        margin="auto"
      />
      <Text>Loading</Text>
    </Flex>
  );
  const renderPosts = () => groupPosts.map((post) => (
    <Box
      id={`group_post_${post._id}`}
      padding="1rem"
      borderRadius="10px"
      marginY="3rem"
      backgroundColor="#DDD"
    >
      <Flex
        alignItems="left"
        paddingY="0.5rem"
      >
        <Box>
          <Flex
            justifyContent="center"
            flexDir="column"
            alignItems="left"
          >
            <Avatar
              size="md"
              objectFit="cover"
              src={post.author.image}
              cursor="pointer"
            />
          </Flex>
        </Box>
        <Box>
          <Flex
            justifyContent="center"
            flexDir="column"
            alignItems="left"
            height="100%"
          >
            <Text
              fontSize="1.2rem"
              fontWeight="650"
              marginLeft="0.5rem"
              cursor="pointer"
              color="#777"
            >
              {post.author.username}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Box
        marginY="1em"
      >
        <Text
          color="#444"
          fontWeight="bold"
          fontSize={{ base: '1.2em', sm: '1.4em' }}
        >
          {post.text}
        </Text>
      </Box>
      <Box>
        {
          post.album ? <Box>Hello</Box> : (
            <SimpleGrid
              width="100%"
              gridTemplateColumns="1fr 1fr"
            >
              {post.posts.map((allposts, index) => (
                <Box>
                  {index < 4
                    ? (
                      <Box>
                        {
                  allposts.image
                    ? (
                      <Box
                        width="100%"
                        onClick={() => { openMedia(allposts, 'image'); }}
                      >
                        <AspectRatio ratio={16 / 9} backgroundColor="#111">
                          <Image
                            alt="thumbnail"
                            objectFit="cover"
                            width="auto"
                            maxHeight="100%"
                            margin="auto"
                            borderRadius=".70rem"
                            src={allposts.image.thumbNail}
                            cursor="pointer"
                          />
                        </AspectRatio>
                      </Box>
                    ) : (
                      <Box
                        width="100%"
                        onClick={() => { openMedia(allposts, 'video'); }}
                      >
                        <Box
                          marginTop="-20px"
                        >
                          <Box position="relative" textAlign="right" width="100%">
                            <AspectRatio
                              ratio={16 / 9}
                              position="relative"
                            >
                              <Image
                                alt="thumbnail"
                                src={allposts.video.thumbNail}
                                objectFit="cover"
                                cursor="pointer"
                                marginTop="20px"
                                boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                              />
                            </AspectRatio>
                            <Text
                              as="span"
                              position="relative"
                              top="-10px"
                              right="10px"
                              zIndex="2"
                              color="#FFFFFF"
                              backgroundColor="rgb(0, 0, 0, 0.7)"
                              borderRadius="4px"
                              padding="4px"
                              marginLeft="5px"
                            >
                              {Math.floor(allposts.video.duration / 60)}:{`0${Math.floor(allposts.video.duration - Math.floor(allposts.video.duration / 60) * 60)}`.slice(-2)}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    )
                  }
                      </Box>
                    ) : <Box />}
                </Box>
              ))}
            </SimpleGrid>
          )
        }
      </Box>
      <Comments
        groupPostId={post._id}
        profile={profile}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{
          base: '100%', sm: '100%', md: '100%', lg: '770px', xl: '1200px',
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            marginY="2rem"
          >
            <Box
              margin="auto"
            >
              {
                mediaLoaded
                  ? (
                    <Box>
                      <Box>
                        {
                        mediaType === 'image' ? (
                          <Flex justifyContent="center" flexDirection="column" backgroundColor="#111">
                            <AspectRatio ratio={16 / 9}>
                              <Flex justifyContent="center" position="relative">
                                <button type="submit" className={classes.leftButton} onClick={goPreviousPost}>&lt;</button>
                                <Image alt="photo" src={currentSelectedUrl} alignSelf="center" width="auto" maxHeight="100%" />
                                <button type="submit" className={classes.rightButton} onClick={goNextPost}>&gt;</button>
                              </Flex>
                            </AspectRatio>
                          </Flex>
                        ) : <Box />
                      }
                      </Box>
                      <Box>
                        {
                          mediaType === 'video' ? (
                            <Flex justifyContent="center" flexDirection="column" backgroundColor="#111">
                              <AspectRatio ratio={16 / 9}>
                                <Flex justifyContent="center" position="relative">
                                  <button type="submit" className={classes.leftButton} onClick={goPreviousPost}>&lt;</button>
                                  <Box
                                    as="video"
                                    alignSelf="center"
                                    width="auto"
                                    maxHeight="100%"
                                    backgroundColor="#111"
                                    src={currentSelectedUrl}
                                    controls
                                  />
                                  <button type="submit" className={classes.rightButton} onClick={goNextPost}>&gt;</button>
                                </Flex>
                              </AspectRatio>
                            </Flex>

                          ) : <Box />
                        }
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      {renderSpinner()}
                    </Box>
                  )
              }
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  ));
  return (
    <Box
      minHeight="90vh"
      margin="auto"
    >
      {
            loadedPosts
              ? (
                <Box>
                  {
                      renderPosts()
                  }
                </Box>
              )
              : <Box />
        }
    </Box>
  );
}
Post.propTypes = propTypes;
export default Post;
