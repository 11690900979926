/* eslint-disable */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
  Box,
  Button,
  Flex,
  Text,
  Avatar,
  useDisclosure,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
} from "@chakra-ui/react";
import axios from 'axios';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import LikeDislikes from './LikeDislikes';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';

const SeeMoreButton = styled(EyeFill)`
  width: 24px;
  color: #BBB;
  cursor: pointer;
`;
const HeartActive = styled(SuitHeartFill)`
  color: #FF0000; 
  right: 12px;
  top: 12px;
  position: relative;
  width: 16px;
`;
const HeartButton = styled(SuitHeartFill)`
  background-color: inherit;
  width: 24px;
  color: inherit;
  cursor: pointer;
`;

const propTypes = {
  postId: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  comment: PropTypes.shape({
    _id: PropTypes.string,
    writer: PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
    }),
    responseTo: PropTypes.object,
    content: PropTypes.string,
  }).isRequired,
  refreshFunction: PropTypes.func.isRequired,
};

function SingleComment({
  postId,
  comment,
  refreshFunction,
  profile
}) {
  const user = useSelector((state) => state.user);
  const [CommentValue, setCommentValue] = useState('');
  const [OpenReply, setOpenReply] = useState(false);
  const [reactionActive, setReactionActive] = useState(false);
  const [viewReactions, setViewReactions] = useState([]);
  const [commentReactions, setCommentReactions] = useState([]);
  const [error, setError] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (profile._id) {
      getReactions(profile);
    }
  },[profile])

  const getReactions = (profileData) => {
    axios({
      url: '/api/comment/getAllReactions',
      method: 'POST',
      data: {
        commentId: comment._id,
        profile: profileData,
      },
    })
      .then((response) => {
        console.log(response.data.reactions[0])
        setCommentReactions(response.data.reactions);
        setViewReactions(response.data.reactions?.slice(0, 4));
        // console.log(`result: ${response.data.reactions}`);
        // eslint-disable-next-line eqeqeq
        if (response.data.reactions === undefined
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == []
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == '') {
          // console.log('reached me');
          setReactionActive(false);
          setLoadCompleted(true);
          // document.getElementById(`heart-button`).style.color = '#DDD';
          return;
        }
        if (response.data.reactions[0].profile._id === profileData._id) {
          // console.log('match found');
          // document.getElementById(`heart-button`).style.color = '#FF0000';
          setReactionActive(true);
          console.log('reached ne');
        } else {
          // document.getElementById(`heart-button`).style.color = '#DDD';
          setReactionActive(false);
        }
        console.log('finally');
        setLoadCompleted(true);
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
      })
      .catch((err) => {
        setError(err);
      });
  };
  const reactionHandler = () => {
    // console.log(imageReactions);
    if (reactionActive === false) {
      axios({
        url: '/api/comment/addCommentReaction',
        method: 'POST',
        data: {
          commentId: comment._id,
          profile,
          reactionType: 'Love',
        },
      })
        .then((reactionData) => {
          // console.log(`reached? ${reactionData.data.reactionData.profile._id}`);
          const newReaction = viewReactions;
          const allreactions = commentReactions;
          newReaction.unshift(reactionData.data.reactionData);
          allreactions.unshift(reactionData.data.reactionData);
          //  document.getElementById(`heart-button`).style.color = '#FF0000';
          setViewReactions(newReaction);
          setCommentReactions(allreactions);
          setReactionActive(true);
          // console.log(profile.image);
        })
        .catch((err) => {
          setError(err);
        });
    } else {
      axios({
        url: '/api/comment/removeCommentReaction',
        method: 'POST',
        data: {
          profile,
          commentId: comment._id,
        },
      })
        .then(() => {
          // console.log(imageReactions);
          const allReactions = commentReactions;
          const newReaction = viewReactions;
          allReactions.shift();
          newReaction.shift();
          // console.log(`new ${newReactions.length}`);
          setCommentReactions(allReactions);
          setViewReactions(newReaction);
          setReactionActive(false);
        });
    }
  };
  
  return (
    <div>
      <Box>
        <Flex> 
          <Box paddingRight="5px">
          <Box
            as="img"
            crossOrigin="anonymous"
            src={comment.writer.image}
            width="32px"
            height="32px"
            objectFit="cover"
            borderRadius="50%"
            verticalAlign="middle"
          />
          </Box>
           <Box paddingTop="1px">
              <Text fontSize={{base:".9rem", sm:".9rem", md:"1.1rem"}} fontWeight="500" color="#000">{comment.writer.username}</Text>
              <Text fontSize={{base:".8rem", sm:".8rem", md:"1rem"}}>{comment.content}</Text>
              <Box color={reactionActive === false ? '#AAA' : '#FF0000'} marginY="8px">
                <HeartButton id="heart-button" onClick={() => reactionHandler()} />
              </Box>
              <Box>{commentReactions.length > 0
                ? (
                  <SimpleGrid
                    gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                    width="250px"
                  >
                    {viewReactions?.map((data) => (
                      <Flex marginY="8px" height="32px">
                        <Box
                          as="img"
                          crossOrigin="anonymous"
                          src={data.profile && data.profile.image}
                          width="32px"
                          height="32px"
                          objectFit="cover"
                          borderRadius="50%"
                          verticalAlign="middle"
                        />
                        <HeartActive />
                      </Flex>
                    ))}
                    <Flex marginY="8px" marginLeft="5px" height="32px">
                      <SeeMoreButton onClick={() => { onOpen(); }} />
                    </Flex>
                    <Box>
                      <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalCloseButton />
                          <ModalBody>
                            <Box marginY="3rem">
                              {commentReactions?.map((data) => (
                                <Flex marginY="1rem">
                                  <Box
                                    as="img"
                                    crossOrigin="anonymous"
                                    src={data.profile && data.profile.image}
                                    width="32px"
                                    height="32px"
                                    objectFit="cover"
                                    borderRadius="50%"
                                    verticalAlign="middle"
                                  />
                                  <HeartActive />
                                  <Text marginTop="5px" fontWeight="500">{data.profile.username}</Text>
                                </Flex>
                              ))}
                            </Box>
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </Box>
                  </SimpleGrid>
                )
                : <Box />}
              </Box>
           </Box>
        </Flex>
      </Box>
    </div>
  );
}

SingleComment.propTypes = propTypes;

export default SingleComment;
