/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import * as Yup from 'yup';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
// import Amplify, { Auth, Storage } from 'aws-amplify';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import {
  Avatar,
  Button,
  Heading,
  Flex,
  Spinner,
  Stack,
  Box,
  SimpleGrid,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Boxider,
  Text,
  FormControl,
  FormLabel,
  Select,
  AspectRatio,
  Input,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from '@chakra-ui/react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import axios from 'axios';
import { Settings2Outline } from '@styled-icons/evaicons-outline/Settings2Outline';
import { SuitHeartFill } from '@styled-icons/bootstrap/SuitHeartFill';
import { EyeFill } from '@styled-icons/bootstrap/EyeFill';
import { ImageAdd } from '@styled-icons/boxicons-solid/ImageAdd';
import AddImagesAlbum from './AddImagesAlbum';
import EditAlbumImages from './EditAlbumImages';

const ImageAddIcon = styled(ImageAdd)`
  width: 28px;
  color: #BBB;
  cursor: pointer;
  width: 100%;
  height: 70%;
`;

const HeartActive = styled(SuitHeartFill)`
  color: #FF0000; 
  right: 12px;
  top: 12px;
  position: relative;
  width: 16px;
`;
const HeartButton = styled(SuitHeartFill)`
  background-color: inherit;
  width: 18px;
  color: inherit;
  cursor: pointer;
`;
const SeeMoreButton = styled(EyeFill)`
  width: 24px;
  color: #DDD;
  cursor: pointer;
`;

const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',

  },
  familyPostGrid: {
    display: 'grid',
    gridGap: '1em',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#111',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#F1F1F1',
    fontSize: '30px',
    fontWeight: 'bold',
    transition: '0.3s',
    cursor: 'pointer',
    padding: '0 14px',
    borderRadius: '50%',
    '&:hover': {
      color: '#bbb',
      textDecoration: 'none',
    },
  },
  leftButton: {
    position: 'absolute',
    top: '50%',
    left: '24px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  rightButton: {
    position: 'absolute',
    top: '50%',
    right: '-26px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  privacyButton: {
    backgroundColor: '#222',
    borderRadius: '5px',
    padding: '6px 12px',
    color: '#FFF',
    marginTop: '1rem',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];
const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  profile: PropTypes.object.isRequired,
};
function Albums({
  history,
  profile,
}) {
  const {
    isOpen: isOpenAlbumModal,
    onOpen: onOpenAlbumModal,
    onClose: onCloseAlbumModal,
  } = useDisclosure();
  const {
    isOpen: isOpenReactionModal,
    onOpen: onOpenReactionModal,
    onClose: onCloseReactionModal,
  } = useDisclosure();
  const classes = useStyles();
  const [err, setErr] = useState('');
  const [viewAlbum, setViewAlbum] = useState();
  const [imageList, setImageList] = useState();
  const [loadedImage, setLoadedImages] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [imageReactions, setImageReactions] = useState([]);
  const [viewReactions, setViewReactions] = useState();
  const [reactionActive, setReactionActive] = useState();
  const [commentsList, setCommentsList] = useState([]);
  const [initCommentsPage, setInitCommentsPage] = useState(false);
  const [perCommentsPage, setPerCommentsPage] = useState(5);
  const [commentsPage, setCommentsPage] = useState(0);
  const [commentsloading, setCommentsLoading] = useState(true);
  const [lastCommentsPage, setLastCommentsPage] = useState(false);
  const [maximumReactions, setMaximumReactions] = useState(9);
  const [renderState, setRenderState] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState();
  const [dataUsageObj, setDataUsageObj] = useState();
  const [loadedModal, setLoadedModal] = useState(false);
  const [addImagesLoaded, setAddImagesLoaded] = useState(false);
  const [lastPageAlbumReached, setLastPageAlbumReached] = useState(false);
  const [itemsPerAlbumPage, setAlbumsPerMediaPage] = useState(4);
  const [currentAlbumIndex, setCurrenAlbumIndex] = useState(0);
  const [albums, setAlbums] = useState([]);
  const [allAlbums, setAllAlbums] = useState([]);
  const [loadingAlbums, setLoadingAlbums] = useState(true);
  const setImageModal = (image) => {
    // console.log('reached');
    // console.log(image);
    // console.log(viewAlbum);
    setLoadedModal(true);

    // console.log('reached 2 ');
  };
  useEffect(() => {
    if (currentImage !== undefined) {
      // console.log('reached 3');
      // console.log(currentImage._id);
      document.getElementById(`albumId_${viewAlbum._id}`).style.display = 'none';
      document.getElementById('image_modal').style.display = 'block';
    }
  }, [currentImage]);

  const getAlbums = (myId) => {
    axios({
      method: 'POST',
      url: '/api/image/my-albums',
      data: {
        profileId: myId,
      },
    })
      .then((data) => {
        // console.log(data.data.albums);
        if (data.data.albums.length > 0) {
          const tempAlbums = data.data.albums;
          // console.log(data.data.albums[0]);
          const albumArr = [];
          for (let i = 0; i < tempAlbums.length; i += 1) {
            // console.log(albums[i]);
            if (i === tempAlbums.length - 1) {
              setLastPageAlbumReached(true);
              setCurrenAlbumIndex(i);
              albumArr.push(tempAlbums[i]);
              break;
            }
            if (i === itemsPerAlbumPage - 1) {
              albumArr.push(tempAlbums[i]);
              setCurrenAlbumIndex(i + 1);
              break;
            }
            albumArr.push(tempAlbums[i]);
          }
          if (albumArr.length > 0) {
            setLoadingAlbums(true);
          }
          // console.log (albumArr);
          setAlbums(albumArr);
          setAllAlbums((data.data.albums));
          setLoadingAlbums(false);
        }
      })
      .catch((albumErr) => {
        // console.log(err);
        let errMsg;

        switch (albumErr?.response?.status) {
          case 403:
            errMsg = 'You do Not have access to these images';
            break;
          case 500:
            errMsg = 'Server is currently down';
            break;
          default:
            errMsg = 'Something has went wrong';
            break;
        }
        setFormErrorMessage(errMsg);
        setTimeout(() => {
          setFormErrorMessage('');
        }, 3000);
      });
  };
  useEffect(() => {
    if (profile) {
      getAlbums(profile._id);
    }
  }, [profile]);

  const loadMoreAlbums = () => {
    setLoadingAlbums(true);
    const albumArr = [];
    for (let i = currentAlbumIndex; i < allAlbums.length; i += 1) {
      if (i === allAlbums.length - 1) {
        setLastPageAlbumReached(true);
        setCurrenAlbumIndex(i);
        albumArr.push(allAlbums[i]);
        break;
      }
      if (i === currentAlbumIndex + itemsPerAlbumPage) {
        setCurrenAlbumIndex(i);
        break;
      }
      albumArr.push(allAlbums[i]);
    }
    setAlbums(albums.concat(albumArr));
    setLoadingAlbums(false);
    // console.log('this videos' + video.filePath)

    // console.log(`here I am ${displayAlbums}`);
  };
  const updateMyAlbum = (updatedAlbum) => {
    const newAlbum = updatedAlbum;
    const newAlbumList = [];
    // console.log(updatedVideo);
    albums.forEach((eachAlbum) => {
      // console.log(eachVideo);
      if (eachAlbum._id === updatedAlbum._id) {
        newAlbum.writer = eachAlbum.writer;
        newAlbumList.push(newAlbum);
      } else {
        newAlbumList.push(eachAlbum);
      }
    });
    setAlbums(newAlbumList);
  };
  const updateAlbumsList = (newlist) => {
    setAlbums(newlist);
  };

  const deletedImage = (updatedList) => {
    setImageList(updatedList);
    window.location.reload();
  };
  const getReactions = (selectedImage) => {
    axios({
      url: '/api/image/getAllReactions',
      method: 'POST',
      data: {
        imageId: selectedImage._id,
        profile,
      },
    })
      .then((response) => {
        setImageReactions(response.data.reactions);
        setViewReactions(response.data.reactions?.slice(0, 9));
        // console.log(`result: ${response.data.reactions}`);
        // eslint-disable-next-line eqeqeq
        if (response.data.reactions === undefined
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == []
          // eslint-disable-next-line eqeqeq
          || response.data.reactions == '') {
          // console.log('reached me');
          setReactionActive(false);
          setImageModal(selectedImage);
          // document.getElementById(`heart-button`).style.color = '#DDD';
          return;
        }
        if (response.data.reactions[0].profile._id === profile._id) {
          // console.log('match found');
          // document.getElementById(`heart-button`).style.color = '#FF0000';
          setReactionActive(true);
        } else {
          // document.getElementById(`heart-button`).style.color = '#DDD';
          setReactionActive(false);
        }
        setImageModal(selectedImage);
        // document.getElementById(`reaction${currentImage._id}`).style.color = '#DDD';
      })
      .catch((reactionErr) => {
        setErr(reactionErr);
      });
  };
  const updateCommentList = (newComment) => {
    setCommentsList(commentsList.concat(newComment));
  };
  const getComments = (myImageId, reset) => {
    // console.log(myImageId);
    axios.post('/api/comment/getImageComments', { imageId: myImageId, commentsPage, perCommentsPage }).then((response) => {
      if (response.data.success) {
        if (!initCommentsPage) {
          setInitCommentsPage(true);
        }
        // console.log(response.data.comments);
        // console.log('response.data.comments', response.data.comments);
        if (reset === true) {
          // console.log(response.data);
          setCommentsList([].concat(response.data.comments));
        } else {
          setCommentsList(commentsList.concat(response.data.comments));
        }
        if (response.data.comments.length === 0) {
          setLastCommentsPage(true);
          setCommentsLoading(false);
          return;
        }
        // setCommentsPage(commentsPage + 1);
        // document.getElementById(`image_modal_${myImageId._id}`).style.display = 'block';
        setCommentsLoading(false);
      } else {
        alert('Failed to get video Info');
      }
    });
  };

  const setAlbumDisplay = (myAlbum) => {
    // console.log(myAlbum);
    const modal = document.getElementById(myAlbum._id);
    modal.style.display = 'block';
    const body = document.getElementById('root');
    body.style.height = '100vh';
    // // console.log("height: " + body.style.height);
    body.style.overflowY = 'hidden';
    document.getElementById(`albumId_${myAlbum._id}`).style.display = 'block';
  };

  const getAlbumImages = (myAlbum) => {
    axios({
      method: 'POST',
      url: '/api/image/getImageByAlbum',
      data: {
        albumId: myAlbum._id,
      },
    })
      .then((response) => {
        // console.log(myAlbum);
        setImageList(response.data.imageList);
        setViewAlbum(myAlbum);
        setLoadedImages(true);
        setAlbumDisplay(myAlbum);
      })
      .catch((imageErr) => {
        setErr(imageErr);
      });
  };
  const updateImagelist = (newImage) => {
    const updatedImage = newImage;
    const newImageList = [];
    imageList.forEach((eachImage) => {
      if (eachImage._id === updatedImage._id) {
        updatedImage.writer = eachImage.writer;
        newImageList.push(updatedImage);
      } else {
        newImageList.push(eachImage);
      }
    });
    setImageList(newImageList);
  };
  const fetchDataUsage = () => {
    axios({
      method: 'POST',
      url: 'api/users/getDataUsage',
      data: {
        profileId: profile._id,
      },
    })
      .then((response) => {
        // console.log(response.data.dataUsage[0]);
        setDataUsageObj(response.data.dataUsage);
        setRenderState(true);
      })
      .catch((dataErr) => {
        setErr(dataErr);
      });
  };
  const viewImage = (image) => {
    document.getElementById('image_modal').style.display = 'block';
    document.getElementById(`albumId_${viewAlbum._id}`).style.display = 'none';
    // console.log(`image: ${image._id}`);
    setCurrentImage(image);
    getComments(image._id, true);
    // console.log('hello?');
    getReactions(image);
  };
  const goUploadImages = () => {
    document.getElementById(`albumId_${viewAlbum._id}`).style.display = 'none';
    setAddImagesLoaded(true);
    fetchDataUsage();
  };
  const returnFromImage = (image) => {
    document.getElementById(`albumId_${viewAlbum._id}`).style.display = 'block';
    document.getElementById('image_modal').style.display = 'none';
    setLoadedModal(false);
  };
  const returnToAlbum = () => {
    document.getElementById('album-id').style.display = 'block';
    document.getElementById('add_images').style.display = 'none';
    setLoadedModal(false);
  };
  const updateAlbumed = (updatedAlbum) => {
    setViewAlbum(updatedAlbum);
  };

  const resetMaximumReactions = () => {
    setMaximumReactions(9);
  };
  const goPrevious = () => {
    imageList.forEach((data, imageindex) => {
      if (data === currentImage) {
        if ((imageindex - 1) === -1) {
          setCurrentImage(imageList[imageList.length - 1]);
          // setImageDescription(images[images.length - 1].description);
          // setImagePrivacy(images[images.length - 1].privacy);
          getReactions(imageList[imageList.length - 1]);
          setCommentsPage(0);
          getComments(imageList[imageList.length - 1]._id, true);
        } else {
          setCurrentImage(imageList[imageindex - 1]);
          // setImageDescription(images[imageindex - 1].description);
          // setImagePrivacy(images[imageindex - 1].privacy);
          getReactions(imageList[imageindex - 1]);
          setCommentsPage(0);
          getComments(imageList[imageindex - 1]._id, true);
        }
      }
    });
    resetMaximumReactions();
  };

  const goNext = () => {
    imageList.forEach((data, imageindex) => {
      if (data === currentImage) {
        if ((imageindex + 1) === imageList.length) {
          setCurrentImage(imageList[0]);
          // setImageDescription(images[0].description);
          // setImagePrivacy(images[0].privacy);
          getReactions(imageList[0]);
          setCommentsPage(0);
          getComments(imageList[0]._id, true);
        } else {
          setCurrentImage(imageList[imageindex + 1]);
          // setImageDescription(images[imageindex + 1].description);
          // setImagePrivacy(images[imageindex + 1].privacy);
          getReactions(imageList[imageindex + 1]);
          setCommentsPage(0);
          getComments(imageList[imageindex + 1]._id, true);
        }
      }
    });
    resetMaximumReactions();
  };
  const reactionHandler = () => {
    // console.log(imageReactions);
    if (reactionActive === false) {
      axios({
        url: '/api/image/addImageReaction',
        method: 'POST',
        data: {
          imageId: currentImage._id,
          profile,
          reactionType: 'Love',
        },
      })
        .then((reactionData) => {
          // console.log(`reached? ${reactionData.data.reactionData.profile._id}`);
          const newReaction = viewReactions;
          const allreactions = imageReactions;
          newReaction.unshift(reactionData.data.reactionData);
          allreactions.unshift(reactionData.data.reactionData);
          //  document.getElementById(`heart-button`).style.color = '#FF0000';
          setViewReactions(newReaction);
          setImageReactions(allreactions);
          setReactionActive(true);
          // console.log(profile.image);
        })
        .catch((reactionErr) => {
          setErr(reactionErr);
        });
    } else {
      axios({
        url: '/api/image/removeImageReaction',
        method: 'POST',
        data: {
          profile,
          imageId: currentImage._id,
        },
      })
        .then(() => {
          // console.log(imageReactions);
          const allReactions = imageReactions;
          const newReaction = viewReactions;
          allReactions.shift();
          newReaction.shift();
          // console.log(`new ${newReactions.length}`);
          setImageReactions(allReactions);
          setViewReactions(newReaction);
          setReactionActive(false);
        });
    }
  };
  const closeAlbum = () => {
    document.getElementById(`albumId_${viewAlbum._id}`).style.display = 'none';
    const modal = document.getElementById(viewAlbum._id);
    modal.style.display = 'none';
    const body = document.getElementById('root');
    body.style.height = '';
    body.style.overflowY = 'auto';
    setLoadedModal(false);
    setAddImagesLoaded(false);
  };
  const closeImageModal = () => {
    document.getElementById(`albumId_${viewAlbum._id}`).style.display = 'none';
    document.getElementById('image_modal').style.display = 'none';
    const modal = document.getElementById(viewAlbum._id);
    modal.style.display = 'none';
    const body = document.getElementById('root');
    body.style.height = '';
    body.style.overflowY = 'auto';
    setLoadedModal(false);
    setAddImagesLoaded(false);
  };
  const closeImageUpload = () => {
    document.getElementById(`albumId_${viewAlbum._id}`).style.display = 'none';
    const modal = document.getElementById(viewAlbum._id);
    modal.style.display = 'none';
    const body = document.getElementById('root');
    body.style.height = '';
    body.style.overflowY = 'auto';
    setLoadedModal(false);
    setAddImagesLoaded(false);
  };
  const fromUploadtoAlbum = (currentAlbum) => {
    document.getElementById(`albumId_${viewAlbum._id}`).style.display = 'none';
    // document.getElementById('image_modal_id').style.display = 'none';
    getAlbumImages(currentAlbum);
    setLoadedModal(false);
    setAddImagesLoaded(false);
  };
  const goToLink = (link, username) => {
    // console.log(username);
    // const userNameTab = document.getElementById(`sidefamily_${username}`);
    // if (userNameTab) userNameTab.style.color = '#FF0000';
    history.push(link);
  };
  return (
    <Box
      width="95%"
      marginX="auto"
      marginTop="2em"
    >
      {loadingAlbums ? <Box />
        : (
          <Box>
            <Flex
              width={{
                base: '100%',
              }}
              height={{ base: '50px', sm: '75px' }}
            >
              <Box width="100%" margin="auto" marginBottom="1em">
                <Heading
                  fontWeight="500"
                  fontSize={{ base: '1.4em', md: '2em' }}
                  marginBottom="1em"
                  color="#0B67C2"
                >
                  My Album(s)
                </Heading>
              </Box>
            </Flex>
            <Box padding="0" marginBottom="16px">
              <Box
                className={classes.familyPostGrid}
                spacing="10px"
                mb="24px"
                width={{
                  base: '100%',
                }}
              >
                {albums.map((album) => (
                  <Box
                    key={`my_profile_key_${album._id}`}
                  >
                    <AspectRatio ratio={16 / 9}>
                      <Box
                        as="img"
                        crossOrigin="anonymous"
                        alt="thumbnail"
                        src={album.thumbnail}
                        objectFit="cover"
                        cursor="pointer"
                        borderRadius=".70rem"
                        boxShadow=" rgb(0 0 0 / 20%) 0px 3px 3px 0px, rgb(0 0 0 / 19%) 0px 0px 5px 0px"
                        onClick={() => { goToLink(`/albums/${album._id}`); }}
                      />
                    </AspectRatio>
                    <Box paddingLeft="8px">
                      <Text
                        fontWeight="bold"
                        fontSize={{ base: '1.2em', sm: '1.4em' }}
                        color="#003971"
                        whiteSpace="wrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        marginTop="0.5em"
                      >
                        {album.name}
                      </Text>
                      <Text
                        color="#AAA"
                        fontWeight="bold"
                        fontSize={{ base: '1.1em', sm: '1.2em' }}
                        whiteSpace="wrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        { moment(album.createdAt).format('LL') }
                      </Text>
                      <Text
                        color="#AAA"
                        fontWeight="bold"
                        fontSize={{ base: '1em', sm: '1.1em' }}
                      >
                        {album.privacyLabel}
                      </Text>
                      <SettingsDropdown
                        album={album}
                        getAlbumImages={getAlbumImages}
                        profile={profile}
                        updateAlbum={updateMyAlbum}
                        images={imageList}
                        updateAlbumsList={updateAlbumsList}
                        albumList={albums}
                      />
                    </Box>
                    <Box>
                      <Box
                        id={album._id}
                        className={classes.modal}
                        backgroundColor="#333"
                        zIndex="9999"
                      >
                        <Box display={{ base: 'none', lg: 'block' }}>
                          <span className={classes.close} onClick={closeAlbum}>&times;</span>
                        </Box>
                        {loadedImage
                          ? (
                            <Box
                              backgroundColor="#333"
                            >
                              <Box height={{ base: '70px', lg: '0' }} display={{ base: 'block', lg: 'none' }} backgroundColor="#333">
                                <span className={classes.close} onClick={closeAlbum}>&times;</span>
                              </Box>
                              <Box>
                                {addImagesLoaded
                                  ? (
                                    <Box
                                      minHeight="95vh"
                                    >
                                      <h1>h1ll</h1>
                                      <Text
                                        onClick={() => { fromUploadtoAlbum(viewAlbum); }}
                                        as="p"
                                        color="#FFF"
                                        fontWeight="500"
                                        width={{
                                          base: '90vw',
                                          md: '30ch',
                                          lg: '30ch',
                                          xl: '40ch',
                                        }}
                                        whiteSpace="nowrap"
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                        cursor="pointer"
                                        marginLeft="1.5rem"
                                        marginTop="1rem"
                                      >
                                        Back to Album
                                      </Text>
                                      <Box display={{ base: 'none', lg: 'block' }}>
                                        <span className={classes.close} onClick={closeImageUpload}>&times;</span>
                                      </Box>
                                      <Box
                                        width={{
                                          base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1070px',
                                        }}
                                        margin="auto"
                                      >
                                        {renderState
                                          ? (
                                            <AddImagesAlbum
                                              currentAlbum={viewAlbum}
                                              availableSpace={dataUsageObj.freeDataSpace}
                                              maxSpace={dataUsageObj.maxUsage}
                                              currentSpace={dataUsageObj.currentUsage}
                                              profile={profile}
                                              updateAlbum={updateMyAlbum}
                                            />
                                          ) : <Box />}
                                      </Box>
                                    </Box>
                                  ) : <Box /> }
                                <Box
                                  id={`albumId_${album._id}`}
                                  marginTop="1rem"
                                >
                                  <Box
                                    backgroundColor="#333"
                                    width={{
                                      base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1070px',
                                    }}
                                    paddingLeft={{ base: '0.5rem', md: '0' }}
                                    marginX="auto"
                                    marginBottom="1rem"
                                  >
                                    <Text
                                      color="#FFF"
                                      fontWeight="500"
                                      width={{
                                        base: '90vw',
                                        md: '30ch',
                                        lg: '30ch',
                                        xl: '40ch',
                                      }}
                                      whiteSpace="nowrap"
                                      overflow="hidden"
                                      textOverflow="ellipsis"
                                      fontSize="1.5rem"
                                    >
                                      {viewAlbum.name}
                                    </Text>
                                    <Text
                                      color="#FFF"
                                      fontWeight="450"
                                      width={{
                                        base: '90vw',
                                        md: '30ch',
                                        lg: '30ch',
                                        xl: '40ch',
                                      }}
                                      whiteSpace="nowrap"
                                      overflow="hidden"
                                      textOverflow="ellipsis"
                                      fontSize="1rem"
                                    >
                                      {viewAlbum.description}
                                    </Text>
                                  </Box>
                                  <SimpleGrid
                                    gridTemplateColumns={{
                                      base: '1fr',
                                      sm: '1fr',
                                      md: '1fr 1fr ',
                                      lg: '1fr 1fr 1fr ',
                                    }}
                                    width={{
                                      base: '100%', sm: '100%', md: '520px', lg: '770px', xl: '1070px',
                                    }}
                                    spacing="10px"
                                    marginX="auto"
                                    marginTop="2rem"
                                    marginBottom="3rem"
                                  >
                                    <AspectRatio ratio={16 / 9}>
                                      <Box>
                                        <ImageAddIcon onClick={goUploadImages} />
                                      </Box>
                                    </AspectRatio>
                                    {imageList.map((image) => (
                                      <Box>
                                        <Box marginBottom="12px">
                                          <AspectRatio ratio={16 / 9}>
                                            <Image
                                              src={image.thumbNail}
                                              objectFit="cover"
                                              cursor="pointer"
                                              onClick={() => { viewImage(image); }}
                                            />
                                          </AspectRatio>
                                          <Flex paddingLeft="6px">
                                            <Avatar marginTop="16px" size="md" src={image.writer && image.writer.image} alignItems="center" />
                                            <Box paddingLeft="8px" marginTop="4px">
                                              <Text
                                                color="#FFF"
                                                fontWeight="500"
                                                width={{
                                                  base: '90vw',
                                                  md: '30ch',
                                                  lg: '30ch',
                                                  xl: '40ch',
                                                }}
                                                whiteSpace="nowrap"
                                                overflow="hidden"
                                                textOverflow="ellipsis"
                                              >
                                                {image.description}
                                              </Text>
                                              <Text color="#DDD">{ moment(image.createdAt).format('LL') }</Text>
                                              <EditAlbumImages
                                                image={image}
                                                profile={profile}
                                                updateImages={updateImagelist}
                                                images={imageList}
                                                deletedImage={deletedImage}
                                              />
                                            </Box>
                                          </Flex>
                                        </Box>
                                      </Box>
                                    ))}
                                  </SimpleGrid>
                                </Box>
                                <Box
                                  id="image_modal"
                                  display="block"
                                >
                                  {loadedModal
                                    ? (
                                      <Box>
                                        <Box display={{ base: 'none', lg: 'block' }}>
                                          <span className={classes.close} onClick={closeImageModal}>&times;</span>
                                        </Box>
                                        <SimpleGrid
                                          gridTemplateColumns={{ base: '1fr', lg: '1fr 350px' }}
                                          gridTemplateRows={{ base: '1fr 4fr', lg: '1fr' }}
                                          height="80vh"
                                        >
                                          <Box>
                                            <Flex justifyContent="center" flexDirection="column" height={{ base: '100%', lg: '100vh' }} backgroundColor="#111">
                                              <AspectRatio ratio={16 / 9}>
                                                <Flex justifyContent="center" position="relative">
                                                  <button type="submit" className={classes.leftButton} onClick={goPrevious}>&lt;</button>
                                                  <Image alt="photo" src={currentImage.filePath} alignSelf="center" width="auto" maxHeight="100%" />
                                                  <button type="submit" className={classes.rightButton} onClick={goNext}>&gt;</button>
                                                </Flex>
                                              </AspectRatio>
                                            </Flex>
                                          </Box>
                                          <Box
                                            paddingX={{ base: '0.5rem', sm: '1rem', lg: '2rem' }}
                                            paddingTop={{ base: '1rem', lg: '2rem' }}
                                            backgroundColor="#333"
                                          >
                                            <Box marginTop="1rem">
                                              <Flex>
                                                <Box>
                                                  <Avatar size="sm" src={currentImage.writer && currentImage.writer.image} />
                                                </Box>
                                                <Box paddingLeft="10px">
                                                  <Flex>
                                                    <Box marginRight="1rem">
                                                      <Flex justifyContent="center" flexDirection="column" height="100%">
                                                        <Text as="span" fontSize={{ base: '.9rem', sm: '.9rem', md: '1.1rem' }} color="#FFF" fontWeight="500">{currentImage.writer.username}</Text>
                                                      </Flex>
                                                    </Box>
                                                  </Flex>
                                                </Box>
                                              </Flex>
                                            </Box>
                                            <Box>
                                              <Box marginTop="1rem">
                                                <Text
                                                  as="p"
                                                  onClick={() => { returnFromImage(currentImage); }}
                                                  color="#FFF"
                                                  fontWeight="500"
                                                  width={{
                                                    base: '90vw',
                                                    md: '30ch',
                                                    lg: '30ch',
                                                    xl: '40ch',
                                                  }}
                                                  whiteSpace="nowrap"
                                                  overflow="hidden"
                                                  textOverflow="ellipsis"
                                                  cursor="pointer"
                                                >
                                                  Back to Album
                                                </Text>
                                              </Box>
                                              <Text id="img-description" fontSize={{ base: '.8rem', sm: '.8rem', md: '1rem' }} marginY="1rem" color="#FFF" fontWeight="400">
                                                {currentImage.description || ''}
                                              </Text>
                                            </Box>
                                          </Box>
                                        </SimpleGrid>
                                      </Box>
                                    ) : <Box />}
                                </Box>
                              </Box>
                            </Box>
                          )
                          : (
                            <Flex flexDirection="column" justifyContent="center" alignItems="center">
                              <Spinner
                                thickness="4px"
                                emptyColor="gray.200"
                                color="#FF1A75"
                                size="xl"
                                mb="8px"
                              />
                              <Text>Loading</Text>
                            </Flex>
                          )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
              {lastPageAlbumReached
                ? <Box />
                : (
                  <Box
                    display={{ base: 'flex', md: 'flex' }}
                    justifyContent="flex-end"
                    alignItems={{ base: 'center', md: 'flex-end' }}
                    height="100%"
                    marginBottom="4rem"
                    width="100%"
                  >
                    <Button
                      cursor="pointer"
                      onClick={() => { loadMoreAlbums(); }}
                      backgroundColor="#2C6DAD"
                      color="#FFF"
                      padding="10px 20px"
                      width={{ base: '100%', md: '125px', lg: '200px' }}
                      _hover={{ backgroundColor: '#5C8DFF' }}
                      borderRadius="1px"
                    >
                      View More
                    </Button>
                  </Box>
                )}
            </Box>
          </Box>
        )}
    </Box>

  );
}

function SettingsDropdown(props) {
  const classes = useStyles();
  const {
    image,
    profile,
    upDateImages,
    images,
    deletedImages,
    album,
    albumList,
    updateAlbum,
    updateAlbumsList,
    getAlbumImages,
  } = props;
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isOpenEditModal,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();
  const {
    isOpen: isDownloadModal,
    onOpen: onDownloadeModal,
    onClose: onCloseDownloadModal,
  } = useDisclosure();

  const [imagePrivacy, setImagePrivacy] = useState();
  // const { isOpenEdit, onOpen, onClose } = useDisclosure();
  const [errMessage, setErrMessage] = useState();
  const cancelDownload = () => {
    window.location.reload();
  };
  const deleteAlbum = () => {
    // console.log(image);
    // include the path for both thumbnail and actual image
    axios({
      method: 'POST',
      url: '/api/image/deleteAlbumById',
      data: {
        albumId: album._id,
        profileId: profile._id,
      },
    })
      .then((res) => {
        const newAlbumList = [];
        // console.log(albumList);
        albumList.forEach((eachAlbum) => {
          if (eachAlbum._id !== album._id) {
            newAlbumList.push(eachAlbum);
          }
        });
        updateAlbumsList(newAlbumList);
        onCloseDeleteModal();
        window.location.reload();
      })
      .catch((err) => {
        setErrMessage(err.message);
      });
  };

  const renderDetailsForm = () => (
    <Formik
      initialValues={{
        name: album.name,
        description: album.description,
        privacy: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Album must have a name'),
        privacy: Yup.string()
          .required('Must select a privacy option'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let label = '';
        Privacy.forEach((item) => {
          if (item.value === values.privacy) label = item.label;
        });
        setTimeout(() => {
          const data = {
            name: values.name,
            description: values.description,
            privacy: values.privacy,
            albumId: album._id,
            privacyLabel: label,
          };
          axios.post('/api/image/updateAlbum', data).then((response) => {
          // console.log(response.data.imageData);
            updateAlbum(response.data.updatedAlbum);
            onCloseEditModal();
          });
        });
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form onSubmit={handleSubmit} width="100%">
            <Field name="name">
              {() => (
                <FormControl
                  isRequired
                  isInvalid={errors.name && touched.name}
                  mb="4px"
                >
                  <FormLabel htmlFor="title" ml="4px" fontWeight="300">
                    Name
                  </FormLabel>
                  <br />
                  <Input
                    id="name"
                    placeholder="Give your Album a name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width={{ base: '98%', md: '100%' }}
                    margin="auto"
                  />
                  <div className={classes.formErrorMessage}>
                    {touched.name && errors.name}
                  </div>
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {() => (
                <FormControl mb="25px">
                  <FormLabel htmlFor="description" ml="4px" fontWeight="300">
                    Description
                  </FormLabel>
                  <br />
                  <textarea
                    id="description"
                    placeholder="What is this video about?"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '100%' }}
                  />
                </FormControl>
              )}
            </Field>
            <Field
              name="privacy"
            >
              {() => (
                <FormControl
                  isRequired
                  isInvalid={errors.privacy && touched.privacy}
                  mb="25px"
                >
                  <FormLabel htmlFor="privacy" ml="4px" fontWeight="300">
                    Privacy
                  </FormLabel>
                  <br />
                  <Select id="privacy" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                    {Privacy.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                  <Box className={classes.formErrorMessage}>
                    {touched.privacy && errors.privacy}
                  </Box>
                </FormControl>
              )}
            </Field>
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Publishing..."
              onClick={handleSubmit}
              display="block"
              borderRadius="24px"
              mt="16px"
              mb="16px"
              mr="auto"
              ml="auto"
              width="200px"
              color="white"
              bg="#FF1A75"
              _hover={{
                bg: '#FFE6EE',
                color: '#FF1A75',
              }}
            >
              Save
            </Button>
          </form>
        );
      }}
    </Formik>
  );
  const downloadAlbum = () => {
    // console.log(album._id);
    const zip = new JSZip();
    axios.post('/api/post/posts-by-album', { albumId: album._id, profileId: profile._id }).then((response) => {
      if (response.data.success) {
        let count = 0;
        const postResponse = response.data.posts;
        // console.log(response.data);
        postResponse.forEach((post) => {
          const filename = post.video ? post.video.fileName : post.image.fileName;
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(post.video ? post.video.filePath : post.image.filePath, (err, data) => {
            if (err) {
              throw err; // or handle the error
            }
            // console.log(data);
            zip.file(filename, data, { binary: true });
            count += 1;
            if (count === postResponse.length) {
              zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, 'test_zip');
                onCloseDownloadModal();
              });
            }
          });
        });
      }
    }).catch((err) => {
    // console.log(JSON.stringify(err));
      if (err.response && err.response.status === 403) {
        setErrMessage('ACCESS_DENIED');
      }
    });
  };
  return (
    <Box
      zIndex="9"
    >
      <Menu>
        <MenuButton>
          <Text
            marginTop="4px"
            color="#FFF"
            fontSize="1.1em"
            backgroundColor="#3388FF"
            padding="4px 8px"
            borderRadius="3px"
            cursor="pointer"
            width="100px"
            textAlign="center"
          >
            Settings
          </Text>
        </MenuButton>
        <MenuList
          placement="bottom-start"
          zIndex="9999"
          backgroundColor="#EEE"
          width="125px"
        >
          {/*
          <MenuItem onClick={() => { getAlbumImages(album); }}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Add Images
            </Text>
          </MenuItem>
          */
          }
          <MenuItem onClick={onOpenEditModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Edit
            </Text>
          </MenuItem>
          <MenuItem onClick={() => { downloadAlbum(); onDownloadeModal(); }}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Download Album
            </Text>
          </MenuItem>
          <MenuItem onClick={onOpenDeleteModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Delete
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Album Properties</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderDetailsForm()}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Album</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p1>Are you sure you want to Delete this Album?</p1>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseDeleteModal}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={deleteAlbum}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDownloadModal} onClose={() => { onCloseDownloadModal(); cancelDownload(); }} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent
          height="250px"
        >
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
              <Spinner
                thickness="4px"
                emptyColor="gray.200"
                color="#FF1A75"
                size="xl"
                mb="8px"
              />
              <Text fontSize="1.2em" fontWeight="500">Downloading and Preparing Files...</Text>
              <Button
                type="cancel"
                onClick={() => { onCloseDownloadModal(); cancelDownload(); }}
                display="block"
                borderRadius="24px"
                mt="16px"
                mr="auto"
                ml="auto"
                width="200px"
                color="white"
                bg="#FF1A75"
                _hover={{
                  bg: '#FFE6EE',
                  color: '#FF1A75',
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
Albums.propTypes = propTypes;
export default withRouter(Albums);
