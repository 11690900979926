/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import * as Yup from 'yup';
import styled from 'styled-components';
import {
  Avatar,
  Button,
  Heading,
  Flex,
  Spinner,
  Stack,
  Box,
  SimpleGrid,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  FormControl,
  FormLabel,
  Select,
  AspectRatio,
} from '@chakra-ui/react';
import moment from 'moment';
import { Formik, Field } from 'formik';
import axios from 'axios';
import { Settings2Outline } from '@styled-icons/evaicons-outline/Settings2Outline';
import SettingIcon from '../../../../assets/images/settings.png';
import ImageReaction from '../../../sections/ImageReaction';

const baseDropzoneStyle = {
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: '50%',
  borderColor: '#EEEEEE',
  padding: '2px',
  cursor: 'pointer',
};
const CustomSettings = styled(Settings2Outline)`
  color: #CCC;
  width: 24px;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: #FFF;
  }
`;

const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

const LeftButton = styled.button`
  height: 100vh;
  width: 72px;
  background-color: none;
  color: white;
  font-size: 72px;
  border: none;
  borderRadius: 5px;  
`;
const RightButton = styled.button`
  
  height: 100vh;
  width: 72px;
  background-color: none;
  color: white;
  font-size: 72px;
  border: none;
  borderRadius: 5px;  
`;

const LeftContainer = styled.div`

background-color: #111;
height: 100%;
width: 72px;
cursor: pointer;
&: hover {
  background-color: #222;
}
`;
const RightContainer = styled.div`
height: 100%;
width: 72px;
background-color: #111;
cursor: pointer;
&: hover {
  background-color: #222;
}
`;
const ContainerImage = styled.div`
  display: flex;
  flexDirection: column;
  alignItems: center;
  justifyContent: center;
  backgroundColor: #111;
`;
const useStyles = createUseStyles({
  thumbnailContainer: {
    position: 'relative',

  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
  modal: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#111',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#F1F1F1',
    fontSize: '30px',
    fontWeight: 'bold',
    transition: '0.3s',
    cursor: 'pointer',
    padding: '0 14px',
    borderRadius: '50%',
    '&:hover': {
      color: '#bbb',
      textDecoration: 'none',
    },
  },
  leftButton: {
    position: 'absolute',
    top: '50%',
    left: '24px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  rightButton: {
    position: 'absolute',
    top: '50%',
    right: '-26px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '2rem',
    cursor: 'pointer',
    zIndex: '9999',
    width: '50px',
    height: '100%',
    '&:hover': {
      color: 'rgba(255, 255, 255, .8)',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  privacyButton: {
    backgroundColor: '#222',
    borderRadius: '5px',
    padding: '6px 12px',
    color: '#FFF',
    marginTop: '1rem',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});
const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];

function ImageModalB(props) {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    image,
    images,
    index,
    profile,
    upDateImages,
    deletedImages,
  } = props;
  const [myImage, setMyImage] = useState();
  const [imagePrivacy, setImagePrivacy] = useState();
  const [imageDescription, setImageDescription] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (image) {
      // console.log(image);
      setMyImage(image);
      // console.log(index)
      setImageDescription(image.description);
      setImagePrivacy(image.privacyLabel);
      setImageLoaded(true);
    }
  }, [image]);

  const goPrevious = () => {
    images.forEach((data, imageindex) => {
      if (data === myImage) {
        if ((imageindex - 1) === -1) {
          setMyImage(images[images.length - 1]);
          setImageDescription(images[images.length - 1].description);
          setImagePrivacy(images[images.length - 1].privacyLabel);
        } else {
          setMyImage(images[imageindex - 1]);
          setImageDescription(images[imageindex - 1].description);
          setImagePrivacy(images[imageindex - 1].privacyLabel);
        }
      }
    });
  };
  const goNext = () => {
    images.forEach((data, imageindex) => {
      if (data === myImage) {
        if ((imageindex + 1) === images.length) {
          setMyImage(images[0]);
          setImageDescription(images[0].description);
          setImagePrivacy(images[0].privacyLabel);
        } else {
          setMyImage(images[imageindex + 1]);
          setImageDescription(images[imageindex + 1].description);
          setImagePrivacy(images[imageindex + 1].privacyLabel);
        }
      }
    });
  };
  const resetModal = () => {
    setMyImage(image);
  };
  const openModal = () => {
    // grab the modal
    const modal = document.getElementById(myImage._id);
    modal.style.display = 'block';
    modal.style.overflowY = 'hidden';
    const body = document.getElementById('root');
    body.style.height = '100vh';
    // console.log("height: " + body.style.height);
    body.style.overflowY = 'hidden';
  };
  const closeModal = () => {
    const modal = document.getElementById(image._id);
    modal.style.display = 'none';
    const body = document.getElementById('root');
    body.style.height = '';
    body.style.overflowY = 'auto';
    resetModal();
  };
  const renderDetailsForm = () => (
    <Formik
      initialValues={{
        description: imageDescription,
        privacy: '',
      }}
      validationSchema={Yup.object().shape({
        privacy: Yup.string()
          .required('Must select a privacy option'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let label = '';
        Privacy.forEach((item) => {
          if (item.value === values.privacy) label = item.label;
        });
        setTimeout(() => {
          const data = {
            description: values.description,
            privacy: values.privacy,
            imageId: myImage._id,
            privacyLabel: label,
          };
          axios.post('/api/image/updateOneImage', data).then((response) => {
            // console.log(response.data.imageData);
            setImageDescription(response.data.imageData.description);
            setImagePrivacy(response.data.imageData.privacyLabel);
            upDateImages(response.data.imageData);
            onClose();
          });
        });
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form onSubmit={handleSubmit} width="100%">
            <Field name="description">
              {() => (
                <FormControl mb="25px">
                  <FormLabel htmlFor="description" ml="4px" fontWeight="300">
                    Description
                  </FormLabel>
                  <br />
                  <textarea
                    id="description"
                    placeholder="What is this video about?"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '100%' }}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="privacy">
              {() => (
                <FormControl
                  isRequired
                  isInvalid={errors.privacy && touched.privacy}
                  mb="25px"
                >
                  <FormLabel htmlFor="privacy" ml="4px" fontWeight="300">
                    Privacy
                  </FormLabel>
                  <br />
                  <Select id="privacy" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                    {Privacy.map((item) => (
                      <option key={item} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                  <div className={classes.formErrorMessage}>
                    {touched.privacy && errors.privacy}
                  </div>
                </FormControl>
              )}
            </Field>
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Publishing..."
              onClick={handleSubmit}
              display="block"
              borderRadius="24px"
              mt="16px"
              mb="16px"
              mr="auto"
              ml="auto"
              width="200px"
              color="white"
              bg="#FF1A75"
              _hover={{
                bg: '#FFE6EE',
                color: '#FF1A75',
              }}
            >
              Save
            </Button>
          </form>
        );
      }}
    </Formik>
  );
  return (

    <div>{imageLoaded
      ? (
        <div padding="0">
          <Box
            key={image._id}
            marginBottom="16px"
            maxWidth={{
              base: '100%', sm: '100%', md: '250px', lg: '250px', xl: '350px',
            }}
          >
            <AspectRatio ratio={16 / 9} backgroundColor="#111">
              <Image
                alt="thumbnail"
                objectFit="cover"
                width="auto"
                heigh="auto"
                margin="auto"
                src={image.thumbNail}
                cursor="pointer"
                onClick={() => { openModal(image._id); }}
              />
            </AspectRatio>
            <Flex marginTop="12px">

              <Box paddingLeft="12px">
                <Text
                  color="#000"
                  fontWeight="500"
                  width={{
                    base: '90vw',
                    md: '30ch',
                    lg: '30ch',
                    xl: '40ch',
                  }}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {image.description}
                </Text>
                <Text color="#4A5568">{ moment(image.createdAt).format('LL') }</Text>
                <Text color="gray.600" fontSize="sm">{image.privacyLabel}</Text>
                <DeleteModal
                  image={myImage}
                  profile={profile}
                  upDateImages={upDateImages}
                  images={images}
                  deletedImages={deletedImages}
                />
              </Box>
            </Flex>
          </Box>
          <div id={image._id} className={classes.modal}>
            <Box height={{ base: '70px', lg: '0' }} display={{ base: 'block', lg: 'none' }} backgroundColor="#333">
              <span role="handler" className={classes.close} onClick={closeModal}>&times;</span>
            </Box>
            <Box
              display="grid"
              gridTemplateColumns={{ base: '1fr', lg: '1fr 350px' }}
              gridTemplateRows={{ base: '1fr 4fr', lg: '1fr' }}
              height="100vh"
            >
              <Box>
                <Flex justifyContent="center" flexDirection="column" height={{ base: '100%', lg: '100vh' }}>
                  <AspectRatio ratio={16 / 9}>
                    <Flex justifyContent="center" position="relative">
                      <button type="submit" className={classes.leftButton} onClick={goPrevious}>&lt;</button>
                      <Image alt="photo" src={myImage.filePath} alignSelf="center" width="auto" height="100%" />
                      <button type="submit" className={classes.rightButton} onClick={goNext}>&gt;</button>
                    </Flex>
                  </AspectRatio>
                </Flex>
              </Box>
              <Box paddingTop={{ base: '1rem', lg: '5rem' }} paddingX={{ base: '1rem', lg: '2rem' }} backgroundColor="#333">
                <Box>
                  <Box display={{ base: 'none', lg: 'block' }}>
                    <span className={classes.close} onClick={closeModal}>&times;</span>
                  </Box>
                  <Flex height="25px">
                    <Avatar size="sm" marginRight="0.7rem" src={myImage.writer && myImage.writer.image} alignItems="center" />
                    <Text as="span" fontSize={{ base: '.9rem', sm: '.9rem', md: '1.1rem' }} justifySelf="center" color="#FFF" fontWeight="500">
                      {myImage.writer.username}
                    </Text>
                    <CustomSettings onClick={onOpen} alignItems="center" />
                  </Flex>
                  <Flex>
                    <Box>
                      <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Edit Image Properties</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            {renderDetailsForm()}
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </Box>
                  </Flex>
                </Box>
                <Box>
                  <button type="submit" id="img-privacy" className={classes.privacyButton}>
                    {imagePrivacy}
                  </button>
                  <Text id="img-description" fontSize={{ base: '.8rem', sm: '.8rem', md: '1rem' }} marginTop="1rem" color="#FFF" fontWeight="400">
                    {imageDescription || ''}
                  </Text>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      )
      : <Flex><h1>Loading</h1></Flex>}
    </div>

  );
}
function DeleteModal(props) {
  const classes = useStyles();
  const {
    image,
    profile,
    upDateImages,
    images,
    deletedImages,
  } = props;
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isOpenEditModal,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();
  const [imagePrivacy, setImagePrivacy] = useState();
  // const { isOpenEdit, onOpen, onClose } = useDisclosure();
  const [errMessage, setErrMessage] = useState();
  const deleteImage = () => {
    // console.log(image);
    // include the path for both thumbnail and actual image
    const files = [{ Key: image.fileName }, { Key: image.thumbName }];
    axios({
      method: 'POST',
      url: '/api/image/deleteImageByProfile',
      data: {
        fileNames: files,
        filePath: image.filePath,
        profileId: profile._id,
        fileSize: image.fileSize,
        thumbSize: image.thumbSize,
        imageId: image._id,
      },
    })
      .then(() => {
        const newImageList = [];
        images.forEach((eachImage) => {
          if (eachImage._id !== image._id) {
            newImageList.push(eachImage);
          }
        });
        deletedImages(newImageList);
        onCloseDeleteModal();
      })
      .catch((err) => {
        setErrMessage(err.message);
      });
  };

  const renderDetailsForm = () => (
    <Formik
      initialValues={{
        description: image.description,
        privacy: '',
      }}
      validationSchema={Yup.object().shape({
        privacy: Yup.string()
          .required('Must select a privacy option'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let label = '';
        Privacy.forEach((item) => {
          if (item.value === values.privacy) label = item.label;
        });
        setTimeout(() => {
          const data = {
            description: values.description,
            privacy: values.privacy,
            imageId: image._id,
            privacyLabel: label,
          };
          axios.post('/api/image/updateOneImage', data).then((response) => {
            // console.log(response.data.imageData);
            upDateImages(response.data.imageData);
            onCloseEditModal();
          });
        });
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form onSubmit={handleSubmit} width="100%">
            <Field name="description">
              {() => (
                <FormControl mb="25px">
                  <FormLabel htmlFor="description" ml="4px" fontWeight="300">
                    Description
                  </FormLabel>
                  <br />
                  <textarea
                    id="description"
                    placeholder="What is this image about?"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '100%' }}
                  />
                </FormControl>
              )}
            </Field>
            <Field
              name="privacy"
            >
              {() => (
                <FormControl
                  isRequired
                  isInvalid={errors.privacy && touched.privacy}
                  mb="25px"
                >
                  <FormLabel htmlFor="privacy" ml="4px" fontWeight="300">
                    Privacy
                  </FormLabel>
                  <br />
                  <Select id="privacy" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                    {Privacy.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                  <div className={classes.formErrorMessage}>
                    {touched.privacy && errors.privacy}
                  </div>
                </FormControl>
              )}
            </Field>
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Publishing..."
              onClick={handleSubmit}
              display="block"
              borderRadius="24px"
              mt="16px"
              mb="16px"
              mr="auto"
              ml="auto"
              width="200px"
              color="white"
              bg="#FF1A75"
              _hover={{
                bg: '#FFE6EE',
                color: '#FF1A75',
              }}
            >
              Save
            </Button>
          </form>
        );
      }}
    </Formik>
  );
  return (
    <div>
      <Menu>
        <MenuButton>
          <Text
            marginTop="4px"
            color="#FFF"
            fontSize="sm"
            backgroundColor="#3388FF"
            padding="4px 8px"
            borderRadius="3px"
            cursor="pointer"
            width="70px"
            textAlign="center"
          >
            Settings
          </Text>
        </MenuButton>
        <MenuList
          placement="bottom-start"
          zIndex="9999"
          backgroundColor="#EEE"
          width="125px"
        >
          <MenuItem onClick={onOpenEditModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Edit
            </Text>
          </MenuItem>
          <MenuItem onClick={onOpenDeleteModal}>
            <Text
              fontWeight="500"
              fontSize="1rem"
              padding="12px"
            >
              Delete
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal isOpen={isOpenEditModal} onClose={onCloseEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Image Properties</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderDetailsForm()}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p1>Are you sure you want to Delete this image?</p1>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseDeleteModal}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={deleteImage}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ImageModalB;
