/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Flex,
  Spinner,
  Box,
  Heading,
  Text,
} from '@chakra-ui/react';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function EmailConfirmationPage({
  match,
  history,
}) {
  const { token } = match.params;

  const [status, setStatus] = useState('loading');
  const [error, setError] = useState('');

  useEffect(() => {
    setStatus('loading');
    axios.post('/api/users/verify', { token })
      .then(() => {
        setStatus('success');
      })
      .catch((err) => {
        setStatus('error');
        setError(err.message);
        if (err.response.status === 409) {
          history.push('/login');
        }
      });
  }, [token, history]);

  // TODO: Create full page loader component
  if (status === 'loading') {
    return (
      <Flex className="app" mt="128px" minHeight="90vh">
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="#FF1A75"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <Flex justifyContent="center" marginTop="1rem" width="100%">
      <Box minHeight="90vh" textAlign="center">
        <Box padding="1rem" backgroundColor="#EFEFEF" width={{ base: '98%', md: '450px' }} borderRadius="5px">
          <Heading fontSize={{ base: '1.4rem', md: '1.7rem' }}>Welcome to Kloak!</Heading>
          {
          status === 'success'
            ? <Text fontSize="1.1rem">Thank you. Your email has been confirmed.</Text>
            : <Text fontSize="1.1rem">Oops. Something went wrong. Error: {error}</Text>
        }
        </Box>
      </Box>
    </Flex>
  );
}

EmailConfirmationPage.propTypes = propTypes;

export default withRouter(EmailConfirmationPage);
