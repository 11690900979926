/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Flex,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Box,
  Button,
  Text,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { Videos } from '@styled-icons/boxicons-solid/Videos';
import { Images } from '@styled-icons/bootstrap/Images';
import UploadVideos from './sections/UploadVideos';
import UploadImages from './sections/UploadImages';
import MassUploadBrowser from './sections/MassUploadBrowser';
import MassUploadServer from './sections/MassUploadServer';
import MassUploadStable from './sections/MassUploadStable';
import AlertBanner from '../../sections/AlertBanner';
import DataUsage from './sections/DataUsage';

const StyledTab = styled(Tab)`
  margin: 0;
  &:focus {
    outline:0 !important;
    box-shadow: none;
    border: none;
    border-color: #FFF !important;
  }
`;

const VideoButton = styled(Videos)`
  color: ##1890ff;
  width: 36px;
`;

const ImageButton = styled(Images)`
  color: ##1890ff;
  width: 36px;
`;

const useStyles = createUseStyles({
  uploadIcon: {
    margin: '32px auto',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function UploadPage(props) {
  const user = useSelector((state) => state.user);
  const [profile, setProfile] = useState();
  const [renderState, setRenderState] = useState(false);
  const [vertified, setVertified] = useState();
  const [formErrorMessage, setFormErrorMessage] = useState();
  const [resendSuccess, setResendSuccess] = useState(false);
  const alertMessage = 'Warning: Your account is not vertified Please verifty your account before uploading your files!';
  const [dataUsageObj, setDataUsageObj] = useState();
  const { history } = props;

  const fetchDataUsage = (myProfile) => {
    axios({
      method: 'POST',
      url: 'api/users/getDataUsage',
      data: {
        profileId: myProfile._id,
      },
    })
      .then((response) => {
        // console.log(response.data.dataUsage[0]);
        setDataUsageObj(response.data.dataUsage);
        setRenderState(true);
      })
      .catch((err) => {
        setFormErrorMessage(err);
      });
  };

  const fetchProfile = () => {
    axios({
      method: 'POST',
      url: '/api/users/getProfile',
      data: {
        username: user.userData.username,
      },
    })
      .then((data) => {
      // console.log('profile data: ' + data.data._id);
        setProfile(data.data);
        fetchDataUsage(data.data);
      })
      .catch((err) => {
        setFormErrorMessage('Cannot fetch user profile');
      });
  };
  useEffect(() => {
    // console.log('running');
    if (user.userData) {
      if (user.userData.verified) {
        setVertified(true);
      } else {
        setVertified(false);
      }
      fetchProfile();
    }
  }, [user.userData]);
  /*
  useEffect(() => {
    if (profile._id) {
      setRenderState(true);
    }
  }, [profile._id]);
 */
  const resendVertification = () => {
    axios({
      method: 'POST',
      url: '/api/users/resend-vertification',
      data: {
        user: user.userData,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setResendSuccess(true);
        }
      })
      .catch((err) => {
        setFormErrorMessage(err);
      });
  };
  const classes = useStyles();
  return (
    <Flex
      justifyContent="center"
      marginTop="1.5rem"
      minHeight="90vh"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      {renderState
        ? (
          <Box>
            <DataUsage
              displayUsage={dataUsageObj.displayUsage}
              maxUsage={dataUsageObj.displayMax}
              availableSpace={dataUsageObj.displayFreeData}
            />
            <Tabs
              width={{
                base: '95%', sm: '90%', lg: '770px', xl: '1200px',
              }}
              margin="auto"
            >
              <TabList>
                <StyledTab>
                  <ImageButton />
                </StyledTab>
              </TabList>
              <TabPanels>
                <TabPanel
                  minHeight="90vh"
                  className={classes.pageView}
                >
                  {vertified
                    ? (
                      <MassUploadStable
                        history={history}
                        profile={profile}
                        availableSpace={dataUsageObj.freeDataSpace}
                        maxSpace={dataUsageObj.maxUsage}
                        currentSpace={dataUsageObj.currentUsage}
                      />
                    ) : (
                      <Box>
                        <AlertBanner message={alertMessage} />
                        <Flex
                          justifyContent="center"
                          marginTop="1.5rem"
                          flexDirection="column"
                          alignItems="center"
                          width="100%"
                        >
                          {
                            resendSuccess
                              ? (
                                <Box margin="1em" color="red" fontSize="1.2em" fontWeight="500">
                                  <Text textAlign="center">Vertification Sent!</Text>
                                  <Text textAlign="center">Check your Kloak email at {user.userData.email} </Text>
                                </Box>
                              ) : <Box />
                          }
                          <Button
                            onClick={() => resendVertification()}
                            display="block"
                            width="200px"
                            color="white"
                            borderRadius="24px"
                            bg="#FF1A75"
                            _hover={{
                              bg: '#FFE6EE',
                              color: '#FF1A75',
                            }}
                          >
                            Resend Verify Email
                          </Button>
                        </Flex>
                      </Box>
                    )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )
        : (
          <Flex className="app" mt="128px" minHeight="90vh">
            <Spinner
              thickness="4px"
              emptyColor="gray.200"
              color="#FF1A75"
              size="xl"
            />
          </Flex>
        )}
    </Flex>
  );
}

UploadPage.propTypes = propTypes;

export default UploadPage;
