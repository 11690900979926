/* eslint-disable linebreak-style */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Formik, Field } from 'formik';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Avatar,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Input,
  Box,
  AspectRatioBox,
  Link,
  Spacer,
  AspectRatio,
  HStack,
} from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import styled from 'styled-components';
import { UserFriends } from '@styled-icons/fa-solid/UserFriends';
import { InsertInvitation } from '@styled-icons/material/InsertInvitation';
import GroupSearch from '../Groups/sections/GroupSearch';

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
});

const StyledTab = styled(Tab)`
  margin: 0;
  &:focus {
    outline:0 !important;
    box-shadow: none;
    border: none;
    border-color: #FFF !important;
  }
`;

const useStyles = createUseStyles({
  pageView: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
  friendCard: {
    padding: '24px',
    width: '1000px',
    height: '100px',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 4px 16px 0px',
    '@media screen and (max-width: 1000px)': {
      width: '320px',
    },
  },
  thumbnailContainer: {
    position: 'relative',
  },
  groupPostGrid: {
    display: 'grid',
    gridGap: '1em',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '@media screen and (max-width: 1600px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});
const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      videoId: PropTypes.string,
    }),
  }).isRequired,
};
// TODO: Loading page
function Notification({
  history,
}) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [profile, setProfile] = useState();
  const [connectionsFetched, setConnectionsFetched] = useState(false);
  const [connections, setConnections] = useState([]);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [areThereConnections, setAreThereConnections] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [allGroups, setAllGroups] = useState([]);
  const [groupInvites, setGroupInvites] = useState([]);
  const [groupPageLoading, setGroupPageLoading] = useState(true);

  useEffect(() => {
    // console.log("running")
    if (user.userData) {
      axios({
        method: 'POST',
        url: '/api/users/getProfile',
        data: {
          username: user.userData.username,
        },
      })
        .then((data) => {
          setProfile(data.data);
          // console.log(data.data._id)
          axios.get(`/api/connection/all/${data.data._id}`).then((response) => {
            // console.log(response.data.connections);
            setConnectionsFetched(true);
            setConnections(response.data.connections);
            if (response.data.connections.friends.length > 0) {
              setAreThereConnections(true);
            } else {
              setAreThereConnections(false);
            }
            axios({
              method: 'POST',
              url: '/api/groups/groups-by-profile',
              data: {
                profileId: data.data._id,
              },
            })
              .then((res) => {
                setGroupPageLoading(true);
                // console.log(res.data.groupInvites);
                setAllGroups(res.data.myGroups);
                setGroupInvites(res.data.groupInvites);
                setGroupPageLoading(false);
              })
              .catch((groupErr) => {
                setErrMessage(groupErr);
              });
          });
        })
        .catch((err) => {
          setFormErrorMessage('Cannot fetch user profile');
        });
    }
  }, [user.userData, user]);

  const acceptFriendRequest = (profileId) => {
    const body = {
      profileFrom: profile._id,
      profileTo: profileId,
    };
    axios.post('/api/connection/accept', body).then(() => {
      // console.log("ACCEPTED FRIEND REQUEST");
      const updatedConnections = { ...connections };
      const newFriend = updatedConnections.pending.find(
        (conn) => conn._id === profileId,
      );
      updatedConnections.pending = updatedConnections.pending.filter(
        (conn) => conn._id !== profileId,
      );
      updatedConnections.friends.push(newFriend);
      setConnections(updatedConnections);
    });
  };

  const rejectFriendRequest = (profileId) => {
    const body = {
      profileFrom: profile._id,
      profileTo: profileId,
    };
    axios.post('/api/connection/reject', body).then(() => {
      // console.log("REJECTED FRIEND REQUEST");
      const updatedConnections = { ...connections };
      updatedConnections.pending = updatedConnections.pending.filter(
        (conn) => conn._id !== profileId,
      );
      setConnections(updatedConnections);
    });
  };
  const renderPendingRequestsCards = () => connections.pending.map((friend) => (
    <Flex cursor="pointer" onClick={() => { history.push('/families'); }} key={friend._id} className={classes.friendCard} flexDirection="vertical" alignItems="center">
      <Flex width={{ base: '0px', xl: '75px' }}>
        <Box as="img" crossOrigin="anonymous" rounded="full" size="75px" src={friend.image} />
      </Flex>
      <Spacer />
      <Flex>
        <Text fontSize="lg">Family Invitation: <Text fontWeight="bold">{friend.username}</Text></Text>
      </Flex>
      <Spacer />
      <Stack className={classes.friendCardButtonsContainer} direction={{ base: 'column', xl: 'row' }}>
        <Button
          onClick={() => acceptFriendRequest(friend._id)}
          display="block"
          width="110px"
          color="white"
          borderRadius="24px"
          bg="#FF1A75"
          _hover={{
            bg: '#FFE6EE',
            color: '#FF1A75',
          }}
        >
          Accept
        </Button>
        <Button
          onClick={() => rejectFriendRequest(friend._id)}
          isLoading={false}
          variant="outline"
          display="block"
          width="110px"
          borderRadius="24px"
          color="#FF1A75"
          borderColor="#FF1A75"
          _hover={{
            bg: '#FFE6EE',
            color: '#FF1A75',
          }}
        >
          Decline
        </Button>
      </Stack>
    </Flex>
  ));
  const acceptRequest = (invite) => {
    setGroupPageLoading(true);
    axios({
      method: 'POST',
      url: '/api/groups/accept-group-invite',
      data: {
        profileId: profile._id,
        invite,
      },
    })
      .then((res) => {
        const allInvites = [];
        const updatedGroups = allGroups;
        updatedGroups.unshift(res.data.updatedGroup);
        groupInvites.forEach((eachInvite) => {
          if (eachInvite._id !== invite._id) {
            allInvites.push(eachInvite);
          }
        });
        setAllGroups(updatedGroups);
        setGroupInvites(allInvites);
        setGroupPageLoading(false);
      })
      .catch((err) => {
        setErrMessage(err);
      });
  };

  const rejectRequest = (invite) => {
    setGroupPageLoading(true);
    axios({
      method: 'POST',
      url: '/api/groups/reject-group-invite',
      data: {
        profileId: profile._id,
        invite,
      },
    })
      .then((res) => {
        const allInvites = [];
        groupInvites.forEach((eachInvite) => {
          if (eachInvite._id !== invite._id) {
            allInvites.push(eachInvite);
          }
        });
        setGroupInvites(allInvites);
        setGroupPageLoading(false);
      })
      .catch((err) => {
        setErrMessage(err);
      });
  };
  if (!connectionsFetched) {
    return (
      <Flex
        className="app"
        mt="128px"
        minHeight="90vh"
        width="100%"
      >
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="#FF1A75"
          size="xl"
        />
      </Flex>
    );
  }
  const renderMyGroupInvites = () => groupInvites.map((invite) => (
    <Flex onClick={() => { history.push(`/group/${invite.group._id}`); }} cursor="pointer" className={classes.friendCard} flexDirection="vertical" alignItems="center">
      <Box width={{ base: '0px', xl: '75px' }}>
        {invite.group.banner
          ? (
            <Box
              as="img"
              crossOrigin="anonymous"
              alt="thumbnail"
              src={invite.group.banner.thumbNail}
              objectFit="cover"
              cursor="pointer"
              borderRadius=".70rem"
              width="75px"
              height="75px"
              onClick={() => { history.push(`/group/${invite.group._id}`); }}
            />
          ) : (
            <Box
              as="img"
              crossOrigin="anonymous"
              width="75px"
              height="75px"
              cursor="pointer"
              borderRadius=".70rem"
              backgroundColor="#000"
              margin-left="10px"
              onClick={() => { history.push(`/group/${invite.group._id}`); }}
            />
          )}
      </Box>
      <Spacer />
      <Stack>
        <a href={`/group/${invite.group._id}`}>
          <Text fontSize="lg">
            Group Invitation: <Text fontWeight="bold"> {invite.group.name}</Text>
          </Text>
        </a>
      </Stack>
      <Spacer />
      <Stack className={classes.friendCardButtonsContainer} direction={{ base: 'column', xl: 'row' }}>
        <Button
          onClick={() => acceptRequest(invite)}
          display="block"
          width="110px"
          color="white"
          borderRadius="24px"
          bg="#FF1A75"
          _hover={{
            bg: '#FFE6EE',
            color: '#FF1A75',
          }}
        >
          Accept
        </Button>
        <Button
          onClick={() => rejectRequest(invite)}
          isLoading={false}
          variant="outline"
          display="block"
          width="110px"
          borderRadius="24px"
          color="#FF1A75"
          borderColor="#FF1A75"
          _hover={{
            bg: '#FFE6EE',
            color: '#FF1A75',
          }}
        >
          Decline
        </Button>
      </Stack>
    </Flex>
  ));

  return (
    <Flex justifyContent="center" marginTop="2rem" minHeight="90vh" width="100%">
      <Box width="100%">
        <Flex className="app">
          <Heading marginY="2rem" fontWeight="500" fontSize={{ base: '1.8rem' }} color="#0B67C2">
            Notifications
          </Heading>
          <Divider width="100%" />
          {connections.pending?.length > 0 || groupInvites.length > 0
            ? (
              <SimpleGrid columns={1} spacing={5} mb="48px">
                {connections.friends && renderPendingRequestsCards()}
                {renderMyGroupInvites()}
              </SimpleGrid>
            ) : (
              <Box>No New Notification</Box>
            )}
        </Flex>
      </Box>
    </Flex>
  );
}
Notification.propTypes = propTypes;
export default withRouter(Notification);
