/* eslint-disable react/forbid-prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import {
  Input,
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Checkbox,
} from '@chakra-ui/react';
import {
  AddIcon,
} from '@chakra-ui/icons';

const useStyles = createUseStyles({
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
  gridView: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',

  },
  friendCard: {
    position: 'relative',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 4px 16px 0px',
  },
  searchResult: {
    padding: '16px',
    marginTop: '16px',
    width: '460px',
    borderRadius: '8px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  friendCardButtonsContainer: {
    position: 'absolute',
    bottom: '24px',
  },
});
const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  profile: PropTypes.object.isRequired,
  updateInviteList: PropTypes.func.isRequired,
  inviteList: PropTypes.array.isRequired,
};
const SendInvites = ({
  profile,
  updateInviteList,
  inviteList,
}) => {
  const classes = useStyles();
  const [friendEmail, setFriendEmail] = useState('');
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [retrievedInvites, setRetrievedInvites] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (inviteList.length > 0) {
      setRetrievedInvites(true);
    }
  }, [profile, inviteList]);

  const handleSubmit = () => {
    axios({
      method: 'POST',
      url: '/api/invites/adminInvite',
      data: {
        email: friendEmail,
        userId: localStorage.getItem('userId'),
        profile,
      },
    }).then((data) => {
      // console.log(data.data)
      setRetrievedInvites(false);
      updateInviteList(data.data.newList);
      setRetrievedInvites(true);
    })
      .catch((err) => {
        // console.log(err);
        let errMsg;

        switch (err?.response?.status) {
          case 400:
            errMsg = 'Invite has already been sent.';
            break;
          default:
            errMsg = 'Something has went wrong';
            break;
        }
        setFormErrorMessage(errMsg);
        setTimeout(() => {
          setFormErrorMessage('');
        }, 3000);
      });
  };
  const validateEmail = () => {
    const schema = yup.object().shape({
      email: yup.string().email().required(),
    });

    // check validity
    schema
      .isValid({
        email: friendEmail,
      })
      .then((valid) => {
        if (valid) {
          handleSubmit();
        } else {
          setFormErrorMessage('Email is not Valid');
          setTimeout(() => {
            setFormErrorMessage('');
          }, 3000);
        }
      });
  };
  const renderInviteList = () => inviteList.map((invite) => (
    <Flex key={invite._id} className={classes.friendCard} minWidth={{ base: '95%', sm: '300px' }} padding="12px" justifyContent="space-between">
      <Text as="span" fontWeight="500" fontSize={{ base: '0.8em', sm: '1em', md: '1.2em' }} marginRight="1rem">{invite.email} </Text>
      {invite.accountCreated
        ? <Checkbox defaultIsChecked isDisabled colorScheme="green" />
        : <Checkbox isDisabled colorScheme="green" />}
    </Flex>
  ));
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Heading
        marginY="2rem"
        fontWeight="500"
        fontSize={{ base: '1.1em', sm: '1.5em', md: '1.7rem' }}
        color="#0B67C2"
      >
        Invite Your Family to Join Us!
      </Heading>
      <FormControl mb="4px" width={{ base: '100%', sm: '300px' }} margin="auto">
        <FormLabel
          htmlFor="query"
          fontWeight="600"
          fontSize={{ base: '1em', sm: '1.1em', md: '1.2rem' }}
        >
          Email or username:
        </FormLabel>
        <Flex justifyContent="center">
          <Input
            id="query"
            placeholder="Enter an email address "
            type="text"
            width={{ base: '100%', sm: '250px' }}
            onChange={(event) => { setFriendEmail(event.target.value); }}
          />
          <IconButton
            type="submit"
            icon={<AddIcon />}
            onClick={validateEmail}
            ml="5px"
            color="white"
            borderRadius="24px"
            bg="#FF1A75"
            _hover={{
              bg: '#FFE6EE',
              color: '#FF1A75',
            }}
          />
        </Flex>
      </FormControl>
      {formErrorMessage && (
      <label>
        <p
          style={{
            color: '#ff0000bf',
            fontSize: '0.7rem',
            border: '1px solid',
            padding: '1rem',
            borderRadius: '10px',
          }}
        >
          {formErrorMessage}
        </p>
      </label>
      )}
      <Divider width="100%" marginY="24px" />
      <Box
        margin="auto"
      >
        <Heading
          marginY="2rem"
          fontWeight="500"
          fontSize={{ base: '1.2rem', md: '1.7rem' }}
          color="#0B67C2"
          textAlign="center"
        >
          My Sent Invites
        </Heading>
        {!retrievedInvites
          ? <Box />
          : (
            <SimpleGrid
              columns={{ base: 1, lg: 2, xl: 3 }}
              spacing="16px"
              mb="48px"
            >
              {renderInviteList()}
            </SimpleGrid>
          )}
      </Box>
      <Divider width="100%" marginY="28px" />
    </Flex>
  );
};
SendInvites.propTypes = propTypes;
export default SendInvites;
