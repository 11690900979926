/* eslint-disable linebreak-style */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Box, Alert, AlertIcon, AlertTitle, AlertDescription,
} from '@chakra-ui/react';

const AlertBanner = (props) => {
  const { message, title } = props;
  return (
    <Box>
      <Alert status="info" borderRadius="5px" display="flex" justifyContent="center">
        <AlertIcon />
        <AlertTitle fontSize={{ base: '0.6em', sm: '0.9em' }}>{title}</AlertTitle>
        <AlertDescription fontSize={{ base: '0.9em', md: '1.2em' }}>{message}</AlertDescription>
      </Alert>
    </Box>
  );
};

export default AlertBanner;
