/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Button,
  Heading,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from '@chakra-ui/react';
import {
  Badge,
} from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Navicon } from '@styled-icons/evil/Navicon';
import { Home } from '@styled-icons/boxicons-solid/Home';
import { Videos } from '@styled-icons/boxicons-solid/Videos';
import { FamilyRestroom } from '@styled-icons/material/FamilyRestroom';
import { Images } from '@styled-icons/bootstrap/Images';
import { Group2 } from '@styled-icons/remix-line/Group2';
import { ClockFill } from '@styled-icons/bootstrap/ClockFill';
import { Upgrade } from '@styled-icons/material-sharp/Upgrade';
import { ArrowAltCircleUp } from '@styled-icons/fa-solid/ArrowAltCircleUp';
import { Plus } from '@styled-icons/boxicons-regular/Plus';
import { Upload } from '@styled-icons/entypo/Upload';
import { LogOut } from '@styled-icons/boxicons-solid/LogOut';
import { Groups } from '@styled-icons/material-sharp/Groups';
import { Notifications } from '@styled-icons/ionicons-sharp/Notifications';
import { USER_SERVER } from '../Config';
import ChatBox from '../views/ChatBox/ChatBox';
import ChatBox2 from '../views/ChatBox/ChatBox2';
import SideNav from './SideNav';
import { initiateSocket, createProfileRoom } from './Socket';

// const Upload = require('../../assets/images/upload.png');
const UpgradeButton = styled(ArrowAltCircleUp)`
width: 24px;
outline: none;
border: none;
color: #888;
cursor: pointer;
&:focus {
  outline:0 !important;
  border: 0 !important;
  color: #1890ff !important;
}
@media (max-width: 350px) {
  width: 24px;
}
`;

const UploadButton = styled(Upload)`
  width: 24px;
  outline: none;
  border: none;
  color: #888;
  cursor: pointer;
  &:focus {
    outline:0 !important;
    border: 0 !important;
    color: #1890ff !important;
  }
  @media (max-width: 350px) {
    width: 24px;
  }
`;

const NotificationButton = styled(Notifications)`
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  color: #888;
  cursor: pointer;
  &:focus {
    outline:0 !important;
    border: 0 !important;
    color: #1890ff !important;
  }
  @media (max-width: 350px) {
    width: 24px;
  }
`;

const LogOutButton = styled(LogOut)`
  width: 24px;
  outline: none;
  border: none;
  color: #888;
  cursor: pointer;
  &:focus {
    outline:0 !important;
    border: 0 !important;
    color: #1890ff !important;
  }
  @media (max-width: 350px) {
    width: 24px;
  }
`;
const HomeButton = styled(Home)`
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  color: #888;
  @media (max-width: 350px) {
    width: 24px;
  }
`;
const ClockButton = styled(ClockFill)`
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  color: #888;
  &:focus {
    outline:0 !important;
    border: 0 !important;
    color: #FF0000;
  }
  @media (max-width: 350px) {
    width: 24px;
  }
`;

const FamilyButton = styled(FamilyRestroom)`
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  color: #888;
  &:focus {outline:0 !important; border: 0 !important; color: #FF0000;}
  @media (max-width: 350px) {
    width: 24px;
  }
`;

const GroupsButton = styled(Groups)`
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  color: #888;
  &:focus {outline:0 !important; border: 0 !important; color: #FF0000;}
  @media (max-width: 350px) {
    width: 24px;
  }
`;

const NavButton = styled(Navicon)`
  color: #000;
  width: 42px;
`;

const useStyles = createUseStyles({
  hambugerMenu: {
    width: '35px',
    height: '5px',
    backgroundColor: 'black',
    margin: '6px 0',
  },
  navMenuItem: {
    fontSize: '1.2rem',
    fontWeight: '500',
    color: '#2C6DAD',
    padding: '10px 16px',
  },
});

const MenuItems = (props) => {
  const {
    children, isLast, to = '/', ...rest
  } = props;
  return (
    <Flex height="50px" justifyContent="center" flexDirection="column">
      <Heading
        as="h1"
        margin="0"
        fontSize="1rem"
        fontWeight="500"
        padding="10px 16px"
        {...rest}
      >
        <Link to={to}>{children}</Link>
      </Heading>
    </Flex>
  );
};
const HeadingItem = (props) => {
  const {
    children, isLast, to = '/', ...rest
  } = props;
  return (
    <Flex height="50px" justifyContent="center" flexDirection="column">
      <Heading
        fontSize={{ base: '1.5rem' }}
        as="h1"
        margin="0"
        color="#1890ff"
        {...rest}
      >
        <Link to={to}>{children}</Link>
      </Heading>
    </Flex>
  );
};

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function Header({ history, props }) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [profile, setProfile] = useState();
  const [err, setErr] = useState();
  const [connections, setConnections] = useState();
  const [connectionsFetched, setConnectionsFetched] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [connectionCount, setConnectionCount] = useState();
  const [notificationCount, setNotificationCount] = useState();
  const [processCompleted, setProcessCompleted] = useState(false);
  const [vertificationMessage, setVertificationMessage] = useState(false);
  const toggleMenu = () => setShow(!show);
  const [socket, setSocket] = useState();
  const user = useSelector((state) => state.user);
  const [groupCount, setGroupCount] = useState([]);
  const [errMessage, setErrMessage] = useState();

  const getNotifications = (profileId) => {
    axios({
      method: 'POST',
      url: '/api/chatbox/check-notifications',
      data: {
        profileId,
      },
    })
      .then((chatData) => {
        // console.log(chatData.data);
        // console.log(`profile data: + ${data.data._id}`);
        setNotifications(chatData.data.notifications);
        setProcessCompleted(true);
        createProfileRoom(profileId);
      })
      .catch(() => {
        setErr('Cannot fetch user profile');
      });
  };
  const goToLink = (link, type) => {
    switch (type) {
      case 'home':
        document.getElementById('home-side').style.color = '#156BBF';
        document.getElementById('families-side').style.color = '#888888';
        document.getElementById('myprofile-side').style.color = '#888888';
        document.getElementById('groups-side').style.color = '#888888';
        break;
      case 'myprofile':
        document.getElementById('myprofile-side').style.color = '#156BBF';
        document.getElementById('families-side').style.color = '#888888';
        document.getElementById('home-side').style.color = '#888888';
        // if (selectedUsername) document.getElementById(`sidefamily_${selectedUsername}`).style.color = '#333333';
        break;
      case 'none':
        break;
      default:
        document.getElementById('home-side').style.color = '#888888';
        document.getElementById('families-side').style.color = '#888888';
        document.getElementById('myprofile-side').style.color = '#888888';
    }
    history.push(link);
  };
  const goToLinkMobile = (type, link, username) => {
    // console.log(event.target);
    switch (type) {
      case 'home':
        if (document.getElementById('home-side-mobile')) {
          document.getElementById('home-side-mobile').style.color = '#156BBF';
          document.getElementById('families-side-mobile').style.color = '#888888';
          document.getElementById('myprofile-side-mobile').style.color = '#888888';
          document.getElementById('notification-side-mobile').style.color = '#888888';
          document.getElementById('groups-side-mobile').style.color = '#888888';
        // if (selectedUsername) document.getElementById(`sidefamily_${selectedUsername}`).style.color = '#333333';
        }
        break;
      case 'families':
        document.getElementById('families-side-mobile').style.color = '#156BBF';
        document.getElementById('home-side-mobile').style.color = '#888888';
        document.getElementById('myprofile-side-mobile').style.color = '#888888';
        document.getElementById('notification-side-mobile').style.color = '#888888';
        document.getElementById('groups-side-mobile').style.color = '#888888';
        // if (selectedUsername) document.getElementById(`sidefamily_${selectedUsername}`).style.color = '#333333';
        break;
      case 'myprofile':
        document.getElementById('myprofile-side-mobile').style.color = '#156BBF';
        document.getElementById('families-side-mobile').style.color = '#888888';
        document.getElementById('home-side-mobile').style.color = '#888888';
        document.getElementById('notification-side-mobile').style.color = '#888888';
        document.getElementById('groups-side-mobile').style.color = '#888888';
        // if (selectedUsername) document.getElementById(`sidefamily_${selectedUsername}`).style.color = '#333333';
        break;
      case 'familyprofile':
        document.getElementById('myprofile-side-mobile').style.color = '#888888';
        document.getElementById('families-side-mobile').style.color = '#156BBF';
        document.getElementById('home-side-mobile').style.color = '#888888';
        // if (selectedUsername) document.getElementById(`sidefamily_${selectedUsername}`).style.color = '#333333';
        // setSelectedUsername(username);
        document.getElementById('notification-side-mobile').style.color = '#888888';
        document.getElementById('groups-side-mobile').style.color = '#888888';
        document.getElementById(`sidefamily_${username}`).style.color = '#156BBF';
        break;
      case 'notification':
        document.getElementById('families-side-mobile').style.color = '#888888';
        document.getElementById('home-side-mobile').style.color = '#888888';
        document.getElementById('myprofile-side-mobile').style.color = '#888888';
        document.getElementById('notification-side-mobile').style.color = '#156BBF';
        document.getElementById('groups-side-mobile').style.color = '#888888';
        break;
      case 'groups':
        document.getElementById('groups-side-mobile').style.color = '#156BBF';
        document.getElementById('home-side-mobile').style.color = '#888888';
        document.getElementById('myprofile-side-mobile').style.color = '#888888';
        document.getElementById('notification-side-mobile').style.color = '#888888';
        document.getElementById('families-side-mobile').style.color = '#888888';
        break;
      default:
        document.getElementById('home-side-mobile').style.color = '#156BBF';
    }
    history.push(link);
  };

  useEffect(() => {
    // console.log("running")
    if (user.userData && user.userData.isAuth) {
      // console.log(user.userData);
      initiateSocket();
      axios({
        method: 'POST',
        url: '/api/users/getProfile',
        data: {
          username: user.userData.username,
        },
      })
        .then((data) => {
          setProfile(data.data);
          // console.log(data.data._id)
          axios.get(`/api/connection/all/${data.data._id}`).then((response) => {
            // console.log(response.data.connections);
            setConnections(response.data.connections);
            setConnectionCount(response.data.connections.pending.length);
            setConnectionsFetched(true);
            getNotifications(data.data._id);
            axios({
              method: 'POST',
              url: '/api/groups/groups-by-profile',
              data: {
                profileId: data.data._id,
              },
            })
              .then((res) => {
                setGroupCount(res.data.groupInvites.length);
                setNotificationCount(res.data.groupInvites.length + response.data.connections.pending.length);
              })
              .catch((groupErr) => {
                setErrMessage(groupErr);
              });
          });
        })
        .catch(() => {
          setErr('Cannot fetch user profile');
        });
    }
  }, [user.userData, user]);

  /*
  useEffect(() => {
    // console.log('running');
    setTimeout(() => {
      if (profile) {
        axios.get(`/api/connection/all/${profile._id}`).then((response) => {
          // console.log(response.data.connections);
          setConnections(response.data.connections);
          setConnectionCount(response.data.connections.pending.length);
          setConnectionsFetched(true);
        })
          .catch((connectErr) => {
            setErr(connectErr);
          });
      }
    }, 20000);
  });
*/
  const removeNotifications = (notificationList) => {
    setNotifications(notificationList);
    setProcessCompleted(true);
  };
  const updateNotifications = (newNotification) => {
    // console.log(`got it ${notifications.length}`);
    // const newNotifList = notifications;
    // newNotifList.push(newNotification);
    // setNotifications(newNotifList);
    setNotifications(notifications.concat(newNotification));
    setProcessCompleted(true);
  };
  // socket must be disconnected and handled properly
  /*
  useEffect(() => {
    if (profile) {
      socket.once('notificationSent', () => {
        axios({
          method: 'POST',
          url: '/api/chatbox/check-notifications',
          data: {
            profileId: profile._id,
          },
        })
          .then((chatData) => {
            console.log(chatData.data);
            // console.log(`profile data: + ${data.data._id}`);
            setNotifications(chatData.data.notifications);
            setProcessCompleted(true);
          })
          .catch(() => {
            setErr('Cannot fetch user profile');
          });
      });
    }
  }); */
  const logoutHandler = () => {
    axios.get(`${USER_SERVER}/logout`).then((response) => {
      if (response.status === 200) {
        history.push('/login');
      } else {
        alert('Log Out Failed');
      }
    });
  };
  if (user.userData && !user.userData.isAuth) {
    return (
      <Flex
        as="nav"
        align="center"
        justifyContent="space-between"
        p={6}
        wrap="wrap"
        w="100%"
        height="95px"
        {...props}
      >
        {/* <Logo
                        w="100px"
                        color={["white", "white", "primary.500", "primary.500"]}
                    /> */}
        <HeadingItem
          onClick={() => { goToLink('/', 'none'); }}
        >
          Kloak
        </HeadingItem>
        <Box
          display={{ base: show ? 'block' : 'none', md: 'block' }}
          flexBasis={{ base: '100%', md: 'auto' }}
        >
          <Flex
            align={['center', 'center', 'center', 'center']}
            justify={['center', 'space-between', 'flex-end', 'flex-end']}
            direction={['column', 'row', 'row', 'row']}
            pt={[4, 4, 0, 0]}
          >
            {/* <MenuItems to="/how">How It Works </MenuItems>
                        <MenuItems to="/faetures">Features </MenuItems>
                        <MenuItems to="/pricing">Pricing </MenuItems> */}
            <MenuItems to="/register">
              <Button
                className={classes.navMenuItem}
                fontSize="1rem"
                rounded="md"
                color={['white']}
                bg={['#3480E3']}
                _hover={{
                  bg: '#FFE6EE',
                  color: '#FF1A75',
                }}
              >
                Create Account
              </Button>
            </MenuItems>
            <MenuItems className={classes.navMenuItem} to="/login" color="#1890ff" isLast>Sign In </MenuItems>
          </Flex>
        </Box>
        <Menu>
          <MenuButton
            display={{ base: show ? 'none' : 'block', md: 'none' }}
            aria-label="Options"
          >
            <NavButton />
          </MenuButton>
          <MenuList>
            <MenuItems onClick={() => { history.push('/register'); }} className={classes.navMenuItem} icon="add">
              <Text>Create Account</Text>
            </MenuItems>
            <MenuItems onClick={() => { history.push('/login'); }} className={classes.navMenuItem} icon="add">
              <Text>Sign In</Text>
            </MenuItems>
          </MenuList>
        </Menu>
      </Flex>
    );
  }
  if (user.userData && user.userData.isAuth) {
    return (
      <Box>
        <Box
          display={{ base: show ? 'block' : 'none', lg: 'block' }}
        >
          <Flex
            as="nav"
            align="center"
            justifyContent="space-between"
            p={6}
            wrap="wrap"
            w="100%"
            height="95px"
            bg="#F8F8F8"
            color={['white', 'white', 'primary.700', 'primary.700']}
            {...props}
            {...props}
          >
            <Flex align="center">
              {/* <Logo
                        w="100px"
                        color={["white", "white", "primary.500", "primary.500"]}
                    /> */}
              <HeadingItem
                onClick={() => { goToLink('/home', 'home'); }}
              >
                Kloak
              </HeadingItem>
            </Flex>
            <Menu>
              <MenuButton
                aria-label="Options"
              >
                <Box
                  as="img"
                  crossOrigin="anonymous"
                  src={profile?.image}
                  width="42px"
                  height="42px"
                  objectFit="cover"
                  borderRadius="50%"
                  verticalAlign="middle"
                />
              </MenuButton>
              <MenuList
                width="100px"
                placement="bottom-end"
              >
                <MenuItem
                  color="#e75480"
                  fontSize="1.2em"
                  fontWeight="semibold"
                  padding="10px 5px"
                  onClick={() => { goToLink('/upgrade-store'); }}
                >
                  Upgrade
                </MenuItem>
                <MenuItem
                  color="#2C6DAD"
                  fontSize="1.2em"
                  fontWeight="semibold"
                  padding="10px 5px"
                  onClick={() => { goToLink('/upload'); }}
                >
                  Upload
                </MenuItem>
                <MenuItem
                  color="#2C6DAD"
                  fontSize="1.2em"
                  fontWeight="semibold"
                  padding="10px 5px"
                  onClick={() => { goToLink('/my-profile', 'myprofile'); }}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  color="#2C6DAD"
                  fontSize="1.2em"
                  fontWeight="semibold"
                  padding="10px 5px"
                  onClick={logoutHandler}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>
        <Box
          display={{ base: show ? 'none' : 'block', lg: 'none' }}
        >
          <Flex
            as="nav"
            align="center"
            justifyContent="space-between"
            p={6}
            wrap="wrap"
            w="100%"
            height="95px"
            bg="#F8F8F8"
            color={['white', 'white', 'primary.700', 'primary.700']}
            {...props}
            {...props}
          >
            <Menu>
              <MenuButton
                aria-label="Options"
              >
                <Box
                  as="img"
                  crossOrigin="anonymous"
                  src={profile?.image}
                  width="42px"
                  height="42px"
                  objectFit="cover"
                  borderRadius="50%"
                  verticalAlign="middle"
                />
              </MenuButton>
              <MenuList placement="bottom-end" zIndex="9999">
                <MenuItem
                  onClick={() => { goToLinkMobile('home', '/home'); }}
                  className={classes.navMenuItem}
                  color="#1890ff"
                >
                  <Flex
                    width="100%"
                    cursor="pointer"
                    padding="0.5em"
                    className={classes.tabHover}
                    onClick={() => { goToLinkMobile('home', '/home'); }}
                  >
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                        height="100%"
                      >
                        <HomeButton
                          id="home-side-mobile"
                          cursor="pointer"
                          onClick={() => { goToLinkMobile('home', '/home'); }}
                        />
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        marginLeft="1rem"
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                        height="100%"
                      >
                        <Text
                          fontWeight="500"
                          cursor="pointer"
                          color="#1890ff"
                          onClick={() => { goToLinkMobile('home', '/home'); }}
                        >
                          Home
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </MenuItem>
                <MenuItem
                  onClick={() => { goToLinkMobile('home', '/upgrade-store'); }}
                  className={classes.navMenuItem}
                  color="#1890ff"
                >
                  <Flex
                    width="100%"
                    cursor="pointer"
                    padding="0.5em"
                    className={classes.tabHover}
                    onClick={() => { goToLinkMobile('home', '/upgrade-store'); }}
                  >
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                      >
                        <UpgradeButton
                          id="home-side-mobile"
                          cursor="pointer"
                        />
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        marginLeft="1rem"
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                        height="100%"
                      >
                        <Text
                          fontWeight="500"
                          cursor="pointer"
                          color="#e75480"
                          onClick={() => { goToLinkMobile('home', '/upgrade-store'); }}
                        >
                          Upgrade
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </MenuItem>
                <MenuItem
                  onClick={() => { goToLinkMobile('home', '/upload'); }}
                  className={classes.navMenuItem}
                  color="#1890ff"
                >
                  <Flex
                    width="100%"
                    cursor="pointer"
                    padding="0.5em"
                    className={classes.tabHover}
                  >
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                      >
                        <UploadButton
                          id="home-side-mobile"
                          cursor="pointer"
                        />
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        marginLeft="1rem"
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                        onClick={() => { goToLinkMobile('home', '/upload'); }}
                      >
                        <Text
                          fontWeight="500"
                          cursor="pointer"
                          color="#1890ff"
                        >
                          Upload
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </MenuItem>
                <MenuItem
                  onClick={() => { goToLinkMobile('groups', '/groups'); }}
                  className={classes.navMenuItem}
                  color="#1890ff"
                >
                  <Flex
                    className={classes.tabHover}
                    padding="0.5em"
                    cursor="pointer"
                  >
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                      >
                        <Badge
                          badgeContent={groupCount !== undefined ? groupCount : 0}
                          color="error"
                        >
                          <GroupsButton
                            cursor="pointer"
                            id="groups-side-mobile"
                          />
                        </Badge>

                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="center"
                        marginLeft="1rem"
                      >
                        <Text
                          fontWeight="500"
                          cursor="pointer"
                        >
                          Groups
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </MenuItem>
                <MenuItem
                  onClick={() => { goToLinkMobile('families', '/families'); }}
                  className={classes.navMenuItem}
                  color="#1890ff"
                >
                  <Flex
                    className={classes.tabHover}
                    padding="0.5em"
                    cursor="pointer"
                    onClick={() => { goToLinkMobile('families', '/families'); }}
                  >
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                        height="100%"
                      >
                        <Badge
                          badgeContent={connectionCount !== undefined ? connectionCount : 0}
                          color="error"
                        >
                          <FamilyButton
                            cursor="pointer"
                            id="families-side-mobile"
                            onClick={() => { goToLinkMobile('families', '/families'); }}
                          />
                        </Badge>

                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="center"
                        height="100%"
                        marginLeft="1rem"
                      >
                        <Text
                          fontWeight="500"
                          cursor="pointer"
                          onClick={() => { goToLinkMobile('families', '/families'); }}
                        >
                          Family
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </MenuItem>
                <MenuItem
                  onClick={() => { goToLinkMobile('myprofile', '/my-profile'); }}
                  className={classes.navMenuItem}
                  color="#1890ff"
                >
                  <Flex
                    width="100%"
                    cursor="pointer"
                    className={classes.tabHover}
                    padding="0.5em"
                  >
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                        height="100%"
                      >
                        <ClockButton
                          cursor="pointer"
                          id="myprofile-side-mobile"
                          onClick={() => { goToLinkMobile('myprofile', '/my-profile'); }}
                        />
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="center"
                        height="100%"
                        marginLeft="1rem"
                      >
                        <Text
                          fontWeight="500"
                          cursor="pointer"
                        >
                          Profile
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </MenuItem>
                <MenuItem
                  onClick={() => { goToLinkMobile('notification', '/notification'); }}
                  className={classes.navMenuItem}
                  color="#1890ff"
                >
                  <Flex
                    width="100%"
                    cursor="pointer"
                    className={classes.tabHover}
                    padding="0.5em"
                  >
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                        height="100%"
                      >
                        <Badge badgeContent={notificationCount !== undefined ? notificationCount : 0} color="error">
                          <NotificationButton
                            cursor="pointer"
                            id="notification-side-mobile"
                          />
                        </Badge>
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="center"
                        height="100%"
                        marginLeft="1rem"
                      >
                        <Text
                          fontWeight="500"
                          cursor="pointer"
                          onClick={() => { goToLinkMobile('notification', '/notification'); }}
                        >
                          Notification
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </MenuItem>
                <MenuItem onClick={logoutHandler} className={classes.navMenuItem} color="#1890ff">
                  <Flex
                    width="100%"
                    cursor="pointer"
                    className={classes.tabHover}
                    padding="0.5em"
                    onClick={() => { goToLinkMobile('myprofile', '/my-profile'); }}
                  >
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="left"
                        height="100%"
                      >
                        <LogOutButton
                          cursor="pointer"
                          id="myprofile-side-mobile"
                        />
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        justifyContent="center"
                        flexDir="column"
                        alignItems="center"
                        height="100%"
                        marginLeft="1rem"
                      >
                        <Text
                          fontWeight="500"
                          cursor="pointer"
                        >
                          Logout
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
            <Box align="right">
              {/* <Logo
                        w="100px"
                        color={["white", "white", "primary.500", "primary.500"]}
                    /> */}
              <HeadingItem to="/home">Kloak</HeadingItem>
            </Box>
          </Flex>
        </Box>
        { processCompleted === true
          ? (
            <ChatBox2
              profile={profile}
            />
          ) : <Box /> }
      </Box>

    );
  }
  return (
    <div />
  );
}

Header.propTypes = propTypes;

export default withRouter(Header);
