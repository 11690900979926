/* eslint-disable no-alert */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Select,
  Spinner,
  Text,
  Textarea,
  Input,
  Box,
  AspectRatio,
} from '@chakra-ui/react';
import { combineReducers } from 'redux';
import UploadImg from '../../../../assets/images/upload.png';
import s3BucketUrl from '../../../environmentVar';

const UploadStatus = Object.freeze({
  AWAITING_FILE: 'AWAITING_FILE',
  FILE_ACCEPTED: 'FILE_ACCEPTED',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  ERROR: 'ERROR',
});

const Privacy = [
  { value: '', label: 'Select' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'FAMILY_ONLY', label: 'Family' },
  // { value: 'FRIENDS_AND_ANYONE_WITH_LINK', label: 'Friends and anyone with link' },
];

const Category = [
  { value: 'FILM', label: 'Film & Animation' },
  { value: 'AUTOS', label: 'Autos & Vehicles' },
  { value: 'MUSIC', label: 'Music' },
  { value: 'PETS', label: 'Pets & Animals' },
  { value: 'SPORTS', label: 'Sports' },
  { value: 'FAMILY', label: 'Family' },
  { value: 'OTHER', label: 'Other' },
];

const baseDropzoneStyle = {
  height: '65vh',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderRadius: '8px',
  borderColor: '#EEEEEE',
  padding: '32px',
  cursor: 'pointer',
  marginBottom: '128px',
  width: '95%',
};

const activeDropzoneStyle = {
  borderColor: '#FF1A75',
};

const useStyles = createUseStyles({
  uploadIcon: {
    margin: '32px auto',
  },
  formErrorMessage: {
    color: 'red',
    fontSize: '14px',
    minHeight: '21px',
  },
});

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  maxSpace: PropTypes.number.isRequired,
  currentSpace: PropTypes.number.isRequired,
  profile: PropTypes.object.isRequired,
};

function UploadVideos({
  history,
  maxSpace,
  currentSpace,
  profile,
}) {
  const classes = useStyles();
  const [status, setStatus] = useState();
  const [s3FilePath, setS3FilePath] = useState('');
  const [duration, setDuration] = useState('');
  const [thumbNailSrc, setThumbNailSrc] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [videoImage, setVideoImage] = useState();

  useEffect(() => {
    // console.log('running')
    // testing
    if (profile._id) {
      setStatus(UploadStatus.AWAITING_FILE);
    }
  }, [profile]);

  const getVideoCover = (file, seekTo = 0.9) =>
  // console.log('getting video cover for file: ', file);
    new Promise((resolve, reject) => {
      // load the file to a video player
      let duration;
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
        reject('error when loading video file', ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject('video is too short.');
          return;
        }
        duration = videoPlayer.duration;
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // console.log('video is now paused at %ss.', seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement('canvas');
          // console.log(videoPlayer.videoWidth);
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext('2d');
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve({ blob, duration });
            },
            'image/jpeg',
            0.9, /* quality */
          );
        });
      });
    });

  const onDropAccepted = async (files) => {
    setFilesToUpload(files[0]);
    // console.log(files[0])
    const oneGigabyte = 1073741274;
    let totalSize = files[0].size;
    if (totalSize > oneGigabyte) {
      alert('Files size cannot be greater than 1GB during the beta testing.');
      setStatus(UploadStatus.AWAITING_FILE);
      return;
    }
    const thumbBlob = await getVideoCover(files[0], 0.9);
    // (thumbBlob);
    totalSize += thumbBlob.size;
    if (totalSize + currentSpace > maxSpace) {
      alert('Ran out of File space! Please either delete existing files or upgrade your account.');
      setStatus(UploadStatus.AWAITING_FILE);
      return;
    }

    const thumbNail = new File([thumbBlob.blob], `thumb_${nanoid()}.jpeg`);
    // console.log(thumbNail);
    //   const duration = await readVideo(files[0]);
    // console.log(thumbNail);
    // console.log('duration: ' + thumbBlob.duration);

    setThumbNailSrc(
      Object.assign(thumbNail, { preview: URL.createObjectURL(thumbNail) }),
    );

    // console.log(URL.createObjectURL(thumbNail));
    setDuration(thumbBlob.duration);
    setStatus(UploadStatus.FILE_ACCEPTED);
  };

  const onDropRejected = () => alert('File type is unsupported. Please upload a MP4 or MOV video.');

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: 'video/mp4, .MOV', // TODO: Accept other video types
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  const dropzoneStyle = useMemo(() => ({
    ...baseDropzoneStyle,
    ...(isDragActive ? activeDropzoneStyle : {}),
  }), [isDragActive]);

  const renderDropzone = () => (
    <Flex {...getRootProps({ style: dropzoneStyle })}>
      <input {...getInputProps()} />
      <Flex flexDirection="column" alignItems="center" width="100%" justifyContent="center" height="100%">
        <Image className={classes.uploadIcon} size="32px" objectFit="contain" src={UploadImg} />
        <Text fontSize="2xl" fontWeight="300" textAlign="center">Drag and drop your video here to upload</Text>
        <Text>or click here</Text>
      </Flex>
    </Flex>
  );

  const renderUploadProgressIndicator = () => (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Spinner
        thickness="4px"
        emptyColor="gray.200"
        color="#FF1A75"
        size="xl"
        mb="8px"
      />
      <Text>{status === UploadStatus.UPLOADING ? 'Uploading...' : 'Processing...'}</Text>
    </Flex>
  );
  const postData = (formData) => axios.post(s3BucketUrl, formData, { timeout: 0 });
  // see https://stackoverflow.com/questions/52669596/promise-all-with-axios
  // https://github.com/axios/axios/blob/d35b5b5902f72305216f245cc58bc2231470fb19/README.md#example
  const postAllData = (fileList, videoId) => {
    // console.log(videoId);
    const videoForm = new FormData();
    const imageForm = new FormData();
    // formData2.append('fields', response.data.data.fields)

    Object.entries(fileList[0].promise.fields).map(([k, v]) => {
      videoForm.append(k, v);
    });
    Object.entries(fileList[1].promise.fields).map(([k, v]) => {
      imageForm.append(k, v);
    });

    imageForm.append('Content-Type', 'image/jpeg');
    imageForm.append('file', thumbNailSrc);
    videoForm.append('Content-Type', filesToUpload.type);
    videoForm.append('file', filesToUpload);

    Promise.all([postData(videoForm), postData(imageForm)])
      .then((results) => {
        if (results[0].status === 204 && results[0].status === 204) {
          alert('Video has been sucessfully uploaded!');
          history.push(`/video/${videoId}`);
        }
      });
  };

  const renderDetailsForm = (myFile) => (
    <Formik
      initialValues={{
        title: '',
        description: '',
        category: 'FILM',
        privacy: '',
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .required('Title is required'),
        privacy: Yup.string()
          .required('Must select a privacy option'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let label = '';
        Privacy.forEach((item) => {
          if (item.value === values.privacy) label = item.label;
        });

        setStatus(UploadStatus.PROCESSING);

        setTimeout(() => {
          const data = {
            writer: profile._id,
            title: values.title,
            description: values.description,
            privacy: values.privacy,
            privacyLabel: label,
            category: values.category,
            filePath: `${s3BucketUrl}${filesToUpload.name}`,
            key: filesToUpload.name,
            duration,
            thumbNail: `${s3BucketUrl}${thumbNailSrc.name}`,
            thumbSize: thumbNailSrc.size,
            fileSize: filesToUpload.size,
            fileType: filesToUpload.type,
          };

          axios.post('/api/video/uploadfiles', data).then((response) => {
            // console.log(response.data.promises);
            postAllData(response.data.promises, response.data.data._id);
          });
        });
      }}
    >
      { /* eslint-disable-next-line no-shadow */ }
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <Box>
            {thumbNailSrc && (
            <>
              {/* TODO: Add support for custom thumbnails */}
              <Flex justifyContent="center" mb="16px">
                <AspectRatio ratio={16 / 9} backgroundColor="#111" width={{ base: '280px', sm: '320px' }} height={{ base: 'auto', sm: 'auto' }}>
                  <Image alt="thumbnail" objectFit="cover" src={thumbNailSrc.preview} />
                </AspectRatio>
              </Flex>
            </>
            )}
            <Box onSubmit={handleSubmit}>
              <Field name="title">
                {() => (
                  <FormControl
                    isRequired
                    isInvalid={errors.title && touched.title}
                    mb="4px"
                  >
                    <FormLabel htmlFor="title" ml="4px" fontWeight="300">
                      Title
                    </FormLabel>
                    <br />
                    <Input
                      id="title"
                      placeholder="Give your video a title"
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width={{ base: '98%', md: '100%' }}
                      margin="auto"
                    />
                    <div className={classes.formErrorMessage}>
                      {touched.title && errors.title}
                    </div>
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {() => (
                  <FormControl mb="25px">
                    <FormLabel htmlFor="description" ml="4px" fontWeight="300">
                      Description
                    </FormLabel>
                    <br />
                    <Textarea
                      id="description"
                      placeholder="What is this video about?"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width={{ base: '98%', md: '100%' }}
                      margin="auto"
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="category">
                {() => (
                  <FormControl isRequired mb="25px">
                    <FormLabel htmlFor="category" ml="4px" fontWeight="300">
                      Category
                    </FormLabel>
                    <br />
                    <Select id="category" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                      {Category.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Field name="privacy">
                {() => (
                  <FormControl
                    isRequired
                    isInvalid={errors.privacy && touched.privacy}
                    mb="25px"
                  >
                    <FormLabel htmlFor="privacy" ml="4px" fontWeight="300">
                      Privacy
                    </FormLabel>
                    <br />
                    <Select id="privacy" onChange={handleChange} width={{ base: '98%', md: '100%' }} margin="auto">
                      {Privacy.map((item) => (
                        <option key={item} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                    <div className={classes.formErrorMessage}>
                      {touched.privacy && errors.privacy}
                    </div>
                  </FormControl>
                )}
              </Field>
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText="Publishing..."
                onClick={handleSubmit}
                display="block"
                borderRadius="24px"
                mt="16px"
                mb="3rem"
                mr="auto"
                ml="auto"
                width="200px"
                color="white"
                bg="#FF1A75"
                _hover={{
                  bg: '#FFE6EE',
                  color: '#FF1A75',
                }}
              >
                Publish
              </Button>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );

  const renderContent = () => {
    switch (status) {
      case UploadStatus.AWAITING_FILE:
        return renderDropzone();
      case UploadStatus.FILE_ACCEPTED:
        return renderDetailsForm();
      case UploadStatus.UPLOADING:
        return renderDetailsForm(filesToUpload);
      case UploadStatus.PROCESSING:
        return renderUploadProgressIndicator();
      case UploadStatus.PROCESSING_COMPLETE:
        return renderDetailsForm();
      default:
        return renderDetailsForm();
    }
  };

  return (
    <Flex className="app">
      <Heading fontWeight="400" fontSize="1.7rem" margin="2rem">Upload Video</Heading>
      {renderContent()}
    </Flex>
  );
}

UploadVideos.propTypes = propTypes;

export default withRouter(UploadVideos);
