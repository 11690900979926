import {
  GET_PROFILE,
} from '../_actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return { ...state, profileData: action.payload };
    default:
      return state;
  }
};
