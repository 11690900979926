/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  Box, Button, Flex, Image, Heading, Stack, Text, Badge,
} from '@chakra-ui/react';

export default function Hero({
  title,
  subtitle,
  image,
  ctaLink,
  ctaText,
  ...rest
}) {
  const useStyles = createUseStyles({
    highlightedText: {
      backgroundColor: '#FF5578',
      color: '#151b26',
    },
  });
  const classes = useStyles();
  return (

    <Flex
      align="center"
      justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
      {...rest}
    >
      <Box
        justifyContent="left"
        alignItems="left"
        margin="0 auto"
        textAlign="center"
      >
        <Heading as="h1" fontSize={{ base: '1.5rem', sm: '2rem', lg: '3rem' }}>
          What is Kloak?
        </Heading>
        <Box
          width="70%"
          textAlign="center"
          margin="0 auto"
        />
      </Box>
    </Flex>
  );
}
