/* eslint-disable linebreak-style */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Divider,
  Spinner,
  AspectRatio,
  Button,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Home } from '@styled-icons/boxicons-regular/Home';
import { Videos } from '@styled-icons/boxicons-solid/Videos';
import { FamilyRestroom } from '@styled-icons/material/FamilyRestroom';
import { Images } from '@styled-icons/bootstrap/Images';
import { Group2 } from '@styled-icons/remix-line/Group2';

import ProfileImg from '../../../../assets/images/profile.png';
import FamilyImg from '../../../../assets/images/family.png';

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const useStyles = createUseStyles({
  thumbnailContainer: {
  },
  durationLabel: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    color: '#FFFFFF',
    backgroundColor: 'rgb(0, 0, 0, 0.7)',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    margin: 0,
  },
});

function EmptyLibrary({
  history,
}) {
  const classes = useStyles();

  return (
    <Box>
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Image src={ProfileImg} width={{ base: '75%', md: '500px' }} />
        <Text
          fontSize="1.2em"
          fontWeight="600"
          marginY="1rem"
        >
          Start Adding Your Private Photos and Videos now
        </Text>
        <Button
          backgroundColor="#1050ff"
          color="#FFF"
          _hover={{ backgroundColor: '#1890ff' }}
          onClick={() => { history.push('/upload'); }}
        >
          Add Photos
        </Button>
      </Flex>
    </Box>
  );
}
EmptyLibrary.propTypes = propTypes;
export default withRouter(EmptyLibrary);
