/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import Payment from './sections/Payment';

function CheckoutPage() {
  const user = useSelector((state) => state.user);
  const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

  useEffect(() => {
    // console.log(user);
  }, user);

  return (
    <Box width="100%">
      <Box
        width={{
          base: '90%', md: '500px', lg: '700px', xl: '1000px',
        }}
        margin="auto"
      >
        <Elements stripe={stripePromise}>
          <Payment />
        </Elements>
      </Box>
    </Box>
  );
}

export default CheckoutPage;
